import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const HerbCategoryCards = ({ activities }) => {
  const navigate = useNavigate();

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const imagePath = (image) => `/img/explorer/herbs/${image}`;

  const handleCardClick = (activity) => {
    ReactGA.event({
      category: "Herb Navigation",
      action: "Click Herb Card",
      label: activity.title,
    });
    navigate("/view-herbs", {
      state: {
        title: activity.title,
        subcategories: activity.subcategories,
        description: activity.description,
      },
    });
  };

  return (
    <Row>
      {activities.slice(0, 6).map((activity, index) => (
        <Col key={index} md={2} sm={6}>
          <Card
            className="mb-4 shadow-sm explorer-card-hover-effect"
            style={{ cursor: "pointer", border: "none" }}
            onClick={() => handleCardClick(activity)}
            // onClick={() =>
            //   handleCardClick(activity.title, activity.subcategories)
            // }
          >
            <LazyLoadImage
              alt={activity.title}
              src={imagePath(activity.image)}
              effect="blur"
              style={{ height: "200px", width: "100%", borderRadius: "10px" }}
            />
            <Card.Body>
              <Card.Title>{activity.title}</Card.Title>
              <Card.Text>{truncateText(activity.description, 50)}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default HerbCategoryCards;
