// import React from "react";
// import { Container, Row, Col, Card } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import userIcon from "../../images/admin/personal.png";
// import practitionersIcon from "../../images/admin/medical-team-2.png";
// import herbsIcon from "../../images/admin/thyme.png";
// import blogIcon from "../../images/admin/blog-2.png";
// import recipesIcon from "../../images/admin/ingredient.png";
// import decryptIcon from "../../images/admin/settings.png";

// function AdminDashboard() {
//   const navigate = useNavigate();

//   const cardsInfo = [
//     { id: 1, text: "User Management", img: userIcon, route: "/users-info" },
//     {
//       id: 2,
//       text: "Practitioners Management",
//       img: practitionersIcon,
//       route: "/practitioners-info",
//     },
//     { id: 3, text: "Herbs Content", img: herbsIcon, route: "/herbs-info" },
//     {
//       id: 4,
//       text: "Recipes Content ",
//       img: recipesIcon,
//       route: "/recipes-info",
//     },
//     { id: 5, text: "Blog Content", img: blogIcon, route: "/blogs-info" },
//     { id: 6, text: "Decryptor ", img: decryptIcon, route: "/decryptor" },
//     { id: 6, text: "Convert to Array ", img: decryptIcon, route: "/to-array" },
//     {
//       id: 7,
//       text: "Unique Categories ",
//       img: decryptIcon,
//       route: "/unique-categories",
//     },
//   ];

//   const handleCardClick = (route) => {
//     navigate(route);
//   };

//   return (
//     <Container style={{ minHeight: "80vh", paddingTop: "100px" }}>
//       <Row>
//         {cardsInfo.map((card) => (
//           <Col key={card.id} md={6} className="mb-4">
//             <Card
//               onClick={() => handleCardClick(card.route)}
//               className="text-center"
//               style={{ cursor: "pointer" }}
//             >
//               <Card.Body>
//                 <Card.Img
//                   variant="top"
//                   src={card.img}
//                   style={{
//                     width: "100px",
//                     height: "100px",
//                     borderRadius: "50%",
//                   }}
//                   className="mx-auto d-block"
//                 />
//                 <Card.Title>{card.text}</Card.Title>
//               </Card.Body>
//             </Card>
//           </Col>
//         ))}
//       </Row>
//     </Container>
//   );
// }

// export default AdminDashboard;
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import userIcon from "../../images/admin/personal.png";
import practitionersIcon from "../../images/admin/medical-team-2.png";
import herbsIcon from "../../images/admin/thyme.png";
import blogIcon from "../../images/admin/blog-2.png";
import recipesIcon from "../../images/admin/ingredient.png";
import decryptIcon from "../../images/admin/settings.png";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Define the admin email and user ID as constants within the component
  const ADMIN_EMAIL = "maral.a@gmail.com";
  const ADMIN_USER_ID = "iXQCJwwDrga2neDjLKumXfG2KTE3";

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("Logged in user email:", user.email);
        console.log("Logged in user ID:", user.uid);
        console.log("Admin email from const:", ADMIN_EMAIL);
        console.log("Admin user ID from const:", ADMIN_USER_ID);

        if (user.email === ADMIN_EMAIL || user.uid === ADMIN_USER_ID) {
          setIsAuthorized(true);
        } else {
          setIsAuthorized(false);
          setShowModal(true); // Show modal if not authorized
        }
      } else {
        setIsAuthorized(false);
        setShowModal(true); // Show modal if not authorized
      }
    });
  }, [navigate, ADMIN_EMAIL, ADMIN_USER_ID]);

  const handleModalClose = () => {
    setShowModal(false);
    navigate("/"); // Redirect to home page
  };

  const cardsInfo = [
    { id: 1, text: "User Management", img: userIcon, route: "/users-info" },
    {
      id: 2,
      text: "Practitioners Management",
      img: practitionersIcon,
      route: "/practitioners-info",
    },
    { id: 3, text: "Herbs Content", img: herbsIcon, route: "/herbs-info" },
    {
      id: 4,
      text: "Recipes Content ",
      img: recipesIcon,
      route: "/recipes-info",
    },
    { id: 5, text: "Blog Content", img: blogIcon, route: "/blogs-info" },
    { id: 6, text: "Decryptor ", img: decryptIcon, route: "/decryptor" },
    { id: 7, text: "Convert to Array ", img: decryptIcon, route: "/to-array" },
    {
      id: 8,
      text: "Unique Categories ",
      img: decryptIcon,
      route: "/unique-categories",
    },
  ];

  const handleCardClick = (route) => {
    navigate(route);
  };

  if (!isAuthorized && !showModal) {
    return null; // Render nothing while checking auth
  }

  return (
    <Container style={{ minHeight: "80vh", paddingTop: "100px" }}>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Unauthorized Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>You do not have permission to view this page.</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {isAuthorized && (
        <Row>
          {cardsInfo.map((card) => (
            <Col key={card.id} md={6} className="mb-4">
              <Card
                onClick={() => handleCardClick(card.route)}
                className="text-center"
                style={{ cursor: "pointer" }}
              >
                <Card.Body>
                  <Card.Img
                    variant="top"
                    src={card.img}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                    }}
                    className="mx-auto d-block"
                  />
                  <Card.Title>{card.text}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default AdminDashboard;
