import React, { useState } from "react";
import {
  Container,
  ListGroup,
  Card,
  Button,
  Form,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../config/Firebase";
import { addDoc, collection, setDoc, doc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import "../../styles/General.css";
import "../../styles/Enrollment.css";
import ProsandCons from "../jobs/ProsandCons";
import Requirements from "../jobs/Requirements";

const jobTitles = [
  "Naturopathic Doctor",
  "Naturopathic Practitioner",
  "Herbalist",
  "Clinical Herbalist / Herbal Medicine Practitioner",
  "Registered Dietitian Nutritionist (RDN)",
  "Nutritionist",
  // Add other job titles as needed
];

const Careers = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [answers, setAnswers] = useState({
    appliedJobTitle: "",
    "current-time-spent": "",
    "administrative-time": "",
    "interest-reason": "",
    "intended-time-to-spend": "",
    "where-did-you-hear": "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const questions = [
    {
      question: "Where do you currently spend most of your time?",
      options: [
        "Other online platforms like Sârv",
        "Clinic or hospital",
        "Private practice",
        "Community mental health agency",
        "Teaching or counseling in an academic setting",
        "Other clinical setting",
        "Not currently practicing",
      ],
      field: "current-time-spent",
    },
    {
      question:
        "How much of your time is currently spent on administrative and/or billing related tasks?",
      options: [
        "Up to 10% of my time",
        "10 to 30% of my time",
        "More than 30% of my time",
      ],
      field: "administrative-time",
    },
    {
      question: "What makes you most interested in Sârv?",
      options: [
        "To build my own private practice",
        "To supplement my private practice",
        "To supplement my full-time job",
        "To supplement my part-time job",
      ],
      field: "interest-reason",
    },
    {
      question: "How much time do you intend to spend with Sârv clients?",
      options: [
        "Up to 5 hours a week",
        "5 to 10 hours a week",
        "10 to 20 hours a week",
        "20 to 30 hours a week",
        "More than 30 hours a week",
        "Not sure",
      ],
      field: "intended-time-to-spend",
    },
    {
      question: "How did you first hear about Sârv?",
      options: [
        "Email Outreach",
        "Friend or colleague",
        "Podcast",
        "Internet Research",
        "Other",
      ],
      field: "where-did-you-hear",
    },
  ];

  const handleOptionSelect = (field, value) => {
    setAnswers((prev) => ({ ...prev, [field]: value }));
    // Proceed to next step
    if (currentStep < questions.length) {
      setCurrentStep(currentStep + 1);
    } else {
      // Move to the final form step
      setCurrentStep(currentStep + 1);
    }
  };

  const validateForm = () => {
    setError(""); // Reset error message
    if (answers.password !== answers.confirmPassword) {
      setError("Passwords do not match!");
      return false;
    }
    if (answers.password.length < 8) {
      setError("Password must be at least 8 characters long.");
      return false;
    }
    const phoneRegex = /^[0-9]{10}$/; // Basic validation for a 10 digit phone number
    if (!phoneRegex.test(answers.phone)) {
      setError("Please enter a valid phone number.");
      return false;
    }
    return true;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAnswers((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return; // Stop the form submission if validation fails
    }

    // Add validation for password and confirmPassword here...
    if (answers.password !== answers.confirmPassword) {
      console.error("Passwords do not match!");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        answers.email,
        answers.password
      );
      const user = userCredential.user;
      console.log("User signed up in Firebase Auth");

      await setDoc(doc(db, "practitioners", user.uid), {
        ...answers,
        userId: user.uid, // Optionally store user ID
      });
      console.log("Data successfully added to Firestore");

      navigate("/welcome", { state: { email: answers.email } });
    } catch (error) {
      console.error("Error processing signup: ", error);
    }
  };

  if (currentStep >= 1) {
    // This conditionally renders the UI to only display the Card when a list item is selected
    return (
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        {/* Only render the Card for the current step */}
        {currentStep > 0 && currentStep <= questions.length && (
          <Card
            className="shadow no-border-card"
            style={{ height: "500px", width: "650px" }}
          >
            <Card.Body>
              <Card.Title className="dosha-card-title">
                {questions[currentStep - 1].question}
              </Card.Title>
              <Form>
                {questions[currentStep - 1].options.map((option, index) => (
                  <Form.Check
                    type="radio"
                    id={`radio-${index}`}
                    label={option}
                    name={questions[currentStep - 1].field}
                    value={option}
                    checked={
                      answers[questions[currentStep - 1].field] === option
                    }
                    onChange={() =>
                      handleOptionSelect(
                        questions[currentStep - 1].field,
                        option
                      )
                    }
                    key={index}
                  />
                ))}
              </Form>
            </Card.Body>
          </Card>
        )}
        {currentStep === questions.length + 1 && (
          <Card
            className="shadow no-border-card"
            style={{
              height: "600px",
              alignItems: "left",
              paddingTop: "50px",
              width: "850px",
            }}
          >
            <Card.Body>
              <Card.Title className="dosha-card-title">
                Complete Your Profile
              </Card.Title>
              {error && <Alert variant="danger">{error}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="firstName"
                        value={answers.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="lastName"
                        value={answers.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={answers.email}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="phone"
                        value={answers.phone}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="password"
                        value={answers.password}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Control
                        type="password"
                        name="confirmPassword"
                        value={answers.confirmPassword}
                        onChange={handleInputChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "40px", paddingLeft: "20px" }}>
                  <Button
                    variant="primary"
                    type="submit"
                    className="next-button mt-3"
                  >
                    Next
                  </Button>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        )}
        {/* Render the final Card for completing profile */}
        {/* Similar to the above condition but for the final step */}
      </Container>
    );
  }

  return (
    <div className="mission-container">
      <Container
        fluid
        className="d-flex flex-column align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          height: "auto",
          paddingTop: "100px",
        }}
      >
        <Row className="w-100" style={{ paddingBottom: "150px" }}>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="career-title" style={{ fontSize: "48px" }}>
              Join hundreds of practitioners who are earning more with Sârv!
            </div>
          </Col>
          <Col
            md={6}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <div
              style={{
                fontFamily: "Canela",
                fontSize: "20px",
                paddingBottom: "25px",
              }}
            >
              Select your license type to get started
            </div>
            {currentStep === 0 && (
              <ListGroup>
                {jobTitles.map((title, index) => (
                  <ListGroup.Item
                    action
                    key={index}
                    onClick={() => handleOptionSelect("appliedJobTitle", title)}
                    style={{ paddingTop: "15px", marginTop: "10px" }}
                  >
                    {title}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Col>
        </Row>

        <Row style={{ backgroundColor: "white", width: "100%" }}>
          <Col className="d-flex justify-content-center">
            <div style={{ maxWidth: "1000px" }}>
              <ProsandCons />
            </div>
          </Col>
        </Row>

        <Row className="w-100 my-3 justify-content-center">
          <Col>
            <Requirements />
          </Col>
        </Row>
        {/* ProsandCons and Requirements components are removed as they will not be displayed after an item selection */}
      </Container>
    </div>
  );
};

export default Careers;
