import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../config/Firebase"; // Adjust the import path as necessary
import {
  Card,
  Container,
  Row,
  Col,
  Image,
  Button,
  Form,
  Textarea,
} from "react-bootstrap";
import { MdEdit } from "react-icons/md";

const expertiseOptions = [
  "Nutritional counseling",
  "Digestive disorders",
  "Sleep disorders",
  "Cardiovascular health",
  "Stress management and mental health",
  "Allergies and immune system support",
  "Hormonal imbalances",
  "Chronic pain management",
  "Detoxification",
  "Skin conditions",
  "Pediatric health",
  "Men's health (including prostate health and sexual health)",
  "Women's health (including menstrual irregularities, menopause, and fertility issues)",
];

const dayOptions = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

// Assume generateTimeSlots is defined as shown previously or here for convenience
const generateTimeSlots = () => {
  const slots = [];
  for (let hour = 7; hour <= 21; hour++) {
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    const amPm = hour < 12 ? "AM" : "PM";
    slots.push(`${hour12}:00 ${amPm}`);
  }
  return slots;
};

const PractitionerProfile = ({ practitionerId }) => {
  const [practitioner, setPractitioner] = useState(null);
  const [editingBio, setEditingBio] = useState(false);
  const [editingExpertise, setEditingExpertise] = useState(false);
  const [newBio, setNewBio] = useState("");
  const [selectedExpertise, setSelectedExpertise] = useState([]);
  const [editingAvailability, setEditingAvailability] = useState(false);
  const timeSlots = generateTimeSlots();

  useEffect(() => {
    const fetchPractitionerData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, "practitioners", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setPractitioner(docSnap.data());
          setNewBio(docSnap.data().bio || "");
          setSelectedExpertise(docSnap.data().expertise || []);
        } else {
          console.log("No such document!");
        }
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchPractitionerData();
      }
    });
  }, []);

  const handleBioChange = (e) => {
    setNewBio(e.target.value);
  };

  const handleExpertiseChange = (e) => {
    const { name, checked } = e.target;
    if (checked && !selectedExpertise.includes(name)) {
      setSelectedExpertise((prev) => [...prev, name]);
    } else {
      setSelectedExpertise((prev) => prev.filter((item) => item !== name));
    }
  };

  const saveBio = async () => {
    if (auth.currentUser && newBio !== practitioner.bio) {
      const docRef = doc(db, "practitioners", auth.currentUser.uid);
      await updateDoc(docRef, { bio: newBio });
      setPractitioner((prev) => ({ ...prev, bio: newBio }));
      setEditingBio(false);
    }
  };

  const saveExpertise = async () => {
    if (auth.currentUser) {
      const docRef = doc(db, "practitioners", auth.currentUser.uid);
      await updateDoc(docRef, { expertise: selectedExpertise });
      setPractitioner((prev) => ({ ...prev, expertise: selectedExpertise }));
      setEditingExpertise(false);
    }
  };

  const handleAvailabilityChange = (day, timeSlot) => {
    setPractitioner((prevPractitioner) => {
      const updatedAvailability = prevPractitioner.availability[day]?.includes(
        timeSlot
      )
        ? prevPractitioner.availability[day].filter((slot) => slot !== timeSlot)
        : [...(prevPractitioner.availability[day] || []), timeSlot];

      return {
        ...prevPractitioner,
        availability: {
          ...prevPractitioner.availability,
          [day]: updatedAvailability,
        },
      };
    });
  };

  const saveAvailability = async () => {
    if (auth.currentUser && practitioner.availability) {
      const docRef = doc(db, "practitioners", auth.currentUser.uid);
      await updateDoc(docRef, { availability: practitioner.availability });
      setEditingAvailability(false);
    }
  };

  if (!practitioner) {
    return <div>Loading...</div>;
  }

  return (
    <Container style={{ marginTop: "150px" }}>
      <Card
        className="mt-3"
        style={{ marginTop: "100px", marginBottom: "100px" }}
      >
        <Card.Body>
          <Row className="mb-3">
            <Col className="text-center">
              <Image
                src={practitioner.imgUrl}
                roundedCircle
                style={{ width: "100px", height: "100px" }}
              />
            </Col>
          </Row>
          <Row className="mb-3 text-center">
            <Col>
              <h2>
                {practitioner.firstName} {practitioner.lastName} -{" "}
                {practitioner.jobTitle
                  ? practitioner.jobTitle
                  : practitioner.appliedJobTitle}
              </h2>
            </Col>
          </Row>
          <hr />
          <Row className="mb-3">
            <Col>
              <h5>About me</h5>
              {!editingBio ? (
                <>
                  <p>{practitioner.bio}</p>

                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => setEditingBio(true)}
                  >
                    Edit
                  </Button>
                </>
              ) : (
                <>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={newBio}
                    onChange={handleBioChange}
                  />
                  <Button variant="primary" size="sm" onClick={saveBio}>
                    Save
                  </Button>
                </>
              )}
            </Col>
          </Row>
          <hr />
          <Row className="mb-3">
            <Col>
              <h5>Specialties</h5>
              {!editingExpertise ? (
                <>
                  <p>{practitioner.expertise.join(", ")}</p>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => setEditingExpertise(true)}
                  >
                    Edit
                  </Button>
                </>
              ) : (
                <Form>
                  {expertiseOptions.map((option, index) => (
                    <Form.Check
                      key={index}
                      type="checkbox"
                      label={option}
                      name={option}
                      onChange={handleExpertiseChange}
                      checked={selectedExpertise.includes(option)}
                    />
                  ))}
                  <Button variant="primary" size="sm" onClick={saveExpertise}>
                    Save
                  </Button>
                </Form>
              )}
            </Col>
          </Row>
          <hr />
          <Row className="mb-3">
            <Col>
              <h5>Video Visit Hours</h5>
              {Object.entries(practitioner.availability || {}).map(
                ([day, slots], index) => (
                  <div key={index}>
                    <strong>{day}:</strong> {slots.join(", ")}
                  </div>
                )
              )}

              {!editingAvailability ? (
                <div style={{ marginTop: "25px" }}>
                  <Button
                    size="sm"
                    onClick={() => setEditingAvailability(true)}
                    variant="outline-primary"
                  >
                    Edit
                  </Button>
                </div>
              ) : (
                <>
                  {dayOptions.map((day) => (
                    <Card key={day} className="mb-3">
                      <Card.Body>
                        <Card.Title>{day}</Card.Title>
                        {timeSlots.map((time, index) => (
                          <Form.Check
                            inline
                            key={index}
                            label={time}
                            type="checkbox"
                            checked={practitioner.availability[day]?.includes(
                              time
                            )}
                            onChange={() => handleAvailabilityChange(day, time)}
                          />
                        ))}
                      </Card.Body>
                    </Card>
                  ))}
                  <Button onClick={saveAvailability}>Save Availability</Button>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default PractitionerProfile;
