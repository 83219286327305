import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import RecipesCategories from "./recipes-categories/RecipesCategories";
import ViewAll from "../utils/ViewAll";
import HerbCategoryCards from "./herb-categories/HerbCategoryCards";
import BiologicalActivity from "./herb-categories/data/BiologicalActivity";
import TherapeuticUse from "./herb-categories/data/TherapeuticUse";
import FunctionalActions from "./herb-categories/data/FunctionalActions";
import RecommendedHerbs from "./recommedations/RecommendedHerbs";
import PersonalizedRecipes from "./recommedations/PersonalizedRecipes";
import SymptomChecker from "../symptom-checker/SymptomChecker";

const Dashboard = () => {
  return (
    <Container
      fluid
      style={{ minWidth: "1200px", margin: "0 auto", padding: "50px" }}
    >
      <Row className="mt-3" style={{ paddingTop: "50px" }}>
        <Col>
          <h4
            style={{
              fontSize: "24px",
              color: "rgb(22, 26, 58)",
              fontFamily: "Agrandir",
            }}
          >
            Get Personalized Remedy for Your Symptoms
          </h4>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {" "}
          <SymptomChecker />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {" "}
          <RecommendedHerbs />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          {" "}
          <PersonalizedRecipes />
        </Col>
      </Row>
      <Row className="mt-3">
        <ViewAll
          title="Herb Categories by Therapeutic Use"
          navigateTo="/herbs/all"
          state={{ activities: TherapeuticUse }}
        />
        <Col>
          {" "}
          <HerbCategoryCards activities={TherapeuticUse} />
        </Col>
      </Row>
      <Row className="mt-3">
        <ViewAll title="Herbal Recipes" navigateTo="/recipes/all" />
        <Col>
          <RecipesCategories />
        </Col>
      </Row>
      <Row className="mt-3">
        <ViewAll
          title="Herb Categories by Biological Activity"
          navigateTo="/herbs/all"
          state={{ activities: BiologicalActivity }}
        />
        <Col>
          <HerbCategoryCards activities={BiologicalActivity} />
          {/* <HerbsByBiologicalActivity /> */}
        </Col>
      </Row>
      <Row className="mt-3">
        <ViewAll
          title="Herb Categories by Functional Actions"
          navigateTo="/herbs/all"
          state={{ activities: FunctionalActions }}
        />
        <Col>
          {" "}
          <HerbCategoryCards activities={FunctionalActions} />
        </Col>
      </Row>
      {/* Additional rows and columns can be added here for more components */}
    </Container>
  );
};

export default Dashboard;
