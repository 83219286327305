import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import { db } from "../../../config/Firebase"; // Ensure the path matches your project structure
import { collection, query, where, getDocs } from "firebase/firestore";
import ReactGA from "react-ga4";

const Recipes = () => {
  const { categoryName } = useParams();
  const [recipes, setRecipes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Encoded category name from URL:", categoryName);
    const decodedCategory = decodeURIComponent(categoryName);
    console.log("Decoded category name:", decodedCategory);
    fetchRecipes(decodedCategory);
  }, [categoryName]);

  const fetchRecipes = async (categoryName) => {
    console.log("Fetching recipes for category:", categoryName);
    const recipesRef = collection(db, "recipes");
    const q = query(
      recipesRef,
      where("category", "array-contains", categoryName)
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      console.log("No recipes found for this category.");
      ReactGA.event({
        category: "Recipe Fetching",
        action: "No Recipes Found",
        label: categoryName,
      });
      return;
    }
    const fetchedRecipes = [];
    querySnapshot.forEach((doc) => {
      console.log(doc.id, doc.data());
      fetchedRecipes.push({ id: doc.id, ...doc.data() });
    });
    setRecipes(fetchedRecipes);
    ReactGA.event({
      category: "Recipe Fetching",
      action: "Fetched Recipes",
      label: categoryName,
    });
  };

  const handleCardClick = (recipeId) => {
    navigate(`/recipe-details/${recipeId}`);
    ReactGA.event({
      category: "Recipe Interaction",
      action: "View Recipe Details",
      label: `Recipe ID: ${recipeId}`,
    });
  };

  if (!recipes.length) {
    return <div>Loading recipes...</div>;
  }

  return (
    <Container>
      <Row
        className="mb-3 justify-content-center"
        style={{
          alignContent: "center",
          alignItems: "center",
          paddingTop: "100px",
          paddingBottom: "50px",
        }}
      >
        <Col>
          <h1 className="article-categoories-title text-center">
            {categoryName || "Articles"}
          </h1>
        </Col>
      </Row>
      <Row>
        {recipes.map((recipe) => (
          <Col key={recipe.id} lg={3} md={4} sm={6} className="mb-4">
            <Card
              className="mb-4 shadow-sm explorer-card-hover-effect"
              style={{ cursor: "pointer", border: "none" }}
              onClick={() => handleCardClick(recipe.id)}
            >
              <Card.Img
                variant="top"
                src={recipe.imgUrl || "/path/to/default/image.jpg"}
                style={{ height: "250px", width: "100%" }}
              />
              <Card.Body>
                <Card.Title>{recipe.title}</Card.Title>
                <Card.Text>{recipe.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Recipes;
