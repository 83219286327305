import React, { useState, useEffect, useContext } from "react";
import { db, auth } from "../../config/Firebase";
import { useNavigate } from "react-router-dom";
import { setDoc, doc } from "firebase/firestore";
import { Container, ListGroup, Button } from "react-bootstrap";
import "../../styles/General.css";
import "../../styles/Enrollment.css";
// import ProgressBar from "../utils/ProgressBar";
import MultipleBars from "../utils/MultipleBars";

const Age = () => {
  const [selectedAge, setSelectedAge] = useState("");
  const ageRanges = [
    "Under 20s",
    "30s",
    "40s",
    "50s",
    "60s",
    "70s",
    "80s",
    "90s",
  ];
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      }
    });

    return unsubscribe;
  }, []);

  const handleSelection = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    try {
      const userDocRef = doc(db, "users", userId);
      await setDoc(userDocRef, { ageRange: selectedAge }, { merge: true });
      navigate("/gender");
      // navigate("/how-you-heard-about-us");
    } catch (error) {
      console.error("Error adding/updating age range in Firebase:", error);
    }
  };

  return (
    <div className="progress-container">
      <MultipleBars
        progresses={[
          { progress: 2, maxProgress: 100, bgClass: "" }, // For the first category
          { progress: 0, maxProgress: 100, bgClass: "bg-success" }, // For the second category
          { progress: 0, maxProgress: 100, bgClass: "bg-info" }, // For the third category
        ]}
      />

      <Container className="d-flex align-items-center justify-content-center">
        <div
          className="w-100"
          style={{
            maxWidth: "600px",
            paddingTop: "60px",
            paddingBottom: "20px",
          }}
        >
          <h3 className="header-text">What is your age range?</h3>
          <ListGroup>
            {ageRanges.map((age, index) => (
              <ListGroup.Item
                key={index}
                action
                active={age === selectedAge}
                onClick={() => setSelectedAge(age)}
                style={{ marginBottom: "15px" }}
              >
                {age}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Button
            onClick={handleSelection}
            disabled={!selectedAge}
            className="next-button mt-3"
          >
            Next
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Age;
