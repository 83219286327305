import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Alert,
} from "react-bootstrap";
import { db } from "../../config/Firebase";
import "../../styles/General.css";
import { collection, addDoc } from "firebase/firestore";
import { Link } from "react-router-dom";

const Contact = () => {
  const [selectedReason, setSelectedReason] = useState("");
  const [senderFullName, setSenderFullName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const validateForm = () => {
    if (!selectedReason) {
      setError("Please select a reason for contacting us.");
      return false;
    }
    if (!senderFullName) {
      setError("Please enter your full name.");
      return false;
    }
    if (!senderEmail || !/\S+@\S+\.\S+/.test(senderEmail)) {
      setError("Please enter a valid email address.");
      return false;
    }
    if (!message) {
      setError("Please enter a message.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setError(""); // Reset error message

    try {
      await addDoc(collection(db, "contacts"), {
        contactReason: selectedReason,
        senderFullName: senderFullName,
        senderEmail: senderEmail,
        message: message,
      });
      setSelectedReason("");
      setSenderFullName("");
      setSenderEmail("");
      setMessage("");
    } catch (error) {
      setError("Failed to submit the form. Please try again.");
    }
  };

  return (
    <Container
      className="contact-form-background d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "900px" }}>
        <p
          className="text-center"
          style={{ fontSize: "36px", fontWeight: "bold", paddingTop: "100px" }}
        >
          Contact us
        </p>
        <p style={{ fontSize: "20px", paddingTop: "30px" }}>
          Use this form to reach out to our Customer Success Team regarding any
          questions, concerns, or feedback. You can also view our{" "}
          <Link to="/faq">FAQ</Link> for quick answers to commonly asked
          questions.
        </p>{" "}
        {/* Contact Form */}
        <Card style={{ border: "none" }}>
          <Card.Body>
            <Form onSubmit={handleSubmit}>
              {/* Radio Buttons for Reason */}
              <Form.Group style={{ paddingTop: "50px" }}>
                <Form.Label style={{ fontSize: "20px", paddingBottom: "25px" }}>
                  Reason for contacting:
                </Form.Label>
                {[
                  "I am a registered client and I need support.",
                  "I am a current Sarv practitioner and I need support.",
                  "I am a practitioner interested in joining or a current applicant.",
                  "I have a question about the service.",
                  "I have a billing related question.",
                  "I have a press related question.",
                  "I have a business related inquiry.",
                  "I am interested in Sarv for my organization.",
                ].map((reason, idx) => (
                  <Form.Check
                    key={idx}
                    type="radio"
                    label={reason}
                    name="contactReason"
                    value={reason}
                    checked={selectedReason === reason}
                    onChange={(e) => setSelectedReason(e.target.value)}
                    className="mb-2"
                  />
                ))}
              </Form.Group>

              {/* Validation Error Alert */}

              {/* Full Name Field */}
              <Form.Group
                controlId="formFullName"
                style={{ paddingTop: "50px" }}
              >
                <Form.Control
                  type="text"
                  placeholder="Full name"
                  style={{ marginBottom: "15px" }}
                  value={senderFullName}
                  onChange={(e) => setSenderFullName(e.target.value)}
                />
              </Form.Group>

              {/* Email Field */}
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  style={{ marginBottom: "20px" }}
                  value={senderEmail}
                  onChange={(e) => setSenderEmail(e.target.value)}
                />
              </Form.Group>

              {/* Message Box */}
              <Form.Group controlId="formMessage">
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Your message"
                  style={{ marginBottom: "10px", height: "250px" }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </Form.Group>
              {error && <Alert variant="danger">{error}</Alert>}
              {/* Submit Button */}
              <div
                className="d-flex justify-content-center"
                style={{ paddingBottom: "100px", paddingTop: "30px" }}
              >
                <Button
                  variant="primary"
                  type="submit"
                  className="text-center custom-subscribe-btn"
                  // className="w-100 mt-4"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default Contact;
