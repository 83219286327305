import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../../config/Firebase";
import { getDoc, doc } from "firebase/firestore";
import seedrandom from "seedrandom";
import ReactGA from "react-ga4";

const RecommendedHerbs = () => {
  const [herbs, setHerbs] = useState(() => {
    const savedData = localStorage.getItem("herbsData");
    ReactGA.event({
      category: "Herbs",
      action: "Load from Storage",
      label: savedData ? "Loaded" : "Not Found",
    });
    return savedData ? JSON.parse(savedData).herbs : [];
  });

  const [userName, setUserName] = useState(() => {
    return localStorage.getItem("userName") || "";
  });
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        fetchUserDetails(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      loadRecommendedHerbs(userId);
    }
  }, [userId]);

  const fetchUserDetails = async (uid) => {
    try {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists() && userDoc.data().firstName) {
        setUserName(userDoc.data().firstName);
        localStorage.setItem("userName", userDoc.data().firstName); // Save to localStorage
      } else {
        const practitionerDocRef = doc(db, "practitioners", uid);
        const practitionerDoc = await getDoc(practitionerDocRef);
        if (practitionerDoc.exists() && practitionerDoc.data().firstName) {
          setUserName(practitionerDoc.data().firstName);
          localStorage.setItem("userName", practitionerDoc.data().firstName); // Save to localStorage
        } else {
          console.log(
            "User name not found in both 'users' and 'practitioners' collections."
          );
        }
      }
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  };

  const fetchHerbsDetails = async (herbIds) => {
    try {
      const promises = herbIds.map((id) => getDoc(doc(db, "herbs", id)));
      const herbDocs = await Promise.all(promises);
      return herbDocs
        .map((doc) => (doc.exists() ? { id: doc.id, ...doc.data() } : null))
        .filter(Boolean);
    } catch (error) {
      console.error("Failed to fetch herb details:", error);
    }
  };

  const loadRecommendedHerbs = async (uid) => {
    const currentWeek = getWeekNumber(new Date());
    const savedData = localStorage.getItem("herbsData");
    const { week, herbs: savedHerbs } = savedData ? JSON.parse(savedData) : {};

    if (week === currentWeek && savedHerbs) {
      setHerbs(savedHerbs);
      return; // Use cached data if week hasn't changed
    }

    try {
      const recHerbsDoc = await getDoc(doc(db, "recommended-herbs", uid));
      if (recHerbsDoc.exists()) {
        const herbIds = recHerbsDoc.data().herb_ids;
        const randomHerbIds = selectHerbsWeekly(herbIds, currentWeek);
        const herbsDetails = await fetchHerbsDetails(randomHerbIds);
        setHerbs(herbsDetails);
        // Save the fetched data along with the current week to localStorage
        localStorage.setItem(
          "herbsData",
          JSON.stringify({ week: currentWeek, herbs: herbsDetails })
        );
        ReactGA.event({
          category: "Herbs",
          action: "Load Recommended Herbs",
          label: "Herbs Loaded",
        });
      }
    } catch (error) {
      console.error("Failed to fetch recommended herbs:", error);
      ReactGA.event({
        category: "Error",
        action: "Failed to Fetch Recommended Herbs",
        label: error.message,
      });
    }
  };

  const getWeekNumber = (date) => {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDays = (date - startOfYear) / 86400000;
    return Math.ceil((pastDays + startOfYear.getDay() + 1) / 7);
  };

  const selectHerbsWeekly = (herbIds, weekNumber) => {
    const rng = seedrandom(weekNumber);
    return herbIds.sort((a, b) => rng() - 0.5).slice(0, 6);
  };

  const handleCardClick = (id) => {
    navigate(`/herbs-details/${id}`);
    ReactGA.event({
      category: "Herbs",
      action: "Herb Clicked",
      label: id,
    });
  };

  return (
    <>
      <Row>
        <Col>
          <h4
            style={{
              fontSize: "24px",
              color: "rgb(22, 26, 58)",
              fontFamily: "Agrandir",
              paddingBottom: "15px",
            }}
          >
            Recommended Herbs for {userName}
          </h4>
        </Col>
      </Row>
      <Row style={{ paddingBottom: "50px" }}>
        {herbs.map((herb, index) => (
          <Col key={index} md={2} sm={6}>
            <Card
              className="mb-4 shadow-sm explorer-card-hover-effect"
              style={{ cursor: "pointer", border: "none" }}
              onClick={() => handleCardClick(herb.id)}
            >
              <Card.Img
                variant="top"
                src={herb.imgUrl}
                style={{ height: "200px", objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>{herb.title}</Card.Title>
                <Card.Text>
                  {herb.summary.length > 50
                    ? `${herb.summary.substring(0, 50)}...`
                    : herb.summary}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default RecommendedHerbs;
