import React, { useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../../config/Firebase"; // Adjust the import path according to your Firebase config file
import { doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const Expertise = () => {
  const navigate = useNavigate();
  const [selectedExpertise, setSelectedExpertise] = useState([]);
  const [userId, setUserId] = useState(null);

  const expertiseOptions = [
    "Nutritional counseling",
    "Digestive disorders",
    "Sleep disorders",
    "Cardiovascular health",
    "Stress management and mental health",
    "Allergies and immune system support",
    "Hormonal imbalances",
    "Chronic pain management",
    "Detoxification",
    "Skin conditions",
    "Pediatric health",
    "Men's health (including prostate health and sexual health)",
    "Women's health (including menstrual irregularities, menopause, and fertility issues)",
  ];

  // Firebase Auth: Check for authenticated user
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserId(user.uid);
    }
  });

  const handleCheckboxChange = (e) => {
    const value = e.target.name;
    if (e.target.checked) {
      setSelectedExpertise([...selectedExpertise, value]);
    } else {
      setSelectedExpertise(selectedExpertise.filter((item) => item !== value));
    }
  };

  const handleSubmit = async () => {
    if (selectedExpertise.length > 0 && selectedExpertise.length <= 5) {
      try {
        await updateDoc(doc(db, "practitioners", userId), {
          expertise: selectedExpertise,
        });
        navigate("/background"); // Navigate to the next page
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    } else {
      alert("Please select up to 5 areas of expertise.");
    }
  };

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ minHeight: "100vh", minWidth: "600px" }}
    >
      <h2 className="text-center mb-4 career-h2">Add your expertise</h2>
      <Card
        className="shadow no-border-card w-100"
        style={{ maxWidth: "750px" }}
      >
        <Card.Body>
          {/* <Card.Title className="career-title">Areas of expertise</Card.Title> */}
          <Card.Text className="career-text">
            Add up to 5 areas of expertise that you'd like to offer to clients.
          </Card.Text>
          <Form>
            {expertiseOptions.map((option, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                label={option}
                name={option}
                onChange={handleCheckboxChange}
                className={index % 2 === 0 ? "mb-2" : "mb-2"}
              />
            ))}
            <div className="d-flex justify-content-center mt-4">
              <Button onClick={handleSubmit} className="practitioners-button">
                Next
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Expertise;
