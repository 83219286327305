import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, Badge } from "react-bootstrap";
import { useSubscription } from "./SubscriptionContext";
import { auth, db } from "../../config/Firebase";
import { doc, getDoc } from "firebase/firestore";
import "../../styles/Enrollment.css";
import "../../styles/General.css";
import ReactGA from "react-ga4";

const SubscriptionOptions = () => {
  const navigate = useNavigate();
  const [healthGoal, setHealthGoal] = useState("");

  useEffect(() => {
    const fetchHealthGoal = async () => {
      const user = auth.currentUser; // Get the currently authenticated user
      if (user) {
        const uid = user.uid; // User ID of the authenticated user
        const docRef = doc(db, "users", uid); // Reference to the user document in Firestore
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // console.log("Document data:", docSnap.data());
          setHealthGoal(docSnap.data().healthGoal || ""); // Set health goal if available
        } else {
          setHealthGoal(""); // Set empty string if document does not exist
        }
      } else {
        console.log("No user is signed in");
      }
    };

    fetchHealthGoal();
  }, []);

  const subscriptions = [
    {
      title: "12-Month Membership",
      rate: 10,
      total: 120,
      comment: "SAVE 33% vs monthly",
      upfrontCost: "Pay $120 when program starts",
      buttonText: "Get 12 Months",
      value: " Best value",
      period: 12,
    },
    {
      title: "4-Month Membership",
      rate: 12.5, // Updated to numeric value
      total: 50,
      comment: "SAVE 16% vs monthly",
      upfrontCost: "Pay $50 when program starts",
      buttonText: "Get 4 Months",
      value: "Most popular",
      period: 4,
    },
    {
      title: "1-Month Membership",
      rate: 15, // Updated to numeric value
      total: 15,
      comment: "Cancel or upgrade anytime",
      upfrontCost: "Pay $15 when program starts",
      buttonText: "Get Monthly",
      value: " ",
      period: 1,
    },
  ];

  const {
    updateRate,
    updateTitle,
    updateTotal,
    updatePeriod,
    updateIsSubscription,
    updatePriceId,
  } = useSubscription();

  async function fetchPriceIdForPeriod(period) {
    try {
      const response = await fetch(
        `https://api.joinsarv.com/get-price-id/${period}` // digital ocean ipv4
        // `http://159.89.92.213:5255/get-price-id/${period}` // old digital ocean ipv4 way
        // `http://34.16.52.127:5255/get-price-id/${period}`
      );
      if (!response.ok) {
        throw new Error(`Error fetching price ID: ${response.statusText}`);
      }
      const data = await response.json();
      return data.priceId;
    } catch (error) {
      console.error("Failed to fetch price ID:", error);
      throw error; // Rethrow the error so you can handle it in the calling context
    }
  }

  const handleSelectSubscription = async (subscription) => {
    ReactGA.event({
      category: "Subscription",
      action: "Select Subscription",
      label: subscription.title,
    });

    updateRate(subscription.rate);
    updateTitle(subscription.title);
    updateTotal(subscription.total);
    updatePeriod(subscription.period);
    updateIsSubscription(true); // Set as subscription

    try {
      const priceId = await fetchPriceIdForPeriod(subscription.period);
      updatePriceId(priceId);
      navigate(`/order`, { state: { priceId } });
    } catch (error) {
      console.error("Error handling subscription selection:", error);
    }
  };

  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: "auto" }}
    >
      <Row className="text-center subscription-options-title">
        <Col>Your personalized program is ready!</Col>
      </Row>
      <Row className="text-center">
        <Col>
          <span>Improve long-term health</span>
        </Col>
      </Row>
      <Row className="text-center subscription-options-text">
        <Col>
          <span>{healthGoal}</span>
          {/* <span>Feel more energetic</span> */}
        </Col>
      </Row>

      {subscriptions.map((subscription, index) => (
        <Row key={index} className="mb-4">
          <Col>
            <div className="card-badge-container">
              {" "}
              {/* Container for relative positioning */}
              {subscription.value.trim() && (
                <Badge variant="success" className="card-badge">
                  {subscription.value}
                </Badge> // The badge with absolute positioning
              )}
              <Card className="subscription-options-card">
                <Card.Body>
                  <Row className="mb-3 row-separator">
                    <Col md={8}>
                      <h5 className="subscription-options-card-title">
                        {subscription.title}
                      </h5>
                    </Col>
                    <Col md={4} className="text-md-right">
                      <h5 className="subscription-options-card-title">
                        ${subscription.rate}/per month
                      </h5>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <p>{subscription.upfrontCost}</p>
                      <p className="subscription-options-p">
                        {subscription.comment}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        variant="primary"
                        className="subscription-optionse-btn"
                        onClick={() => handleSelectSubscription(subscription)}
                      >
                        {subscription.buttonText}
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      ))}
      <Row style={{ width: "550px", paddingBottom: "30px" }}>
        <Col>
          <p className="cancellation-p">Auto-renewal and cancellation</p>
          <p className="cancellation-second-p">
            You will have a 7-day free trial. Sârv will automatically bill your
            card at the end of each period so your membership renews. No refunds
            or credits for partial months.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default SubscriptionOptions;
