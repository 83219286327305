import { useLocation, useParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { doc, getDoc, addDoc, collection, updateDoc } from "firebase/firestore";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import { db, auth } from "../../config/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import moment from "moment";
import "../../styles/Buttons.css";

const Rescheduling = () => {
  const location = useLocation();
  const { practitionerId } = useParams();
  const { selectedDate, selectedTime, oldAppointmentId } = location.state || {};
  const [practitioner, setPractitioner] = useState(null);
  const state = location.state || {};
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  //   console.log("Received state in MembersScheduleAppointments:", state);
  //   console.log("^^^^^^^^^^^^^^^^ selectedDate in rescheduling", selectedDate);
  //   console.log("^^^^^^^^^^^^^^^^ selectedTime in rescheduling", selectedTime);
  //   console.log(
  //     "^^^^^^^^^^^^^^^^ Old appointment id in rescheduling",
  //     oldAppointmentId
  //   );

  useEffect(() => {
    const fetchPractitionerData = async () => {
      const docRef = doc(db, "practitioners", practitionerId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPractitioner(docSnap.data());
      }
    };
    fetchPractitionerData();
  }, [practitionerId]);

  if (!practitioner) {
    return <div>Loading practitioner details...</div>;
  }

  const handleConfirm = async () => {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (user) {
      await scheduleAppointment(
        practitionerId,
        user.uid,
        selectedDate,
        selectedTime,
        userTimezone
      );

      if (oldAppointmentId) {
        await cancelOldAppointment(oldAppointmentId);
      }

      navigate("/");
    } else {
      console.error("No user logged in");
    }
  };

  const formattedSelectedDate = selectedDate ? selectedDate.toString() : "";
  const formattedSelectedTime = selectedTime ? selectedTime.toString() : "";

  return (
    <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <Row>
        <h4 style={{ paddingBottom: "25px" }}>
          Rescheduling appointment with:
        </h4>
        {/* <p></p> */}
        <Col md={6}>
          <Card style={{ width: "600px" }}>
            {/* Practitioner Details */}

            <Row className="g-0">
              <Col md={4}>
                <Image
                  src={practitioner.imgUrl}
                  alt="Practitioner"
                  fluid
                  style={{ width: "200px", height: "200px" }}
                />
              </Col>
              <Col md={8}>
                <Card.Body>
                  <Card.Title>
                    {practitioner.firstName} {practitioner.lastName}
                  </Card.Title>
                  <Card.Text>
                    {" "}
                    {practitioner.jobTitle
                      ? practitioner.jobTitle
                      : practitioner.appliedJobTitle}
                  </Card.Text>
                  <Card.Text>
                    {formattedSelectedDate} at {formattedSelectedTime}
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card>
          <Row
            className="justify-content-center"
            style={{ paddingTop: "50px" }}
          >
            <Col md={9} className="d-flex justify-content-center">
              <Button
                onClick={handleConfirm}
                className="custom-services-btn"
                style={{ width: "100%", borderRadius: "5px" }}
              >
                Confirm Reschedule
              </Button>
            </Col>
          </Row>

          <Row
            style={{
              margin: "15px",
              fontSize: "14px",
              paddingTop: "25px",
              paddingLeft: "0px",
              width: "550px",
            }}
          >
            <p className="cancellation-p">
              Cancellation and Rescheduling Policy{" "}
            </p>
            <p className="cancellation-second-p">
              <b>Cancellations:</b> {"  "}
              Patients may cancel their appointments without any charge up to 48
              hours before the scheduled appointment time. Cancellations made
              less than 48 hours before the scheduled appointment time will
              incur a 50% consultation fee. To cancel an appointment, please use
              our app's cancellation feature or contact our support team.
            </p>
            <p className="cancellation-second-p">
              {" "}
              <b>Rescheduling:</b> {"  "}Patients are encouraged to reschedule
              appointments instead of canceling them. You can reschedule your
              appointment for another available time slot up to 24 hours before
              the scheduled appointment time at no additional cost. Rescheduling
              within 24 hours of the appointment time is subject to provider
              availability and may incur a fee equivalent to 25% of the
              consultation fee.
            </p>
            <p className="cancellation-second-p">
              {" "}
              For more information about refound, missed appointments: and late
              arrival please view our temrs and services.
            </p>
          </Row>
        </Col>
        <Col md={6}></Col>
      </Row>
    </Container>
  );
};

export default Rescheduling;

const scheduleAppointment = async (
  practitionerId,
  userId,
  selectedDate,
  selectedTime,
  userTimezone
) => {
  try {
    // Correctly parse the verbal date and time format before formatting
    const reformattedDate = moment(selectedDate, "dddd, MMMM D, YYYY").format(
      "YYYY-MM-DD"
    );
    const reformattedTime = moment(selectedTime, "h:mm A").format("HH:mm");

    // Create a moment object for the appointment time in the local timezone
    const appointmentLocalTime = moment.tz(
      `${reformattedDate} ${reformattedTime}`,
      "YYYY-MM-DD HH:mm",
      userTimezone
    );

    // Validate the date and time
    if (!appointmentLocalTime.isValid()) {
      throw new Error("Invalid date/time value provided.");
    }

    // Convert that local time to UTC and get a JavaScript Date object
    const appointmentTimeUTC = appointmentLocalTime.utc().toDate();

    // Reference to the 'appointments' collection in Firestore
    const appointmentsRef = collection(db, "appointments");
    const docRef = await addDoc(appointmentsRef, {
      practitionerId,
      userId,
      selectedDate: selectedDate,
      selectedTime: selectedTime,
      status: "rescheduled",
      timeZone: userTimezone,
      appointmentTimeUTC,
      clientRequestDate: moment.utc().toDate(),
    });

    // console.log("Appointment rescheduled with ID:", docRef.id);
    alert("Appointment successfully rescheduled.");
  } catch (error) {
    console.error("Error rescheduling appointment:", error);
    alert(
      "Failed to reschedule the appointment. Please check the date/time format and try again."
    );
  }
};

const cancelOldAppointment = async (oldAppointmentId) => {
  if (!oldAppointmentId) return;

  try {
    const oldAppointmentRef = doc(db, "appointments", oldAppointmentId);
    await updateDoc(oldAppointmentRef, {
      status: "cancelled",
    });
    // console.log("Old appointment cancelled successfully.");
  } catch (e) {
    console.error("Error cancelling old appointment: ", e);
    throw new Error("Failed to cancel old appointment");
  }
};
