import React, { useState } from "react";
import { Card, Button, Form, Container } from "react-bootstrap";
import { storage, db } from "../../../../config/Firebase"; // Adjust this import to your Firebase config file
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const HerbPicture = ({ herbId }) => {
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }

    const fileRef = ref(storage, `herbs/${herbId}`);
    try {
      // Upload the file to Firebase Storage
      const uploadResult = await uploadBytes(fileRef, file);

      // Get the URL of the uploaded file
      const imgUrl = await getDownloadURL(uploadResult.ref);

      // Update the Firestore document
      const herbDocRef = doc(db, "herbs", herbId);
      await updateDoc(herbDocRef, { imgUrl });

      alert("Image uploaded and URL updated successfully!");
      navigate("/herbs-info");
    } catch (error) {
      console.error("Error uploading file: ", error);
      alert("Error uploading file!");
    }
  };

  return (
    <Container className="mt-3">
      <Card>
        <Card.Body>
          <Card.Title>Upload Herb Image</Card.Title>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Control type="file" onChange={handleFileChange} />
          </Form.Group>
          <Button onClick={handleUpload}>Upload</Button>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default HerbPicture;
