import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/General.css";
import "../../styles/Article.css";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PopupSignUp from "../login/PopupSignUp";
import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
} from "firebase/auth";
import { doc, setDoc, getFirestore } from "firebase/firestore";
import ReactGA from "react-ga4";

const Options = () => {
  const navigate = useNavigate();
  const [showSignUp, setShowSignUp] = useState(false);
  const [userId, setUserId] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && selectedService) {
        updateUserServiceSelection(user.uid, selectedService);
      }
    });

    return () => unsubscribe();
  }, [selectedService]);

  const handleCardClick = (serviceType) => () => {
    const auth = getAuth();

    ReactGA.event({
      category: "Services",
      action: "Clicked Options Card",
      label: serviceType,
    });

    if (auth.currentUser) {
      updateUserServiceSelection(auth.currentUser.uid, serviceType);
    } else {
      setSelectedService(serviceType);
      setShowSignUp(true);
    }
  };

  const updateUserServiceSelection = async (userId, serviceType) => {
    const db = getFirestore();
    const userRef = doc(db, "users", userId);
    await setDoc(userRef, { selectedService: serviceType }, { merge: true })
      .then(() => {
        ReactGA.event({
          category: "Firestore",
          action: "Updated User Service Selection",
          label: serviceType,
        });
        navigate("/get-started");
      })
      .catch((error) => {
        console.error("Error updating Firestore:", error);
      });
  };

  const handleSuccess = () => {
    ReactGA.event({
      category: "User Registration",
      action: "Successful Sign Up",
    });

    setShowSignUp(false);
    navigate("/options");
  };

  return (
    <>
      <div
        className="parent-container"
        style={{ paddingBottom: "300px", paddingTop: "100px" }}
      >
        <Container>
          <Row className="my-3">
            <Col className="text-center">
              <p
                className="email-subscription-title"
                style={{ marginTop: "50px" }}
              >
                Discover how Sârv can support your journey to wellness with
                evidence-based approaches to medicinal herbs and holistic
                healthcare practices
              </p>
            </Col>
          </Row>

          <Row
            className="my-3 justify-content-center"
            style={{ paddingTop: "100px" }}
          >
            <Col xs={12} className="text-center">
              {/* <div className="arrow-down-animation">↓</div> */}
              <Button
                className="custom-services-btn"
                onClick={handleCardClick("auto recommendation")}
              >
                Continue
              </Button>
            </Col>
          </Row>

          {/* <Row className="my-3">
            <Col xs={12} md={6} className="mb-3">
              <Card
                onClick={handleCardClick("auto recommendation")}
                className="options-card"
              >
                <Card.Body>
                  <Card.Title
                    className="options-title"
                    style={{ marginBottom: "25px", fontWeight: "bold" }}
                  >
                    Personalized Health Insights
                  </Card.Title>
                  <ul className="checkmark-list">
                    <li>
                      Receive health recommendations customized to your unique
                      body profile and health goals.
                    </li>
                    <li>
                      Our suggestions are based on extensive research from
                      scientific books and peer-reviewed articles.
                    </li>
                    <li>
                      Get immediate insights and wellness plans at your
                      fingertips, without the need for scheduling or waiting.
                    </li>
                    <li>
                      As new research emerges and your health evolves, your
                      recommendations adapt to provide ongoing support.
                    </li>
                    <li>
                      Benefit from expert knowledge at a fraction of the cost of
                      traditional consultations.
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={6}>
              <Card
                onClick={handleCardClick("virtual visit")}
                className="options-card"
              >
                <Card.Body>
                  <Card.Title
                    className="options-title"
                    style={{ marginBottom: "25px", fontWeight: "bold" }}
                  >
                    Consult with Wellness Experts
                  </Card.Title>
                  <ul className="checkmark-list">
                    <li>
                      Connect with certified holistic healthcare practitioners
                      for personalized advice.
                    </li>
                    <li>
                      Book virtual appointments at times that fit your busy
                      lifestyle.
                    </li>
                    <li>
                      Discuss your health concerns and get answers in real-time
                      through secure video or chat sessions.
                    </li>
                    <li>
                      Build a relationship with practitioners who understand
                      your health journey and can guide your wellness strategy.
                    </li>
                    <li>
                      Receive personalized wellness plans that integrate various
                      holistic approaches to suit your unique health needs and
                      goals, helping you achieve optimal health and well-being.
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
      <Modal show={showSignUp} onHide={() => setShowSignUp(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PopupSignUp onSignUpSuccess={handleSuccess} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Options;
