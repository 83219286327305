import React, { useState, useEffect } from "react";
import { db, auth } from "../../config/Firebase";
import { useNavigate } from "react-router-dom";
import { setDoc, doc } from "firebase/firestore";
import { Container, ListGroup, Button } from "react-bootstrap";
import "../../styles/General.css";
import "../../styles/Enrollment.css";
import MultipleBars from "../utils/MultipleBars";

const ScreenTime = () => {
  const [selectedScreenTime, setSelectedScreenTime] = useState("");
  const screenTimeOptions = [
    "Less than 2 hours",
    "2-4 hours",
    "5-7 hours",
    "More than 7 hours",
  ];
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSelection = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    try {
      const userDocRef = doc(db, "users", userId);
      await setDoc(
        userDocRef,
        { dailyScreenTime: selectedScreenTime },
        { merge: true }
      );
      navigate("/pregnancyStatus");
    } catch (error) {
      console.error("Error updating daily screen time in Firebase:", error);
    }
  };

  return (
    <div className="progress-container">
      <MultipleBars
        progresses={[
          { progress: 25, maxProgress: 100, bgClass: "" },
          { progress: 0, maxProgress: 100, bgClass: "bg-success" },
          { progress: 0, maxProgress: 100, bgClass: "bg-info" },
        ]}
      />
      <Container className="d-flex align-items-center justify-content-center">
        <div
          className="w-100"
          style={{
            maxWidth: "600px",
            paddingTop: "60px",
            paddingBottom: "20px",
          }}
        >
          <h3 className="header-text">
            How much screen time do you have daily?
          </h3>
          <ListGroup>
            {screenTimeOptions.map((time, index) => (
              <ListGroup.Item
                key={index}
                action
                active={time === selectedScreenTime}
                onClick={() => setSelectedScreenTime(time)}
                style={{ marginBottom: "15px" }}
              >
                {time}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Button
            onClick={handleSelection}
            disabled={!selectedScreenTime}
            className="next-button mt-3"
          >
            Next
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default ScreenTime;
