// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// const firebase = require("firebase/app");

const firebaseConfig = {
  apiKey: "AIzaSyC9YIOU8PiL2ELNgcOKE-MTENdwHLHY1eo",
  authDomain: "cedar-18da3.firebaseapp.com",
  projectId: "cedar-18da3",
  storageBucket: "cedar-18da3.appspot.com",
  messagingSenderId: "44917177111",
  appId: "1:44917177111:web:20c8cbab2e49a59a4e1552",
  measurementId: "G-0M1S0Y2WMR",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();
export const db = getFirestore(app);
export const storage = getStorage(app);
