// VideoCallWrapper.js
import React from "react";
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import Basics from "./Basics"; // Adjust the import path as necessary

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const VideoCallWrapper = () => {
  return (
    <AgoraRTCProvider client={client}>
      <Basics />
    </AgoraRTCProvider>
  );
};

export default VideoCallWrapper;
