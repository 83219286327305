import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const RecipesAll = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const title = location.state?.title || "";

  React.useEffect(() => {
    // ReactGA.pageview("/recipes/all");
    ReactGA.send({ hitType: "pageview", page: "/recipes/all" });
  }, []);

  const activities = [
    {
      image: "everyday.jpg",
      title: "Everyday Recipes",
      description: "Herbs that prevent oxidative damage, protecting cells.",
    },
    {
      image: "immune-support.jpg",
      title: "Immune Defense",
      description: "Herbs that protect the liver and enhance its function.",
    },
    {
      image: "women.jpg",
      title: "Women's Health",
      description:
        "Herbs that help the body adapt to stress and normalize body processes.",
    },
    {
      image: "men.jpg",
      title: "Men's Health",
      description: "Herbs that aid in bowel movements to relieve constipation",
    },
    {
      image: "elder.jpg",
      title: "Recipes for Elder",
      description:
        "Herbs that relieve gas, bloating, and digestive discomfort.",
    },
    {
      image: "child.jpg",
      title: "Care for Babies and Children",
      description:
        "Herbs that promote urine production to help in fluid balance.",
    },
    {
      image: "emotional-support.jpg",
      title: "Emotional Support",
      description: "Herbs that protect the liver and enhance its function.",
    },
    {
      image: "all-purpuse.jpg",
      title: "Herbs for All Purposes",
      description: "Herbs that protect the liver and enhance its function.",
    },
    // {
    //   image: "beauty.jpg",
    //   title: "Herbs for Natural Beauty",
    //   description: "Herbs that protect the liver and enhance its function.",
    // },
    {
      image: "travel.jpg",
      title: "Travel Health Kit",
      description: "Herbs that protect the liver and enhance its function.",
    },
  ];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const imagePath = (image) => `/img/explorer/recipes/${image}`;

  const handleCardClick = (categoryTitle) => {
    navigate(`/recipes/${encodeURIComponent(categoryTitle)}`);
    ReactGA.event({
      category: "Recipe Category",
      action: "Click",
      label: categoryTitle,
    });
  };

  return (
    <Container
      style={{ minWidth: "1200px", margin: "0 auto", padding: "25px" }}
    >
      <Row
        className="mb-3 justify-content-center"
        style={{
          alignContent: "center",
          alignItems: "center",
          paddingTop: "100px",
          paddingBottom: "50px",
        }}
      >
        <Col>
          <h1 className="article-categoories-title text-center">{title}</h1>
        </Col>
      </Row>
      <Row
        className="g-4"
        style={{ paddingTop: "100px", paddingBottom: "100px" }}
      >
        {" "}
        {/* Added g-4 for spacing between columns */}
        {activities.map((activity, index) => (
          <Col
            key={index}
            md={3}
            sm={6}
            onClick={() => handleCardClick(activity.title)}
          >
            <Card
              className="h-100"
              style={{ cursor: "pointer", border: "none" }}
            >
              {" "}
              {/* h-100 makes cards of equal height */}
              <Card.Img
                variant="top"
                src={imagePath(activity.image)}
                style={{ height: "300px" }}
              />
              <Card.Body>
                <Card.Title>{activity.title}</Card.Title>
                <Card.Text>{truncateText(activity.description, 50)}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default RecipesAll;
