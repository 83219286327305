// import React, { useState } from "react";
// import { Container, Row, Col, Form, Button } from "react-bootstrap";
// import { db } from "../../../config/Firebase"; // Adjust this import according to your project structure
// import { collection, addDoc } from "firebase/firestore";
// import HerbsPicture from "./sub-elements/HerbsPicture";
// import Dosage from "./sub-elements/Dosage";

// const AddHerbs = () => {
//   const initialHerbState = {
//     title: "",
//     other_names: "",
//     attribute: "",
//     family: "",
//     flower_essence: "",
//     key_phrases: "",
//     last_update: "",
//     medicinal_uses: "",
//     nature: "",
//     organs_systems_affected: "",
//     parts_used: "",
//     plant_constituents: "",
//     therapeutic_actions: "",
//     summary: "",
//   };

//   const [herbDetails, setHerbDetails] = useState(initialHerbState);
//   const [newHerbId, setNewHerbId] = useState("");
//   const [currentStep, setCurrentStep] = useState(1);

//   const handleChange = (e) => {
//     setHerbDetails({ ...herbDetails, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const docRef = await addDoc(collection(db, "herbs"), herbDetails);
//       setNewHerbId(docRef.id);
//       alert("Herb details submitted successfully!");
//       setCurrentStep(2);
//     } catch (error) {
//       console.error("Error adding document: ", error);
//     }
//   };

//   const handleDosageSubmit = () => {
//     setCurrentStep(3);
//   };

//   // Array of keys that should be textareas
//   const textAreaKeys = ["medicinal_uses", "summary"];

//   // Manually arrange the keys for the layout
//   const fieldGroups = [
//     ["title", "other_names"],
//     ["attribute", "family"],
//     ["flower_essence", "key_phrases"],
//     ["last_update", "nature"],
//     ["organs_systems_affected", "parts_used"],
//     ["plant_constituents", "therapeutic_actions"],
//     // The last two fields will be handled separately
//   ];

//   return (
//     <Container>
//       {currentStep === 1 && (
//         <Form onSubmit={handleSubmit}>
//           {fieldGroups.map((group, index) => (
//             <Row key={index} className="mb-3">
//               {group.map((key) => (
//                 <Col key={key}>
//                   <Form.Group controlId={`form${key}`}>
//                     <Form.Label>
//                       {key.replace("_", " ").toUpperCase()}
//                     </Form.Label>
//                     <Form.Control
//                       type="text"
//                       name={key}
//                       value={herbDetails[key]}
//                       onChange={handleChange}
//                       placeholder={`Enter ${key.replace("_", " ")}`}
//                     />
//                   </Form.Group>
//                 </Col>
//               ))}
//             </Row>
//           ))}
//           {textAreaKeys.map((key) => (
//             <Row key={key} className="mb-3">
//               <Col>
//                 <Form.Group controlId={`form${key}`}>
//                   <Form.Label>{key.replace("_", " ").toUpperCase()}</Form.Label>
//                   <Form.Control
//                     as="textarea"
//                     rows={3}
//                     name={key}
//                     value={herbDetails[key]}
//                     onChange={handleChange}
//                     placeholder={`Enter ${key.replace("_", " ")}`}
//                   />
//                 </Form.Group>
//               </Col>
//             </Row>
//           ))}
//           <Button variant="primary" type="submit">
//             Next
//           </Button>
//         </Form>
//       )}
//       {currentStep === 2 && (
//         <Dosage herbId={newHerbId} onDosageSubmit={handleDosageSubmit} />
//       )}
//       {currentStep === 3 && <HerbsPicture herbId={newHerbId} />}
//     </Container>
//   );
// };

// export default AddHerbs;

import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { db } from "../../../config/Firebase"; // Adjust this import according to your project structure
import { collection, addDoc } from "firebase/firestore";
import HerbsPicture from "./sub-elements/HerbsPicture";
import Dosage from "./sub-elements/Dosage";

const AddHerbs = () => {
  const initialHerbState = {
    title: "",
    other_names: "",
    attribute: "",
    family: "",
    flower_essence: "",
    key_phrases: "",
    last_update: "",
    medicinal_uses: "",
    nature: "",
    organs_systems_affected: "",
    parts_used: "",
    plant_constituents: "",
    therapeutic_actions: [],
    benefits: "",
    summary: "",
  };

  const [herbDetails, setHerbDetails] = useState(initialHerbState);
  const [newTherapeuticAction, setNewTherapeuticAction] = useState("");
  const [newHerbId, setNewHerbId] = useState("");
  const [currentStep, setCurrentStep] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "therapeutic_actions") {
      setNewTherapeuticAction(value);
    } else {
      setHerbDetails({ ...herbDetails, [name]: value });
    }
  };

  const handleAddTherapeuticAction = () => {
    if (newTherapeuticAction.trim() !== "") {
      setHerbDetails({
        ...herbDetails,
        therapeutic_actions: [
          ...herbDetails.therapeutic_actions,
          newTherapeuticAction.trim(),
        ],
      });
      setNewTherapeuticAction("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = await addDoc(collection(db, "herbs"), herbDetails);
      setNewHerbId(docRef.id);
      alert("Herb details submitted successfully!");
      setCurrentStep(2);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const handleDosageSubmit = () => {
    setCurrentStep(3);
  };

  // Array of keys that should be textareas
  const textAreaKeys = ["medicinal_uses", "summary"];

  // Manually arrange the keys for the layout
  const fieldGroups = [
    ["title", "other_names"],
    ["attribute", "family"],
    ["flower_essence", "key_phrases"],
    ["last_update", "nature"],
    ["organs_systems_affected", "parts_used"],
    ["plant_constituents", "benefits"],
    // The last two fields will be handled separately
  ];

  return (
    <Container>
      {currentStep === 1 && (
        <Form onSubmit={handleSubmit}>
          {fieldGroups.map((group, index) => (
            <Row key={index} className="mb-3">
              {group.map((key) => (
                <Col key={key}>
                  <Form.Group controlId={`form${key}`}>
                    <Form.Label>
                      {key.replace("_", " ").toUpperCase()}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={key}
                      value={herbDetails[key]}
                      onChange={handleChange}
                      placeholder={`Enter ${key.replace("_", " ")}`}
                    />
                  </Form.Group>
                </Col>
              ))}
            </Row>
          ))}
          {textAreaKeys.map((key) => (
            <Row key={key} className="mb-3">
              <Col>
                <Form.Group controlId={`form${key}`}>
                  <Form.Label>{key.replace("_", " ").toUpperCase()}</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    name={key}
                    value={herbDetails[key]}
                    onChange={handleChange}
                    placeholder={`Enter ${key.replace("_", " ")}`}
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="formTherapeuticActions">
                <Form.Label>THERAPEUTIC ACTIONS</Form.Label>
                <Form.Control
                  type="text"
                  name="therapeutic_actions"
                  value={newTherapeuticAction}
                  onChange={handleChange}
                  placeholder="Enter therapeutic action"
                />
                <Button
                  variant="secondary"
                  className="mt-2"
                  onClick={handleAddTherapeuticAction}
                >
                  Add Therapeutic Action
                </Button>
                <ul>
                  {herbDetails.therapeutic_actions.map((action, index) => (
                    <li key={index}>{action}</li>
                  ))}
                </ul>
              </Form.Group>
            </Col>
          </Row>
          <Button variant="primary" type="submit">
            Next
          </Button>
        </Form>
      )}
      {currentStep === 2 && (
        <Dosage herbId={newHerbId} onDosageSubmit={handleDosageSubmit} />
      )}
      {currentStep === 3 && <HerbsPicture herbId={newHerbId} />}
    </Container>
  );
};

export default AddHerbs;
