import React, { useState } from "react";
import { Container, Card, Form, Button } from "react-bootstrap";
import CryptoJS from "crypto-js";

const Decryptor = () => {
  const [input, setInput] = useState("");
  const [decrypted, setDecrypted] = useState("");

  const handleDecrypt = () => {
    try {
      const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
      const bytes = CryptoJS.AES.decrypt(input, encryptionKey);
      const originalText = bytes.toString(CryptoJS.enc.Utf8);
      setDecrypted(originalText);
    } catch (error) {
      console.error("Decryption error:", error);
      setDecrypted("Decryption failed!");
    }
  };

  return (
    <Container className="p-3">
      <Card>
        <Card.Body>
          <Card.Title>Decrypt Your Data</Card.Title>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Encrypted Text</Form.Label>
              <div className="d-flex">
                <Form.Control
                  type="text"
                  placeholder="Enter encrypted text"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                />
                <Button variant="primary" onClick={handleDecrypt}>
                  Decrypt
                </Button>
              </div>
            </Form.Group>
          </Form>
          {decrypted && <Card.Text>Decrypted Text: {decrypted}</Card.Text>}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Decryptor;
