import React, { useState } from "react";
import { Form, Button, Container, Card, Alert } from "react-bootstrap";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
} from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import "../../styles/General.css";
import { auth, db, googleProvider } from "../../config/Firebase";
import "../../styles/Login.css";

const PopupSignUp = ({ onSignUpSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const auth = getAuth();

  const createStripeCustomerAndStoreData = async (userEmail) => {
    const user = auth.currentUser;

    if (user) {
      const userId = user.uid;

      try {
        // Example POST request to your server to create a Stripe Customer
        const response = await fetch("http://localhost:5255/create-customer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: userEmail, userId }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error.message);
        }

        // Store Stripe Customer ID and user email in Firestore
        const userDocRef = doc(db, "users", userId);
        await setDoc(
          userDocRef,
          { stripeCustomerId: data.customerId, email: userEmail },
          { merge: true }
        );
      } catch (error) {
        console.error("Failed to create customer or save:", error);
      }
    } else {
      console.error("No authenticated user found.");
    }
  };

  const signUpWithPopup = () => {
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        const user = result.user;
        await createStripeCustomerAndStoreData(user.email);
        onSignUpSuccess(); // Handle post-sign-up logic
      })
      .catch((error) => {
        console.error("Sign-up error:", error);
      });
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setMessage("");
    setError("");

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) || !password.trim()) {
      setError(
        "Both email and password are required, and email must be valid."
      );
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      await createStripeCustomerAndStoreData(email);
      await sendEmailVerification(auth.currentUser);
      setMessage(
        "Account has been created successfully! Check your inbox for verification."
      );
      onSignUpSuccess(); // Handle post-sign-up logic
    } catch (error) {
      setError(`Failed to create an account: ${error.message}`);
      console.error("Sign-up error:", error);
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh", height: "600px" }}
    >
      <div className="w-100" style={{ maxWidth: "500px", paddingTop: "60px" }}>
        <Card className="shadow no-border-card" style={{ height: "600px" }}>
          <Card.Body>
            <Card.Title className="login-card-title">
              Create an account
            </Card.Title>
            <div
              className="w-100"
              style={{ maxWidth: "400px", paddingTop: "25px" }}
            ></div>
            <Form onSubmit={handleSignUp}>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <div style={{ paddingTop: "20px", paddingBottom: "25px" }}>
                <Form.Group controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </div>

              {/* Include Phone Number Field and Verification Code Field Here */}

              {/* {message && <div style={{ color: "green" }}>{message}</div>}
              {error && <div style={{ color: "red" }}>{error}</div>} */}

              {message && (
                <Alert variant="success" className="mt-3">
                  {message}
                </Alert>
              )}
              {error && (
                <Alert variant="danger" className="mt-3">
                  {error}
                </Alert>
              )}

              <div style={{ paddingTop: "20px" }}>
                <Button
                  variant="primary"
                  type="submit"
                  className="login-btn w-100 mt-3"
                >
                  Sign Up
                </Button>
                <Button
                  variant="primary"
                  type="button" // Changed from 'submit' to 'button'
                  onClick={signUpWithPopup}
                  className="w-100 mt-3 google-sign-up-button"
                >
                  Sign Up With Google
                </Button>
              </div>
              <div className="text-center mt-3">
                <a href="/login">Already have an Account? Sign In</a>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default PopupSignUp;
