import React, { useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { auth, db } from "../../../config/Firebase";
import { useNavigate } from "react-router-dom";
import "../../../styles/Stripe.css";
import { useSubscription } from "../SubscriptionContext";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import axios from "axios";
import { Modal, Button, Spinner } from "react-bootstrap";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { total, isSubscription, priceId, period } = useSubscription();

  const updateSubscriptionStatus = async (userId, status) => {
    const userRef = doc(db, "users", userId);
    try {
      await updateDoc(userRef, {
        isSubscribed: status,
      });
    } catch (error) {
      console.error("Failed to update subscription status:", error);
    }
  };

  const recommendHerbs = async (userId, narrative) => {
    try {
      const response = await axios.post(
        // "http://localhost:8002/recommend-herbs",
        // "http://34.16.52.127:8002/recommend-herbs", //GCP Could IP
        // "http://204.48.18.82:8002/recommend-herbs", // digital ocean ipv4
        "https://rec-api.joinsarv.com/recommend-herbs", // Newest update

        {
          user_id: userId,
          narrative: narrative,
        }
      );
      console.log(response.data);
      // Redirect to the explorer page after recommendations are ready
      navigate("/explorer");
    } catch (error) {
      console.error("Error recommending herbs:", error);
      setMessage("An error occurred while generating recommendations.");
      setShowModal(false); // Hide the modal if an error occurs
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Ensure Stripe.js and Elements are loaded
      setMessage("Please wait until the payment form is fully loaded.");
      return;
    }

    setIsProcessing(true);
    setMessage("");

    const cardElement = elements.getElement(CardElement);

    // Attempt to create a payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.error("[Error]", error);
      setMessage(error.message);
      setIsProcessing(false);
      return;
    }

    // Optionally handle the payment method ID (e.g., save it, use it for a payment)
    // For demonstration, let's assume you're sending it to your backend:
    const userId = auth.currentUser?.uid;
    const serverUrl = process.env.REACT_APP_SERVER_URL;

    try {
      // const response = await fetch("http://159.89.92.213:5255/create-payment", { // old way using ip from digital ocean
      const response = await fetch(" https://api.joinsarv.com/create-payment", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId: userId,
          paymentMethodId: paymentMethod.id,
          paymentType: "subscription",
          period: period,
          // Include other necessary payment data
        }),
      });

      const data = await response.json();

      if (data.error) {
        console.error("Payment error:", data.error);
        setMessage(data.error.message);
      } else {
        setMessage("Payment setup successfully.");
        await updateSubscriptionStatus(userId, true);

        // Show the modal to inform the user that recommendations are being generated
        setShowModal(true);

        // Fetch the user's narrative from Firestore
        const userDocRef = doc(db, "users", userId);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const narrative = userData.narrative;
          if (narrative) {
            await recommendHerbs(userId, narrative);
          } else {
            console.error("No narrative found for user.");
            setMessage("No narrative found for user.");
            setShowModal(false); // Hide the modal if an error occurs
          }
        } else {
          console.error("User not found in Firestore.");
          setMessage("User not found in Firestore.");
          setShowModal(false); // Hide the modal if an error occurs
        }
      }
    } catch (error) {
      console.error("An error occurred while processing your payment:", error);
      setMessage("An error occurred while processing your payment.");
    } finally {
      setIsProcessing(false);
    }
  };

  const formattedAmount = total.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <CardElement id="card-element" />
        <button disabled={isProcessing || !stripe || !elements} id="submit">
          {isProcessing ? "Processing..." : "Pay"}
        </button>
        {message && <div id="payment-message">{message}</div>}
      </form>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header>
          <Modal.Title>Building Your Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <Spinner animation="border" role="status" className="me-3">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <span>
              Please wait while we build your personalized recommendations; it
              might take a few minutes...
            </span>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
