import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../../styles/Article.css";
import ArticlesRow from "./ArticlesRow";

const MainArticle = () => {
  const [article, setArticle] = useState({
    id: "",
    title: "",
    text: "",
    img: "",
  });

  useEffect(() => {
    fetch("/data/mainArticle.json")
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          setArticle(data[0]);
        }
      })
      .catch((error) => console.error("Error fetching article:", error));
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <div className="parent-container">
      <Container>
        <Row className="justify-content-md-center">
          <Col md="auto">
            <div className="article-overview-title">Intuitive Health</div>
          </Col>
        </Row>

        <Row className="justify-content-md-center">
          <Col md="auto">
            {/* <img
            src={article.img}
            alt={article.title}
            style={{ maxWidth: "100%", height: "auto" }}
          /> */}
            <img
              src={article.img}
              alt={article.title}
              className="zoom-effect"
            />
          </Col>
        </Row>

        <Row style={{ paddingTop: "50px" }}>
          <Col md={6}>
            <Link
              to={{
                pathname: `/learn/article`,
                search: `?topic=/data/mainArticle.json&articleId=${article.id}`,
              }}
              className="main-article-title"
            >
              <h3>{article.title}</h3>
            </Link>
          </Col>
          <Col md={6}>
            <p className="main-article-content">
              {truncateText(article.text, 300)}
            </p>
          </Col>
        </Row>
        <Row>
          <ArticlesRow />
        </Row>
      </Container>
    </div>
  );
};

export default MainArticle;
