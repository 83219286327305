const FunctionalActions = [
  {
    image: "Antioxidants.jpg",
    title: "Antioxidants",
    description: "Herbs that prevent oxidative damage, protecting cells",
    subcategories: ["Antioxidant"],
  },
  {
    image: "Adaptogens.jpg",
    title: "Adaptogens",
    description:
      "Herbs that help the body adapt to stress and normalize body processes",
    subcategories: [
      "Adaptogen",
      "Adaptogenic",
      "Anti-stress and antidepressant",
      "Antistress",
      "Mood enhancer",
      "Nervine",
    ],
  },
  {
    image: "Laxatives.jpg",
    title: "Laxatives",
    description: "Herbs that aid in bowel movements to relieve constipation",
    subcategories: ["Laxative", "Aperient", "Cathartic", "Purgative"],
  },
  {
    image: "Carminatives.jpg",
    title: "Carminatives",
    description: "Herbs that relieve gas, bloating, and digestive discomfort",
    subcategories: [
      "Carminative",
      "Digestive aid",
      "Stomachic",
      "Cholagogue",
      "Antinausea",
      "Antiemetic",
    ],
  },
  {
    image: "Diuretics.jpg",
    title: "Diuretics",
    description: "Herbs that promote urine production to help in fluid balance",
    subcategories: ["Diuretic", "Electrolyte balance"],
  },
  {
    image: "Hepatoprotectives.jpg",
    title: "Hepatoprotectives",
    description: "Herbs that protect the liver and enhance its function",
    subcategories: [
      "Hepatoprotective",
      "Liver tonic",
      "Detoxifying",
      "Depurative",
      "Blood purifier",
    ],
  },
  {
    image: "Anti-inflammatory.jpg",
    title: "Anti-inflammatory Agents",
    description: "Herbs that reduce inflammation and pain",
    subcategories: [
      "Anti-inflammatory",
      "Analgesic",
      "Antipyretic",
      "Antispasmodic",
      "Mild analgesic",
      "Muscle relaxant",
      "Anti-fatigue",
      "Counterirritant",
      "Antihistamine",
      "Cough suppressant",
    ],
  },
  {
    image: "Respiratory_Support.jpg",
    title: "Respiratory Support",
    description:
      "Herbs that support respiratory function and relieve congestion",
    subcategories: [
      "Anti-asthmatic",
      "Expectorant",
      "Decongestant",
      "Anticatarrhal",
      "Mucolytic",
      "Respiratory health",
    ],
  },
  {
    image: "Immune_Boosters.jpg",
    title: "Immune Boosters",
    description:
      "Herbs that enhance immune system function and fight infections",
    subcategories: [
      "Immune-boosting",
      "Immune-modulating",
      "Immunomodulatory",
      "Antibacterial",
      "Antibiotic",
      "Antifungal",
      "Antiviral",
      "Antimicrobial",
      "Antiparasitic",
      "Antiseptic",
    ],
  },
  {
    image: "Pain_Relief.jpg",
    title: "Pain Relief",
    description: "Herbs that offer relief from pain and discomfort",
    subcategories: [
      "Analgesic",
      "Mild analgesic",
      "Antipyretic",
      "Muscle relaxant",
      "Sedative",
      "Anxiolytic",
    ],
  },
];

export default FunctionalActions;
