import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../../../config/Firebase";
import { collection, addDoc, setDoc, doc, updateDoc } from "firebase/firestore";
import moment from "moment";

const OneTimeCheckoutForm = ({
  visitFeeAmount,
  customerId,
  practitionerId,
  selectedDate,
  selectedTime,
  oldAppointmentId,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [stripePromise, setStripePromise] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [userId, setUserId] = useState("");
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //   console.log("local user time is", userTimezone);

  //   console.log(
  //     "^^^^^^^^^^^^^^^^ visist fee in checkout is ^^^^^^^^^^^^^^^^",
  //     visitFeeAmount
  //   );

  //   console.log(
  //     "^^^^^^^^^^^oldAppointmentId in OneTimeCheckoutForm is ^^^^^^",
  //     oldAppointmentId
  //   );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      }
    });

    return unsubscribe;
  }, []);

  const navigate = useNavigate();

  const scheduleAppointment = async (
    practitionerId,
    userId,
    selectedDate,
    selectedTime,
    userTimezone
  ) => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    try {
      const appointmentsRef = collection(db, "appointments");
      const clientRequestDate = moment().utc().format();

      const reformattedDate = moment(selectedDate, "dddd, MMMM D, YYYY").format(
        "YYYY-MM-DD"
      );
      const reformattedTime = moment(selectedTime, "h:mm A").format("HH:mm");

      const appointmentLocalTime = moment.tz(
        `${reformattedDate} ${reformattedTime}`,
        "YYYY-MM-DD HH:mm",
        userTimezone
      );

      const appointmentTimeUTC = appointmentLocalTime.utc().toDate();
      //   console.log("Original selectedDate format:", selectedDate);

      //   console.log(
      //     "********************",
      //     selectedDate,
      //     selectedTime,
      //     userTimezone
      //   );
      //   console.log(appointmentLocalTime.isValid());

      // Add a new document with the appointment details
      const docRef = await addDoc(appointmentsRef, {
        practitionerId: practitionerId,
        userId: userId,
        selectedDate: selectedDate,
        selectedTime: selectedTime,
        status: "requested",
        timeZone: userTimezone,
        clientRequestDate: moment().utc().toDate(),
        appointmentTimeUTC: appointmentTimeUTC,
      });

      //   console.log("Appointment scheduled with ID: ", docRef.id);
      //   console.log("Appointment scheduled details: ", docRef);

      return docRef.id; // Optionally return the appointment ID
    } catch (e) {
      console.error("Error adding appointment to Firestore: ", e);
    }
  };

  const cancelOldAppointment = async (oldAppointmentId) => {
    if (!oldAppointmentId) return;

    try {
      const oldAppointmentRef = doc(db, "appointments", oldAppointmentId);
      await updateDoc(oldAppointmentRef, {
        status: "cancelled",
      });
      console.log("Old appointment cancelled successfully.");
    } catch (e) {
      console.error("Error cancelling old appointment: ", e);
      throw new Error("Failed to cancel old appointment");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet.");
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      console.error("Error creating payment method:", error.message);
      setMessage(error.message);
      return;
    }

    const userId = auth.currentUser?.uid;
    let endpoint, requestBody;

    endpoint = "/one-time-purchase";
    requestBody = JSON.stringify({
      customerId: customerId,
      visitFeeAmount: visitFeeAmount * 100, // Convert to cents
      cardToken: token.id,
    });

    try {
      const response = await fetch(`http://localhost:5255${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          data.error || "An error occurred while processing your payment."
        );
      }

      //   console.log("Transaction successful:", data);
      setMessage("Transaction successful.");

      const appointmentId = await scheduleAppointment(
        practitionerId,
        userId,
        selectedDate,
        selectedTime,
        userTimezone
      );
      //   console.log("Appointment ID:", appointmentId);

      if (oldAppointmentId) {
        await cancelOldAppointment(oldAppointmentId);
      }

      navigate("/appointment-completion");
    } catch (error) {
      console.error("Transaction failed:", error);
      setMessage(
        error.message || "An error occurred while processing your payment."
      );
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Container style={{ width: "600px", paddingTop: "30px" }}>
      <h4>Payment information</h4>
      <Card style={{ width: "550px" }}>
        <form onSubmit={handleSubmit} style={{ paddingTop: "25px" }}>
          <CardElement />
          <div
            style={{
              paddingBottom: "25px",
              paddingTop: "25px",
              paddingLeft: "20px",
            }}
          >
            {/* <button type="submit" disabled={!stripe}>
              Pay
            </button> */}
            <button disabled={isProcessing || !stripe || !elements} id="submit">
              <span id="button-text">
                {isProcessing ? "Processing ... " : "Pay now"}
              </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
          </div>
        </form>
      </Card>
    </Container>
  );
};

export default OneTimeCheckoutForm;
