import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom"; // Import Link

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

const ArticleCard = ({ article, jsonFilePath }) => (
  <Link
    to={`/learn/article?topic=${encodeURIComponent(jsonFilePath)}&articleId=${
      article.id
    }`}
    style={{ textDecoration: "none", color: "inherit" }}
  >
    <Card
      style={{
        border: "none",
        borderRadius: "15px",
        marginBottom: "50px",
        padding: "15px",
      }}
    >
      <Card.Img
        variant="top"
        src={article.img}
        style={{
          height: "200px",
          objectFit: "cover",
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
      />
      <Card.Body>
        <Card.Title>{article.title}</Card.Title>
        <Card.Text>{truncateText(article.text, 100)}</Card.Text>
      </Card.Body>
    </Card>
  </Link>
);

const CardGrid = ({ jsonFilePath }) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetch(jsonFilePath)
      .then((response) => response.json())
      .then((data) => setArticles(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [jsonFilePath]);

  return (
    <Container>
      {[0, 3].map((rowStart) => (
        <Row key={rowStart}>
          {articles.slice(rowStart, rowStart + 3).map((article) => (
            <Col xs={12} md={4} key={article.id}>
              <ArticleCard article={article} jsonFilePath={jsonFilePath} />
            </Col>
          ))}
        </Row>
      ))}
    </Container>
  );
};

export default CardGrid;
