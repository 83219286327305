import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";

const MembershipBenefits = () => {
  return (
    <Container style={{ width: "600px", paddingTop: "30px" }}>
      <Card style={{ backgroundColor: "#e7f9e9", width: "550px" }}>
        {" "}
        {/* Light green background */}
        <Card.Body>
          <Card.Title className="text-left">Membership benefits</Card.Title>
          <Row className="mb-2">
            <Col
              xs={1}
              className="d-flex justify-content-center align-items-center"
            >
              <span>&#10003;</span>
              {/* Bootstrap success color */}
            </Col>
            <Col>Choose and keep the same healthcare practitioner</Col>
          </Row>
          <Row className="mb-2">
            <Col
              xs={1}
              className="d-flex justify-content-center align-items-center"
            >
              <span>&#10003;</span>
            </Col>
            <Col>Same day or week video appointment</Col>
          </Row>
          <Row className="mb-2">
            <Col
              xs={1}
              className="d-flex justify-content-center align-items-center"
            >
              <span>&#10003;</span>
            </Col>
            <Col>Access to exclusive content</Col>
          </Row>
          <Row className="mb-2">
            <Col
              xs={1}
              className="d-flex justify-content-center align-items-center"
            >
              <span>&#10003;</span>
            </Col>
            <Col>Get personalized health recommendation</Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MembershipBenefits;
