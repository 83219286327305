import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../../config/Firebase"; // Adjust path as needed
import { onAuthStateChanged } from "firebase/auth";

const PhytotherapyIndex = () => {
  const [herbs, setHerbs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        checkAccess(user.uid);
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const checkAccess = async (userId) => {
    const practitionerRef = doc(db, "practitioners", userId);
    const docSnap = await getDoc(practitionerRef);
    if (docSnap.exists() && docSnap.data().isVerified) {
      fetchHerbs();
    } else {
      setShowModal(true);
    }
  };

  const fetchHerbs = async () => {
    const herbsCollectionRef = collection(db, "herbs");
    const herbsSnapshot = await getDocs(herbsCollectionRef);
    const herbsList = herbsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setHerbs(herbsList);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredHerbs = herbs.filter((herb) => {
    const searchLower = searchTerm.toLowerCase();
    const titleMatch = herb.title.toLowerCase().includes(searchLower);
    const otherNamesMatch =
      herb.other_names && herb.other_names.toLowerCase().includes(searchLower);
    return titleMatch || otherNamesMatch;
  });

  if (showModal) {
    return (
      <Modal show={showModal} onHide={() => navigate("/")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Access Restricted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You do not currently have access to this page. Your access will be
          granted once your application has been verified by our team.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => navigate("/")}
            className="header-btn"
            style={{
              marginTop: "10px",
              borderRadius: "5px",
              width: "100%",
              height: "40px",
            }}
          >
            Go to Home
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Container style={{ paddingTop: "100px" }}>
      <Row className="mb-4" style={{ paddingBottom: "50px" }}>
        <Col>
          <Form.Control
            type="text"
            placeholder="Search for herbs by title or other names..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>
      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Name</th>
            <th>Other Names</th>
            <th>Medical Uses</th>
          </tr>
        </thead>
        <tbody>
          {filteredHerbs.map((herb, index) => (
            <tr
              key={herb.id}
              onClick={() => navigate(`/herbs-details/${herb.id}`)}
            >
              <td>{index + 1}</td>
              <td>
                <img
                  src={herb.imgUrl}
                  alt={herb.title}
                  style={{ width: "50px", height: "50px" }}
                />
              </td>
              <td>{herb.title}</td>
              <td>{herb.other_names}</td>
              <td>{herb.benefits}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default PhytotherapyIndex;
