import React, { useState, useEffect } from "react";
import { db, auth } from "../../config/Firebase";
import { useNavigate } from "react-router-dom";
import { setDoc, doc } from "firebase/firestore";
import { Container, ListGroup, Button } from "react-bootstrap";
import "../../styles/General.css";
import "../../styles/Enrollment.css";
import MultipleBars from "../utils/MultipleBars";

const Weight = () => {
  const [selectedWeight, setSelectedWeight] = useState("");
  const weightCategories = [
    "Underweight",
    "Normal weight",
    "Overweight",
    "Obese",
  ];
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      }
    });

    return unsubscribe;
  }, []);

  const handleSelection = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    try {
      const userDocRef = doc(db, "users", userId);
      await setDoc(
        userDocRef,
        { weightCategory: selectedWeight },
        { merge: true }
      );
      navigate("/diet");
      // navigate("/allergies"); // Replace with your next page path
    } catch (error) {
      console.error("Error updating weight category in Firebase:", error);
    }
  };

  return (
    <div className="progress-container">
      <MultipleBars
        progresses={[
          { progress: 8, maxProgress: 100, bgClass: "" },
          { progress: 0, maxProgress: 100, bgClass: "bg-success" },
          { progress: 0, maxProgress: 100, bgClass: "bg-info" },
        ]}
      />
      <Container className="d-flex align-items-center justify-content-center">
        <div
          className="w-100"
          style={{
            maxWidth: "600px",
            paddingTop: "60px",
            paddingBottom: "20px",
          }}
        >
          <h3 className="header-text">What is your current weight?</h3>
          <ListGroup>
            {weightCategories.map((weight, index) => (
              <ListGroup.Item
                key={index}
                action
                active={weight === selectedWeight}
                onClick={() => setSelectedWeight(weight)}
                style={{ marginBottom: "15px" }}
              >
                {weight}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Button
            onClick={handleSelection}
            disabled={!selectedWeight}
            className="next-button mt-3"
          >
            Next
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Weight;
