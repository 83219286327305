import React, { useState } from "react";
import { Button, Form, Container, Row, Col, InputGroup } from "react-bootstrap";
import { db } from "../../../../config/Firebase";
import { doc, updateDoc } from "firebase/firestore";

const Dosage = ({ herbId, onDosageSubmit }) => {
  const [dosage, setDosage] = useState({
    Bath: "",
    Decoction: "",
    Fomentation: "",
    "Gargle or mouthwash": "",
    Tincture: "",
    "Essential Oil": "",
    Tea: "",
    Capsules: "",
    Infusion: "",
    "Sitz Bath": "",
    "Fresh Application": "",
    "Salve or Liniment": "",
    Syrup: "",
    "Ear Drops": "",
    Mouthwash: "",
    Powder: "",
    Topical: "",
    "Eye wash": "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDosage({ ...dosage, [name]: value });
  };

  //   const handleSubmit = async (e) => {
  //     e.preventDefault();
  //     const herbDocRef = doc(db, "herbs", herbId);
  //     try {
  //       await updateDoc(herbDocRef, { dosage });
  //       alert("Dosage information updated successfully!");
  //       onDosageSubmit();
  //     } catch (error) {
  //       console.error("Error updating document: ", error);
  //     }
  //   };

  // In DosageForm component

  const handleSubmit = async (e) => {
    e.preventDefault();
    const filteredDosage = Object.keys(dosage).reduce((acc, key) => {
      if (dosage[key].trim() !== "") {
        acc[key] = dosage[key];
      }
      return acc;
    }, {});

    try {
      // Assuming you have herbId and a way to reference the correct document
      const herbDocRef = doc(db, "herbs", herbId);
      await updateDoc(herbDocRef, { dosage: filteredDosage });
      alert("Dosage information updated successfully!");
      onDosageSubmit(); // Call the prop function to indicate successful submission
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        {Object.keys(dosage).map((key, index) => (
          <Row key={index} className="mb-3">
            <Col>
              <InputGroup>
                <InputGroup.Text>{key}</InputGroup.Text>
                <Form.Control
                  type="text"
                  name={key}
                  value={dosage[key]}
                  onChange={handleInputChange}
                  placeholder={`Enter ${key} dosage`}
                />
              </InputGroup>
            </Col>
          </Row>
        ))}
        <Button variant="primary" type="submit">
          Submit Dosage
        </Button>
      </Form>
    </Container>
  );
};

export default Dosage;
