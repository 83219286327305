import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Accordion,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../config/Firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import Payment from "./stripe/Payment";
import Order from "./Order";
import { useSubscription } from "./SubscriptionContext";

const SubscriptionPayment = () => {
  const { rate, tax, total, updateTax } = useSubscription();
  const location = useLocation();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [activeKey, setActiveKey] = useState("0");
  const [narrative, setNarrative] = useState("");
  const [userData, setUserData] = useState({});
  const [errors, setErrors] = useState({});
  const [formErrors, setFormErrors] = useState({
    email: "",
    phone: "",
  });

  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    if (user) {
      setFormData((prevData) => ({
        ...prevData,
        email: user.email,
      }));
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      getDoc(userDocRef).then((docSnap) => {
        if (docSnap.exists()) {
          setUserData(docSnap.data());
        }
      });
    }
  }, [user]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/
      );
  };

  const validatePhone = (phone) => {
    return String(phone).match(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
    );
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Reset errors based on input changes
    if (name === "email" && validateEmail(value)) {
      setFormErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    } else if (name === "phone" && validatePhone(value)) {
      setFormErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
    } else if (name === "firstName" && value.trim().length >= 2) {
      setFormErrors((prevErrors) => ({ ...prevErrors, firstName: "" }));
    }
  };

  function createComprehensiveNarrative(data) {
    let narrativeParts = [];

    // Basic Info & Lifestyle
    let basicInfo = `${data.firstName || "The user"} is a ${
      data.ageRange || "not specified"
    } old ${data.gender?.toLowerCase() || "gender not specified"} following a ${
      data.dietType?.toLowerCase() || "no specific"
    } diet. `;
    basicInfo += `Typically consumes ${
      data.fruitVeggieServings || "an unspecified number of"
    } fruits and vegetables daily and exercises ${
      data.exerciseFrequency || "unknown frequency"
    } per week. `;
    basicInfo += `Water intake is about ${
      data.dailyWaterIntake || "unknown"
    } daily. `;
    if ((data.pregnancyStatus || "").toLowerCase() !== "no") {
      basicInfo += "Currently pregnant or planning to become pregnant soon. ";
    }
    narrativeParts.push(basicInfo);

    // Health Goal
    let healthGoal = `Main health goal is to focus on ${
      data.healthGoal?.toLowerCase() || "not specified"
    }. `;
    narrativeParts.push(healthGoal);

    // Disease History
    let diseaseHistory = "Health history includes: ";
    if (data.hasChronicDiseases) {
      diseaseHistory += `chronic diseases such as ${
        data.chronicDiseaseDetails || "not detailed"
      }, `;
    }
    if (data.hasAllergies) {
      diseaseHistory += `allergies specifically ${
        data.allergyDetails || "not detailed"
      }, `;
    }
    if (data.hasMobilityIssue) {
      diseaseHistory += `mobility issues, `;
    }
    diseaseHistory = diseaseHistory.trim().replace(/,$/, ".") + " ";
    narrativeParts.push(diseaseHistory);

    // Mental Health
    let mentalHealth = `Describes mental health as ${
      data.mentalHealth?.toLowerCase() || "not described"
    }. `;

    if (data.hasSleepDisorder) {
      mentalHealth += `Has sleep disorders: ${
        data.sleepDisorderDetails || "no details"
      }. `;
    }

    mentalHealth += `Typically sleeps about ${
      data.sleepDuration || "duration unknown"
    } nightly and experiences ${
      data.stressLevel?.toLowerCase() || "unknown"
    } levels of stress. `;

    if (data.stressSymptomsFrequency) {
      mentalHealth += `Reports experiencing stress-related symptoms ${
        data.stressSymptomsFrequency.toLowerCase() || "at an unknown frequency"
      }.`;
    } else {
      mentalHealth += "Frequency of stress-related symptoms is not specified.";
    }

    narrativeParts.push(mentalHealth);

    return narrativeParts.join(" ");
  }

  const handleContinue = async () => {
    let newErrors = {};
    if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!validatePhone(formData.phone)) {
      newErrors.phone = "Please enter a valid phone number.";
    }
    if (formData.firstName.trim().length < 2) {
      newErrors.firstName = "First name must be at least 2 characters.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return; // Stop if there are errors
    }

    const combinedData = { ...userData, ...formData };

    const narrativeText = createComprehensiveNarrative(combinedData);

    try {
      const userDocRef = doc(db, "users", user.uid);
      await setDoc(
        userDocRef,
        { ...combinedData, narrative: narrativeText },
        { merge: true }
      );

      setActiveKey("1");
    } catch (error) {
      console.error("Error updating user information in Firestore:", error);
    }
  };

  return (
    <Container>
      <Row>
        {/* Personal Information Form */}
        <Col md={6}>
          {/* <Accordion activeKey={activeKey}>
            {/* <Accordion defaultActiveKey="0"> */}

          <Accordion activeKey={activeKey}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Personal Information</Accordion.Header>
              <Accordion.Body>
                <Form>
                  {formErrors.email && (
                    <Alert variant="danger">{formErrors.email}</Alert>
                  )}
                  {formErrors.phone && (
                    <Alert variant="danger">{formErrors.phone}</Alert>
                  )}
                  {formErrors.firstName && (
                    <Alert variant="danger">{formErrors.firstName}</Alert>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      placeholder="Enter phone number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          placeholder="First name"
                          name="firstName"
                          value={formData.firstName}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>Last Name (Optional)</Form.Label>
                        <Form.Control
                          placeholder="Last name"
                          name="lastName"
                          value={formData.lastName}
                          onChange={handleInputChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="primary"
                    onClick={handleContinue}
                    className="payment-btn"
                  >
                    Continue
                  </Button>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            {/* Payment Information */}
            <Accordion.Item eventKey="1">
              <Accordion.Header>Payment Information</Accordion.Header>
              <Accordion.Body>
                {/* Payment Component */}
                <Payment amount={total} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
        {/* Order Summary */}
        <Col md={6}>
          <Order showContinueButton={false} passedRate={rate} />
          <Row
            style={{
              margin: "15px",
              fontSize: "14px",
              paddingTop: "25px",
              paddingLeft: "30px",
              width: "550px",
            }}
          >
            <p className="cancellation-p">Auto-renewal and cancellation</p>
            <p className="cancellation-second-p">
              You will have a 7-day free trial. Sârv will automatically bill
              your card at the end of each period so your membership renews. No
              refunds or credits for partial months.
            </p>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SubscriptionPayment;
