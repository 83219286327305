import React, { useState, useEffect } from "react";
import { db, auth } from "../../config/Firebase";
import { useNavigate } from "react-router-dom";
import { setDoc, doc } from "firebase/firestore";
import { Container, ListGroup, Button, Form } from "react-bootstrap";
import "../../styles/General.css";
import "../../styles/Enrollment.css";
import MultipleBars from "../utils/MultipleBars";

const Allergies = () => {
  const [hasAllergies, setHasAllergies] = useState("");
  const [allergyDetails, setAllergyDetails] = useState("");
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleAllergySelection = (selection) => {
    setHasAllergies(selection);
    if (selection === "No") {
      setAllergyDetails("");
    }
  };

  const handleSubmit = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    const data = { hasAllergies: hasAllergies === "Yes", allergyDetails };
    try {
      const userDocRef = doc(db, "users", userId);
      await setDoc(userDocRef, data, { merge: true });
      navigate("/currentMedication");
    } catch (error) {
      console.error("Error updating allergy information in Firebase:", error);
    }
  };

  return (
    <div className="progress-container">
      <MultipleBars
        progresses={[
          { progress: 35, maxProgress: 100, bgClass: "" }, // For the first category
          { progress: 23, maxProgress: 100, bgClass: "bg-success" }, // For the second category
          { progress: 0, maxProgress: 100, bgClass: "bg-info" }, // For the third category
        ]}
      />
      <Container className="d-flex align-items-center justify-content-center">
        <div
          className="w-100"
          style={{
            maxWidth: "600px",
            paddingTop: "60px",
            paddingBottom: "20px",
          }}
        >
          <h3 className="header-text">Do you have any known allergies?</h3>
          <ListGroup>
            {["Yes", "No"].map((option, index) => (
              <ListGroup.Item
                key={index}
                action
                active={hasAllergies === option}
                onClick={() => handleAllergySelection(option)}
                style={{ marginBottom: "15px" }}
              >
                {option}
              </ListGroup.Item>
            ))}
          </ListGroup>
          {hasAllergies === "Yes" && (
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="Please specify your allergies"
                value={allergyDetails}
                onChange={(e) => setAllergyDetails(e.target.value)}
              />
            </Form.Group>
          )}
          <Button
            onClick={handleSubmit}
            disabled={
              !hasAllergies || (hasAllergies === "Yes" && !allergyDetails)
            }
            className="next-button mt-3"
          >
            Next
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Allergies;
