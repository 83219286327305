import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Dropdown,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../../config/Firebase";
import { doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const Background = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [professionalSchool, setProfessionalSchool] = useState("");
  const [graduationYear, setGraduationYear] = useState("");
  const [fieldOfStudy, setFieldOfStudy] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Firebase Auth: Check for authenticated user
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserId(user.uid);
    }
  });

  const currentYear = new Date().getFullYear();

  const validateForm = () => {
    if (!yearsOfExperience) return "Please select your years of experience.";
    if (!professionalSchool) return "Professional school cannot be empty.";
    if (!graduationYear) return "Graduation year cannot be empty.";
    if (!/^\d{4}$/.test(graduationYear))
      return "Graduation year must be 4 digits.";
    if (graduationYear < 1970 || graduationYear > currentYear)
      return `Graduation year must be between 1970 and ${currentYear}.`;
    if (!fieldOfStudy) return "Field of study cannot be empty.";
    return "";
  };

  const handleSubmit = async () => {
    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      return;
    }
    if (userId) {
      try {
        await updateDoc(doc(db, "practitioners", userId), {
          yearsOfExperience,
          education: {
            professionalSchool,
            graduationYear,
            fieldOfStudy,
          },
        });
        navigate("/general-info"); // Replace with your next page route
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    } else {
      alert("User not found.");
    }
  };

  //   const handleSubmit = async () => {
  //     if (userId) {
  //       try {
  //         await updateDoc(doc(db, "practitioners", userId), {
  //           yearsOfExperience,
  //           education: {
  //             professionalSchool,
  //             graduationYear,
  //             fieldOfStudy,
  //           },
  //         });
  //         navigate("/general-info"); // Replace with your next page route
  //       } catch (error) {
  //         console.error("Error updating document: ", error);
  //       }
  //     } else {
  //       alert("User not found.");
  //     }
  //   };

  return (
    // <Container className="mt-4">
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ minHeight: "80vh", width: "600px" }}
    >
      <Row className="mb-3">
        <Col>
          <h2 className="text-center mb-4 career-h2">
            Add your professional background
          </h2>
        </Col>
      </Row>
      <Row>
        {errorMessage && (
          <Row className="mb-3">
            <Col>
              <Alert variant="danger">{errorMessage}</Alert>
            </Col>
          </Row>
        )}

        <Col>
          <Card
            className="shadow no-border-card w-100"
            style={{ minWidth: "750px" }}
          >
            <Card.Body>
              <Card.Title>Years of experience</Card.Title>
              <Card.Text>
                How many years have you been providing healing services in a
                professional setting?
              </Card.Text>
              <Form.Select
                aria-label="Years of experience"
                onChange={(e) => setYearsOfExperience(e.target.value)}
                defaultValue=""
              >
                <option value="">Select the number of years</option>
                {[...Array(40)].map((_, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
              </Form.Select>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row style={{ paddingTop: "30px" }}>
        <Col>
          <Card
            className="shadow no-border-card w-100"
            style={{ minWidth: "750px" }}
          >
            <Card.Body>
              <Card.Title>Education</Card.Title>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Professional school</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(e) => setProfessionalSchool(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Graduation year</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="YYYY"
                      value={graduationYear}
                      onChange={(e) => setGraduationYear(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3">
                <Form.Label>Field of study</Form.Label>
                <Form.Control
                  type="text"
                  onChange={(e) => setFieldOfStudy(e.target.value)}
                />
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="d-flex justify-content-center">
          <Button onClick={handleSubmit} className="practitioners-button">
            Next
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Background;
