import {
  LocalUser,
  RemoteUser,
  useIsConnected,
  useJoin,
  useLocalMicrophoneTrack,
  useLocalCameraTrack,
  usePublish,
  useRemoteUsers,
} from "agora-rtc-react";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { doc, updateDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../config/Firebase";
import "../../styles/Agora.css";

export const Basics = () => {
  //   const [channel, setChannel] = useState("");
  const isConnected = useIsConnected();
  const location = useLocation();
  const [calling, setCalling] = useState(location.state?.calling || false);

  const appId = "826abb4e28d247d19823026240be1c4a";
  const { token, channelName, meetingId } = location.state || {};
  //   const searchParams = new URLSearchParams(location.search);
  //   const meetingId = searchParams.get("meetingId");
  //   const channelName = searchParams.get("channelName");
  //   const token = searchParams.get("token");

  //   console.log("Is Connected: ", isConnected);

  useJoin({ appid: appId, channel: channelName, token }, calling);

  const [micOn, setMic] = useState(true);
  const [cameraOn, setCamera] = useState(true);
  const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
  const { localCameraTrack } = useLocalCameraTrack(cameraOn);
  usePublish([localMicrophoneTrack, localCameraTrack]);
  const remoteUsers = useRemoteUsers();

  //   console.log("channelName, appId, token ******", channelName, appId, token);
  //   console.log("^^^^^^^^^^^", meetingId);

  useEffect(() => {
    const updateMeetingEndTime = async () => {
      if (meetingId) {
        // Make sure meetingId is defined
        const meetingRef = doc(db, "meetings", meetingId);
        try {
          await updateDoc(meetingRef, {
            meetingEndTime: serverTimestamp(), // Use serverTimestamp for consistency
          });
          console.log("Meeting end time updated successfully.");
        } catch (error) {
          console.error("Error updating meeting end time:", error);
        }
      }
    };
    // console.log("Calling: ", calling, "IsConnected: ", isConnected);

    if (!calling && isConnected) {
      console.log("Leaving the video session...");
      updateMeetingEndTime(); // Call the function to update Firestore
    }
  }, [calling, isConnected, meetingId]);

  const leaveTheMeeting = async () => {
    if (meetingId) {
      // Ensure meetingId is available
      const meetingRef = doc(db, "meetings", meetingId);
      try {
        await updateDoc(meetingRef, {
          meetingEndTime: serverTimestamp(),
        });
        console.log("Meeting end time updated successfully.");
      } catch (error) {
        console.error("Error updating meeting end time:", error);
      }
    }
  };

  const toggleCallingAndLeaveMeeting = () => {
    // If currently in a call (calling is true), prepare to leave the meeting
    if (calling) {
      leaveTheMeeting(); // Update Firestore only when leaving
    }
    // Toggle the calling state regardless
    setCalling((current) => !current);
  };

  return (
    <>
      <div className="room">
        <div className="user-list">
          <div className="user">
            <LocalUser
              audioTrack={localMicrophoneTrack}
              cameraOn={cameraOn}
              micOn={micOn}
              videoTrack={localCameraTrack}
              cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
            >
              <samp className="user-name">You</samp>
            </LocalUser>
          </div>
          {remoteUsers.map((user) => (
            <div className="user" key={user.uid}>
              <RemoteUser
                cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                user={user}
              >
                <samp className="user-name">{user.uid}</samp>
              </RemoteUser>
            </div>
          ))}
        </div>
      </div>
      {isConnected && (
        <div className="control">
          <div className="left-control">
            <button className="agora-btn" onClick={() => setMic((a) => !a)}>
              <i className={`i-microphone ${!micOn ? "off" : ""}`} />
            </button>
            <button className="agora-btn" onClick={() => setCamera((a) => !a)}>
              <i className={`i-camera ${!cameraOn ? "off" : ""}`} />
            </button>
          </div>
          <button
            className={`agora-btn agora-btn-phone ${
              calling ? "agora-btn-phone-active" : ""
            }`}
            onClick={toggleCallingAndLeaveMeeting}
          >
            {calling ? (
              <i className="i-phone-hangup" />
            ) : (
              <i className="i-mdi-phone" />
            )}
          </button>

          {/* <button
            className={`agora-btn agora-btn-phone ${
              calling ? "agora-btn-phone-active" : ""
            }`}
            onClick={() => setCalling((a) => !a)}
          >
            {calling ? (
              <i className="i-phone-hangup" />
            ) : (
              <i className="i-mdi-phone" />
            )}
          </button> */}
        </div>
      )}
    </>
  );
};

export default Basics;
