import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/Header.css";

const Header = () => {
  const navigate = useNavigate();
  const redirectToOptions = () => {
    navigate("/options"); // Make sure the path matches your route setup
  };

  return (
    <Card style={{ border: "none" }}>
      <Card.Body>
        <Row style={{ marginLeft: "100px" }}>
          {/* Left Column divided into 3 Rows */}
          <Col md={6}>
            <Row>
              <Col className="header-title">
                <span>
                  {" "}
                  Get personalize natural health recommandations for <i> you</i>
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span
                  style={{
                    fontSize: "20px",
                    paddingRight: "25px",
                    color: "#585858",
                  }}
                >
                  {" "}
                  We deliver personalized health solutions tailored to each
                  individual's unique characteristics and health goals,
                  supporting them at every stage of their wellness journey.
                </span>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button className="header-btn" onClick={redirectToOptions}>
                  {" "}
                  Get started
                </Button>
              </Col>
            </Row>
          </Col>

          {/* Right Column with an Image */}
          <Col
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src="/img/article/oil-1.jpg"
              alt="Descriptive Alt Text"
              className="header-image"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Header;
