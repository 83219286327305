import React, { useState, useEffect } from "react";
import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../../config/Firebase";
import { Link, useNavigate } from "react-router-dom";
import MenuLink from "./MenuLink";
import "../../styles/Menu.css";

const Menu = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userInitials, setUserInitials] = useState("");
  const [isSubscribed, setIsSubscribed] = useState(false);
  const navigate = useNavigate();

  const handleLibraryClick = (event) => {
    if (event.target.id === "library-nav-dropdown") {
      event.preventDefault();
      navigate("/learn");
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsLoggedIn(true);
        const userRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
          const userData = docSnap.data();
          setIsSubscribed(userData.isSubscribed || false);
          const initials = user.displayName
            ? user.displayName
                .split(" ")
                .map((name) => name[0])
                .join("")
                .toUpperCase()
            : "U";
          setUserInitials(initials);
        }
      } else {
        setIsLoggedIn(false);
        setUserInitials("");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      window.location.href = "/login";
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <Navbar bg="white" expand="lg">
      <Container fluid className="custom-navbar-container">
        <Navbar.Brand href="/">
          <span className="custom-logo" style={{ marginLeft: "3rem" }}>
            Sârv.
          </span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/mission">About</Nav.Link>
            {/* <Nav.Link href="/explorer">Explorer</Nav.Link>
            <Nav.Link href="/admin-dashboard">Admin</Nav.Link> */}
            <NavDropdown
              title="Library"
              id="library-nav-dropdown"
              className="no-caret"
              onClick={handleLibraryClick}
            >
              <NavDropdown.Item>
                <MenuLink
                  title="Medicial Herbs"
                  jsonDataPath="/data/blogs/basics-of-herbalism.json"
                />
              </NavDropdown.Item>
              <NavDropdown.Item>Sleep Science</NavDropdown.Item>
              <NavDropdown.Item>
                <MenuLink
                  title="Women Health"
                  jsonDataPath="/data/blogs/women-health.json"
                />
              </NavDropdown.Item>
              <NavDropdown.Item>
                <MenuLink
                  title="Mental Health"
                  jsonDataPath="/data/blogs/depression-anxiety.json"
                />
              </NavDropdown.Item>
              <NavDropdown.Item>
                <MenuLink
                  title="Nutrition & Recipes"
                  jsonDataPath="/data/blogs/recipes.json"
                />
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title="Quizes"
              id="library-nav-dropdown"
              className="no-caret"
            >
              <NavDropdown.Item href="/quiz">Dosha Quiz</NavDropdown.Item>
            </NavDropdown>

            {/* <Nav.Link href="/careers">Jobs</Nav.Link> */}
            <Nav.Link href="/faq">FAQ</Nav.Link>

            {isLoggedIn ? (
              <NavDropdown
                title={
                  <div style={{ textAlign: "center" }}>
                    <div className="menu-dropdown">{userInitials}</div>
                  </div>
                }
                id="user-profile-dropdown"
                className="custom-center-dropdown"
              >
                <b style={{ paddingLeft: "15px" }}>Services</b>
                <NavDropdown.Item href="/explorer">Dashboard</NavDropdown.Item>
                <NavDropdown.Item href="/diseaseCategories">
                  Personalized Remedies
                </NavDropdown.Item>
                <hr />
                {/* <NavDropdown.Item href="/user-profile">
                  Profile
                </NavDropdown.Item> */}
                <NavDropdown.Item href="/user-settings">
                  Settings
                </NavDropdown.Item>
                <hr />
                <NavDropdown.Item onClick={handleLogout}>
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link href="/login" className="secondary-button">
                Login
              </Nav.Link>
            )}

            {!isSubscribed && (
              <Nav.Link href="/options" className="btn menu-button primary">
                Get Started
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Menu;
