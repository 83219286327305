import React from "react";
import { Container, Row, Col, Button, Card, Image } from "react-bootstrap";
import privacy from "../../images/compliant.png";
import "../../styles/Buttons.css";

const Requirements = () => {
  const redirectToCareerPage = () => {
    window.location.href = "/careers"; // Update '/career-page' to the actual path
  };

  return (
    <div className="mission-container">
      <div className="mission-subcontainer">
        <Container fluid className="text-center">
          <Row
            className="mb-3"
            style={{ paddingTop: "50px", paddingBottom: "30px" }}
          >
            <Col style={{ fontWeight: "bold", fontSize: "24px" }}>
              Requirements
            </Col>
          </Row>
          <Row className="mb-1">
            <Col>
              • Valid license to provide healing services issued by a state
              board
            </Col>
          </Row>
          {/* <Row className="mb-1">
            <Col>• Prrof of training & practical for her</Col>
          </Row> */}
          <Row className="mb-1">
            <Col>
              • 3+ years of experience in natural healing modalities for adults,
              and/or teens
            </Col>
          </Row>
          <Row className="mb-1">
            <Col>
              • Desktop or laptop computer with a reliable internet connection
              and a webcam
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>• Currently residing in the U.S.</Col>
          </Row>
          <Row className="mb-3" style={{ textAlign: "center" }}>
            <Col
              style={{
                // textAlign: "left",
                fontFamily: "Canela",
                fontStyle: "italic",
              }}
            >
              Note: Unfortunately, if you are an intern or require supervision
              to provide healthcare services, you cannot be a provider at Sârv
              at this time. Practitioners are not Sârv employees, but
              independent providers.
            </Col>
          </Row>
          <Row className="mb-3" style={{ paddingTop: "50px" }}>
            <Col>
              <Button
                onClick={redirectToCareerPage}
                className="custom-services-btn"
              >
                Go to Career Page
              </Button>
            </Col>
          </Row>
          <Row
            className="mb-3"
            style={{ paddingTop: "100px", paddingBottom: "100px" }}
          >
            <Card style={{ backgroundColor: "floralwhite" }}>
              <Card.Body>
                <Row>
                  <Col md={3}>
                    <Image
                      src={privacy}
                      alt="Descriptive Alt Text"
                      style={{ width: "120px", height: "120px" }}
                    />
                  </Col>
                  <Col md={9}>
                    <Row className="mb-2">
                      <Col
                        style={{
                          textAlign: "left",
                          fontFamily: "Canela",
                          fontWeight: "bold",
                          fontSize: "18px",
                        }}
                      >
                        Fraud and privacy notice
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ textAlign: "left", fontSize: "18px" }}>
                        Sârv does not require you to install an application to
                        your computer, nor do we support chat apps such as Wire,
                        WhatsApp, or Signal for any legitimate use.
                        Practitioners are not required to pay fees or incur any
                        costs to join Sârv.
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Requirements;
