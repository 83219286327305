import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import EmailSubscription from "../utils/EmailSubscription";
import "../../styles/General.css";

const JoinNewsLetter = () => {
  return (
    <Container>
      <Row style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        {/* Left Column */}
        <Col md={6}>
          {/* First Row in Left Column */}
          <Row>
            <Col>
              <div className="email-subscription-title">
                Receive complimentary insights on natural remedies from leading
                holistic healthcare providers
              </div>
            </Col>
          </Row>
          {/* Second Row in Left Column */}
          <Row style={{ paddingTop: "30px" }}>
            <Col>
              <div className="main-article-content">
                Join our newsletter. No spam. Its free.
              </div>
            </Col>
          </Row>
        </Col>

        {/* Right Column */}
        <Col md={6}>
          <EmailSubscription />
        </Col>
      </Row>
    </Container>
  );
};

export default JoinNewsLetter;
