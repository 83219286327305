import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ContactCard from "./ContactCard";
import check from "../../images/utils/verify.png";

const DemoRequest = () => {
  return (
    <Container style={{ marginBottom: "50px" }}>
      <Row>
        <Col md={6}>
          <Row style={{ paddingTop: "50px" }}>
            <p
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "darkgreen",
              }}
            >
              Leading the Future of Personalized Natural Healing
            </p>
          </Row>
          <Row>
            <p>
              Sarv stands at the forefront of holistic well-being,
              revolutionizing the way we approach natural healing and wellness.
              As an emergent platform, Sarv is dedicated to offering
              personalized, natural healing solutions, tailored to meet the
              unique needs of every individual. Our mission is to empower lives
              through the integration of ancient wisdom and modern science,
              ensuring accessible, effective, and sustainable health solutions.
            </p>
          </Row>
          <Row>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "darkgreen",
              }}
            >
              Why Partner with Sarv?
            </p>
          </Row>
          <Row>
            <ul style={{ listStyleType: "none" }}>
              <li>
                <img
                  src={check}
                  alt="Check"
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    verticalAlign: "middle",
                  }}
                />
                <b>Innovative Approach:</b> Dive into the world of holistic
                health with our cutting-edge platform, designed to blend the
                best of traditional healing practices with the precision of
                modern technology.
              </li>
              <li>
                <img
                  src={check}
                  alt="Check"
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    verticalAlign: "middle",
                  }}
                />
                <b>Comprehensive Wellness Solutions:</b> From personalized
                healing plans to an expansive network of certified natural
                health practitioners, Sarv offers a one-stop solution for all
                wellness needs.
              </li>
              <li>
                <img
                  src={check}
                  alt="Check"
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    verticalAlign: "middle",
                  }}
                />
                <b>Empowering Communities:</b> Join us in creating a healthier
                world. Our platform not only supports individual well-being but
                also offers a powerful tool for employers, health plans, and
                wellness communities to foster a culture of health and vitality.
              </li>
              <li>
                <img
                  src={check}
                  alt="Check"
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    verticalAlign: "middle",
                  }}
                />
                <b>Flexibility and Accessibility:</b> With Sarv, accessing
                personalized natural health solutions has never been easier. Our
                platform is built to accommodate the dynamic needs of
                businesses, large or small, ensuring that comprehensive wellness
                is just a click away.
              </li>
              <li>
                <img
                  src={check}
                  alt="Check"
                  style={{
                    width: "20px",
                    marginRight: "10px",
                    verticalAlign: "middle",
                  }}
                />
                <b>Commitment to Privacy and Security: </b> Trust and
                confidentiality form the cornerstone of our services. Sarv is
                committed to maintaining the highest standards of data security
                and privacy, ensuring peace of mind for all our users and
                partners.
              </li>
            </ul>
            <p
              style={{
                fontSize: "20px",
                fontWeight: "bold",
                color: "darkgreen",
              }}
            >
              Join the Sarv Movement
            </p>
            <p>
              As Sarv embarks on this journey to redefine wellness and natural
              healing, we invite you to become a part of our growing network of
              business partners and affiliates. Together, we can unlock the full
              potential of natural healing, making wellness accessible to
              everyone, everywhere. Whether you're an employer looking to
              enhance your corporate wellness offerings, a health plan aiming to
              broaden your services, or a wellness enthusiast seeking to spread
              the word about natural healing, Sarv offers unparalleled
              opportunities for collaboration and growth.
            </p>
          </Row>
        </Col>
        <Col md={6} style={{ paddingTop: "50px" }}>
          <ContactCard />
          <p
            style={{
              paddingTop: "30px",
              paddingBottom: "50px",
              fontSize: "12px",
              paddingLeft: "15px",
              paddingRight: "15px",
            }}
          >
            "Through this form, Sarv will collect information including your
            name, email address, and employment details. This information will
            only be retained for as long as needed to fulfill the purposes of
            advertising, sales outreach and client support. By clicking request
            a demo, you consent to allow Sarv Business to store and process the
            personal information submitted above to provide you the content
            requested. For more information about how we use and retain your
            information, please see our{" "}
            <a href="/privacy-policy">Privacy Policy</a> and{" "}
            <a href="/terms-of-use">Terms of Use</a>."
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default DemoRequest;
