import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const ViewAll = ({ title, navigateTo, state = {} }) => {
  const navigate = useNavigate();

  //   const handleViewAllClick = () => {
  //     navigate(navigateTo, { state: state });
  //   };

  const handleViewAllClick = () => {
    navigate(navigateTo, { state: { ...state, title: title } });
  };

  return (
    <Container style={{ paddingTop: "50px" }}>
      <Row className="align-items-center" style={{ paddingBottom: "25px" }}>
        <Col xs={6}>
          <h4
            style={{
              fontSize: "24px",
              color: "rgb(22, 26, 58)",
              fontFamily: "Agrandir",
            }}
          >
            {title}
          </h4>
        </Col>
        <Col xs={6} className="text-end">
          <a
            onClick={handleViewAllClick}
            style={{
              cursor: "pointer",
              textDecoration: "none",
              fontSize: "20px",
            }}
            href=""
          >
            View All
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewAll;
