import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "../../styles/Article.css";
import { useNavigate } from "react-router-dom";

const MedicinaHerbs = ({ data }) => {
  const navigate = useNavigate();
  const handleNavigate = (id) => {
    const newUrl = `/herbs-details/${id}`;
    window.open(newUrl, "_blank"); // '_blank' opens a new tab
    // navigate(`/herbs-details/${id}`);
  };

  if (!data || data.length === 0) {
    return <div>No Herbs Data Available</div>;
  }

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const renderDosage = (dosage) => {
    if (!dosage) {
      return <p>No dosage information available.</p>;
    }

    return Object.entries(dosage).map(([key, value], index) => {
      if (Array.isArray(value)) {
        return (
          <div key={index}>
            {/* <p> */}
            <b>{key}:</b>
            <ul>
              {value.map((step, idx) => (
                <li key={idx}>{step}</li>
              ))}
            </ul>
            {/* </p> */}
          </div>
        );
      } else if (typeof value === "string") {
        return (
          <div key={index}>
            {/* <p> */} <b> {key}:</b> {value} {/* </p> */}
          </div>
        );
      } else {
        console.error(
          `Unexpected format for '${key}'. Expected array or string, but received:`,
          value
        );
        return (
          <div key={index}>
            <h3>{key}:</h3>
            <p>Incorrect data format.</p>
          </div>
        );
      }
    });
  };
  return (
    <Container style={{ paddingTop: "50px" }}>
      {data.map((item, index) => (
        <Card key={index} className="mb-3">
          <Card.Body>
            <Row className="align-items-center">
              {" "}
              {/* Adjusted to align items in the center */}
              <Col xs={12} md={3}>
                <Card.Img
                  variant="top"
                  src={item.imgUrl}
                  style={{
                    width: "100%", // Set width to 100% of the column
                    height: "auto", // Adjust height to be auto for scaling
                    objectFit: "cover",
                  }}
                />
              </Col>
              <Col xs={12} md={8}>
                <Row>
                  <Card.Title>{item.title}</Card.Title>
                  <Card.Text>{truncateText(item.summary, 500)}</Card.Text>
                  {item?.dosage && (
                    <div className="mt-3">
                      {" "}
                      {/* Added margin top for spacing */}
                      <strong>Dosage: </strong>
                      {renderDosage(item.dosage)}
                    </div>
                  )}
                </Row>
              </Col>
              <Col
                xs={12}
                md={1}
                className="d-flex justify-content-end align-items-end pb-3"
              >
                {/* This will push all content to the end (right) of the column */}
              </Col>
            </Row>
            <Row
              className="justify-content-end"
              style={{ paddingRight: "20px" }}
            >
              <Col
                md="auto"
                className="d-flex justify-content-end align-items-end pb-3"
              >
                <Button
                  variant="link"
                  className="readmore-btn"
                  onClick={() => handleNavigate(item.id)}
                >
                  Read More {">"}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ))}
    </Container>
  );
};

export default MedicinaHerbs;
