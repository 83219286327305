import React, { useState } from "react";
import { Form, Button, Container, Card, Alert } from "react-bootstrap";
import "../../styles/General.css";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth, googleProvider, db } from "../../config/Firebase";
import { useNavigate } from "react-router-dom";
import "../../styles/Login.css";
import { doc, getDoc } from "firebase/firestore";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const signIn = async (e) => {
    e.preventDefault();

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userId = userCredential.user.uid;
      const userRef = doc(db, "users", userId);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (userData.isSubscribed) {
          navigate("/explorer");
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error signing in: ", error.message);
      setError("Failed to sign in. Please check your credentials.");
      setShowAlert(true);
    }
  };

  const signInWithGoogle = async () => {
    try {
      const userCredential = await signInWithPopup(auth, googleProvider);
      const userId = userCredential.user.uid;

      const userRef = doc(db, "users", userId);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (userData.isSubscribed) {
          navigate("/explorer");
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    } catch (error) {
      console.error("Error signing in with Google: ", error.message);
      setError("Failed to sign in with Google.");
      setShowAlert(true);
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "500px", paddingTop: "60px" }}>
        <Card className="shadow no-border-card" style={{ height: "auto" }}>
          <Card.Body>
            <Card.Title className="login-card-title">
              Login to your account
            </Card.Title>
            <div
              className="w-100"
              style={{ maxWidth: "400px", paddingTop: "25px" }}
            ></div>
            <Form onSubmit={signIn}>
              {/* Email or Username Field */}
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter email or username"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              {/* Password Field */}
              <div style={{ paddingTop: "20px", paddingBottom: "25px" }}>
                <Form.Group controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </div>

              {/* Submit Button */}
              {showAlert && (
                <Alert
                  variant="danger"
                  onClose={() => setShowAlert(false)}
                  dismissible
                >
                  {error}
                </Alert>
              )}

              <div style={{ paddingTop: "20px" }}>
                <Button
                  variant="primary"
                  type="submit"
                  className="login-btn w-100 mt-3"
                >
                  Sign In
                </Button>
              </div>
              <Button
                variant="primary"
                type="button"
                onClick={signInWithGoogle}
                className="w-100 mt-3 google-sign-up-button"
              >
                Sign In With Google
              </Button>

              {/* Forgot Password Link */}
              <div className="text-center mt-3">
                <a href="/forgotPassword">Forgot Password?</a>
              </div>
              <div className="text-center mt-3">
                <a href="/signUp">Don't Have an Account? Sign Up</a>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default Login;
