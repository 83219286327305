import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactGA from "react-ga4";

// const trackingId = "G-D979R8MZ1C"; // old
const trackingId = "G-EMRNLHKV1G"; //ga4 tracking
ReactGA.initialize(trackingId);
// ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
