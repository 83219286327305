import { useLocation, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import MembersBillingDetails from "./billing/MembersBillingDetails";
import { db } from "../../config/Firebase";
import OneTimePayment from "./payment/one-time/OneTimePayment";

const MembersScheduleAppointments = () => {
  const location = useLocation();
  const { userId } = useParams();
  const { practitionerId, selectedDate, selectedTime, oldAppointmentId } =
    location.state || {};
  const [visitFeeAmount, setVisitFeeAmount] = useState(0);
  const [practitioner, setPractitioner] = useState(null);
  const state = location.state || {};
  const [currentFee, setCurrentFee] = useState(0);
  const [calculatedFee, setCalculatedFee] = useState(visitFeeAmount);

  //   console.log("Received state in MembersScheduleAppointments:", state);
  //   console.log(
  //     "^^^^^^^^^^^oldAppointmentId in MembersScheduleAppointments is ^^^^^^",
  //     oldAppointmentId
  //   );

  const handleFeeUpdate = (newFee) => {
    setCalculatedFee(newFee);
  };

  //   useEffect(() => {
  //     const fetchPractitionerData = async () => {
  //       if (userId) {
  //         const docRef = doc(db, "practitioners", userId);
  //         const docSnap = await getDoc(docRef);
  //         if (docSnap.exists()) {
  //           const practitionerData = docSnap.data();
  //           setPractitioner(practitionerData);
  //           const calculatedVisitFee = Math.round(
  //             practitionerData.minimumPay * 1.25
  //           );
  //           setVisitFeeAmount(calculatedVisitFee);
  //         }
  //       }
  //     };

  //     fetchPractitionerData();
  //   }, [userId, selectedDate, selectedTime]);

  useEffect(() => {
    const fetchPractitionerData = async () => {
      if (userId) {
        const docRef = doc(db, "practitioners", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const practitionerData = docSnap.data();
          setPractitioner(practitionerData);
          // Calculate the default visit fee (for new bookings) immediately upon fetching
          const defaultVisitFee = Math.round(
            practitionerData.minimumPay * 1.25
          );
          setVisitFeeAmount(defaultVisitFee);
          setCalculatedFee(defaultVisitFee); // Set initial calculated fee to default visit fee
        }
      }
    };

    fetchPractitionerData();
  }, [userId, selectedDate, selectedTime]);

  if (!practitioner) {
    return <div>Loading practitioner details...</div>;
  }

  const formattedSelectedDate = selectedDate ? selectedDate.toString() : "";
  const formattedSelectedTime = selectedTime ? selectedTime.toString() : "";

  return (
    <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <Row>
        <h4 style={{ paddingBottom: "25px" }}>Scheduling appointment with:</h4>
        {/* <p></p> */}
        <Col md={6}>
          <Card style={{ width: "600px" }}>
            {/* Practitioner Details */}

            <Row className="g-0">
              <Col md={4}>
                <Image
                  src={practitioner.imgUrl}
                  alt="Practitioner"
                  fluid
                  style={{ width: "200px", height: "200px" }}
                />
              </Col>
              <Col md={8}>
                <Card.Body>
                  <Card.Title>
                    {practitioner.firstName} {practitioner.lastName}
                  </Card.Title>
                  <Card.Text>{practitioner.jobTitle}</Card.Text>
                  <Card.Text>
                    {formattedSelectedDate} at {formattedSelectedTime}
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card>
          <Row
            style={{
              margin: "15px",
              fontSize: "14px",
              paddingTop: "25px",
              paddingLeft: "0px",
              width: "550px",
            }}
          >
            <p className="cancellation-p">
              Cancellation and Rescheduling Policy{" "}
            </p>
            <p className="cancellation-second-p">
              <b>Cancellations:</b> {"  "}
              Patients may cancel their appointments without any charge up to 48
              hours before the scheduled appointment time. Cancellations made
              less than 48 hours before the scheduled appointment time will
              incur a 50% consultation fee. To cancel an appointment, please use
              our app's cancellation feature or contact our support team.
            </p>
            <p className="cancellation-second-p">
              {" "}
              <b>Rescheduling:</b> {"  "}Patients are encouraged to reschedule
              appointments instead of canceling them. You can reschedule your
              appointment for another available time slot up to 24 hours before
              the scheduled appointment time at no additional cost. Rescheduling
              within 24 hours of the appointment time is subject to provider
              availability and may incur a fee equivalent to 25% of the
              consultation fee.
            </p>
            <p className="cancellation-second-p">
              {" "}
              For more information about refound, missed appointments: and late
              arrival please view our temrs and services.
            </p>
          </Row>
        </Col>
        <Col md={6}>
          <MembersBillingDetails
            practitioner={practitioner}
            selectedDate={formattedSelectedDate}
            selectedTime={formattedSelectedTime}
            isRescheduling={location.state?.isRescheduling}
            rescheduleFeeApplicable={location.state?.rescheduleFeeApplicable}
            onFeeCalculated={handleFeeUpdate}
            // onFeeCalculated={setCurrentFee}
          />
          <Row>
            <OneTimePayment
              visitFeeAmount={calculatedFee}
              //   visitFeeAmount={visitFeeAmount}
              practitionerId={practitionerId}
              selectedDate={formattedSelectedDate}
              selectedTime={formattedSelectedTime}
              oldAppointmentId={oldAppointmentId}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MembersScheduleAppointments;
