import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../../config/Firebase"; // Adjust the import path according to your Firebase config file
import { doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const ProfileDescription = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [bio, setBio] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
  }, []);

  const handleSubmit = async () => {
    if (bio.length < 300) {
      setError("Bio must be at least 300 characters.");
      return;
    } else if (bio.length > 1000) {
      setError("Bio cannot exceed 1000 characters.");
      return;
    }

    try {
      await updateDoc(doc(db, "practitioners", userId), {
        bio,
      });
      navigate("/profile-photo"); // Replace with your next page route
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ minHeight: "80vh", minWidth: "600px" }}
    >
      <Row className="mt-3">
        <Col>
          <h2 className="text-center mb-4 career-h2">
            Describe yourself to clients
          </h2>
        </Col>
      </Row>
      <Card
        className="shadow no-border-card w-100"
        style={{ maxWidth: "750px", minHeight: "500px" }}
      >
        <Card.Body>
          <Card.Title>Please write your profile description</Card.Title>
          <Card.Text>
            This information will be displayed on your public profile page for
            clients to learn about you and your areas of expertise.
          </Card.Text>
          <Form.Control
            as="textarea"
            rows={3}
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            maxLength={1000}
            style={{ minHeight: "300px" }}
          />
          <Card.Text className="mt-2">
            You can edit this any time from your Account settings
          </Card.Text>
          <p style={{ fontSize: "12px" }}>
            * Please ensure your bio is at least 300 characters and does not
            exceed 1000 characters in length.
          </p>
          {error && <Alert variant="danger">{error}</Alert>}
        </Card.Body>
      </Card>
      <Row className="mt-3" style={{ paddingTop: "25px" }}>
        <Col className="d-flex justify-content-center">
          <Button onClick={handleSubmit} className="practitioners-button">
            Next
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileDescription;
