import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // Make sure Bootstrap CSS is imported
import "../../styles//Footer.css"; // Assuming your custom styles are in Footer.css
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import appleStoreBadge from "../../images/apple-store.png";
import googlePlayBadge from "../../images/google-play-badge.png";
import FooterLink from "./FooterLink";

const Footer = () => {
  return (
    <footer className="footer bg-light-gray">
      <div
        className="container"
        style={{ marginTop: "100px", fontFamil: "Lato", fontSize: "14px" }}
      >
        <div className="row">
          {/* Column 1: About Care.com */}
          <div className="col-md-3">
            <h5>About Sârv</h5>
            <ul>
              <li>
                <a href="/mission">About Us</a>
              </li>
              {/* <li>
                <a href="/services">Services</a>
              </li> */}
              <li>
                <a href="/faq">FAQ</a>
              </li>
              <li>
                <a href="/careers">Careers</a>
              </li>
              {/* <li>
                <a href="#">News Coverage</a>
              </li> */}
              <li>
                <a href="/contact">Contact Us</a>
              </li>
              <li>
                <Link to="/terms-of-service">Terms of Use</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
            </ul>
          </div>

          {/* Column 2: Get Help */}
          <div className="col-md-3">
            <h5>Articles</h5>
            <ul>
              <li>
                <a href="/learn">All Articles</a>
              </li>
              <li>
                <FooterLink
                  title="Medicinal Herbs"
                  jsonDataPath="/data/blogs/basics-of-herbalism.json"
                />
                {/* <a href="/article-categories">Medicinal Herbs</a> */}
              </li>
              <li>
                <FooterLink
                  title="Women Health"
                  jsonDataPath="/data/blogs/women-health.json"
                />
              </li>
              <li>
                <FooterLink
                  title="Mental Health"
                  jsonDataPath="/data/blogs/depression-anxiety.json"
                />
              </li>
              {/* <li>
                <FooterLink
                  title="Diabetes"
                  jsonDataPath="/data/blogs/diabetes.json"
                />
              </li> */}
              <li>
                <FooterLink
                  title="Sleep Science"
                  jsonDataPath="/data/blogs/sleep.json"
                />
              </li>
              <li>
                <FooterLink
                  title="Digestive System"
                  jsonDataPath="/data/blogs/digestive.json"
                />
              </li>
              <li>
                <FooterLink
                  title="Nutrition & Recipes"
                  jsonDataPath="/data/blogs/recipes.json"
                />
              </li>
              {/* <li>
                <FooterLink
                  title="Ayurveda"
                  jsonDataPath="/data/ayurveda.json"
                />
              </li> */}
            </ul>
          </div>

          {/* Column 3: Homepay Nanny */}
          <div className="col-md-3">
            <h5>Business Partnership</h5>
            <ul>
              {/* <li>
                <a href="#">List Your Business</a>
              </li> */}
              <li>
                <a href="/demo-request">Sârv for Business</a>
              </li>
              <li>
                <a href="/affiliate-request">Become an Affiliate</a>
              </li>
              {/* <li>
                <a href="#">Sârv Directory</a>
              </li> */}
              <li>
                <a href="#">Online Classes</a>
              </li>
              {/* <li>
                <a href="#">Sârv Cost Guides</a>
              </li> */}
            </ul>
          </div>

          {/* Column 4: App Stores and Social Media */}
          <div className="col-md-3">
            {/* Row for App Stores */}
            <div className="app-and-social">
              {/* <p>Appstore | Googlestore</p> */}
              <div className="app-stores">
                <a
                  href="/app-waitlist"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={appleStoreBadge}
                    alt="Download on the Apple Store"
                  />
                </a>
              </div>
              <div className="app-stores">
                <a
                  href="/app-waitlist"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={googlePlayBadge} alt="Get it on Google Play" />
                </a>
              </div>
            </div>

            {/* Row for Social Media Icons */}
            <div className="social-icons">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} /> {/* Adjust size here */}
              </a>
              <a
                href="https://www.instagram.com/joinsarv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} /> {/* Adjust size here */}
              </a>
              <a
                href="https://www.twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} /> {/* Adjust size here */}
              </a>
              <a
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} /> {/* Adjust size here */}
              </a>
            </div>
            <div
              className="social-icons"
              style={{ color: "white", paddingTop: "30px", fontSize: "12px" }}
            >
              © 2024 M31-V1 LLC. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
