import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { auth } from "../../../config/Firebase";
import { Card, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { db } from "../../../config/Firebase";
import { collection, addDoc, setDoc, doc } from "firebase/firestore";
import moment from "moment";

const CheckoutForm = ({
  priceId,
  // clientSecret,
  visitFeeAmount,
  membershipOption,
  customerId,
  practitionerId,
  selectedDate,
  selectedTime,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [stripePromise, setStripePromise] = useState(null);
  // const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState(null);
  // const [priceId, setPriceId] = useState("");
  const [message, setMessage] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [userId, setUserId] = useState("");
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // console.log("local user time is", userTimezone);
  // console.log("Selected Date in Checkout Form:", selectedDate);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      }
    });

    return unsubscribe;
  }, []);

  const navigate = useNavigate();

  const updateUserSubscriptionStatus = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    try {
      await setDoc(userDocRef, { isSubscribed: true }, { merge: true });
    } catch (error) {
      console.error("Error updating subscription status:", error);
    }
  };

  const updateUserMembershipStatus = async (userId, membershipOption) => {
    if (!userId) {
      return;
    }

    try {
      // Reference to the user's document in the 'users' collection
      const userDocRef = doc(db, "users", userId);

      // Update the document with the membershipOption
      await setDoc(
        userDocRef,
        { membershipOption: membershipOption },
        { merge: true }
      );
      // console.log("User membership option updated successfully.");
    } catch (error) {
      console.error("Error updating user membership status:", error);
    }
  };

  const scheduleAppointment = async (
    practitionerId,
    userId,
    selectedDate,
    selectedTime,
    userTimezone
  ) => {
    if (!userId) {
      return;
    }

    try {
      // Debugging the inputs
      // console.log("Selected Date:", selectedDate);
      // console.log("Selected Time:", selectedTime);
      // console.log("User Timezone:", userTimezone);

      const reformattedDate = moment(selectedDate, "dddd, MMMM D, YYYY").format(
        "YYYY-MM-DD"
      );
      const reformattedTime = moment(selectedTime, "h:mm A").format("HH:mm");

      // Create a moment object for the appointment time in the local timezone
      const appointmentLocalTime = moment.tz(
        `${reformattedDate} ${reformattedTime}`,
        "YYYY-MM-DD HH:mm",
        userTimezone
      );

      // Convert that local time to UTC and get a JavaScript Date object
      const appointmentTimeUTC = appointmentLocalTime.utc().toDate();

      const appointmentsRef = collection(db, "appointments");
      const docRef = await addDoc(appointmentsRef, {
        practitionerId: practitionerId,
        userId: userId,
        selectedDate: selectedDate,
        selectedTime: selectedTime,
        status: "requested",
        clientRequestDate: moment().utc().toDate(),
        timeZone: userTimezone,
        appointmentTimeUTC: appointmentTimeUTC,
      });

      // console.log("Appointment scheduled with ID: ", docRef.id);
      return docRef.id; // Optionally return the appointment ID
    } catch (e) {
      console.error("Error adding appointment to Firestore: ", e);
    }
  };

  const handleChange = (selectedValue) => {
    if (selectedValue === "Yearly") {
      setInterval("year");
    } else {
      setInterval("month");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js has not loaded yet.");
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(cardElement);

    if (error) {
      console.error("Error creating payment method:", error.message);
      setMessage(error.message);
      return;
    }

    const userId = auth.currentUser?.uid;
    const isSubscriptionNeeded = membershipOption !== "One-time visit";
    let endpoint, requestBody;

    if (isSubscriptionNeeded) {
      endpoint = "/subscription-and-purchase";
      requestBody = JSON.stringify({
        customerId: customerId,
        recurringPriceId: priceId, // This variable should have been set from a previous operation
        visitFeeAmount: visitFeeAmount * 100, // Convert to cents
        cardToken: token.id,
      });
    } else {
      endpoint = "/one-time-purchase";
      requestBody = JSON.stringify({
        customerId: customerId,
        visitFeeAmount: visitFeeAmount * 100, // Convert to cents
        cardToken: token.id,
      });
    }

    try {
      const response = await fetch(`http://localhost:5255${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: requestBody,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          data.error || "An error occurred while processing your payment."
        );
      }

      // console.log("Transaction successful:", data);
      setMessage("Transaction successful.");

      await updateUserMembershipStatus(userId, membershipOption);

      const appointmentId = await scheduleAppointment(
        practitionerId,
        userId,
        selectedDate,
        selectedTime,
        userTimezone
      );
      // console.log("Appointment ID:", appointmentId);
      if (isSubscriptionNeeded) {
        await updateUserSubscriptionStatus(userId);
      }

      navigate("/appointment-completion");
    } catch (error) {
      console.error("Transaction failed:", error);
      setMessage(
        error.message || "An error occurred while processing your payment."
      );
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Container style={{ paddingTop: "50px" }}>
      <Card>
        <form onSubmit={handleSubmit} style={{ paddingTop: "25px" }}>
          <CardElement />
          <div
            style={{
              paddingBottom: "25px",
              paddingTop: "25px",
              paddingLeft: "20px",
            }}
          >
            {/* <button type="submit" disabled={!stripe}>
              Pay
            </button> */}
            <button disabled={isProcessing || !stripe || !elements} id="submit">
              <span id="button-text">
                {isProcessing ? "Processing ... " : "Pay now"}
              </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
          </div>
        </form>
      </Card>
    </Container>
  );
};

export default CheckoutForm;
