import React from "react";
import PropTypes from "prop-types";
import "../../styles/General.css";

const ProgressBar = ({ progress, maxProgress }) => {
  return (
    <div className="progress-bar">
      <div
        className="progress-bar-fill"
        style={{ width: `${(progress / maxProgress) * 100}%` }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  maxProgress: PropTypes.number,
};

ProgressBar.defaultProps = {
  maxProgress: 100,
};

export default ProgressBar;
