import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const MembersBillingDetails = ({
  practitioner,
  selectedDate,
  selectedTime,
  isRescheduling, // Receive as props
  rescheduleFeeApplicable,
  onFeeCalculated,
}) => {
  const location = useLocation();
  //   console.log(location.state);
  //   console.log("Rescheduling:", isRescheduling);
  //   console.log("Fee applicable:", rescheduleFeeApplicable);

  const calculateFees = () => {
    const baseFee = Math.floor(practitioner.minimumPay * 1.25); // Ensure the practitioner's fee is obtained correctly
    let finalFee = baseFee;

    if (isRescheduling && rescheduleFeeApplicable) {
      finalFee = baseFee * 0.25; // Add 25% of the base fee for late rescheduling
    }
    onFeeCalculated(finalFee);
    return finalFee; // Return the numeric value for further calculations
  };

  const visitFee =
    practitioner && practitioner.minimumPay
      ? Math.floor(practitioner.minimumPay * 1.25)
      : 0;
  const rescheduleFee = isRescheduling ? calculateFees() : 0;

  return (
    <Container style={{ width: "600px", paddingTop: "0px" }}>
      <Row className="mb-3">
        <Col>
          <h4>Billing details</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ width: "550px" }}>
            <Card.Body>
              {/* Visit Fee Section */}
              <Row className="mb-3">
                <Col xs={8}>
                  <div>Visit fee</div>
                  <div>
                    With{" "}
                    {practitioner
                      ? `${practitioner.firstName} ${practitioner.lastName}`
                      : "the practitioner"}
                  </div>
                  <div>
                    {selectedDate} at {selectedTime}
                  </div>
                </Col>
                <Col xs={4} className="text-end">
                  ${visitFee.toFixed(2)}
                </Col>
              </Row>
              {/* Conditional Rescheduling Fee Section */}
              {isRescheduling && (
                <Row className="mb-3">
                  <Col xs={8}>
                    <div>Rescheduling fee</div>
                  </Col>
                  <Col xs={4} className="text-end">
                    ${rescheduleFee.toFixed(2)}
                  </Col>
                </Row>
              )}
              {/* Today's Total Section */}
              <Row>
                <Col xs={8}>
                  <strong>Today's total</strong>
                </Col>
                <Col xs={4} className="text-end">
                  <strong>
                    $
                    {isRescheduling
                      ? rescheduleFee.toFixed(2)
                      : visitFee.toFixed(2)}
                  </strong>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default MembersBillingDetails;
