import React, { useState } from "react";

function QueryForm() {
  const [query, setQuery] = useState("");
  const [responses, setResponses] = useState(null);

  const baseUrl = "http://localhost:4247/article/";

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const response = await fetch(
      "https://myfastapiapp-yjr4lxzhzq-uc.a.run.app/find-answers/",
      {
        // const response = await fetch("http://localhost:8000/find-answers/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      setResponses(data.responses);
    } else {
      console.error("Failed to fetch answers");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Enter your query"
        />
        <button type="submit">Submit</button>
      </form>

      {responses && (
        <div>
          <h3>Responses:</h3>
          <ul>
            {responses.map((response, index) => (
              <li key={index}>
                <p>Answer: {response.answer}</p>
                {/* <p>Source: {response.source}</p> */}
                <p>
                  {" "}
                  Learn more:{" "}
                  <a
                    href={`http://localhost:4247/article/${response.source}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {response.source}
                  </a>
                </p>
                {/* <p>Score: {response.score}</p> */}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default QueryForm;
