import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "../../../config/Firebase"; 
import { doc, getDoc, setDoc } from "firebase/firestore";
import moment from "moment";
import ClinetsDetails from "../clients/ClinetsDetails";

const MeetingNote = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userId, meetingId, practitionerId } = location.state || {};

  useEffect(() => {
    if (
      !location.state ||
      !location.state.userId ||
      !location.state.meetingId ||
      !location.state.practitionerId
    ) {
      // Redirect user to another page, for example, back to the dashboard or meetings list
      navigate("/meetings-management"); // Adjust the route as needed
    }
  }, [location.state, navigate]);

  //   const { userId, meetingId, practitionerId } = location.state;
  console.log("********", userId, meetingId, practitionerId);

  const [patientInfo, setPatientInfo] = useState({});
  const [visitDetails, setVisitDetails] = useState({
    dateAndTime: "",
    typeOfVisit: "",
    primaryReason: "",
  });
  const [clinicalNotes, setClinicalNotes] = useState({
    symptoms: "",
    diagnosis: "",
    treatmentPlan: "",
    additionalNotes: "",
  });
  const [prescriptions, setPrescriptions] = useState({
    medicationName: "",
    duration: "",
    refillInfo: "",
  });

  useEffect(() => {
    const fetchPatientAndMeetingInfo = async () => {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        setPatientInfo(userDocSnap.data());
      } else {
        console.log("No such user document!");
      }

      const meetingDocRef = doc(db, "meetings", meetingId);
      const meetingDocSnap = await getDoc(meetingDocRef);
      if (meetingDocSnap.exists()) {
        const appointmentTime = moment(
          meetingDocSnap.data().appointmentTime.toDate()
        ).format("YYYY-MM-DD HH:mm");
        setVisitDetails((prevDetails) => ({
          ...prevDetails,
          dateAndTime: appointmentTime,
        }));
      } else {
        console.log("No such meeting document!");
      }
    };

    fetchPatientAndMeetingInfo();
  }, [userId, meetingId]);

  const handleInputChange = (section, key) => (e) => {
    const value = e.target.value;
    switch (section) {
      case "visitDetails":
        setVisitDetails((prev) => ({ ...prev, [key]: value }));
        break;
      case "clinicalNotes":
        setClinicalNotes((prev) => ({ ...prev, [key]: value }));
        break;
      case "prescriptions":
        setPrescriptions((prev) => ({ ...prev, [key]: value }));
        break;
      default:
        break;
    }
  };

  const filterTrueKeys = (obj) => {
    return Object.keys(obj)
      .filter((key) => obj[key]) // Filter keys where the value is true
      .join(", "); // Join the filtered keys with a comma and a space
  };

  const handleSave = async () => {
    const historyRef = doc(db, "clients-history", `${meetingId}-${userId}`);
    await setDoc(historyRef, {
      ...visitDetails,
      ...clinicalNotes,
      ...prescriptions,
      practitionerId,
      userId,
      meetingId,
    });
    alert("Saved successfully!");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formValues = { ...visitDetails, ...clinicalNotes, ...prescriptions };
    const emptyFields = Object.values(formValues).some((value) => value === "");
    if (emptyFields) {
      alert("Please fill in all the fields.");
      return;
    }
    // Ideally, you'd handle the submission more robustly here, e.g., updating the meeting status in Firestore
    alert("All fields are filled. Implement submission logic here.");
  };

  //   const viewPatientProfile = () => {
  //     navigate(`/client-details/${userId}`); // Adjust as per your routing setup
  //   };

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  //   const viewPatientProfile = () => {
  //     // Opens in a new tab/window depending on the browser setting
  //     window.open(`/client-details/${userId}`, "_blank");
  //   };

  return (
    <Container style={{ paddingTop: "100px" }}>
      <h2>Patient Information</h2>
      <Row style={{ paddingBottom: "30px", paddingTop: "30px" }}>
        <Col>
          Clinet name: {patientInfo.firstName} {patientInfo.lastName}
        </Col>
        <Col>Gender: {patientInfo.gender}</Col>
        <Col>Age range: {patientInfo.ageRange}</Col>
        <Col>Email:{patientInfo.email}</Col>
        <Col>
          <Button onClick={handleShow}>View Patient Profile</Button>
          {/* <Button onClick={viewPatientProfile}>View Patient Profile</Button> */}
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Client Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Here you would include the content you want to display, potentially another component */}
          <ClinetsDetails userId={userId} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <h2>Visit Details</h2>
      <hr />
      <Form onSubmit={handleSubmit}>
        <div style={{ paddingTop: "20px", paddingBottom: "30px" }}>
          <Row style={{ paddingBottom: "20px" }}>
            <Col>Date and Time of Visit: {visitDetails.dateAndTime}</Col>
          </Row>
          <Row style={{ paddingBottom: "20px" }}>
            <Col md={6}>
              <Form.Select
                aria-label="Type of Visit"
                onChange={handleInputChange("visitDetails", "typeOfVisit")}
                style={{ width: "100%" }} // Adjusted to 100% to fill the column
              >
                <option value="">Select Type of Visit</option>
                <option value="Initial consultation">
                  Initial consultation
                </option>
                <option value="Follow-up">Follow-up</option>
                <option value="Nutritional Counseling">
                  Nutritional Counseling
                </option>
                <option value="Herbal Medicine Consultation">
                  Herbal Medicine Consultation
                </option>
                <option value="Detoxification Therapy">
                  Detoxification Therapy
                </option>
                <option value="Chronic Condition Management">
                  Chronic Condition Management
                </option>
                <option value="Stress Management and Mindfulness Training">
                  Stress Management and Mindfulness Training
                </option>
                <option value="Sleep Medicine Counseling">
                  Sleep Medicine Counseling
                </option>
              </Form.Select>
            </Col>

            {/* Second Column */}
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder="Primary reason for the visit"
                onChange={handleInputChange("visitDetails", "primaryReason")}
                style={{ width: "100%" }} // Adjusted to 100% to fill the column
              />
            </Col>
          </Row>
        </div>

        <div style={{ paddingTop: "50px", paddingBottom: "30px" }}>
          <h2>Clinical Notes</h2>
          <hr />
          <Row style={{ paddingBottom: "20px" }}>
            <Col>
              <Form.Control
                as="textarea"
                placeholder="Detailed description of symptoms"
                onChange={handleInputChange("clinicalNotes", "symptoms")}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: "20px" }}>
            <Col>
              <Form.Control
                as="textarea"
                placeholder="Provisional or confirmed diagnosis"
                onChange={handleInputChange("clinicalNotes", "diagnosis")}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: "20px" }}>
            <Col>
              <Form.Control
                as="textarea"
                placeholder="Medications prescribed, lifestyle advice, follow-up instructions"
                onChange={handleInputChange("clinicalNotes", "treatmentPlan")}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: "20px" }}>
            <Col>
              <Form.Control
                as="textarea"
                placeholder="Any other observations or notes"
                onChange={handleInputChange("clinicalNotes", "additionalNotes")}
              />
            </Col>
          </Row>
        </div>

        <div style={{ paddingTop: "50px", paddingBottom: "30px" }}>
          <h2>Prescriptions</h2>
          <hr />
          <Row style={{ paddingBottom: "20px" }}>
            <Col>
              <Form.Control
                type="text"
                placeholder="Medication Name with dosage and frequency"
                onChange={handleInputChange("prescriptions", "medicationName")}
              />
            </Col>
          </Row>
          <Row style={{ paddingBottom: "20px" }}>
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder="Duration"
                onChange={handleInputChange("prescriptions", "duration")}
              />
            </Col>
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder="Refill Information"
                onChange={handleInputChange("prescriptions", "refillInfo")}
              />
            </Col>
          </Row>
          {/* <Row style={{ paddingBottom: "20px" }}></Row> */}
        </div>

        <Row
          style={{
            paddingBottom: "100px",
          }}
          className="justify-content-md-center"
        >
          <Col className="d-flex justify-content-center">
            <Button
              variant="secondary"
              type="button"
              onClick={handleSave}
              style={{ marginRight: "10px" }}
            >
              Save and Continue Later
            </Button>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default MeetingNote;
