import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../config/Firebase"; // Adjust this import path
import {
  doc,
  getDoc,
  query,
  where,
  getDocs,
  collection,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import moment from "moment";
import { Timestamp } from "firebase/firestore";

const MeetingRoom = () => {
  const [showModal, setShowModal] = useState(false);
  const [passcode, setPasscode] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  //   const [channelNameFromQuery, setChannelNameFromQuery] = useState("");
  //   const searchParams = new URLSearchParams(window.location.search);
  //   const channelName = searchParams.get("channelName");
  //   const practitionerId = searchParams.get("practitionerId");
  //   const meetingId = searchParams.get("meetingId");
  //   const token = searchParams.get("token");

  useEffect(() => {
    // Function to extract query parameters
    const getQueryParams = () => {
      const searchParams = new URLSearchParams(window.location.search);
      return {
        practitionerId: searchParams.get("practitionerId"),
        channelName: searchParams.get("channelName"),
        token: searchParams.get("token"),
        meetingId: searchParams.get("meetingId"),
      };
    };

    const checkUserRole = async (userId) => {
      const { practitionerId, channelName, token, meetingId } =
        getQueryParams();
      const docRef = doc(db, "practitioners", userId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        // User is a client
        setShowModal(true);
      } else if (practitionerId && channelName && token) {
        // console.log("&&&&&&&&&&&&&&&&", meetingId, channelName, token);
        // Practitioner with valid parameters, navigate to video-wrapper
        navigate("/video-wrapper", {
          state: {
            calling: true,
            meetingId,
            channelName,
            token,
          },
        });
      }
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        checkUserRole(user.uid);
      } else {
        // Handle user not logged in or redirect
        console.log("No user signed in");
      }
    });
  }, [navigate]); // Add navigate to useEffect dependencies

  const validateMeeting = async () => {
    const userId = auth.currentUser.uid;
    const startOfDay = moment().startOf("day").toDate();
    const endOfDay = moment().endOf("day").toDate();

    // console.log(
    //   `Validating meeting for userId: ${userId}, passcode: ${passcode}`
    // );
    // console.log(`Start of day: ${startOfDay}, End of day: ${endOfDay}`);

    const q = query(
      collection(db, "meetings"),
      where("userId", "==", userId),
      where("channelName", "==", passcode),
      where("appointmentTime", ">=", Timestamp.fromDate(startOfDay)),
      where("appointmentTime", "<=", Timestamp.fromDate(endOfDay))
    );

    const querySnapshot = await getDocs(q);

    // console.log(`Meetings found: ${querySnapshot.size}`); // See how many meetings are returned by the query

    if (querySnapshot.empty) {
      console.error("Incorrect passcode or no appointments at this time.");
      setError("Incorrect passcode or no appointments at this time.");
      return;
    }

    let isValidMeeting = false;
    let meetingToken = null;
    querySnapshot.forEach((doc) => {
      const meetingData = doc.data();
      const meetingTimestamp = meetingData.appointmentTime.toDate();
      const meetingTime = moment(meetingTimestamp);
      const now = moment();
      const startWindow = meetingTime.clone().subtract(20, "minutes");
      const endWindow = meetingTime.clone().add(20, "minutes");
      //   const meetingToken = meetingData.token;
      //   console.log("meeting token is ^^^^^^^^^^^^^^^", meetingToken);

      if (now.isBetween(startWindow, endWindow) && meetingData.token) {
        // console.log(`Meeting ${doc.id} is valid`);
        meetingToken = meetingData.token; // Assign the token from Firestore document
        isValidMeeting = true;
      } else {
        console.log(
          `Meeting ${doc.id} is outside the valid time window or missing token.`
        );
      }
    });

    if (isValidMeeting && meetingToken) {
      navigate("/video-wrapper", {
        state: {
          calling: true,
          //   appId: "Your_Agora_App_ID",
          channelName: passcode,
          token: meetingToken, // Pass the fetched token
        },
      });
      //   console.log("meeting token that is passed is***********", meetingToken);
    } else {
      setError("Your appointment is at a later time or you have missed it.");
    }
  };

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Enter Meeting Passcode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form>
            <Form.Group>
              <Form.Label>Passcode</Form.Label>
              <Form.Control
                type="text"
                value={passcode}
                onChange={(e) => setPasscode(e.target.value)}
              />
            </Form.Group>
            <Button
              variant="primary"
              onClick={validateMeeting}
              className="mt-3"
            >
              Join Video Session
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MeetingRoom;
