import React from "react";
import {
  Container,
  Accordion,
  Form,
  Button,
  ProgressBar,
  Dropdown,
} from "react-bootstrap";
import "../../styles/General.css";

const topics = [
  {
    title: "General Info",
    content: [
      {
        question: "What is Sârv?",
        answer:
          "Sârv is an app designed to help you receive personalized holistic healthcare recommendations that fit your body and health goals. All recommendations are based on natural and medicinal herbs",
      },
      {
        question:
          "How is Sârv's recommendation different from other online platforms?",
        answer:
          "Sârv first understands how your body works by asking a few questions, then creates a body profile describing your characteristics and health goals or concerns. It then recommends natural remedies based on medicinal plants to help you achieve your health goals. \n\n Our knowledge base has been created from hundreds of scientific books and peer-reviewed articles written by experts in the fields of phytotherapy, medicinal plants, naturopathy, and nutrition. ",
      },
      {
        question: "What does Sârv premium membership include?",
        answer:
          "Sarv premium membership offers a comprehensive educational database on medicinal plants and over 300 natural remedies for common ailments. It also provides personalized health recommendations tailored to the user's symptoms and biology.",
      },
      {
        question: "Is my data secure? What is your privacy policy?",
        answer:
          "We take data security and your privacy seriously and your information will be stored securely. Our full privacy policy can be found at privacy policy section.",
      },
      {
        question: "Is Sârv FDA approved?",
        answer:
          "No, Sârv is not meant to diagnose or treat any medical conditions. It is an app that is meant for general wellness purposes only.",
      },
      {
        question: "How much does the Sârv program cost?",
        answer: [
          "There are three different membership options. Payment is charged the day you register and auto-renews at the end of each period, unless you reach out to us to cancel.",
          "1 month for $15, charged monthly.",
          "4 months for a total of $50, charged upfront.",
          "12 months for a total of $120, charged upfront.",
        ],
      },
    ],
  },
  {
    title: "Account",
    content: [
      {
        question: "Do I need a smartphone to access Sârv?",
        answer:
          "Yes. The Sârv app is currently available on both iOS (iPhone or iPad with iOS version 13.2 or later) and Android devices (Samsung, LG, Motorola etc. with Android 8.0 or later).",
      },
      {
        question: "What do I do if I forgot my login credentials?",
        answer:
          "We're sorry about that! Please contact us at support@joinsarv.com, and we will assist you with credential retrieval.",
      },
      // More questions and answers for Topic 1
    ],
  },
  {
    title: "Partnerships",
    content: [
      {
        question: "I would love to partner up with Sârv! Who can I contact?",
        answer:
          "Please forward any affiliate/partnership requests to support@joinsarv.com  or fill out this <a href='/affiliate-request'>form</a>  and our team will get back to you.",
        isHtml: true,
      },
      {
        question:
          "I'm a licensed practitioner. How can I provide services using Sârv?",
        answer:
          "Please start the application process by clicking <a href='/careers'>here</a>.",
        isHtml: true, // Add a flag to indicate this is HTML content
      },

      // { question: "Question 1.2", answer: "Answer 1.2" },
      // More questions and answers for Topic 1
    ],
  },
  {
    title: "Cancellations & Refunds",
    content: [
      {
        question: "What is your returns & refund policy?",
        answer:
          "All purchases are non-refundable and cannot be cancelled once you have enrolled in our premium membership program.",
      },
      {
        question: "How do I update or cancel my Sârv program membership?",
        answer:
          "You can manage your membership in the Sârv app. In the menu, tap on ‘Settings’, and you’ll be able to cancel or update your subscription from the ‘Account’ section.",
      },
      {
        question: "How can I give feedback or raise a complaint?",
        answer:
          "We're sorry to hear about any disappointment, and we're eager to sort it out. Thank you for taking the time to talk to us. We want to ensure that we're aware of any issues so we can correct them, not just for you, but for others, too. We'll do our best not to make the same mistake again. Please email us anytime at support@joinsarv.com or reach out to us via the chat and select the complaints option.",
      },
      // More questions and answers for Topic 2
    ],
  },
  // More topics
];

const FAQ = () => {
  return (
    <div className="progress-container">
      <Container className="d-flex align-items-center justify-content-center">
        <div
          className="w-100"
          style={{
            maxWidth: "1000px",
            paddingTop: "60px",
            paddingBottom: "50px",
          }}
        >
          <div style={{ textAlign: "center" }}>
            <h3 className="faq-title">Frequently asked questions</h3>
          </div>

          <Form>
            {topics.map((topic, topicIndex) => (
              <Accordion
                defaultActiveKey="0"
                key={topic.title}
                className="my-accordion"
              >
                <Accordion.Item
                  eventKey={topicIndex.toString()}
                  className="my-accordion-item"
                  style={{ border: "none" }}
                >
                  <Accordion.Header>
                    <div className="email-subscription-title">
                      {topic.title}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {topic.content.map((item, itemIndex) => (
                      <div key={itemIndex}>
                        <h5>{item.question}</h5>
                        {Array.isArray(item.answer) ? (
                          <ul>
                            {item.answer.map((point, index) => (
                              <li key={index}>{point}</li>
                            ))}
                          </ul>
                        ) : item.isHtml ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                            style={{ paddingBottom: "15px" }}
                          ></div> // Render HTML using dangerouslySetInnerHTML
                        ) : (
                          <p>{item.answer}</p>
                        )}
                      </div>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default FAQ;

// const FAQ = () => {
//   return (
//     <div className="progress-container">
//       <Container className="d-flex align-items-center justify-content-center">
//         <div
//           className="w-100"
//           style={{
//             maxWidth: "1000px",
//             paddingTop: "60px",
//             paddingBottom: "50px",
//           }}
//         >
//           <div style={{ textAlign: "center" }}>
//             <h3 className="faq-title">Frequently asked questions</h3>
//           </div>

//           <Form>
//             {topics.map((topic, topicIndex) => (
//               <Accordion
//                 defaultActiveKey="0"
//                 key={topic.title}
//                 className="my-accordion"
//               >
//                 <Accordion.Item
//                   eventKey={topicIndex.toString()}
//                   className="my-accordion-item"
//                   style={{ border: "none" }}
//                 >
//                   <Accordion.Header>
//                     <div className="email-subscription-title">
//                       {topic.title}
//                     </div>
//                   </Accordion.Header>
//                   <Accordion.Body>
//                     {topic.content.map((item, itemIndex) => (
//                       <div key={itemIndex}>
//                         <h5>{item.question}</h5>
//                         {Array.isArray(item.answer) ? (
//                           <ul>
//                             {item.answer.map((point, index) => (
//                               <li key={index}>{point}</li>
//                             ))}
//                           </ul>
//                         ) : (
//                           <p>{item.answer}</p>
//                         )}
//                       </div>
//                     ))}
//                   </Accordion.Body>
//                 </Accordion.Item>
//               </Accordion>
//             ))}
//           </Form>
//         </div>
//       </Container>
//     </div>
//   );
// };

// export default FAQ;
