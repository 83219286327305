import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../config/Firebase";
import { Plus } from "react-bootstrap-icons";

const HerbsInfo = () => {
  const [herbs, setHerbs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHerbs = async () => {
      const herbsCollectionRef = collection(db, "herbs");
      const herbsSnapshot = await getDocs(herbsCollectionRef);
      const herbsList = herbsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setHerbs(herbsList);
    };

    fetchHerbs();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredHerbs = herbs.filter((herb) => {
    const searchLower = searchTerm.toLowerCase();
    const titleMatch = herb.title.toLowerCase().includes(searchLower);
    const otherNamesMatch =
      herb.other_names && herb.other_names.toLowerCase().includes(searchLower);
    return titleMatch || otherNamesMatch;
  });

  return (
    <Container style={{ paddingTop: "100px" }}>
      <Row className="mb-4" style={{ paddingBottom: "50px" }}>
        <Col>
          <Form.Control
            type="text"
            placeholder="Search for herbs by title or other names..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Button
            variant="outline-primary"
            onClick={() => navigate("/add-herbs")}
          >
            <Plus size={20} />
          </Button>
        </Col>
      </Row>
      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>Image</th>
            <th>Name</th>
            <th>Other Names</th>
            <th>Medical Uses</th>
          </tr>
        </thead>
        <tbody>
          {filteredHerbs.map((herb, index) => (
            <tr
              key={herb.id}
              onClick={() => navigate(`/herbs-details/${herb.id}`)}
            >
              <td>{index + 1}</td>
              <td>{herb.id}</td>
              <td>
                <img
                  src={herb.imgUrl}
                  alt={herb.title}
                  style={{ width: "50px", height: "50px" }}
                />
              </td>
              <td>{herb.title}</td>
              <td>{herb.other_names}</td>
              <td>{herb.benefits}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default HerbsInfo;
