import React, { useEffect } from "react";
// import { BrowserRouter as Router, Route } from "react-router-dom";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Header from "./components/generals/Header";
import Mission from "./components/about/Mission";
import FAQ from "./components/generals/FAQ";
import Footer from "./components/generals/Footer";
import Home from "./components/generals/Home";
import Menu from "./components/generals/Menu";
import TermsOfService from "./components/generals/TermsOfService";
import PrivacyPolicy from "./components/generals/PrivacyPolicy";
import HerbCategories from "./components/herbs/HerbCategories";
import "./App.css";
import Payment from "./components/payment/stripe/Payment";
import Completion from "./components/payment/stripe/Completion";
import LibraryHome from "./components/library/LibraryHome";
import ArticleDisplay from "./components/library/ArticleDisplay";
import ArticleComponent from "./components/library/ArticleComponent";
import DoshaQuiz from "./components/quizzes/DoshaQuiz";
import Login from "./components/login/Login";
import AppWaitList from "./components/generals/AppWaitList";
import Contact from "./components/generals/Contact";
import SignUp from "./components/login/SignUp";
import ForgotPassword from "./components/login/ForgotPassword";
import Age from "./components/enrollment/Age";
import Gender from "./components/enrollment/Gender";
import Weight from "./components/enrollment/Weight";
import Alcohol from "./components/enrollment/Alcohol";
import DrVisits from "./components/enrollment/DrVisits";
import Exercise from "./components/enrollment/Exercise";
import Stress from "./components/enrollment/Stress";
import Sleep from "./components/enrollment/Sleep";
import Smoke from "./components/enrollment/Smoke";
import Diet from "./components/enrollment/Diet";
import FruitsVeggies from "./components/enrollment/FruitsVeggies";
import DietRating from "./components/enrollment/DietRating";
import ScreenTime from "./components/enrollment/ScreenTime";
import HealthIssues from "./components/enrollment/HealthIssues";
import StressSymptoms from "./components/enrollment/StressSymptoms";
import PregnancyStatus from "./components/enrollment/PregnancyStatus";
import MentalHealthStatus from "./components/enrollment/MentalHealthStatus";
import HealthGoal from "./components/enrollment/HealthGoal";
import MentalHealthSatisfaction from "./components/enrollment/MentalHealthSatisfaction";
import PhysicalHealthSatisfaction from "./components/enrollment/PhysicalHealthSatisfaction";
import WaterIntake from "./components/enrollment/WaterIntake";
import Allergies from "./components/enrollment/Allergies";
import ChronicDiseases from "./components/enrollment/ChronicDiseases";
import CurrentMedication from "./components/enrollment/CurrentMedication";
import FamilyHistory from "./components/enrollment/FamilyHistory";
import SleepDisorders from "./components/enrollment/SleepDisorders";
import DiseaseCategories from "./components/symptom-checker/DiseaseCategories";
import WellnessPractices from "./components/enrollment/WellnessPractices";
import MobilityIssues from "./components/enrollment/MobilityIssues";
import ChronicPain from "./components/enrollment/ChronicPain";
import Options from "./components/enrollment/Options";
import Article from "./components/library/Article"; // Adjust the import path as necessary
import QueryForm from "./components/library/QueryForm";
import MaraljsonManager from "./components/utils/MaraljsonManager";
import RemedyRecommendation from "./components/symptom-checker/RemedyRecommendation";
import QuizComponent from "./components/symptom-checker/QuizComponent";
import ArticleCategories from "./components/library/ArticleCategories";
import SubscriptionPayment from "./components/payment/SubscriptionPayment";
import SubscriptionOptions from "./components/payment/SubscriptionOptions";
import Order from "./components/payment/Order";
import { SubscriptionProvider } from "./components/payment/SubscriptionContext";
import Careers from "./components/practitioners/Careers";
import Expertise from "./components/practitioners/signup/Expertise";
import Background from "./components/practitioners/signup/Background";
import GeneralInfo from "./components/practitioners/signup/GeneralInfo";
import ProfileDescription from "./components/practitioners/signup/ProfileDescription";
import ProfilePhoto from "./components/practitioners/signup/ProfilePhoto";
import Availability from "./components/practitioners/signup/Availability";
import DirectDepositInfo from "./components/practitioners/signup/DirectDepositInfo";
import ApplicationComplete from "./components/practitioners/signup/ApplicationComplete";
import HerbalistForm from "./components/practitioners/signup/HerbalistForm";
import PractitionerProfile from "./components/practitioners/profile/PractitionerProfile";
import PractitionersDashboard from "./components/practitioners/PractitionersDashboard";
import AppointmentManagement from "./components/practitioners/management/AppointmentManagement";
import MeetingsManagement from "./components/practitioners/management/MeetingsManagement";
import MeetingNote from "./components/practitioners/management/MeetingNote";
import ClientsInfo from "./components/practitioners/clients/ClientsInfo";
import ClinetsDetails from "./components/practitioners/clients/ClinetsDetails";
import ViewPractitioners from "./components/appointment/ViewPractitioners";
import PractitionerProfileDetails from "./components/appointment/PractitionerProfileDetails";
import ScheduleAppointment from "./components/appointment/ScheduleAppointment";
import MembersScheduleAppointments from "./components/appointment/MembersScheduleAppointments";
import Rescheduling from "./components/appointment/Rescheduling";
import UserProfile from "./components/user-profile/UserProfile";
import UpcomingAppointments from "./components/user-profile/appointments-history/UpcomingAppointments";
import PastAppointments from "./components/user-profile/appointments-history/PastAppointments";
import UserSettings from "./components/user-profile/UserSettings";
import AppointmentCompletion from "./components/appointment/payment/AppointmentCompletion";
import HerbsInfo from "./components/admin/herbs/HerbsInfo";
import PhytotherapyIndex from "./components/admin/herbs/PhytotherapyIndex";
import HerbsDetails from "./components/admin/herbs/HerbsDetails";
import AddHerbs from "./components/admin/herbs/AddHerbs";
import AddRecipe from "./components/admin/recipes/AddRecipe";
import RecipeDetails from "./components/admin/recipes/RecipeDetails";
import RecipesInfo from "./components/admin/recipes/RecipesInfo";

import PractitionerInfo from "./components/admin/practitioners-mng/PractitionerInfo";
import PractitionersDetails from "./components/admin/practitioners-mng/PractitionersDetails";
import UsersInfo from "./components/admin/users-mng/UsersInfo";
import Decryptor from "./components/admin/users-mng/Decryptor";
import ConvertFieldToArray from "./components/admin/tools/ConvertFieldToArray";
import UniqueCategories from "./components/admin/tools/UniqueCategories";
import AdminDashboard from "./components/admin/AdminDashboard";

import DemoRequest from "./components/business/DemoRequest";
import AffiliateRequest from "./components/business/AffiliateRequest";

import Welcome from "./components/practitioners/Welcome";
import PractitionerSettings from "./components/practitioners/PractitionerSettings";
import HowDidYouHearAboutUs from "./components/enrollment/HowDidYouHearAboutUs";
import Services from "./components/services/Services";
import MeetingRoom from "./components/video-call/MeetingRoom";
import VideoCallWrapper from "./components/video-call/VideoCallWrapper";

import Dashboard from "./components/explorer/Dashboard";
import Recipes from "./components/explorer/recipes-categories/Recipes";
import RecipesAll from "./components/explorer/recipes-categories/RecipesAll";
import HerbsAll from "./components/explorer/herb-categories/HerbsAll";
import HerbsGrid from "./components/explorer/herb-categories/HerbsGrid";

// import PersonalizedRecipes from "./components/explorer/recommedations/PersonalizedRecipes";

// import ReactGA from "react-ga";
import ReactGA from "react-ga4";

// const trackingId = "G-D979R8MZ1C"; // ga tracking
const trackingId = "G-EMRNLHKV1G"; // GA4 tracking ID

ReactGA.initialize(trackingId);

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
}

const Layout = ({ children }) => {
  usePageTracking();
  return <div>{children}</div>;
};

const FooterWrapper = () => {
  const location = useLocation();
  const noFooterPaths = [
    "/video-room",
    "/meeting-room",
    "/video-wrapper",
    "/meeting-notes",
  ];
  if (noFooterPaths.includes(location.pathname)) {
    return null;
  }
  return <Footer />;
};

function App() {
  return (
    <Router>
      <SubscriptionProvider>
        <Layout>
          <Menu />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/learn" element={<LibraryHome />} />
            <Route path="/article/:id" element={<Article />} />
            <Route path="/article-categories" element={<ArticleCategories />} />
            <Route path="/QueryForm" element={<QueryForm />} />
            <Route path="/MaraljsonManager" element={<MaraljsonManager />} />
            <Route path="/get-started" element={<Age />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/expertise" element={<Expertise />} />
            <Route path="/background" element={<Background />} />
            <Route path="/general-info" element={<GeneralInfo />} />
            <Route path="/bio" element={<ProfileDescription />} />
            <Route path="/profile-photo" element={<ProfilePhoto />} />
            <Route path="/availability" element={<Availability />} />
            <Route path="/payment-info" element={<DirectDepositInfo />} />
            <Route path="/herbalist-form" element={<HerbalistForm />} />
            <Route
              path="/application-complete"
              element={<ApplicationComplete />}
            />
            <Route
              path="/practitioner-profile"
              element={<PractitionerProfile />}
            />
            <Route path="/view-practitioners" element={<ViewPractitioners />} />
            {/* <Route
            path="/practitioner-profile-details/:userId"
            element={<PractitionerProfileDetails />}
          /> */}
            <Route
              path="/practitioner-profile-details/:practitionerId"
              element={<PractitionerProfileDetails />}
            />
            <Route
              path="/scheduling-appointment/:userId"
              element={<ScheduleAppointment />}
            />
            <Route
              path="/members-scheduling-appointment/:userId"
              element={<MembersScheduleAppointments />}
            />
            <Route
              path="/rescheduling/:practitionerId"
              element={<Rescheduling />}
            />
            <Route
              path="/appointment-completion"
              element={<AppointmentCompletion />}
            />
            {/* <Route path="/learn/article" element={<ArticleDisplay />} /> */}
            <Route path="/learn/article" element={<ArticleComponent />} />
            {/* <Route path="/learn/article/:articleId" element={<ArticleDisplay />} /> */}
            {/* <Route path="/article/:articleId" component={ArticleDisplay} /> */}
            <Route path="/mission" element={<Mission />} />
            <Route path="/login" element={<Login />} />
            <Route path="/app-waitlist" element={<AppWaitList />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/forgotPassword" element={<ForgotPassword />} />
            <Route path="/quiz" element={<DoshaQuiz />} />
            <Route path="/herbCategories" element={<HerbCategories />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/completion" element={<Completion />} />
            <Route path="/services" element={<Services />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/gender" element={<Gender />} />
            <Route path="/alcohol" element={<Alcohol />} />
            <Route path="/allergies" element={<Allergies />} />
            <Route path="/chronicDiseases" element={<ChronicDiseases />} />
            <Route path="/chronicPain" element={<ChronicPain />} />
            <Route path="/currentMedication" element={<CurrentMedication />} />
            <Route path="/diet" element={<Diet />} />
            <Route path="/dietRating" element={<DietRating />} />
            <Route path="/drVisits" element={<DrVisits />} />
            <Route path="/exercise" element={<Exercise />} />
            <Route path="/familyHistory" element={<FamilyHistory />} />
            <Route path="/fruits" element={<FruitsVeggies />} />
            <Route path="/healthGoal" element={<HealthGoal />} />
            <Route path="/healthIssues" element={<HealthIssues />} />
            <Route
              path="/mentalHealthStatus"
              element={<MentalHealthStatus />}
            />
            <Route
              path="/mentalHealthSatisfaction"
              element={<MentalHealthSatisfaction />}
            />
            <Route path="/mobilityIssues" element={<MobilityIssues />} />
            <Route path="/options" element={<Options />} />
            <Route path="/pregnancyStatus" element={<PregnancyStatus />} />
            <Route path="/screenTime" element={<ScreenTime />} />
            <Route path="/sleep" element={<Sleep />} />
            <Route path="/sleepDisorders" element={<SleepDisorders />} />
            <Route path="/smoke" element={<Smoke />} />
            <Route path="/stress" element={<Stress />} />
            <Route path="/stressSymptoms" element={<StressSymptoms />} />
            <Route
              path="/remedy-recommendation"
              element={<RemedyRecommendation />}
            />
            <Route path="/diseaseCategories" element={<DiseaseCategories />} />
            <Route
              path="/physicalHealthSatisfaction"
              element={<PhysicalHealthSatisfaction />}
            />
            <Route path="/weight" element={<Weight />} />
            <Route path="/waterIntake" element={<WaterIntake />} />
            <Route path="/wellnessPractices" element={<WellnessPractices />} />
            <Route
              path="/how-you-heard-about-us"
              element={<HowDidYouHearAboutUs />}
            />
            <Route path="/quizComponent" element={<QuizComponent />} />

            {/* <Route path="/subscriptionOptions" element={<SubscriptionOptions />} /> */}
            <Route
              path="/subscription-payment"
              element={<SubscriptionPayment />}
            />
            {/* <Route path="/order" element={<Order />} /> */}
            <Route
              path="/subscriptionOptions"
              element={<SubscriptionOptions />}
            />
            <Route path="/user-profile" element={<UserProfile />} />
            <Route path="/upcoming-visits" element={<UpcomingAppointments />} />
            <Route path="/past-visits" element={<PastAppointments />} />
            <Route path="/user-settings" element={<UserSettings />} />
            {/********************* practitioners components ***********************/}
            <Route
              path="/practitioners-dashboard"
              element={<PractitionersDashboard />}
            />
            <Route
              path="/appointments-mng"
              element={<AppointmentManagement />}
            />
            <Route path="/meetings-mng" element={<MeetingsManagement />} />
            <Route path="/meeting-notes" element={<MeetingNote />} />
            <Route path="/clients-info" element={<ClientsInfo />} />
            <Route path="/client-details/:id" element={<ClinetsDetails />} />
            <Route path="/order" element={<Order />} />
            <Route path="/welcome" element={<Welcome />} />
            <Route
              path="/practitioner-settings"
              element={<PractitionerSettings />}
            />

            {/********************* admin components ***********************/}

            <Route path="/herbs-info" element={<HerbsInfo />} />
            <Route path="/phytotherapy-index" element={<PhytotherapyIndex />} />
            <Route path="/add-herbs" element={<AddHerbs />} />
            <Route path="/herbs-details/:id" element={<HerbsDetails />} />
            <Route path="/maral1362" element={<AdminDashboard />} />
            <Route path="/practitioners-info" element={<PractitionerInfo />} />
            <Route
              path="/practitioners-details/:id"
              element={<PractitionersDetails />}
            />
            <Route path="/users-info" element={<UsersInfo />} />
            <Route path="/decryptor" element={<Decryptor />} />
            <Route path="/add-recipe" element={<AddRecipe />} />
            <Route path="/recipe-details/:id" element={<RecipeDetails />} />
            <Route path="/recipes-info" element={<RecipesInfo />} />
            <Route path="/to-array" element={<ConvertFieldToArray />} />
            <Route path="/unique-categories" element={<UniqueCategories />} />
            {/********************* Business components ***********************/}
            <Route path="/demo-request" element={<DemoRequest />} />
            <Route path="/affiliate-request" element={<AffiliateRequest />} />

            {/********************* Video components ***********************/}
            <Route path="/meeting-room" element={<MeetingRoom />} />
            <Route path="/video-wrapper" element={<VideoCallWrapper />} />
            {/********************* Explorer components ***********************/}
            <Route path="/explorer" element={<Dashboard />} />
            <Route path="/recipes/:categoryName" element={<Recipes />} />
            <Route path="/recipes/all" element={<RecipesAll />} />
            <Route path="/herbs/all" element={<HerbsAll />} />
            <Route path="/view-herbs/" element={<HerbsGrid />} />

            {/* <Route
              path="/recipes/recommended"
              element={<PersonalizedRecipes />}
            />*/}
          </Routes>
          <FooterWrapper />
          {/* <Footer /> */}
        </Layout>
      </SubscriptionProvider>
    </Router>
  );
}

export default App;
