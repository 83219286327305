import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db, auth } from "../../../config/Firebase";

function AppointmentCompletion() {
  const location = useLocation();
  const [message, setMessage] = useState(""); // State to store a message to the user

  return (
    <div>
      <h1>Thank you! 🎉</h1>
      <p>{message}</p>
    </div>
  );
}

export default AppointmentCompletion;
