import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SearchComponent from "../utils/SearchComponent";
import { auth, db } from "../../config/Firebase";
import { doc, getDoc } from "firebase/firestore";
import "../../styles/General.css";
import ReactGA from "react-ga4";

const terms = [
  {
    title: "Absence Of Mense",
    fileName: "absence-of-menses.json",
    condition: "Absence Of Menses",
  },
  { title: "Acne", fileName: "acne.json", condition: "Acne" },
  { title: "Allergies", fileName: "allergy.json", condition: "Allergies" },
  { title: "Anemia", fileName: "anemia.json", condition: "Anemia" },
  { title: "Anxiety", fileName: "anxiety.json", condition: "Anxiety" },
  { title: "Arthritis", fileName: "arthritis.json", condition: "Arthritis" },
  { title: "Asthma", fileName: "asthma.json", condition: "Asthma" },
  { title: "Bad Breath", fileName: "bad-breath.json", condition: "Bad Breath" },
  {
    title: "Bites And Stings",
    fileName: "bites-and-stings.json",
    condition: "Bites And Stings",
  },
  {
    title: "Bladder Incontinence",
    fileName: "bladder-incontinence.json",
    condition: "Bladder Incontinence",
  },
  {
    title: "Brain Fatigue",
    fileName: "brain-fatigue.json",
    condition: "Brain Fatigue",
  },
  {
    title: "Breast Health",
    fileName: "breast-health.json",
    condition: "Breast Health",
  },
  { title: "Bronchitis", fileName: "bronchitis.json", condition: "Bronchitis" },
  { title: "Bruises", fileName: "bruises.json", condition: "Bruises" },
  {
    title: "Chest Colds",
    fileName: "chest-colds.json",
    condition: "Chest Colds",
  },
  {
    title: "Chronic Low Immunity",
    fileName: "chronic-low-immunity.json",
    condition: "Chronic Low Immunity",
  },
  { title: "Cold Sores", fileName: "cold-sores.json", condition: "Cold Sores" },
  { title: "Coughs", fileName: "cold.json", condition: "Coughs" },
  {
    title: "Constipation",
    fileName: "constipation.json",
    condition: "Constipation",
  },
  { title: "Depression", fileName: "depression.json", condition: "Depression" },
  { title: "Diarrhea", fileName: "diarrhea.json", condition: "Diarrhea" },
  { title: "Dizziness", fileName: "dizziness.json", condition: "Dizziness" },
  { title: "Earaches", fileName: "earaches.json", condition: "Earaches" },
  { title: "Eczema", fileName: "eczema.json", condition: "Eczema" },
  { title: "Fatigue", fileName: "fatigue.json", condition: "Fatigue" },
  { title: "Fertility", fileName: "fertility.json", condition: "Fertility" },
  { title: "Fever", fileName: "fever-adults.json", condition: "Fever" },
  { title: "Flu", fileName: "flu.json", condition: "Flu" },
  { title: "Gallstones", fileName: "gallstones.json", condition: "Gallstones" },
  {
    title: "Gum Disease",
    fileName: "gum-disease.json",
    condition: "Gum Disease",
  },
  { title: "Hay Fever", fileName: "hay-fever.json", condition: "Hay Fever" },
  { title: "Head Colds", fileName: "head-colds.json", condition: "Head Colds" },
  { title: "Headache", fileName: "headaches.json", condition: "Headache" },
  {
    title: "Hemorrhoids",
    fileName: "hemorrhoids.json",
    condition: "Hemorrhoids",
  },
  { title: "Hives", fileName: "hives.json", condition: "Hives" },
  {
    title: "Indigestion or Digestion Problems",
    fileName: "indigestion.json",
    condition: "Indigestion",
  },
  { title: "Insomnia", fileName: "insomnia.json", condition: "Insomnia" },
  {
    title: "Irregular Cycles",
    fileName: "irregular-cycles.json",
    condition: "Irregular Cycles",
  },
  {
    title: "Kidney Stones",
    fileName: "kidney-stones.json",
    condition: "Kidney Stones",
  },
  { title: "Menopause", fileName: "menopause.json", condition: "Menopause" },
  {
    title: "Menstrual Cramping",
    fileName: "menstrual-cramping.json",
    condition: "Menstrual Cramping",
  },
  {
    title: "Muscle Pain",
    fileName: "muscle-pain.json",
    condition: "Muscle Pain",
  },
  {
    title: "Perspiration",
    fileName: "perspiration.json",
    condition: "Perspiration",
  },
  { title: "Pink Eye", fileName: "pink-eye.json", condition: "Pink Eye" },
  {
    title: "Prostate Health",
    fileName: "prostate-health.json",
    condition: "Prostate Health",
  },
  { title: "Rosacea", fileName: "rosacea.json", condition: "Rosacea" },
  { title: "Sinusitis", fileName: "sinusitis.json", condition: "Sinusitis" },
  { title: "Skin Problems", fileName: "skin.json", condition: "Acne" },
  {
    title: "Sleep Problems",
    fileName: "sleep-problems.json",
    condition: "Insomnia",
  },
  {
    title: "Sore Throat",
    fileName: "sore-throat.json",
    condition: "Sore Throat",
  },
  {
    title: "Stomach Aches",
    fileName: "stomach-ach.json",
    condition: "Stomach Ulcers",
  },
  {
    title: "Stomach Ulcers",
    fileName: "stomach-ulcers.json",
    condition: "Stomach Ulcers",
  },
  {
    title: "Strep Throat",
    fileName: "strep-throat.json",
    condition: "Strep Throat",
  },
  { title: "Thrush", fileName: "thrush.json", condition: "Thrush" },
  {
    title: "Tooth Conditions",
    fileName: "tooth-conditions.json",
    condition: "Tooth Conditions",
  },
  {
    title: "Urination Frequency",
    fileName: "urination-frequency.json",
    condition: "Urination Frequency",
  },
  {
    title: "Bladder Infections or UIT",
    fileName: "UTI.json",
    condition: "Bladder Infections",
  },
  {
    title: "Vaginal Bacterial Infections",
    fileName: "vaginal-bacterial-infections.json",
    condition: "Vaginal Bacterial Infections",
  },
  {
    title: "Vaginal Infections",
    fileName: "vaginal-infections.json",
    condition: "Vaginal Infections",
  },
  {
    title: "Varicose Veins",
    fileName: "varicose-veins.json",
    condition: "Varicose Veins",
  },
  {
    title: "Vision Changes",
    fileName: "vision-changes.json",
    condition: "Vision Changes",
  },
  { title: "Warts", fileName: "warts.json", condition: "Warts" },
  { title: "Hair Loss", fileName: "hair-loss.json", condition: "Boils" },
];

const SymptomChecker = () => {
  const [checkFor, setCheckFor] = useState("myself");
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        checkAccess(currentUser.uid);
      } else {
        navigate("/login");
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [navigate]);

  const checkAccess = async (userId) => {
    let userRef = doc(db, "users", userId);
    let docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      const userData = docSnap.data();
      if (userData.isSubscribed) {
      } else {
        setShowModal(true);
      }
    } else {
      // Check if the user is a practitioner
      userRef = doc(db, "practitioners", userId);
      docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        const practitionerData = docSnap.data();
        if (practitionerData.isVerified) {
        } else {
          setShowModal(true);
        }
      } else {
        setShowModal(true);
      }
    }
  };

  if (!user) {
    return (
      <div
        style={{
          marginTop: "150px",
          marginBottom: "100px",
          minHeight: "450px",
        }}
      >
        Loading...
      </div>
    ); // Or some other loading indicator
  }

  const handleSelectTerm = (selectedTerm) => {
    navigate("/remedy-recommendation", {
      state: {
        file: selectedTerm.fileName,
        checkFor,
        condition: selectedTerm.condition,
      },
    });
    ReactGA.event({
      category: "Term Selection",
      action: "Select Term",
      label: `Selected ${selectedTerm.title}`,
    });
  };

  const handleRadioButtonChange = (event) => {
    setCheckFor(event.target.value);
    ReactGA.event({
      category: "Radio Button",
      action: "Change",
      label: `Checked ${event.target.value}`,
    });
  };

  return (
    <Container className="no-padding">
      <Modal show={showModal} centered backdrop="static" keyboard={false}>
        <Modal.Header>
          <Modal.Title>Access Restricted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This service is only available for premium members.
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center w-100">
            <Button
              variant="primary"
              onClick={() => navigate("/options")}
              className="header-btn"
              style={{
                marginTop: "10px",
                borderRadius: "5px",
                width: "100%",
                height: "40px",
              }}
            >
              Subscribe to Premium Membership
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {!showModal && (
        <>
          <Row>
            <Col>
              <Form>
                <div key={`inline-radio`} className="mb-3">
                  <Form.Check
                    inline
                    label="I am checking for myself"
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    value="myself"
                    className="no-left-padding"
                    style={{ paddingRight: "30px" }}
                    checked={checkFor === "myself"}
                    onChange={handleRadioButtonChange}
                  />
                  <Form.Check
                    inline
                    label="I am checking for someone else"
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    value="someone_else"
                    checked={checkFor === "someone_else"}
                    style={{ paddingRight: "30px" }}
                    onChange={handleRadioButtonChange}
                  />
                  <SearchComponent
                    terms={terms}
                    onSelectTerm={handleSelectTerm}
                  />
                </div>
              </Form>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default SymptomChecker;
