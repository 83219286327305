import React from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import SubscriptionComponent from "../utils/SubscriptionComponent";
import "../../styles/General.css";
import "../../styles/Buttons.css";
import FAQComponent from "../utils/FAQComponent";
// import JoinNewsLetter from "./JoinNewsLetter";
import pic from "../../images/facetime-1.jpg";
import CommonServices from "./CommonServices";
import Benefits from "./Benefits";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const navigate = useNavigate();
  const redirectToOptions = () => {
    navigate("/options"); // Make sure the path matches your route setup
  };
  return (
    <div className="banner-background">
      <Container>
        <Row>
          {/* Column for Text */}
          <Col>
            <Row>
              <Col>
                <p className="mission-label">Personalized Holistic Health</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="header-agrandir">
                  Experienced licensed practitioners.{" "}
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="mission-text">
                  Sârv, provides a convenient and comprehensive approach to
                  health by connecting individuals with expert naturopaths,
                  herbalists, and dietitians through video calls, and messaging.
                  Our platform allows users to access personalized, natural
                  healing strategies from the comfort of their homes, fostering
                  a deeper understanding of their bodies and promoting a
                  balanced, healthy lifestyle. By integrating various holistic
                  disciplines, Sârv empowers you to take control of your health,
                  offering tailored advice and natural remedies that align with
                  your unique needs and wellness goals.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  className="online-services-btn"
                  onClick={redirectToOptions}
                >
                  Get Started With a Practitioner
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="mission-text">Accessible and affordable</p>
              </Col>
            </Row>
          </Col>

          {/* Column for Picture */}
          <Col>
            <Image
              src={pic}
              style={{
                width: "100%",
                height: "500px",
                margin: "50px",
                borderRadius: "25px",
              }}
              alt="Descriptive Alt Text"
            />
          </Col>
        </Row>
        {/* <SubscriptionComponent /> */}
      </Container>
      <CommonServices />
      <Benefits />
      <FAQComponent />
      {/* <JoinNewsLetter /> */}
      {/* <SubscriptionComponent /> */}
      {/* <FAQComponent /> */}
    </div>
  );
};

export default Services;
