import { Container, Row, Col, Card, Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

const ArticleCard = ({ article, isRightSide, jsonFilePath }) => (
  <Link
    to={`/learn/article?topic=${encodeURIComponent(jsonFilePath)}&articleId=${
      article.id
    }`}
    style={{ textDecoration: "none", color: "inherit" }}
  >
    <Card
      style={{
        ...(isRightSide ? { maxHeight: "300px", marginBottom: "20px" } : {}),
        border: "none",
        borderRadius: "15px",
        marginBottom: "50px",
        padding: "15px",
      }}
    >
      <Card.Img
        variant="top"
        src={article.img}
        style={
          isRightSide
            ? { height: "200px", objectFit: "cover", borderRadius: "15px" }
            : {
                height: "600px",
                objectFit: "cover",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                borderRadius: "15px",
              } // Adjusted size for the left card
        }
      />
      <Card.Body>
        <Card.Title>{article.title}</Card.Title>
        <Card.Text>{truncateText(article.text, 100)}</Card.Text>
      </Card.Body>
    </Card>
  </Link>
);

const Featured = ({ jsonFilePath }) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetch(jsonFilePath)
      .then((response) => response.json())
      .then((data) => setArticles(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [jsonFilePath]);

  return (
    <Container>
      <Row>
        {/* Left column with one large card */}
        <Col xs={12} md={8}>
          {articles[0] && (
            <ArticleCard article={articles[0]} jsonFilePath={jsonFilePath} />
          )}
        </Col>

        {/* Right column split into two rows */}
        <Col xs={12} md={4}>
          {articles.slice(1, 3).map((article, index) => (
            <Row key={index} style={{ paddingBottom: "25px" }}>
              <Col xs={12}>
                <ArticleCard
                  article={article}
                  isRightSide={true}
                  jsonFilePath={jsonFilePath}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Row style={{ paddingTop: "25px" }}>
        {articles.slice(3, 6).map((article, index) => (
          <Col xs={12} md={4} key={index}>
            <ArticleCard
              article={article}
              isRightSide={true}
              jsonFilePath={jsonFilePath}
            />
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Featured;
