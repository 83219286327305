import React, { useState } from "react";
import { Container, Form, Button, Alert } from "react-bootstrap";
import { db } from "../../config/Firebase";
import { collection, addDoc } from "firebase/firestore";
import ReactGA from "react-ga4";

const AppWaitList = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState({ fullName: "", email: "" });

  const validateForm = () => {
    let isValid = true;
    setError({ fullName: "", email: "" }); // Reset errors

    if (!fullName.trim()) {
      setError((prev) => ({
        ...prev,
        fullName: "Please enter your full name.",
      }));
      isValid = false;
    }

    if (!email.trim()) {
      setError((prev) => ({
        ...prev,
        email: "Please enter your email address.",
      }));
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError((prev) => ({
        ...prev,
        email: "Please enter a valid email address.",
      }));
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Stop submission if validation fails

    try {
      await addDoc(collection(db, "app-waitlist"), {
        fullName: fullName,
        email: email,
      });

      ReactGA.event({
        category: "Waitlist Form",
        action: "Submit Success",
        label: "Join Waitlist",
      });

      setFullName("");
      setEmail("");
      setError({ fullName: "", email: "" });
    } catch (error) {
      console.error("Error adding document: ", error);

      ReactGA.event({
        category: "Waitlist Form",
        action: "Submit Failed",
        label: "Firestore Error",
      });
    }
  };

  return (
    <Container
      className="mt-5"
      style={{ maxWidth: "700px", minHeight: "500px" }}
    >
      <h2
        style={{
          marginBottom: "50px",
          marginTop: "150px",
          fontFamily: "Canela",
          color: "#0a0a39",
        }}
      >
        Our application is under development; submit your email to be notified
        when the app is ready in the app store.
      </h2>

      <Form onSubmit={handleSubmit}>
        <Form.Group
          className="mb-3"
          controlId="formFullName"
          style={{ width: "600px" }}
        >
          <Form.Control
            type="text"
            placeholder="Enter your full name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            isInvalid={!!error.fullName}
          />
          <Form.Control.Feedback type="invalid">
            {error.fullName}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="formBasicEmail"
          style={{ width: "600px" }}
        >
          <Form.Control
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={!!error.email}
          />
          <Form.Control.Feedback type="invalid">
            {error.email}
          </Form.Control.Feedback>
        </Form.Group>
        <div
          className="d-flex justify-content-center"
          style={{ paddingBottom: "100px", paddingTop: "30px" }}
        >
          <Button variant="primary" type="submit" className="business-button">
            Join the Waitlist
          </Button>
        </div>
      </Form>
    </Container>
  );
};

export default AppWaitList;
