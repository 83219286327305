import { useLocation, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import PaymentOptions from "./billing/PaymentOptions";
import MembershipBenefits from "./billing/MembershipBenefits";
import BillingDetails from "./billing/BillingDetails";
import { db, auth } from "../../config/Firebase";
import Payment from "./payment/Payment";
import { useSubscription } from "../payment/SubscriptionContext";
import moment from "moment";

const ScheduleAppointment = () => {
  const location = useLocation();
  const { userId } = useParams();
  const { rate, tax, total, updateTax } = useSubscription();
  const { practitionerId, selectedDate, selectedTime } = location.state || {};
  const [selectedMembershipFee, setSelectedMembershipFee] = useState(null);
  const [selectedMembershipOption, setSelectedMembershipOption] = useState("");
  const [nextChargeDate, setNextChargeDate] = useState(new Date());
  const [visitFee, setVisitFee] = useState(null);
  const [visitFeeAmount, setVisitFeeAmount] = useState(0);

  //   console.log("user Id is ", userId);

  const calculateNextChargeDate = (option) => {
    const currentDate = new Date();
    switch (option) {
      case "3-month membership":
        currentDate.setMonth(currentDate.getMonth() + 3);
        break;
      case "1-year membership":
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        break;
      // Add more cases as needed
    }
    return currentDate;
  };

  const handleMembershipSelection = (option, fee) => {
    setSelectedMembershipOption(option);
    setSelectedMembershipFee(fee);
    setNextChargeDate(calculateNextChargeDate(option));
  };

  const [practitioner, setPractitioner] = useState(null);

  useEffect(() => {
    const fetchPractitionerData = async () => {
      if (userId) {
        const docRef = doc(db, "practitioners", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const practitionerData = docSnap.data();
          setPractitioner(practitionerData);
          const calculatedVisitFee = Math.round(
            practitionerData.minimumPay * 1.25
          );
          setVisitFeeAmount(calculatedVisitFee);
        }
      }
    };

    fetchPractitionerData();
  }, [userId, selectedDate, selectedTime]);

  const formattedDate = selectedDate
    ? moment(selectedDate).format("dddd, MMMM D, YYYY")
    : "";
  const formattedTime = selectedTime;

  if (!practitioner) {
    return <div>Loading practitioner details...</div>;
  }

  //   const formattedSelectedDate = selectedDate ? selectedDate.toString() : "";
  //   const formattedSelectedTime = selectedTime ? selectedTime.toString() : "";
  //   console.log(
  //     "*********** formattedSelectedDate is ***********",
  //     formattedDate
  //   );

  //   console.log("visitFeeAmount is ", visitFeeAmount);
  return (
    <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <Row>
        <Col md={6}>
          {" "}
          {/* Left Column */}
          <h4 style={{ paddingBottom: "25px" }}>
            Scheduling appointment with:
          </h4>
          <Card>
            <Row className="g-0">
              <Col md={4}>
                {" "}
                {/* Practitioner Image */}
                <Image src={practitioner.imgUrl} alt="Practitioner" fluid />
              </Col>
              <Col md={8}>
                {" "}
                {/* Practitioner Details */}
                <Card.Body>
                  <Card.Title>
                    {practitioner.firstName} {practitioner.lastName}
                  </Card.Title>
                  <Card.Text>
                    {practitioner.jobTitle
                      ? practitioner.jobTitle
                      : practitioner.appliedJobTitle}
                  </Card.Text>
                  <Card.Text>
                    <Card.Text>
                      {formattedDate} at {formattedTime}
                    </Card.Text>
                  </Card.Text>
                </Card.Body>
              </Col>
            </Row>
          </Card>
          <Row>
            <Payment
              visitFeeAmount={visitFeeAmount}
              membershipOption={selectedMembershipOption}
              practitionerId={practitionerId}
              selectedDate={formattedDate}
              selectedTime={formattedTime}
            />
          </Row>
        </Col>
        <Col md={6}>
          {" "}
          {/* Right Column */}
          {/* Assuming PaymentOptions is imported and ready to be used */}
          {/* <PaymentOptions
            onSelectionChange={handleMembershipSelection}
            selectedOption={selectedMembershipOption}
          /> */}
          <PaymentOptions
            visitFeeAmount={visitFeeAmount}
            onSelectionChange={handleMembershipSelection}
            selectedOption={selectedMembershipOption}
          />
          <Row>
            {" "}
            <MembershipBenefits />{" "}
          </Row>
          <Row>
            <BillingDetails
              selectedFee={selectedMembershipFee}
              selectedOption={selectedMembershipOption}
              nextChargeDate={nextChargeDate}
              practitioner={practitioner}
              selectedDate={formattedDate}
              selectedTime={formattedTime}
            />
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ScheduleAppointment;
