import React, { useState, useEffect } from "react";
import { collection, getDocs, updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, auth, storage } from "../../config/Firebase";
import {
  ListGroup,
  Button,
  Form,
  Alert,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function MaraljsonManager() {
  const [documents, setDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "herbs"));
      const docsArray = [];
      querySnapshot.forEach((doc) => {
        docsArray.push({ id: doc.id, ...doc.data() });
      });
      // Sort the documents array alphabetically by the title field
      docsArray.sort((a, b) => a.title.localeCompare(b.title));
      setDocuments(docsArray);
    } catch (error) {
      console.error("Error fetching documents: ", error);
      setError("Failed to fetch documents.");
    }
  };

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleSelect = (docId) => {
    const doc = documents.find((d) => d.id === docId);
    setSelectedDoc(doc);
  };

  const handleUpload = async () => {
    if (!image || !selectedDoc) {
      setError("Please select a document and an image.");
      return;
    }
    setError("");
    setMessage("");

    const imageRef = ref(storage, `herbs/${selectedDoc.id}/${image.name}`);
    try {
      const snapshot = await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(snapshot.ref);
      await updateDoc(doc(db, "herbs", selectedDoc.id), {
        imgUrl: imageUrl,
      });
      setMessage("Image uploaded and linked successfully.");
    } catch (err) {
      console.error("Error uploading image: ", err);
      setError("Error uploading image.");
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <ListGroup>
            {documents.map((doc) => (
              <ListGroup.Item
                action
                onClick={() => handleSelect(doc.id)}
                key={doc.id}
              >
                {doc.title} {/* Assuming each document has a 'name' field */}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
        <Col>
          {selectedDoc && (
            <div>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Select Image for {selectedDoc.name}</Form.Label>
                <Form.Control type="file" onChange={handleChange} />
              </Form.Group>
              <Button onClick={handleUpload}>Upload Image</Button>
            </div>
          )}
        </Col>
      </Row>
      {message && <Alert variant="success">{message}</Alert>}
      {error && <Alert variant="danger">{error}</Alert>}
    </Container>
  );
}
export default MaraljsonManager;
