import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { useNavigate } from "react-router-dom";
import "../../styles/General.css";
import { Container, Row, Col } from "react-bootstrap";
import ProgressBar from "../utils/ProgressBar";

const ItemCard = ({ item }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/subcategory/${item.name}`);
  };

  return (
    <Card className="itemCard cardShadow mb-3" onClick={handleClick}>
      <Card.Img variant="top" src={item.img} className="cardImage" />
      <Card.Body className="cardBody">
        <Card.Title>{item.name}</Card.Title>
      </Card.Body>
    </Card>
  );
};

const HerbCategories = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Fetching from the public directory
    fetch("/data/herbs.json")
      .then((response) => response.json())
      .then((data) => setItems(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <div>
      <ProgressBar progress={60} />
      <Container>
        <Row xs={1} md={2} lg={4} className="g-4">
          {" "}
          {/* Adjust the number of columns based on screen size */}
          {items.map((item, index) => (
            <Col key={index}>
              <ItemCard item={item} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default HerbCategories;
