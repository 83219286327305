import React from "react";
import Header from "./Header";
import HerbCategories from "../herbs/HerbCategories";
import TitleLine from "../utils/TitleLine";
import SubscriptionComponent from "../utils/SubscriptionComponent";
import Overview from "./Overview";
import MainArticle from "../library/MainArticle";
import ArticlesRow from "../library/ArticlesRow";
import ScrollChangeComponent from "./ScrollChangeComponent";
import { useNavigate } from "react-router-dom";
import SpecialEdition from "./SpecialEdition";
import HowItWorks from "./HowItWorks";
import Headline from "./Headline";

function HomePage() {
  const navigate = useNavigate();

  const content = [
    {
      header: "Ready to enhance your self-care ritual?",
      text: "Our Ayurveda-inspired well-being products, are designed to nourish you on every level - mind, body, and spirit. Each of our products is paired with a self-care practice for the ultimate wellness ritual.",
      buttonCaption: "Get personalized health Recommandation",
      onClickFunction: () => navigate("/page1"),
    },
    {
      header: "Discover how your body responds to different foods",
      text: "Our Ayurveda-inspired well-being products, are designed to nourish you on every level - mind, body, and spirit. Each of our products is paired with a self-care practice for the ultimate wellness ritual.",
      buttonCaption: "Take a test to get to know your body",
      onClickFunction: () => navigate("/page2"),
    },
    {
      header: "Learn what foods are important for you",
      text: "Text 3",
      buttonCaption: "Explore our library",
      onClickFunction: () => navigate("/page3"),
    },
  ];

  return (
    <div>
      <Header />
      <div className="content"> </div>
      {/* <TitleLine title="New Title" link="/view-all" /> */}
      {/* <HerbCategories /> */}
      <SpecialEdition />
      <Headline />
      <HowItWorks />
      {/* <Overview /> */}
      {/* <ScrollChangeComponent content={content} /> */}
      <MainArticle />

      <SubscriptionComponent />
    </div>
  );
}

export default HomePage;
