import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faTwitter,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";

const SocialShare = () => {
  const currentUrl = window.location.href;

  const handleSocialClick = (platform) => {
    const encodedUrl = encodeURIComponent(window.location.href);
    let url = "";

    switch (platform) {
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
        break;
      case "linkedin":
        url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`;
        break;
      case "twitter":
        url = `https://twitter.com/intent/tweet?url=${encodedUrl}`;
        break;
      case "gmail":
        url = `https://mail.google.com/mail/?view=cm&su=Check out this page&body=${encodedUrl}`;
        break;
      default:
        break;
    }

    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <Row
      className="align-items-center"
      style={{ width: "700px", paddingLeft: "20px", paddingTop: "100px" }}
    >
      <Col>
        <span className="social-share-title">
          Share this article with your friends
        </span>
      </Col>
      <Col>
        <Button variant="link" onClick={() => handleSocialClick("facebook")}>
          <FontAwesomeIcon icon={faFacebook} />
        </Button>
        <Button variant="link" onClick={() => handleSocialClick("linkedin")}>
          <FontAwesomeIcon icon={faLinkedin} />
        </Button>
        <Button variant="link" onClick={() => handleSocialClick("twitter")}>
          <FontAwesomeIcon icon={faTwitter} />
        </Button>
        <Button variant="link" onClick={() => handleSocialClick("gmail")}>
          <FontAwesomeIcon icon={faGoogle} />
        </Button>
      </Col>
    </Row>
  );
};

export default SocialShare;
