import React, { useState, useEffect } from "react";
import { db, auth } from "../../config/Firebase";
import { useNavigate } from "react-router-dom";
import { setDoc, doc } from "firebase/firestore";
import { Container, ListGroup, Button } from "react-bootstrap";
import "../../styles/General.css";
import "../../styles/Enrollment.css";
import ProgressBar from "../utils/ProgressBar";

const DrVisits = () => {
  const [selectedVisitFrequency, setSelectedVisitFrequency] = useState("");
  const visitFrequencies = ["Regularly", "Occasionally", "Rarely", "Never"];
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSelection = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    try {
      const userDocRef = doc(db, "users", userId);
      await setDoc(
        userDocRef,
        { healthcareVisitFrequency: selectedVisitFrequency },
        { merge: true }
      );
      navigate("/familyHistory");
    } catch (error) {
      console.error(
        "Error updating healthcare visit frequency in Firebase:",
        error
      );
    }
  };

  return (
    <div className="progress-container">
      <ProgressBar progress={5} />
      <Container className="d-flex align-items-center justify-content-center">
        <div
          className="w-100"
          style={{
            maxWidth: "600px",
            paddingTop: "60px",
            paddingBottom: "20px",
          }}
        >
          <h3 className="header-text">
            How often do you visit a healthcare provider?
          </h3>
          <ListGroup>
            {visitFrequencies.map((frequency, index) => (
              <ListGroup.Item
                key={index}
                action
                active={frequency === selectedVisitFrequency}
                onClick={() => setSelectedVisitFrequency(frequency)}
                style={{ marginBottom: "15px" }}
              >
                {frequency}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Button
            onClick={handleSelection}
            disabled={!selectedVisitFrequency}
            className="next-button mt-3"
          >
            Next
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default DrVisits;
