// import React, { useState } from "react";
// import { Container, Row, Col, Form, Button } from "react-bootstrap";
// import { db, storage } from "../../../config/Firebase";
// import { collection, addDoc, doc, updateDoc } from "firebase/firestore"; // Import 'doc' and 'updateDoc' here
// import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

// const AddRecipe = () => {
//   const [recipeName, setRecipeName] = useState("");
//   const [ingredients, setIngredients] = useState([""]);
//   const [recipeText, setRecipeText] = useState("");
//   const [file, setFile] = useState(null);

//   const handleRecipeNameChange = (e) => {
//     setRecipeName(e.target.value);
//   };

//   const handleIngredientChange = (index, event) => {
//     const values = [...ingredients];
//     values[index] = event.target.value;
//     setIngredients(values);
//   };

//   const handleAddIngredient = () => {
//     setIngredients([...ingredients, ""]);
//   };

//   const handleRemoveIngredient = (index) => {
//     const values = [...ingredients];
//     values.splice(index, 1);
//     setIngredients(values);
//   };

//   const handleRecipeTextChange = (e) => {
//     setRecipeText(e.target.value);
//   };

//   const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (!recipeName || ingredients.length === 0 || !recipeText) {
//       alert("Please fill out all fields");
//       return;
//     }

//     try {
//       // Add document to Firestore
//       const docRef = await addDoc(collection(db, "recipes"), {
//         title: recipeName,
//         ingredients: ingredients,
//         content: recipeText,
//       });

//       // If file is selected, upload it to Storage
//       if (file) {
//         const fileRef = ref(storage, `recipes/${docRef.id}`);
//         const uploadResult = await uploadBytes(fileRef, file);
//         const imgUrl = await getDownloadURL(uploadResult.ref);
//         // Update the Firestore document with the image URL
//         await updateDoc(doc(db, "recipes", docRef.id), { imgUrl });
//       }

//       console.log("Document written with ID: ", docRef.id);
//       alert("Recipe added successfully!");

//       // Reset form after submission
//       setRecipeName("");
//       setIngredients([""]);
//       setRecipeText("");
//       setFile(null);
//     } catch (e) {
//       console.error("Error adding document: ", e);
//       alert("Error adding recipe");
//     }
//   };

//   return (
//     <Container>
//       <Form onSubmit={handleSubmit}>
//         <Row className="mb-3">
//           <Col>
//             <Form.Label>Recipe Name</Form.Label>
//             <Form.Control
//               type="text"
//               placeholder="Enter recipe name"
//               value={recipeName}
//               onChange={handleRecipeNameChange}
//             />
//           </Col>
//         </Row>
//         <Row className="mb-3">
//           <Col>
//             <Form.Label>Ingredients</Form.Label>
//             {ingredients.map((ingredient, index) => (
//               <Row key={index} className="mb-2">
//                 <Col md={10}>
//                   <Form.Control
//                     type="text"
//                     placeholder="Enter an ingredient"
//                     value={ingredient}
//                     onChange={(e) => handleIngredientChange(index, e)}
//                   />
//                 </Col>
//                 <Col md={2}>
//                   <Button
//                     variant="danger"
//                     onClick={() => handleRemoveIngredient(index)}
//                   >
//                     Remove
//                   </Button>
//                 </Col>
//               </Row>
//             ))}
//             <Button onClick={handleAddIngredient}>Add Ingredient</Button>
//           </Col>
//         </Row>
//         <Row className="mb-3">
//           <Col>
//             <Form.Label>Recipe Text</Form.Label>
//             <Form.Control
//               as="textarea"
//               rows={3}
//               placeholder="Enter the text of the recipe"
//               value={recipeText}
//               onChange={handleRecipeTextChange}
//             />
//           </Col>
//         </Row>
//         <Row className="mb-3">
//           <Col>
//             <Form.Label>Upload Image</Form.Label>
//             <Form.Control type="file" onChange={handleFileChange} />
//           </Col>
//         </Row>
//         <Button variant="primary" type="submit">
//           Submit Recipe
//         </Button>
//       </Form>
//     </Container>
//   );
// };

// export default AddRecipe;

import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { db, storage } from "../../../config/Firebase";
import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const AddRecipe = () => {
  const [recipeName, setRecipeName] = useState("");
  const [ingredients, setIngredients] = useState([""]);
  const [recipeText, setRecipeText] = useState("");
  const [file, setFile] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);

  const recipeCategories = [
    "Everyday Recipes",
    "Immune Defense",
    "Women's Health",
    "Men's Health",
    "Recipes for Elder",
    "Care for Babies and Children",
    "Emotional Support",
    "Herbs for All Purposes",
    "Herbs for Natural Beauty",
    "Travel Health Kit",
  ];

  const handleRecipeNameChange = (e) => {
    setRecipeName(e.target.value);
  };

  const handleIngredientChange = (index, event) => {
    const values = [...ingredients];
    values[index] = event.target.value;
    setIngredients(values);
  };

  const handleAddIngredient = () => {
    setIngredients([...ingredients, ""]);
  };

  const handleRemoveIngredient = (index) => {
    const values = [...ingredients];
    values.splice(index, 1);
    setIngredients(values);
  };

  const handleRecipeTextChange = (e) => {
    setRecipeText(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory([e.target.value]);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (
      !recipeName ||
      ingredients.length === 0 ||
      !recipeText ||
      selectedCategory.length === 0
    ) {
      alert("Please fill out all fields including selecting a category");
      return;
    }

    try {
      // Add document to Firestore
      const docRef = await addDoc(collection(db, "recipes"), {
        title: recipeName,
        ingredients: ingredients,
        content: recipeText,
        category: selectedCategory, // Store category as an array
      });

      // If file is selected, upload it to Storage
      if (file) {
        const fileRef = ref(storage, `recipes/${docRef.id}`);
        const uploadResult = await uploadBytes(fileRef, file);
        const imgUrl = await getDownloadURL(uploadResult.ref);
        // Update the Firestore document with the image URL
        await updateDoc(doc(db, "recipes", docRef.id), { imgUrl });
      }

      console.log("Document written with ID: ", docRef.id);
      alert("Recipe added successfully!");

      // Reset form after submission
      setRecipeName("");
      setIngredients([""]);
      setRecipeText("");
      setSelectedCategory([]);
      setFile(null);
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Error adding recipe");
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Label>Recipe Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter recipe name"
              value={recipeName}
              onChange={handleRecipeNameChange}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Ingredients</Form.Label>
            {ingredients.map((ingredient, index) => (
              <Row key={index} className="mb-2">
                <Col md={10}>
                  <Form.Control
                    type="text"
                    placeholder="Enter an ingredient"
                    value={ingredient}
                    onChange={(e) => handleIngredientChange(index, e)}
                  />
                </Col>
                <Col md={2}>
                  <Button
                    variant="danger"
                    onClick={() => handleRemoveIngredient(index)}
                  >
                    Remove
                  </Button>
                </Col>
              </Row>
            ))}
            <Button onClick={handleAddIngredient}>Add Ingredient</Button>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Recipe Text</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Enter the text of the recipe"
              value={recipeText}
              onChange={handleRecipeTextChange}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Category</Form.Label>
            <Form.Select
              aria-label="Select recipe category"
              onChange={handleCategoryChange}
              value={selectedCategory[0] || ""}
            >
              <option value="">Select a category</option>
              {recipeCategories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Upload Image</Form.Label>
            <Form.Control type="file" onChange={handleFileChange} />
          </Col>
        </Row>
        <Button variant="primary" type="submit">
          Submit Recipe
        </Button>
      </Form>
    </Container>
  );
};

export default AddRecipe;
