import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import { db, auth } from "../../config/Firebase";
import {
  collection,
  query,
  where,
  doc,
  getDocs,
  getDoc,
} from "firebase/firestore";
import MedicinaHerbs from "./MedicinaHerbs";

const QuizComponent = () => {
  const [currentNode, setCurrentNode] = useState(null);
  const [path, setPath] = useState([]);
  const [quizEnded, setQuizEnded] = useState(false);
  const [resultMessage, setResultMessage] = useState(""); // State to store the result message
  const [data, setData] = useState({});
  const [detectedDisease, setDetectedDisease] = useState("");
  const [herbs, setHerbs] = useState([]);
  const location = useLocation(); // Access location object
  const fileName = location.state?.file; // Access the passed file name

  const diseaseList = [
    "Alopecia Areata",
    "Type 2 Diabetes",
    "Cancer",
    "Malaria",
    "SEBORRHEA",
    "LICHEN PLANUS",
    "RINGWORM",
  ];

  useEffect(() => {
    if (fileName) {
      // Construct the file path dynamically based on the passed file name
      const filePath = `/data/symptoms/${fileName}`;
      fetch(filePath)
        .then((response) => response.json())
        .then((fetchedData) => {
          // Handle fetched data as before
          // console.log("Fetched data:", fetchedData);
          setData(fetchedData);
          setCurrentNode(fetchedData["1"]);
        })
        .catch((error) => console.error("Failed to load quiz data:", error));
    }
  }, [fileName]); // Depend on fileName so it re-runs the effect when the file name changes

  const detectDiseaseName = (message) => {
    const detected = diseaseList.find((disease) =>
      message.toLowerCase().includes(disease.toLowerCase())
    );
    setDetectedDisease(detected || "No disease detected");
  };

  // Function to detect all disease names in the result message
  //   const detectDiseaseName = (message) => {
  //     const detectedDiseases = diseaseList.filter((disease) =>
  //       message.toLowerCase().includes(disease.toLowerCase())
  //     );
  //     if (detectedDiseases.length > 0) {
  //       setDetectedDisease(detectedDiseases.join(", ")); // Join multiple diseases with a comma
  //     } else {
  //       setDetectedDisease("No disease detected");
  //     }
  //   };

  const fetchDiseaseInfo = async (resultMessage) => {
    const detectedDisease = diseaseList.find((disease) =>
      resultMessage.toLowerCase().includes(disease.toLowerCase())
    );

    if (detectedDisease) {
      setDetectedDisease(detectedDisease);

      const diseasesRef = collection(db, "diseases");
      const q = query(diseasesRef, where("name", "==", detectedDisease));
      const querySnapshot = await getDocs(q);

      // Assuming each disease document only has one set of herb IDs
      if (!querySnapshot.empty) {
        const diseaseData = querySnapshot.docs[0].data();
        const herbIds = diseaseData.herbs;

        // Fetch each herb by its ID
        const herbsData = await Promise.all(
          herbIds.map(async (id) => {
            const herbDocRef = doc(db, "herbs", id); // Directly access the document by its ID
            const herbDocSnap = await getDoc(herbDocRef);
            return herbDocSnap.exists() ? herbDocSnap.data() : null; // Return herb data if it exists
          })
        );

        setHerbs(herbsData.filter(Boolean)); // Filter out any null values (in case some documents don't exist)
      }
    } else {
      setDetectedDisease("No disease detected");
      setHerbs([]);
    }
  };

  useEffect(() => {
    if (detectedDisease) {
      fetchDiseaseInfo(detectedDisease);
    }
  }, [detectedDisease]);

  useEffect(() => {
    // console.log("my herbs areeeee", herbs); // Log to see if herbs data is being fetched and set
  }, [herbs]);

  const handleOptionClick = (selectedOption) => {
    const nextQuestionKey = currentNode.next[selectedOption];
    setPath([...path, currentNode.question]);

    if (nextQuestionKey === "end") {
      //   setResultMessage(currentNode.result[selectedOption]); // Set the result message
      const finalResultMessage = currentNode.result[selectedOption];
      setResultMessage(finalResultMessage);
      detectDiseaseName(finalResultMessage);
      fetchDiseaseInfo(finalResultMessage);
      // console.log(
      //   "the fetched diease is ",
      //   fetchDiseaseInfo(finalResultMessage)
      // );
      setQuizEnded(true);
    } else {
      const nextNode = data[nextQuestionKey];
      if (nextNode) {
        setCurrentNode(nextNode);
      } else {
        console.error("Next question not found:", nextQuestionKey);
      }
    }
  };

  if (!currentNode) {
    return <div>Loading...</div>; // Show loading state while data is being fetched
  }

  return (
    <Container style={{ width: "100%" }}>
      <Row className="justify-content-md-center">
        <Col md={8}>
          {!quizEnded ? (
            <Card style={{ width: "100%" }}>
              <Card.Body>
                <Card.Title>Question</Card.Title>
                <Card.Text>{currentNode.question}</Card.Text>
                {currentNode.options.map((option) => (
                  <Button
                    key={option}
                    variant="primary"
                    onClick={() => handleOptionClick(option)}
                    style={{ margin: "10px" }}
                  >
                    {option}
                  </Button>
                ))}
              </Card.Body>
            </Card>
          ) : (
            <div>
              <h3>Result</h3>
              <p>{resultMessage}</p>
              <p>Disease Detected: {detectedDisease}</p>{" "}
              {/* Display the detected disease */}
              {/* <MedicinaHerbs data={herbs} /> */}
              <MedicinaHerbs key={herbs.length} data={herbs} />
            </div>
          )}
          {/* <div>
            <h3>Path Taken:</h3>
            <ul>
              {path.map((question, index) => (
                <li key={index}>{question}</li>
              ))}
            </ul>
          </div> */}
        </Col>
      </Row>
    </Container>
  );
};

export default QuizComponent;
