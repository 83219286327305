import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/General.css";
import "../../styles/Buttons.css";

const SpecialEdition = () => {
  const navigate = useNavigate();

  const redirectToPageX = () => {
    navigate("/learn");
  };

  return (
    <div className="special-edition-parent-container">
      <Container style={{ paddingLeft: "50px" }}>
        <Row className="mb-3" style={{ paddingTop: "30px" }}>
          <Col>LEARN HOW MEDICINAL HERBS CAN HELP YOU</Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <a
              href="#"
              onClick={redirectToPageX}
              className="special-edition-title"
              style={{ cursor: "pointer" }}
            >
              Discover Evidence-Based Remedies from Hundreds of Medicinal Herbs
            </a>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="text-lato">
            Our personalized health recommendations, grounded in the science of
            medicinal herbs, can help you by harnessing natural properties that
            support health and wellness in a holistic approach to care.
          </Col>
        </Row>
        <Row style={{ paddingBottom: "20px" }}>
          <Col>
            <Button
              variant="link"
              onClick={redirectToPageX}
              className="readmore-btn"
            >
              Learn More {">"}
            </Button>
            {/* <Button onClick={redirectToPageX}>Learn More</Button> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SpecialEdition;
