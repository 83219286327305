import React, { useEffect, useState } from "react";
import { Container, Table, Button } from "react-bootstrap";
import { db, auth } from "../../../config/Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import moment from "moment";
import "moment-timezone";

const AppointmentManagement = () => {
  const [appointments, setAppointments] = useState([]);
  const practitionerTimeZone = moment.tz.guess();
  //   console.log(" guest time zone is ****", practitionerTimeZone);

  const fetchAppointments = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) return;
    const practitionerId = currentUser.uid;

    const appointmentsRef = collection(db, "appointments");
    const q = query(
      appointmentsRef,
      where("practitionerId", "==", practitionerId)
    );
    const querySnapshot = await getDocs(q);

    const appointmentsData = await Promise.all(
      querySnapshot.docs.map(async (docSnapshot) => {
        const appointment = docSnapshot.data();
        const userRef = doc(db, "users", appointment.userId);
        const userSnap = await getDoc(userRef);

        // New check for first time visit based on the 'meetings' collection
        const meetingsRef = collection(db, "meetings");
        const meetingsQuery = query(
          meetingsRef,
          where("practitionerId", "==", practitionerId),
          where("userId", "==", appointment.userId)
        );
        const meetingsSnapshot = await getDocs(meetingsQuery);
        const isFirstTimeVisit = meetingsSnapshot.empty;

        // Convert appointmentTimeUTC to the practitioner's current time zone for display
        const appointmentTimeLocal = appointment.appointmentTimeUTC
          ? moment(appointment.appointmentTimeUTC.toDate())
              .tz(practitionerTimeZone)
              .format("YYYY-MM-DD HH:mm")
          : "N/A";

        return {
          id: docSnapshot.id,
          appointmentTimeLocal,
          ...appointment,
          ...(userSnap.data() || {}), // Incorporate user data
          isFirstTimeVisit: isFirstTimeVisit ? "Yes" : "No",
        };
      })
    );

    // Sorting appointments by clientRequestDate
    appointmentsData.sort((a, b) =>
      moment(a.clientRequestDate).diff(moment(b.clientRequestDate))
    );
    setAppointments(appointmentsData);
  };

  useEffect(() => {
    fetchAppointments();
  }, []); // Dependency array is empty, so this effect runs once on mount

  const updateAppointmentStatus = async (appointmentId, status) => {
    const appointmentRef = doc(db, "appointments", appointmentId);
    const docSnap = await getDoc(appointmentRef);

    if (!docSnap.exists()) {
      return;
    }

    const appointment = docSnap.data();
    // const { selectedDate, selectedTime, timeZone } = appointment;
    const practitionerId = auth.currentUser.uid; // Assuming this is how you get the current user/practitioner ID
    const { userId, appointmentTimeUTC, selectedDate, selectedTime, timeZone } =
      appointment;

    // Generate a 5-character random string for channelName
    const channelName = Math.random().toString(36).substring(2, 7);

    const appointmentEndTime = moment(appointmentTimeUTC.toDate())
      .add(2, "hours") // Assuming a 1-hour meeting duration
      .unix();
    //   .toISOString();

    // console.log("the app ends at .................", appointmentEndTime);
    // Fetch the token from your backend
    const response = await fetch(
      `/generate_token?channelName=${channelName}&appointmentEndTime=${appointmentEndTime}`
    );
    const { token } = await response.json();

    const format = "dddd, MMMM D YYYY hh:mm A";
    const dateTimeInTimeZone = moment.tz(
      `${selectedDate} ${selectedTime}`,
      format,
      timeZone
    );
    // const confirmedTime = dateTimeInTimeZone.utc().format();
    const confirmedTime = new Date();

    await updateDoc(appointmentRef, {
      status,
      confirmedTime,
    });

    if (status === "confirmed") {
      await updateDoc(appointmentRef, { status });

      // Create a document in 'meetings' collection
      await addDoc(collection(db, "meetings"), {
        practitionerId,
        userId,
        appointmentTime: appointmentTimeUTC,
        status: "scheduled",
        channelName,
        appointmentId,
        token,
      });

      console.log("Meeting scheduled and appointment confirmed.");
    } else if (status === "cancelled") {
      const canceledTime = new Date(); // Current date-time in UTC

      await updateDoc(appointmentRef, {
        status,
        canceledTime,
        cancelRequestBy: "practitioner",
      });

      // Check for a scheduled meeting for this appointmentId and delete it
      const meetingsRef = collection(db, "meetings");
      const q = query(meetingsRef, where("appointmentId", "==", appointmentId));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref); // Delete each meeting document found
      });

      console.log("Appointment cancelled and any scheduled meeting removed.");
    }

    // } else {
    //   // If the status is not 'confirmed', just update the appointment status
    //   await updateDoc(appointmentRef, { status });
    // }
    // Refresh appointments list to reflect the change
    await fetchAppointments();
  };

  return (
    <Container style={{ paddingTop: "100px", paddingBottom: "50px" }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Date</th>
            <th>Time</th>
            <th>Local Appointment Time</th> {/* New Column */}
            <th>Status</th>
            <th>First Time Visit</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment) => (
            <tr key={appointment.id}>
              <td>{appointment.firstName}</td>
              <td>{appointment.lastName}</td>
              <td>{appointment.email}</td>
              <td>{appointment.selectedDate}</td>
              <td>{appointment.selectedTime}</td>
              <td>{appointment.appointmentTimeLocal}</td>{" "}
              {/* Display Converted Time */}
              <td>{appointment.status}</td>
              <td>{appointment.isFirstTimeVisit}</td>
              <td>
                <Button
                  variant="success"
                  onClick={() =>
                    updateAppointmentStatus(appointment.id, "confirmed")
                  }
                  disabled={appointment.status === "confirmed"}
                >
                  Confirm
                </Button>{" "}
                <Button
                  variant="danger"
                  onClick={() =>
                    updateAppointmentStatus(appointment.id, "cancelled")
                  }
                  disabled={appointment.status === "cancelled"}
                >
                  Cancel
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default AppointmentManagement;
