import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "../../styles/Article.css";

const ArticleCategories = () => {
  const [articles, setArticles] = useState([]);
  const location = useLocation();
  const jsonFilePath = location.state?.jsonData; // Assuming this is passed correctly

  // Function to truncate text to a specified length
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  useEffect(() => {
    if (jsonFilePath) {
      fetch(jsonFilePath)
        .then((response) => response.json())
        .then((data) => setArticles(data))
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [jsonFilePath]);

  if (!articles.length) {
    return <Container>Loading...</Container>;
  }

  return (
    <Container>
      <Row
        className="mb-3 justify-content-center"
        style={{
          alignContent: "center",
          alignItems: "center",
          paddingTop: "100px",
          paddingBottom: "50px",
        }}
      >
        <Col>
          <h1 className="article-categoories-title text-center">
            {location.state?.title || "Articles"}
          </h1>
        </Col>
      </Row>
      <Row>
        {articles.map((article, index) => (
          <Col xs={12} md={4} key={article.id}>
            <Link
              to={{
                pathname: `/learn/article`,
                search: `?topic=${encodeURIComponent(jsonFilePath)}&articleId=${
                  article.id
                }`,
              }}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Card
                style={{
                  border: "none",
                  borderRadius: "15px",
                  marginBottom: "50px",
                  padding: "15px",
                }}
              >
                <Card.Img
                  variant="top"
                  src={article.img}
                  style={{
                    height: "200px",
                    objectFit: "cover",
                    borderTopLeftRadius: "15px",
                    borderTopRightRadius: "15px",
                  }}
                />
                <Card.Body>
                  <Card.Title>{article.title}</Card.Title>
                  <Card.Text>{truncateText(article.text, 100)}</Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ArticleCategories;
