import React from "react";
import "../../styles/General.css";

const MissionStatement = () => {
  return (
    <div className="mission-container">
      <div className="mission-subcontainer">
        <div className="overview-title">Overview</div>
        <div style={{ paddingBottom: "25px" }}></div>
        <div>
          Sârv is a pioneering health app designed to blend the timeless wisdom
          of herbal remedies with cutting-edge technology, offering a
          personalized path to wellness for every user. At the heart of Sârv's
          mission is the belief in the power of individualized natural
          healthcare, tailored to meet the unique needs and goals of each
          person. Our platform is not just about suggesting herbal solutions;
          it's about educating users on the rich medicinal properties of herbs,
          enabling them to make informed choices about their health.
        </div>
        <div style={{ paddingBottom: "50px" }}></div>
        <div>
          We stands for those seeking a more natural, informed approach to
          health, offering a community-driven, supportive environment where
          wellness is not just a goal, but a shared journey. Join Sârv, and be
          part of a movement towards a healthier, more natural future.
        </div>
        <div style={{ paddingBottom: "50px" }}></div>
      </div>
    </div>
  );
};

export default MissionStatement;
