import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Badge,
} from "react-bootstrap";
import { auth, db } from "../../../config/Firebase"; // Adjust the import path as necessary
import { doc, getDoc } from "firebase/firestore";

const PaymentOptions = ({ onSelectionChange, visitFeeAmount }) => {
  const [subscriptionOptions, setSubscriptionOptions] = useState([]);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [userMembershipOption, setUserMembershipOption] = useState("");

  useEffect(() => {
    // console.log("Sending visitFeeAmount to server:", visitFeeAmount);
    const fetchSubscriptionOptions = async () => {
      try {
        const response = await fetch(
          `http://localhost:5255/subscription-options?visitFee=${visitFeeAmount}`
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        // console.log("Received subscription options:", data);
        setSubscriptionOptions(data);
      } catch (error) {
        console.error("Error fetching subscription options:", error);
      }
    };

    fetchSubscriptionOptions();
  }, [visitFeeAmount, userMembershipOption]);

  return (
    <Container style={{ width: "600px" }}>
      <Row className="mb-4">
        <Col className="text-center">
          <h3>Confirm payment</h3>
        </Col>
      </Row>
      {subscriptionOptions.map((option, index) => (
        <Row key={option.id} className="mb-4">
          <Col>
            <Card style={{ width: "550px", position: "relative" }}>
              {option.name === "1-year membership" && (
                <Badge
                  bg="success"
                  style={{
                    position: "absolute",
                    top: "-10px",
                    left: "10px",
                    zIndex: "1000",
                  }}
                >
                  Best value
                </Badge>
              )}
              <Card.Body>
                <Row>
                  <Col xs={8}>
                    <Form.Check
                      type="radio"
                      label={option.name}
                      name="membershipOption"
                      id={`option-${option.id}`}
                      onChange={() =>
                        onSelectionChange(option.name, option.price)
                      }
                      defaultChecked={index === 0 && !showMoreOptions}
                    />
                    {option.name === "1-year membership" && (
                      <div
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#e7f9e9",
                          borderRadius: "5px",
                          height: "25px",
                        }}
                      >
                        <Button
                          variant="light"
                          disabled
                          style={{
                            padding: "2px 2px",
                            fontSize: "12px",
                            lineHeight: "1",
                            cursor: "default",
                            marginLeft: "5px",
                          }}
                        >
                          <span style={{ fontSize: "14px" }}>
                            Special offer
                          </span>
                        </Button>
                        <span style={{ marginLeft: "10px", fontSize: "14px" }}>
                          50% off your first visit
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col xs={4} className="text-end">
                    ${option.price}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default PaymentOptions;
