import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../config/Firebase";
import { doc, getDoc } from "firebase/firestore";
import { Container, Row, Col } from "react-bootstrap";
import "../../styles/Article.css";

function Article() {
  const { id } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      const docRef = doc(db, "herbs", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setArticle(docSnap.data());
      } else {
        console.log("No such document!");
      }
    };

    fetchArticle();
  }, [id]);

  const renderDosage = (dosage) => {
    if (!dosage) {
      return <p>No dosage information available.</p>;
    }

    return Object.entries(dosage).map(([key, value], index) => {
      if (Array.isArray(value)) {
        // If 'value' is an array, render a list of instructions
        return (
          <div key={index}>
            <p>
              <b>{key}:</b>
              <ul>
                {value.map((step, idx) => (
                  <li key={idx}>{step}</li>
                ))}
              </ul>
            </p>

            {/* <h3>{key}:</h3>
            <ul>
              {value.map((step, idx) => (
                <li key={idx}>{step}</li>
              ))}
            </ul> */}
          </div>
        );
      } else if (typeof value === "string") {
        // If 'value' is a string, render it directly
        return (
          <div key={index}>
            <p>
              {" "}
              <b> {key}:</b> {value}{" "}
            </p>
            {/* <h5>{key}:</h5>
            <p>{value}</p> */}
          </div>
        );
      } else {
        // If 'value' is neither an array nor a string, log an error and render a fallback message
        console.error(
          `Unexpected format for '${key}'. Expected array or string, but received:`,
          value
        );
        return (
          <div key={index}>
            <h3>{key}:</h3>
            <p>Incorrect data format.</p>
          </div>
        );
      }
    });
  };

  const renderMedicinalUses = (medicinalUses) => {
    return medicinalUses.split("\n\n").map((paragraph, index) => (
      <p key={index} className="herbs-article-content">
        {paragraph}
      </p>
    ));
  };

  return (
    <Container>
      {/* First Row for Image, Title, and Summary */}
      <Row className="mb-3">
        {" "}
        {/* 'mb-3' adds some margin below the row for spacing */}
        <Col md={6}>
          {" "}
          {/* Image column */}
          {article && (
            <img
              className="herbs-article-image"
              src={article.imgUrl}
              alt={article.title}
              // 'height: auto' to maintain aspect ratio
            />
          )}
        </Col>
        <Col md={6}>
          {" "}
          {/* Title and Summary column */}
          {article && (
            <>
              <h2 className="herbs-article-title">{article.title}</h2>
              <p className="herbs-article-content">{article.summary}</p>
              <p className="herbs-article-content">
                {" "}
                <b>Plant nature:</b> {article.nature}
              </p>
              <p className="herbs-article-content">
                {" "}
                <b>Theraputic effect:</b> {article.therapeutic_actions}
              </p>
              <p className="herbs-article-content">
                {" "}
                <b>Usable plant parts:</b> {article.parts_used}
              </p>
            </>
          )}
        </Col>
      </Row>

      {/* Second Row for Medicinal Uses */}
      <Row className="mb-3">
        {" "}
        {/* 'mb-3' adds some margin below the row for spacing */}
        <Col>
          {article?.medicinal_uses && ( // Using optional chaining '?.' to check if 'medicinal_uses' exists
            <>
              <h3 className="herbs-article-title">Medicinal Uses</h3>
              {renderMedicinalUses(article.medicinal_uses)}
            </>
          )}
        </Col>
      </Row>

      {/* Third Row for Dosage */}
      <Row>
        <Col>
          {article?.dosage && ( // Using optional chaining '?.' to check if 'dosage' exists
            <>
              <h3 className="herbs-article-title">Dosage</h3>
              {renderDosage(article.dosage)}
            </>
          )}
        </Col>
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <Col>
          {" "}
          {/* Title and Summary column */}
          {article && (
            <>
              <p className="herbs-article-content">
                {" "}
                <b>Plant attribute: </b> {article.attribute}
              </p>
              <p className="herbs-article-content">
                {" "}
                <b>Flower essence: </b>
                {article.flower_essence}
              </p>
              <p className="herbs-article-content">
                {" "}
                <b>Other names: </b> {article.other_names}
              </p>
              <p className="herbs-article-content">
                {" "}
                <b>Usable plant parts: </b> {article.parts_used}
              </p>
              <p className="herbs-article-content">
                {" "}
                <b>Organs and systems affected: </b>
                {article.organs_systems_affected}
              </p>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Article;
