import React from "react";
import { Container, Card, Button, Row, Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import img from "../../images/flower-4.jpg";
import { auth, db } from "../../config/Firebase";
import { doc, getDoc } from "firebase/firestore";
import "../../styles/General.css";
import "../../styles/Enrollment.css";

const Welcome = () => {
  const navigate = useNavigate();

  const handleNextClick = async () => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, "practitioners", user.uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        const { appliedJobTitle } = docSnap.data();
        if (
          appliedJobTitle === "Herbalist" ||
          appliedJobTitle ===
            "Clinical Herbalist / Herbal Medicine Practitioner"
        ) {
          navigate("/herbalist-form");
        } else {
          navigate("/expertise");
        }
      } else {
        console.log("No such document!");
        navigate("/expertise"); // Default route if no document is found
      }
    } else {
      // User is not logged in, handle accordingly
      console.log("No user logged in");
      // Possibly redirect to login page or handle as per your flow
    }
  };

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{
        height: "auto",
        width: "600px",
        paddingTop: "150px",
        paddingBottom: "100px",
      }}
    >
      <Card className="text-center" style={{ width: "auto", border: "none" }}>
        <Card.Body>
          <Row className="mb-4">
            <Col>
              <Card.Title className="dosha-card-title">
                Thanks for creating an account!
              </Card.Title>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Image src={img} rounded fluid />
              {/* Replace 'your-image-url.jpg' with your image path */}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Card.Text>
                Next, we'll start learning about your experiences as a
                practitioner.
              </Card.Text>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={handleNextClick}
                className="next-button mt-3"
              >
                Next
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Welcome;
