import React, { useState, useEffect } from "react";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { db, auth } from "../../config/Firebase";
import { Card, Container, Row, Col, Image, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "moment-timezone";
import { useLocation } from "react-router-dom";

const PractitionerProfileDetails = () => {
  const [practitioner, setPractitioner] = useState(null);
  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [confirmedAppointments, setConfirmedAppointments] = useState([]);
  const [userMembershipOption, setUserMembershipOption] = useState("");
  const navigate = useNavigate();
  // const { userId } = useParams();
  const { practitionerId } = useParams();
  const location = useLocation();

  // console.log("******** practitionerId *****", practitionerId);
  // console.log("Received state at profile details:", location.state);

  const { isRescheduling, rescheduleFeeApplicable, oldAppointmentId } =
    location.state || {
      isRescheduling: false,
      rescheduleFeeApplicable: false,
    };

  // console.log("Received state at profile details:", location.state);
  // console.log(
  //   "^^^^^^^^^^^oldAppointmentId in PractitionerProfileDetails is ^^^^^^",
  //   oldAppointmentId
  // );

  const today = new Date().toISOString().slice(0, 10);

  useEffect(() => {
    const fetchPractitionerDataAndAppointments = async () => {
      const practitionerRef = doc(db, "practitioners", practitionerId);
      const practitionerSnap = await getDoc(practitionerRef);

      if (practitionerSnap.exists()) {
        setPractitioner(practitionerSnap.data());
      } else {
        console.log("No such document for practitioner!");
        return;
      }
      const appointmentsRef = collection(db, "appointments");
      const q = query(
        appointmentsRef,
        where("practitionerId", "==", practitionerId)
      );
      const querySnapshot = await getDocs(q);
      const appointments = querySnapshot.docs.map((doc) => doc.data());

      setConfirmedAppointments(appointments);
      // console.log(
      //   "^^^^^^^^^^^^^^^^^^^^^confirmed app time is ^^^^^^^^^^^^^^^^^^^^^",
      //   confirmedAppointments
      // );
    };

    const fetchUserMembershipOption = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUserMembershipOption(userSnap.data().membershipOption || "");
        }
      }
    };
    fetchPractitionerDataAndAppointments();
    fetchUserMembershipOption();
  }, [practitionerId]);

  // const isSlotAvailable = (date, slot) => {
  //   // Get tomorrow's date in UTC
  //   const tomorrow = moment.utc().add(1, "days").startOf("day");

  //   // Create a moment object for the slot time in UTC on the given date
  //   const slotDateTime = moment.utc(
  //     `${moment(date).format("YYYY-MM-DD")} ${slot}`,
  //     "YYYY-MM-DD HH:mm"
  //   );

  //   // Check if the slot is before tomorrow (thus today or in the past)
  //   if (slotDateTime.isBefore(tomorrow)) {
  //     return false; // Slot is too early and should not be displayed
  //   }

  //   // Check against confirmed appointments
  //   return !confirmedAppointments.some((appointment) => {
  //     let confirmedDateTimeUTC;
  //     try {
  //       confirmedDateTimeUTC = moment.utc(
  //         appointment.appointmentTimeUTC.toDate()
  //       );
  //     } catch (error) {
  //       console.error("Error converting appointment time to UTC:", error);
  //       return true; // Assume slot is taken if there's an error
  //     }

  //     return slotDateTime.isSame(confirmedDateTimeUTC, "minute");
  //   });
  // };

  const isSlotAvailable = (date, slot) => {
    const practitionerTimeZone = practitioner?.address?.timezone;
    const tomorrow = moment.utc().add(1, "days").startOf("day");
    // Convert the given date and slot to a UTC moment object
    const slotDateTime = moment
      .tz(
        `${moment(date).format("YYYY-MM-DD")} ${slot}`,
        "YYYY-MM-DD HH:mm",
        practitionerTimeZone
      )
      .utc();

    if (slotDateTime.isBefore(tomorrow)) {
      return false; // Slot is too early and should not be displayed
    }

    const availability = !confirmedAppointments.some((appointment) => {
      // Directly convert the Firestore Timestamp to a moment object in UTC
      let confirmedDateTimeUTC;
      try {
        confirmedDateTimeUTC = moment.utc(
          appointment.appointmentTimeUTC.toDate()
        );
      } catch (error) {
        console.error("Error converting appointment time to UTC:", error);
        return false;
      }

      // console.log(
      //   `Comparing Slot: ${slotDateTime.format()} with Appointment: ${confirmedDateTimeUTC.format()}`
      // );

      return slotDateTime.isSame(confirmedDateTimeUTC, "minute");
    });

    return availability;
  };

  // const handleSlotClick = async (selectedDay, selectedTimeSlot) => {
  //   const userId = auth.currentUser?.uid; // Ensure you have the user's ID, adjust as needed

  //   if (!userId) {
  //     console.error("User is not logged in");
  //     return;
  //   }

  //   const userRef = doc(db, "users", userId);
  //   try {
  //     const docSnap = await getDoc(userRef);
  //     if (docSnap.exists()) {
  //       const userData = docSnap.data();
  //       const isSubscribed = userData.isSubscribed;

  //       // Determine the route based on the subscription status
  //       const route = isSubscribed
  //         ? "/members-scheduling-appointment"
  //         : "/scheduling-appointment";

  //       navigate(`${route}/${practitionerId}`, {
  //         state: {
  //           practitionerId: practitionerId, // Assuming you meant to pass userId as practitionerId, adjust as needed
  //           selectedDate: selectedDay.toLocaleDateString("en-US", {
  //             weekday: "long",
  //             year: "numeric",
  //             month: "long",
  //             day: "numeric",
  //           }),
  //           selectedTime: selectedTimeSlot,
  //         },
  //       });
  //     } else {
  //       console.error("No user data available");
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch user data:", error);
  //   }
  // };

  const handleSlotClick = async (selectedDay, selectedTimeSlot) => {
    const userId = auth.currentUser?.uid; // Ensure you have the user's ID, adjust as needed

    if (!userId) {
      console.error("User is not logged in");
      return;
    }
    const userRef = doc(db, "users", userId);
    try {
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        const isSubscribed = userData.isSubscribed;

        // const route =
        //   userMembershipOption !== "one-time"
        //     ? "/members-scheduling-appointment"
        //     : "/scheduling-appointment";

        // const route = isSubscribed
        //   ? "/members-scheduling-appointment"
        //   : "/scheduling-appointment";

        // let route;
        // if (isRescheduling) {
        //   route = "/members-scheduling-appointment";
        // } else {
        //   route = isSubscribed
        //     ? "/members-scheduling-appointment"
        //     : "/scheduling-appointment";
        // }

        let route;
        if (isRescheduling) {
          if (rescheduleFeeApplicable) {
            route = "/members-scheduling-appointment"; // Redirect to billing if fee is applicable
          } else {
            route = "/rescheduling"; // Redirect to the rescheduling page when no fee is needed
          }
        } else {
          // For normal booking
          route = isSubscribed
            ? "/members-scheduling-appointment"
            : "/scheduling-appointment";
        }

        navigate(`${route}/${practitionerId}`, {
          state: {
            practitionerId: practitionerId,
            selectedDate: selectedDay.toLocaleDateString("en-US", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            }),
            selectedTime: selectedTimeSlot,
            isRescheduling,
            rescheduleFeeApplicable,
            oldAppointmentId,
          },
        });
      }
    } catch (error) {
      console.error("Failed to fetch user data:", error);
    }
  };

  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log(userTimezone);
  const convertTimeToUserTimezone = (time, practitionerTimezone) => {
    if (!practitionerTimezone) return time;

    const todayInPractitionerTimezone = moment
      .tz(practitionerTimezone)
      .format("YYYY-MM-DD");

    const datetimeInPractitionerTimezone = moment.tz(
      `${todayInPractitionerTimezone} ${time}`,
      "YYYY-MM-DD hh:mm A",
      practitionerTimezone
    );

    const datetimeInUserTimezone = datetimeInPractitionerTimezone
      .clone()
      .tz(userTimezone);

    // Format the datetime in a user-friendly format, e.g., "3:00 PM"
    return datetimeInUserTimezone.format("h:mm A");
  };

  const handleDateChange = (event) => {
    setStartDate(event.target.value);
  };

  if (!practitioner || !practitioner.availability) {
    return <div>Loading...</div>; // Or any other loading state representation
  }

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const availability = practitioner.availability || {};
  const sortedAvailability =
    practitioner && practitioner.availability
      ? Object.entries(practitioner.availability)
          .sort((a, b) => daysOfWeek.indexOf(a[0]) - daysOfWeek.indexOf(b[0]))
          .map(([day, slots]) => ({
            day,
            date: getDateObjectForDay(day, startDate),
            slots,
          }))
      : [];

  // Check if 'availability' exists and is an object, otherwise default to an empty object

  // if (!practitioner) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Container style={{ paddingBottom: "100px", paddingTop: "50px" }}>
      <Row className="justify-content-center">
        <Col xs={12} md={8} lg={8}>
          <Card className="mt-3">
            <Card.Body>
              {/* Practitioner Details and other rows */}
              <Row className="mb-3">
                <Col className="text-center">
                  <Image
                    src={practitioner.imgUrl}
                    roundedCircle
                    style={{ width: "100px", height: "100px" }}
                  />
                </Col>
              </Row>
              <Row className="mb-3 text-center">
                <Col>
                  <h2>
                    {practitioner.firstName} {practitioner.lastName} -{" "}
                    {practitioner.jobTitle
                      ? practitioner.jobTitle
                      : practitioner.appliedJobTitle}
                  </h2>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <h5>About me</h5>
                  <p>{practitioner.bio}</p>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <h5>Specialties</h5>
                  <p>{practitioner.expertise.join(", ")}</p>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <h5>Starting from</h5>
                  <input
                    type="date"
                    value={startDate}
                    onChange={handleDateChange}
                    min={today}
                  />
                </Col>
              </Row>

              <Row className="mb-3">
                <Col>
                  <h5>Video Visit Hours</h5>
                  {sortedAvailability.map(({ day, date, slots }, index) => (
                    <Row key={index} className="mb-2">
                      <Col>
                        <strong>{day}</strong>:
                        <span style={{ marginRight: "15px" }}></span>
                        {slots.map((time, timeIndex) => {
                          if (isSlotAvailable(date, time)) {
                            // This should return false for taken slots
                            const convertedTime = convertTimeToUserTimezone(
                              time,
                              practitioner.address.timezone
                            );
                            return (
                              <Button
                                key={timeIndex}
                                variant="outline-primary"
                                size="sm"
                                className="me-2"
                                onClick={() =>
                                  handleSlotClick(date, convertedTime)
                                }
                              >
                                {convertedTime}
                              </Button>
                            );
                          }
                          return null;
                        })}
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PractitionerProfileDetails;

const getDateObjectForDay = (dayOfWeek, startDate) => {
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const start = new Date(startDate);
  const dayIndex = daysOfWeek.indexOf(dayOfWeek);
  const startDayIndex = start.getDay();
  let addDays = dayIndex - startDayIndex;
  if (addDays < 0) addDays += 7; // Ensure it's a future date
  start.setDate(start.getDate() + addDays);
  return start;
};
