import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import "../../styles/General.css";
import "../../styles/Article.css";
import { db } from "../../config/Firebase";
import { collection, addDoc } from "firebase/firestore";

const SubscriptionComponent = () => {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true); // State to track if the input is valid
  const [feedbackMessage, setFeedbackMessage] = useState("");

  // Function to validate email
  const validateEmail = (email) => {
    // Simple regex for basic email validation
    return /\S+@\S+\.\S+/.test(email);
  };

  // Handle input change
  const handleInputChange = (e) => {
    setEmail(e.target.value);
    // Validate the input as the user types
    setIsValid(validateEmail(e.target.value));
  };

  // Handle input blur (when the input loses focus)
  const handleInputBlur = () => {
    // Check if the input is empty or not a valid email when input loses focus
    setIsValid(email === "" || validateEmail(email));
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Check for email validity before proceeding
    if (!validateEmail(email)) {
      setIsValid(false);
      setFeedbackMessage("Please enter a valid email address.");
      return;
    }

    try {
      // Add email to the Firestore collection
      await addDoc(collection(db, "subscribed"), {
        email: email,
      });
      // Reset email input, set a success feedback message
      setEmail("");
      setIsValid(true);
      setFeedbackMessage("You have been successfully subscribed!");
    } catch (error) {
      console.error("Error subscribing: ", error);
      setFeedbackMessage("An error occurred. Please try again.");
    }
  };

  return (
    <Container style={{ paddingTop: "100px", paddingBottom: "100px" }}>
      <Form onSubmit={handleSubmit}>
        <Row>
          {/* Left Column */}
          <Col>
            <Row>
              <p className="email-subscription-title">
                Bring health to your inbox
              </p>
            </Row>
            <Row>
              <p className="main-article-content">
                We’ll send you content you'll not only enjoy reading but also
                want to apply.
              </p>
            </Row>
            <Row style={{ paddingRight: "25px" }}>
              <p className="main-article-content">
                By submitting, I consent to Sârv, and its affiliates contacting
                me by email at the address provided about its products and
                services.
              </p>
            </Row>
          </Col>

          {/* Right Column */}
          <Col>
            <Row>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter your email address"
                  className={`custom-subscribe-input ${
                    !isValid && "input-invalid"
                  }`} // Apply 'input-invalid' class if not valid
                  value={email}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                />
                {!isValid && (
                  <Form.Text style={{ color: "red" }}>
                    Please enter a valid email address.
                  </Form.Text>
                )}
              </Form.Group>
            </Row>
            <Row>
              {feedbackMessage && (
                <Row style={{ marginTop: "10px" }}>
                  <Alert variant={isValid ? "success" : "danger"}>
                    {feedbackMessage}
                  </Alert>
                </Row>
              )}
              <Button
                variant="primary"
                type="submit"
                className="custom-subscribe-btn"
              >
                Subscribe
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default SubscriptionComponent;
