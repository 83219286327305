import React, { useState } from "react";
import { db } from "../../../config/Firebase";
import {
  collection,
  getDocs,
  updateDoc,
  query,
  where,
} from "firebase/firestore";

const CollectionOperations = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [output, setOutput] = useState("");
  const [uniqueActions, setUniqueActions] = useState("");
  const [searchAction, setSearchAction] = useState("");
  const [searchResult, setSearchResult] = useState("");

  const handleUpdateAll = async () => {
    setIsLoading(true);
    try {
      const q = query(collection(db, "herbs"));
      const querySnapshot = await getDocs(q);
      const updates = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (
          data.therapeutic_actions &&
          typeof data.therapeutic_actions === "string"
        ) {
          const actionsArray = data.therapeutic_actions
            .toLowerCase()
            .split(",")
            .map((item) => item.trim());
          const updatePromise = updateDoc(doc.ref, {
            therapeutic_actions: actionsArray,
          });
          updates.push(updatePromise);
        }
      });

      await Promise.all(updates);
      setOutput(`Updated ${updates.length} documents.`);
    } catch (error) {
      console.error("Error updating documents:", error);
      setOutput("Error updating documents.");
    }
    setIsLoading(false);
  };

  const fetchUniqueActions = async () => {
    setIsLoading(true);
    try {
      const q = query(collection(db, "herbs"));
      const querySnapshot = await getDocs(q);
      const actionsSet = new Set();

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (
          data.therapeutic_actions &&
          Array.isArray(data.therapeutic_actions)
        ) {
          data.therapeutic_actions.forEach((action) => actionsSet.add(action));
        }
      });

      setUniqueActions(Array.from(actionsSet).sort().join("\n"));
    } catch (error) {
      console.error("Error fetching unique actions:", error);
      setUniqueActions("Error fetching actions.");
    }
    setIsLoading(false);
  };

  const cleanTherapeuticActions = async () => {
    setIsLoading(true);
    try {
      const q = query(collection(db, "herbs"));
      const querySnapshot = await getDocs(q);
      const updates = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (
          data.therapeutic_actions &&
          Array.isArray(data.therapeutic_actions)
        ) {
          // Remove all periods from each action
          const cleanedActions = data.therapeutic_actions.map(
            (action) => action.replace(/\./g, "") // Use global flag to replace all occurrences
          );
          const updatePromise = updateDoc(doc.ref, {
            therapeutic_actions: cleanedActions,
          });
          updates.push(updatePromise);
        }
      });

      await Promise.all(updates);
      setOutput(`Cleaned ${updates.length} documents.`);
    } catch (error) {
      console.error("Error cleaning documents:", error);
      setOutput("Error cleaning documents.");
    }
    setIsLoading(false);
  };

  const findHerbByAction = async () => {
    setIsLoading(true);
    try {
      const q = query(
        collection(db, "herbs"),
        where("therapeutic_actions", "array-contains", searchAction)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const foundDoc = querySnapshot.docs[0]; // Get the first document found
        setSearchResult(foundDoc.id); // Display the document ID
      } else {
        setSearchResult("No document found with that action.");
      }
    } catch (error) {
      console.error("Error searching for document:", error);
      setSearchResult("Error searching for documents.");
    }
    setIsLoading(false);
  };

  return (
    <div>
      <button onClick={handleUpdateAll} disabled={isLoading}>
        Update All Documents
      </button>
      <button onClick={fetchUniqueActions} disabled={isLoading}>
        Fetch Unique Actions
      </button>
      <button onClick={cleanTherapeuticActions} disabled={isLoading}>
        Clean Therapeutic Actions
      </button>
      <button onClick={findHerbByAction} disabled={isLoading || !searchAction}>
        Find Herb
      </button>
      <input type="text" value={output} readOnly />
      <textarea
        value={uniqueActions}
        readOnly
        style={{ width: "100%", height: "200px", marginTop: "10px" }}
      />
      <input
        type="text"
        value={searchAction}
        onChange={(e) => setSearchAction(e.target.value)}
        placeholder="Enter therapeutic action"
        style={{ margin: "10px 0" }}
      />
      <input
        type="text"
        value={searchResult}
        readOnly
        style={{ width: "100%" }}
      />
    </div>
  );
};

export default CollectionOperations;
