import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ReactGA from "react-ga4";

const RecipesCategories = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/recipes-categories" });
    // ReactGA.pageview("/recipes-categories");
  }, []);

  const activities = [
    {
      image: "everyday.jpg",
      title: "Everyday Recipes",
      description: "Herbs that prevent oxidative damage, protecting cells.",
    },
    {
      image: "immune-support.jpg",
      title: "Immune Defense",
      description: "Herbs that protect the liver and enhance its function.",
    },
    {
      image: "women.jpg",
      title: "Women's Health",
      description:
        "Herbs that help the body adapt to stress and normalize body processes.",
    },
    {
      image: "men.jpg",
      title: "Men's Health",
      description: "Herbs that aid in bowel movements to relieve constipation",
    },
    {
      image: "elder.jpg",
      title: "Recipes for Elder",
      description:
        "Herbs that relieve gas, bloating, and digestive discomfort.",
    },
    {
      image: "child.jpg",
      title: "Care for Babies and Children",
      description:
        "Herbs that promote urine production to help in fluid balance.",
    },
  ];

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const imagePath = (image) => `/img/explorer/recipes/${image}`;

  const handleCardClick = (category) => {
    // Make sure the category names are correctly formatted
    navigate(`/recipes/${encodeURIComponent(category)}`);
    ReactGA.event({
      category: "Recipe Category Clicks",
      action: "Click on Category Card",
      label: category, // This will track which category was clicked
    });
  };

  return (
    <Row>
      {activities.map((activity, index) => (
        <Col
          key={index}
          md={2}
          sm={6}
          onClick={() => handleCardClick(activity.title)}
        >
          <Card className="mb-4" style={{ cursor: "pointer", border: "none" }}>
            {/* <Card.Img
              variant="top"
              src={imagePath(activity.image)}
              style={{ height: "300px" }}
            /> */}
            <LazyLoadImage
              alt={activity.title}
              src={imagePath(activity.image)}
              effect="blur"
              style={{ height: "300px", width: "100%", borderRadius: "10px" }}
            />
            <Card.Body>
              <Card.Title>{activity.title}</Card.Title>
              <Card.Text>{truncateText(activity.description, 50)}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default RecipesCategories;
