import React, { useEffect, useState } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Image,
  Modal,
} from "react-bootstrap";
import { db, auth } from "../../../config/Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc as firebaseDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const UpcomingAppointments = () => {
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] =
    useState(false);
  const [showLateCancelFeeModal, setShowLateCancelFeeModal] = useState(false);
  const [currentAppointment, setCurrentAppointment] = useState(null);
  const [showRescheduleConfirmationModal, setShowRescheduleConfirmationModal] =
    useState(false);
  const [showFeeModal, setShowFeeModal] = useState(false);
  const [currentPractitionerId, setCurrentPractitionerId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // console.log("User ID from auth:", user.uid);
        fetchUpcomingAppointments(user.uid);
      } else {
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUpcomingAppointments = async (userId) => {
    const appointmentsRef = collection(db, "appointments");
    const today = moment().startOf("day");

    const q = query(
      appointmentsRef,
      where("userId", "==", userId),
      where("status", "!=", "cancelled")
    );

    try {
      const querySnapshot = await getDocs(q);
      let filteredAppointments = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const appointment = { id: docSnapshot.id, ...docSnapshot.data() };
          const appointmentDate = moment(
            appointment.selectedDate,
            "dddd, MMMM D, YYYY"
          );
          if (appointmentDate.isSameOrAfter(today)) {
            const practitionerSnap = await getDoc(
              firebaseDoc(db, "practitioners", appointment.practitionerId)
            );
            const practitioner = practitionerSnap.exists()
              ? practitionerSnap.data()
              : {};
            return { ...appointment, practitioner };
          }
          return null;
        })
      );

      filteredAppointments = filteredAppointments.filter(
        (appointment) => appointment !== null
      );
      setIsLoading(false);
      setUpcomingAppointments(filteredAppointments);
    } catch (error) {
      console.error("Failed to fetch appointments:", error);
      setIsLoading(false);
    }
  };

  const handleCancelClick = async (appointment) => {
    setCurrentAppointment(appointment); // Set the current appointment before showing any modal
    const now = moment.utc();
    const appointmentTimeUTC = moment.utc(
      appointment.appointmentTimeUTC.toDate()
    );
    const hoursLeft = appointmentTimeUTC.diff(now, "hours");

    if (hoursLeft >= 48) {
      setShowCancelConfirmationModal(true);
    } else {
      setShowLateCancelFeeModal(true);
    }
  };

  const cancelAppointment = async (isLateCancellation) => {
    if (!currentAppointment) return;

    const updateData = {
      canceledTime: new Date(),
      cancelRequestBy: "client",
      status: "cancelled",
    };

    if (isLateCancellation) {
      updateData.lateCancellationFee = true; // Additional field to track late cancellations
    }

    try {
      await updateDoc(
        firebaseDoc(db, "appointments", currentAppointment.id),
        updateData
      );
      alert("Your appointment has been cancelled.");
    } catch (error) {
      console.error("Failed to cancel appointment:", error);
      alert("Failed to cancel the appointment.");
    }
  };

  const updateReschedulingInfo = async (incursFees) => {
    if (!currentAppointment) {
      console.error("No appointment selected for updating");
      return;
    }

    const appointmentRef = firebaseDoc(
      db,
      "appointments",
      currentAppointment.id
    );

    // Ensure only the required information is updated
    const updateData = {
      rescheduledTime: new Date(),
      isRescheduled: true,
      rescheduleFeeIncurred: incursFees ? "25%" : "None",
    };

    try {
      await updateDoc(appointmentRef, updateData);
      if (incursFees) {
        alert(
          "A 25% rescheduling fee will be applied due to the short notice."
        );
      } else {
        alert("Your appointment has been rescheduled without additional fees.");
      }
      navigate(
        `/practitioner-profile-details/${currentAppointment.practitionerId}`,
        {
          state: {
            isRescheduling: true,
            rescheduleFeeApplicable: incursFees,
            oldAppointmentId: currentAppointment.id,
          },
        }
      );
    } catch (error) {
      console.error("Failed to update appointment rescheduling info:", error);
      alert("Failed to update rescheduling information.");
    }
  };

  const handleRescheduleClick = (appointment) => {
    // console.log("Reschedule clicked", appointment);
    setCurrentAppointment(appointment);
    setCurrentPractitionerId(appointment.practitionerId);
    const now = moment.utc();
    const appointmentTimeUTC = moment.utc(
      appointment.appointmentTimeUTC.toDate()
    );
    const hoursUntilAppointment = appointmentTimeUTC.diff(now, "hours");
    // console.log("Hours until appointment:", hoursUntilAppointment);

    if (hoursUntilAppointment < 24) {
      //   console.log("Showing fee modal");
      setShowFeeModal(true);
    } else {
      //   console.log("Showing reschedule confirmation modal");
      setShowRescheduleConfirmationModal(true);
    }
  };

  const ModalForCancellationConfirmation = () => (
    <Modal
      show={showCancelConfirmationModal}
      onHide={() => setShowCancelConfirmationModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Cancellation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to cancel this appointment?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setShowCancelConfirmationModal(false)}
        >
          No
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            cancelAppointment(false);
            setShowCancelConfirmationModal(false);
          }}
        >
          Yes, Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const ModalForLateCancellationFee = () => (
    <Modal
      show={showLateCancelFeeModal}
      onHide={() => setShowLateCancelFeeModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Late Cancellation Fee Warning</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Your appointment is less than 48 hours away. Cancelling now will incur a
        50% late cancellation fee.
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setShowLateCancelFeeModal(false)}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            cancelAppointment(true);
            setShowLateCancelFeeModal(false);
          }}
        >
          Proceed with Cancellation
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const ModalForRescheduleConfirmation = () => (
    <Modal
      show={showRescheduleConfirmationModal}
      onHide={() => setShowRescheduleConfirmationModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Reschedule</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to reschedule this appointment without any fees?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setShowRescheduleConfirmationModal(false)}
        >
          No
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            updateReschedulingInfo(false);
            setShowRescheduleConfirmationModal(false);
          }}
        >
          Yes, Reschedule
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const ModalForRescheduleFee = () => (
    <Modal show={showFeeModal} onHide={() => setShowFeeModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Reschedule Fee Notification</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        It is less than 24 hours to your appointment. If you choose to
        reschedule, there will be a 25% fee.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowFeeModal(false)}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (!currentAppointment) {
              //   console.error("No appointment available for rescheduling");
              setShowFeeModal(false);
              return;
            }
            updateReschedulingInfo(currentAppointment, true);
            setShowFeeModal(false);
          }}
        >
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (isLoading) {
    return <Container>Loading upcoming appointments...</Container>;
  } else if (upcomingAppointments.length === 0) {
    return (
      <Container
        style={{
          paddingBottom: "100px",
          paddingTop: "100px",
          minHeight: "550px",
        }}
      >
        No upcoming appointments found.
      </Container>
    );
  } else {
    return (
      <Container
        style={{
          minHeight: "450px",
          paddingBottom: "100px",
          paddingTop: "100px",
        }}
      >
        {upcomingAppointments.map((appointment) => (
          <Card
            className="mt-3"
            key={appointment.id}
            style={{ width: "900px", marginTop: "30px" }}
          >
            <Row className="m-3">
              <Col md={3}>
                <Image
                  src={appointment.practitioner?.imgUrl}
                  alt="Practitioner"
                  fluid
                  style={{ width: "250px", height: "250px" }}
                />
              </Col>
              <Col md={9}>
                <Row>
                  <h4>{`${appointment.practitioner?.firstName} ${appointment.practitioner?.lastName}`}</h4>
                </Row>
                <Row>
                  <p>{`${appointment.selectedDate} at ${appointment.selectedTime}`}</p>
                </Row>
                <Row>
                  <p>Status: {appointment.status}</p>
                </Row>
                {/* Row for buttons */}
                <Row className="mt-auto" style={{ paddingTop: "75px" }}>
                  <Col md={12} className="text-right">
                    <Button
                      variant="outline-danger"
                      className="mr-2"
                      onClick={() => handleCancelClick(appointment)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outline-primary"
                      onClick={() => handleRescheduleClick(appointment)}
                      style={{ marginLeft: "20px" }}
                    >
                      Reschedule
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        ))}
        <ModalForCancellationConfirmation />
        <ModalForLateCancellationFee />
        <ModalForRescheduleConfirmation />
        <ModalForRescheduleFee />
      </Container>
    );
  }
};

export default UpcomingAppointments;
