import React from "react";

const PrivacyPolicy = () => {
  return (
    <div style={{ width: "80%", margin: "20px auto", padding: "10px" }}>
      <h4>Sarv Privacy Policy</h4>
      <h5>1. Introduction</h5>
      <p>
        Welcome to Sarv, a personalized holistic health recommendation system
        that connects individuals with holistic healthcare practitioners to
        enhance their well-being operated by M31-V1 LLC. (“Sarv”, “we”, “us”, or
        “our”). We understand your concerns about privacy, which is why we have
        developed this privacy policy (“Privacy Policy”) to explain how we
        collect, use, and disclose information from and/or about you when you
        utilize the System, whether you are a User or a visitor (“Visitor”)
        simply visiting www.joinsarv.com (the “Site”).
      </p>

      <p>
        A note on terms: In this Privacy Policy, when we mention “Personal
        Information,” we mean information that is personally identifiable to
        you, such as your email address and any other non-public information
        associated with it (collectively, “Personal Information”). Additionally,
        “De-Identified Information” refers to information that is not intended
        to be used to personally identify an individual. Lastly, “Cookies” are
        defined as small pieces of information that a website sends to your
        browser while you are viewing the website.
      </p>

      <p>
        <strong>No Information from Children under age 16.</strong> The Site is
        not intended for children, and children are not eligible to use our
        Service. If you are under age 16, please do not attempt to use our
        Service or provide any personal information to us. If we discover that
        we have collected personal information from a child under age 16, we
        will delete that information as quickly as possible. If you believe we
        might have any information from a child under age 16, please contact us
        at <a href="mailto:support@joinsarv.com">support@joinsarv.com</a>.
      </p>

      <h5>2. Accountability & Contact</h5>
      <p>
        Sarv has appointed a Privacy Officer who is responsible for ensuring
        compliance with privacy laws and these policies. All Sarv employees
        undergo privacy training and are aware of their obligation to protect
        your personal information. If you have any questions regarding our
        privacy policies and practices, please contact our Privacy Officer,
        whose contact details are provided below.
      </p>
      <p>support@joinsarv.com</p>
      <h5>3. How We Collect Your Information</h5>
      <p>
        <strong>User Information.</strong> We receive information related to a
        User ("User Information") when:
      </p>
      <ul>
        <li>
          You provide us with information, including when you create an account
          as a User, participate in health analysis using an Analyzer
          distributed by Sarv, or communicate with us through our Site. This
          information may include:
          <ul>
            <li>
              Health Information (e.g., age, gender, health goals, symptoms);
            </li>
            <li>Personal Information (e.g., email address); and</li>
            {/* <li>Demographic Information (e.g., ethnic origin).</li> */}
          </ul>
        </li>
        <li>You interact with the Site, as further described below;</li>
        <li>
          We create De-Identified information by removing information that
          personally identifies you from the User Information otherwise
          collected.
        </li>
      </ul>
      <p>
        <strong>Visitor Information.</strong> We receive information related to
        a Visitor ("Visitor Information") when:
      </p>
      <ul>
        <li>
          You provide us with information, including when you communicate with
          us through our Site, or send us an email. This may include Personal
          Information of the Visitor.
        </li>
        <li>You interact with the Site, as further described below.</li>
      </ul>
      <p>
        <strong>Information we collect when you interact with the Site.</strong>{" "}
        As you use the Site, certain information may be passively collected by
        Cookies, navigational data like Uniform Resource Locators (URLs), and
        third-party tracking services, including:
      </p>
      <ul>
        <li>
          <strong>Activity Information:</strong> We keep track of some of the
          actions you take, such as the pages you visit and the links you click
          on.
        </li>
        <li>
          <strong>Access Device and Browser Information:</strong> When you
          access the Site from a computer or other device, we may collect
          anonymous information from that device, such as your Internet protocol
          address, browser type, and access times (collectively, "Anonymous
          Information").
        </li>
        <li>
          <strong>Cookies:</strong> We may use both session Cookies (which
          expire once you close your web browser) and persistent Cookies to make
          the System easier to use, to make our advertising better, and to
          protect both you and Sarv. You can instruct your browser, by changing
          its options, to stop accepting Cookies or to prompt you before
          accepting a Cookie from the websites you visit. If you do not accept
          Cookies, however, you may not be able to stay logged in to the Site.
        </li>
      </ul>
      <p>
        The information we collect when you interact with the Site may be
        Personal Information (e.g., if you are logged in) or Anonymous
        Information.
      </p>
      <h5>4. How We Use Your Information</h5>
      <p>
        <strong>User Information.</strong> We will use your User Information to:
      </p>
      <ul>
        <li>Administer your account;</li>
        <li>Verify your identity as a User;</li>
        <li>Provide you with customer support;</li>
        <li>
          Tailor the features, performance, and support of the System to you;
        </li>
        <li>
          Respond to your requests, resolve disputes, and/or troubleshoot
          problems;
        </li>
        <li>Improve the quality of the System; and</li>
        <li>Communicate with you about the System.</li>
      </ul>
      <p>
        In addition, we may use your De-Identified Information to conduct
        research or allow others to do so.
      </p>
      <p>
        You can opt out of receiving certain optional communications from Sarv,
        such as newsletters and advertisements, by emailing us at{" "}
        <a href="mailto:support@joinsarv.com">support@joinsarv.com</a>. Please
        note that even if you opt out, we may still send you certain
        System-related communications.
      </p>
      <p>
        <strong>Visitor Information.</strong> We will use your Visitor
        Information to:
      </p>
      <ul>
        <li>
          Respond to your requests, resolve disputes, and/or troubleshoot
          problems;
        </li>
        <li>Improve the quality of the System; and</li>
        <li>Communicate with you about the System.</li>
      </ul>
      <p>
        <strong>Anonymous Information & De-Identified Information.</strong> We
        may use your Anonymous Information and De-Identified Information created
        by us without restriction. For example, we may, through our use of
        Cookies, keep you logged into our site, track your session history, and
        tailor content to you. We may use search content or Anonymous
        Information to analyze usage patterns so that we may enhance the System,
        improve our internal operations, and the content of our software, notify
        Visitors about updates to our System, and improve the efficacy of our
        content and advertisements that appear on the Site.
      </p>
      <h5>5. How We Share Your Information</h5>
      <p>
        We do not share your information, including your email address, for
        marketing or advertising purposes. We only share your information with
        third parties when we have your permission, when it is necessary to
        operate our System, or when we are legally required to do so.
      </p>
      <ul>
        <li>
          Third-party vendors who assist us in operating our System, such as
          application service providers;
        </li>
        <li>
          Third parties to whom you have directed us to send such information;
        </li>
        <li>
          Any parent company, subsidiaries, joint ventures, or other companies
          under common control ("Affiliates"), should we have such Affiliates in
          the future, in which case we will require our Affiliates to adhere to
          this Privacy Policy;
        </li>
        <li>
          A company that merges with us, acquires us, or purchases our assets,
          in which case such company may continue to process User Information
          and Personal Information of Visitors as described in this Privacy
          Policy; or
        </li>
        <li>
          If we believe in good faith that disclosure is necessary to (i)
          resolve disputes, investigate problems, or enforce our Terms of Use;
          (ii) comply with applicable laws or respond to law enforcement or
          other government requests for information relating to investigations
          or alleged illegal activities, in which case we may disclose Personal
          Information without subpoenas or warrants; or (iii) protect and defend
          our rights or property, or the rights or property of you or third
          parties.
        </li>
      </ul>
      <p>
        Additionally, we may disclose Anonymous Information and De-Identified
        Information without restriction in a public forum.
      </p>
      <h5>6. Information You Share With Third Parties</h5>
      <p>
        This Privacy Policy outlines how we handle and disclose the information
        we collect from you on the Site. If you disclose information to third
        parties, such as partners providing goods and services, their use and
        disclosure of the information you provide may be governed by different
        rules. The restrictions on use and disclosure in this Privacy Policy do
        not apply to any third party. We do not oversee the privacy policies of
        third parties, and you are subject to the privacy policies of those
        third parties where applicable. When you click on links on our Site, you
        may be directed away from our site. We are not responsible for the
        privacy practices of other websites, and we encourage you to review
        their privacy statements.
      </p>
      <h5>7. How You Can Change or Remove Your Information</h5>
      <p>
        Sarv cannot verify, modify, or otherwise alter any of the Visitor
        Information, and will not verify, modify, or otherwise alter any of the
        User Information without the consent of the applicable user. However,
        Sarv may correct any errors or modify any immaterial information at any
        time. When available, users have the capability to update some of their
        information through the site.
      </p>
      <p>
        You may request the deletion of your Personal Information by us, but
        please be aware that we may be legally obligated to retain this
        information and not delete it (or to retain this information for a
        certain period, in which case we will comply with your deletion request
        only after meeting such obligations). When we delete Personal
        Information, it will be removed from the active database but may remain
        in our archives. We may also retain Anonymous Information about your use
        of our system. Once we have shared some of your Personal Information
        with third parties, we no longer have access to that information and
        cannot force the deletion or modification of such information by those
        third parties. After deleting Personal Information, we will retain
        De-Identified Data and continue to use De-Identified Data as allowed
        under this Privacy Policy.
      </p>
      <h5>8. How We Protect Your Information</h5>
      <p>
        We employ reasonable physical, electronic and managerial security
        methods to help protect against unauthorized access to Personal
        Information. Please be aware that no data transmission over the Internet
        or data storage facility can be guaranteed to be perfectly secure. As a
        result, while we try to protect your Personal Information, we cannot
        ensure or guarantee the security of any information you transmit to us,
        and you do so at your own risk. You should report any security
        violations to us by sending an email to support@joinsarv.com
      </p>
      <h5>9. Changes to this Privacy Policy</h5>
      <p>
        We may change this Privacy Policy from time to time in the future. We
        will post any revised version of the Privacy Policy at
        joinsatv.com/privacy-policy. If we make any material changes to it, we
        may also send you notice to the last email address you have provided to
        us. Continued use of our System following notice of such changes will
        indicate your acknowledgement of such changes and agreement to be bound
        by the terms and conditions of such changes.
      </p>
      <h5></h5>
      <h5></h5>
      <h5></h5>
      <h5></h5>
    </div>
  );
};

export default PrivacyPolicy;
