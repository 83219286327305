import React, { useState } from "react";
import "../../styles/Search.css";

const SearchComponent = ({ terms, onSelectTerm }) => {
  const [inputValue, setInputValue] = useState("");
  const [filteredTerms, setFilteredTerms] = useState([]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (!value.trim()) {
      setFilteredTerms([]);
    } else {
      // Filter terms based on the input value, comparing against term.title
      const filtered = terms.filter((term) =>
        term.title.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredTerms(filtered);
    }
  };

  const handleSelectTerm = (term) => {
    onSelectTerm(term); // Notify parent component
  };

  return (
    // <div style={{ marginTop: "30px" }}>
    //   <input
    //     type="text"
    //     value={inputValue}
    //     onChange={handleInputChange}
    //     className="search-input"
    //     placeholder="  Search ..."
    //   />
    //   <ul className="search-results">
    //     {filteredTerms.map((term, index) => (
    //       <li key={index} onClick={() => handleSelectTerm(term)}>
    //         {term.title}
    //       </li>
    //     ))}
    //   </ul>
    // </div>
    <span style={{ marginLeft: "20px" }}>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        className="search-input"
        placeholder="  Search ..."
      />
      <ul className="search-results">
        {filteredTerms.map((term, index) => (
          <li key={index} onClick={() => handleSelectTerm(term)}>
            {term.title}
          </li>
        ))}
      </ul>
    </span>
  );
};

export default SearchComponent;
