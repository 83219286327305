import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Table, Form } from "react-bootstrap";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../../../config/Firebase";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

const HerbsGrid = () => {
  const [herbs, setHerbs] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const subcategoriesQuery = searchParams.get("subcategories") || "";
  //   const subcategories = subcategoriesQuery.split(",").map(decodeURIComponent);
  //   const title = decodeURIComponent(searchParams.get("title") || "");
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const { title, subcategories, description } = location.state || {};

  useEffect(() => {
    const fetchHerbs = async () => {
      const loweredSubcategories = subcategories.map((item) =>
        item.toLowerCase()
      );
      const herbsQuery = query(
        collection(db, "herbs"),
        where("therapeutic_actions", "array-contains-any", loweredSubcategories)
      );
      const querySnapshot = await getDocs(herbsQuery);
      const fetchedHerbs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setHerbs(fetchedHerbs);
    };

    if (subcategories.length > 0) {
      fetchHerbs();
    }
  }, [subcategories]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    ReactGA.event({
      category: "Herb Search",
      action: "Search Herbs",
      label: `Search Term: ${e.target.value}`,
    });
  };

  const filteredHerbs = herbs.filter((herb) => {
    const searchLower = searchTerm.toLowerCase();
    const titleMatch = herb.title.toLowerCase().includes(searchLower);
    const otherNamesMatch =
      herb.other_names && herb.other_names.toLowerCase().includes(searchLower);
    return titleMatch || otherNamesMatch;
  });

  return (
    <Container style={{ paddingTop: "20px", paddingBottom: "100px" }}>
      <Row
        className="mb-3 justify-content-center"
        style={{
          alignContent: "center",
          alignItems: "center",
          paddingTop: "100px",
          //   paddingBottom: "50px",
        }}
      >
        <Col>
          <h1 className="article-categoories-title text-center">{title}</h1>
        </Col>
      </Row>
      <Row
        className="mb-3 justify-content-center"
        style={{
          alignContent: "center",
          alignItems: "center",
          paddingTop: "10px",
          paddingBottom: "50px",
        }}
      >
        <Col>
          <h4 className="article-categoories-subtitle text-center">
            {description}
          </h4>
        </Col>
      </Row>

      <Row className="mb-4" style={{ paddingBottom: "50px" }}>
        <Col>
          <Form.Control
            type="text"
            placeholder="Search for herbs by name or alternative names... "
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>
      <Table bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Image</th>
            <th>Name</th>
            <th>Health Benefits</th>
            <th>Parts Used</th>
          </tr>
        </thead>
        <tbody>
          {filteredHerbs.map((herb, index) => (
            <tr
              key={herb.id}
              onClick={() => navigate(`/herbs-details/${herb.id}`)}
            >
              <td>{index + 1}</td>
              <td>
                <img
                  src={herb.imgUrl || "default_image.jpg"}
                  alt={herb.title}
                  style={{ width: "50px", height: "50px" }}
                />
              </td>
              <td>{herb.title}</td>
              <td>{herb.benefits}</td>
              <td>{herb.parts_used}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default HerbsGrid;
