import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db, auth } from "../../../config/Firebase";

function Completion() {
  const location = useLocation();
  const [message, setMessage] = useState(""); // State to store a message to the user

  useEffect(() => {
    // Log for debugging
    // console.log("Query parameters:", location.search);
    // const queryParams = new URLSearchParams(location.search);
    // const paymentIntentId = queryParams.get("payment_intent");
    // console.log(" First PaymentIntent ID:", paymentIntentId);

    // Authentication state listener
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const userId = user.uid; // Get the current user ID
        const queryParams = new URLSearchParams(location.search);
        const paymentIntentId = queryParams.get("payment_intent"); // Get the paymentIntentId from query params

        // Log for debugging
        // console.log("Current user ID:", userId);
        // console.log("PaymentIntent ID:", paymentIntentId);

        if (paymentIntentId) {
          // Confirm payment on the backend
          fetch(`/confirm-payment/${paymentIntentId}?userId=${userId}`)
            .then((response) => response.json())
            .then((data) => {
              if (data.success) {
                setMessage(
                  "Payment succeeded! 🎉 Your transaction was successful."
                );
              } else {
                setMessage(
                  "Payment did not succeed. Please try again or contact support."
                );
              }
            })
            .catch((error) => {
              console.error("Error confirming payment:", error);
              setMessage(
                "An error occurred during payment confirmation. Please contact support."
              );
            });
        } else {
          setMessage(
            "No payment intent found. Please ensure you have completed the payment process."
          );
        }
      } else {
        // Handle case where user is not logged in
        setMessage(
          "You are not logged in. Please log in to confirm your payment."
        );
      }
    });

    // Cleanup function to unsubscribe from auth state changes
    return () => unsubscribe();
  }, [location.search]); // Depend on location.search to re-run useEffect if query params change

  return (
    <div>
      <h1>Thank you! 🎉</h1>
      <p>{message}</p>
    </div>
  );
}

export default Completion;
