import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Container,
  Card,
  Modal,
  Row,
  Col,
  ProgressBar,
  Table,
} from "react-bootstrap";
import SubscriptionForm from "../utils/SubscriptionForm"; // Ensure this path is correct
import "../../styles/General.css"; // Ensure this path is correct

const DoshaQuiz = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [result, setResult] = useState([]);
  const [showSubscriptionForm, setShowSubscriptionForm] = useState(false);

  const doshaDescriptions = {
    Kapha:
      "The Kapha dosha is predominant in your basic nature, giving you a deep sense of stability and inner calm. Your body will tend to be heavyset and strong, and you will enjoy excellent stamina and energy. When Kapha becomes imbalanced, however, it can manifest in your body as excess weight gain, fluid retention, and allergies. On the level of the mind, excess Kapha can lead to resistance to change and stubbornness. If you have a Kapha imbalance, you will tend to hold on to things, jobs, habits, and relationships long after they are no longer nourishing or necessary. To lighten the heaviness of Kapha, be sure to get regular physical exercise. Eat light, spicy foods, and give away things you have been accumulating that you know you’ll never use. As you learn more about the qualities of each dosha, you’ll be able to recognize when an imbalance arises in your mind or body and take steps in your daily life to restore overall well-being.",
    Vata: "The Vata dosha is predominant in your basic nature. Since Vata is the principle of movement and change, you will tend to always be on the go, with an energetic and creative mind. As long as Vata is in balance, you will be lively and enthusiastic, with a lean body. If Vata becomes imbalanced in your mind-body system, your activity will start to feel out of control. Your mind may race, contributing to anxiety and insomnia. You may start skipping meals, resulting in unintended weight loss, and your digestion may become irregular. If you notice these early symptoms of a Vata imbalance, slow down, take time to meditate, don’t skip meals, and get to bed earlier. A regular lifestyle routine helps ground Vata so you are not carried away into the ethers. As you learn more about the qualities of each dosha, you’ll be able to recognize when an imbalance arises in your mind or body and take steps in your daily life to restore overall well-being.",
    Pitta:
      "The Pitta dosha is predominant in your basic nature, giving you a tendency to be assertive and determined, with a muscular body and a penetrating mind. As long as Pitta is balanced in your mind-body constitution, you will enjoy a strong appetite and digestive powers. When Pitta becomes imbalanced, heat begins to rise in the body and mind. Heartburn, ulcers, hypertension, and inflammatory conditions reflect excessive accumulation of the fire element. On the level of emotions, too much Pitta manifests as irritability and anger. These symptoms are signals to “chill.” Stop packing in too many things to do in too little time. Reduce your competitive activities, spend time in the inner quiet of meditation, and go for a walk in a natural setting that is soothing and cooling. As you learn more about the qualities of each dosha, you’ll be able to recognize when an imbalance arises in your mind or body and take steps in your daily life to restore overall well-being.",
  };

  const doshaCharacteristics = {
    Kapha: {
      Physical: [
        "Strong, sturdy, and heavier build",
        "Smooth, oily, and cool skin",
        "Thick, lustrous, wavy hair",
        "Slow metabolism and digestion",
        "Good physical strength and endurance",
      ],
      Psychological: [
        "Calm, tolerant, and forgiving nature",
        "Loyal and stable in relationships",
        "Methodical in work",
        "Resists change, prefers routine",
        "Prone to congestion, colds, and sinus problems",
      ],
    },
    Vata: {
      Physical: [
        "Light, slim build and may struggle to gain weight",
        "Dry skin and hair",
        "Cold hands and feet",
        "Quick and irregular digestion",
        "Prone to fatigue but often possesses high energy in short bursts",
      ],
      Psychological: [
        "Enthusiastic, lively, and imaginative",
        "Quick to learn but also quick to forget",
        "Variable mood and emotions",
        "Tendency towards worry and anxiety when out of balance",
        "Creative, flexible, and adaptable",
      ],
    },
    Pitta: {
      Physical: [
        "Medium build, strong, well-proportioned",
        "Warm skin, prone to rashes or acne",
        "Strong digestion, strong appetite",
        "Can be bald or have thinning hair",
        "Perspires easily and may have a strong body odor",
      ],
      Psychological: [
        "Sharp intellect and keen sense of concentration",
        "Ambitious, driven, and competitive",
        "Can be assertive and confident",
        "Prone to irritability and anger when out of balance",
        "Organized and leadership-oriented",
      ],
    },
  };

  useEffect(() => {
    // Replace "/data/doshas.json" with the correct path to your questions data
    fetch("/data/doshas.json")
      .then((response) => response.json())
      .then((data) => setQuestions(data))
      .catch((error) => console.error("Failed to load quiz data:", error));
  }, []);

  const handleOptionChange = (selectedOption) => {
    const newAnswers = [...userAnswers];
    newAnswers[currentQuestionIndex] = selectedOption;
    setUserAnswers(newAnswers);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowSubscriptionForm(true);
    }
  };

  const onSubscriptionFormSubmit = () => {
    setShowSubscriptionForm(false);
    calculateAndSortResult();
  };

  const calculateAndSortResult = () => {
    const tally = { Vata: 0, Pitta: 0, Kapha: 0 };
    userAnswers.forEach((answer, index) => {
      const question = questions[index];
      if (question && answer === question.options[0]) {
        tally.Vata += 1;
      } else if (question && answer === question.options[1]) {
        tally.Pitta += 1;
      } else if (question && answer === question.options[2]) {
        tally.Kapha += 1;
      }
    });

    const totalAnswers = userAnswers.length;
    const sortedTally = Object.entries(tally)
      .map(([type, count]) => ({
        type,
        percentage: Math.round((count / totalAnswers) * 100),
      }))
      .sort((a, b) => b.percentage - a.percentage);

    setResult(sortedTally);
  };

  return (
    <div>
      {questions.length > 0 && result.length === 0 ? (
        <>
          <Container
            className="d-flex align-items-left justify-content-center"
            style={{ minHeight: "100vh", height: "600px" }}
          >
            <div
              className="w-100"
              style={{ maxWidth: "500px", paddingTop: "60px" }}
            >
              <Card
                className="shadow no-border-card"
                style={{
                  height: "500px",
                  alignItems: "left",
                  paddingTop: "50px",
                }}
              >
                <Card.Body>
                  <Card.Title className="dosha-card-title">
                    {questions[currentQuestionIndex].question}
                  </Card.Title>
                  <Form style={{ paddingLeft: "20px" }}>
                    {questions[currentQuestionIndex].options.map(
                      (option, index) => (
                        <Form.Check
                          type="radio"
                          label={option}
                          name="quizOption"
                          key={index}
                          onChange={() => handleOptionChange(option)}
                          checked={userAnswers[currentQuestionIndex] === option}
                        />
                      )
                    )}
                  </Form>
                  <Button
                    onClick={handleNextQuestion}
                    style={{ marginTop: "50px", marginLeft: "40px" }}
                    className="scroll-button"
                  >
                    Next
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </Container>

          <Modal
            show={showSubscriptionForm}
            onHide={() => setShowSubscriptionForm(false)}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Subscription Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SubscriptionForm onFormSubmit={onSubscriptionFormSubmit} />
            </Modal.Body>
          </Modal>
        </>
      ) : result.length > 0 ? (
        <Container>
          <Row style={{ paddingTop: "100px" }}>
            <Col>
              <div>Your mind-body Type is:</div>
              <div>
                <strong>{result[0].type}</strong>: {result[0].percentage}%
              </div>
            </Col>
            <Col>
              {result.map((item, index) => (
                <Row key={index} className="mb-2">
                  <Col>
                    <div>
                      {item.type}: {item.percentage}%
                    </div>
                    <ProgressBar
                      now={item.percentage}
                      label={`${item.percentage}%`}
                    />
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>

          <Row className="mb-3" style={{ marginTop: "100px" }}>
            <Col className="text-center">
              <h4>DOSHA OVERVIEW</h4>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="text-center">
              <h5>About your mind-body type</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              {/* <Table bordered>
                <tbody>
                  <tr>
                    <td>{result[0].type}</td>
                    <td>{doshaDescriptions[result[0].type]}</td>
                  </tr>
                </tbody>
              </Table> */}

              <div>
                <strong>{result[0].type}:</strong>
              </div>
              <p style={{ fontSize: "18px" }}>
                {doshaDescriptions[result[0].type]}
              </p>
            </Col>
          </Row>

          <Row className="mb-3" style={{ marginTop: "100px" }}>
            <Col className="text-center">
              <h4>DOSHA Charactristics</h4>
            </Col>
          </Row>
          {result.length > 0 && doshaCharacteristics[result[0].type] ? (
            <Row
              className="mb-3"
              style={{ marginTop: "50px", fontSize: "18px" }}
            >
              <Col md={6}>
                <div>
                  <strong>Physical Characteristics:</strong>
                  <ul>
                    {doshaCharacteristics[result[0].type].Physical.map(
                      (item, index) => (
                        <li key={index}>{item}</li>
                      )
                    )}
                  </ul>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <strong>Psychological Characteristics:</strong>
                  <ul>
                    {doshaCharacteristics[result[0].type].Psychological.map(
                      (item, index) => (
                        <li key={index}>{item}</li>
                      )
                    )}
                  </ul>
                </div>
              </Col>
            </Row>
          ) : (
            <div>
              Please complete the quiz to see your Dosha characteristics.
            </div>
          )}
        </Container>
      ) : (
        <div>Loading questions...</div>
      )}
    </div>
  );
};

export default DoshaQuiz;
