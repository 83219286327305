import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import { db, storage } from "../../../config/Firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

// Assuming practitioner is the object containing all the details from Firestore
function PractitionersDetails() {
  const [practitioner, setPractitioner] = useState(null);
  const { id } = useParams(); // Retrieves the ID from the URL

  // Example of creating a formatted address string

  useEffect(() => {
    const fetchPractitionerDetails = async () => {
      const docRef = doc(db, "practitioners", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setPractitioner({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("No such document!");
      }
    };

    fetchPractitionerDetails();
  }, [id]);

  if (!practitioner) {
    return <div>Loading...</div>; // or any other loading state representation
  }

  const formattedAddress = practitioner.address
    ? `${practitioner.address.addressLine1}, ${
        practitioner.address.unit ? practitioner.address.unit + ", " : ""
      }${practitioner.address.city}, ${practitioner.address.state} ${
        practitioner.address.zipCode
      }, ${practitioner.address.country}`
    : "No address provided";

  const availabilityList = practitioner.availability
    ? Object.entries(practitioner.availability)
        .filter(([_, isAvailable]) => isAvailable)
        .map(([day]) => day)
        .join(", ")
    : "No availability information";

  const educationInfo = practitioner.education
    ? `${practitioner.education.fieldOfStudy} at ${practitioner.education.professionalSchool}, Class of ${practitioner.education.graduationYear}`
    : "Education information not provided";

  return (
    <Container style={{ paddingTop: "100px" }}>
      {/* Section 1 */}
      <Row className="mb-4">
        <Col md={6}>
          <Image
            src={practitioner.imgUrl}
            fluid
            style={{ width: "250px", height: "250px" }}
          />
        </Col>
        <Col md={6}>
          <Row>
            <Col>First Name: {practitioner.firstName}</Col>
            <Col>Last Name: {practitioner.lastName}</Col>
          </Row>
          <Row style={{ paddingBottom: "50px" }}>
            <Col>Date of Birth: {practitioner.dob}</Col>
            <Col>Gender: {practitioner.gender}</Col>
          </Row>
        </Col>
      </Row>

      {/* Section 2 - Contact Information */}
      <div style={{ paddingTop: "50px" }}></div>
      <h5>Contact Information</h5>
      <hr />
      <Row className="mb-4">
        <Col md={6}>Phone: {practitioner.phone}</Col>
        <Col md={6}>Email: {practitioner.email}</Col>
      </Row>
      <Row style={{ paddingBottom: "50px" }}>
        {/* <Col>{practitioner.address}</Col> */}
        <Col>{formattedAddress}</Col>
      </Row>

      {/* Section 3 - Background Information */}
      <h5>Background Information</h5>
      <hr />
      <Row className="mb-4">
        <Col md={6}> Current Job Title: {practitioner.jobTitle}</Col>
        <Col md={6}>
          {" "}
          Years of Professionl experience: {practitioner.yearsOfExperience}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>Education: {educationInfo}</Col>
        {/* <Col md={6}>{practitioner.education}</Col>
        <Col md={6}>{practitioner.expertise}</Col> */}
      </Row>
      <Row style={{ paddingBottom: "50px" }}>
        <Col>Bio: {practitioner.bio}</Col>
      </Row>

      {/* Section 4 - Application Details */}
      <h5>Application Details</h5>
      <hr />
      <Row className="mb-4">
        <Col>
          How did you first hear about Sârv?{" "}
          {practitioner["where-did-you-hear"]}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          How much time do you intend to spend with Sârv clients?{" "}
          {practitioner["intended-time-to-spend"]}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          What makes you most interested in Sârv?{" "}
          {practitioner["interest-reason"]}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          How much of your time is currently spent on administrative and/or
          billing related tasks? {practitioner["administrative-time"]}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>Applied for: {practitioner.appliedJobTitle}</Col>
      </Row>
      <Row className="mb-4">
        <Col>Minimum Hourly Rate:{practitioner.minimumPay}</Col>
      </Row>
      <Row className="mb-4">
        {/* <Col>{practitioner.availability}</Col> */}
        <Col>Availability: {availabilityList}</Col>
      </Row>
      <Row>
        <Col md={4}>Mentor Email:{practitioner.mentorEmail}</Col>
        <Col md={4}>Mentor Name:{practitioner.mentorName}</Col>
        <Col md={4}>Mentor Phone:{practitioner.mentorPhone}</Col>
      </Row>
    </Container>
  );
}

export default PractitionersDetails;
