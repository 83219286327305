import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../config/Firebase";
import { doc, getDoc } from "firebase/firestore";
import { Container, Row, Col, Card, Modal, Button } from "react-bootstrap";
import patientsIcon from "../../images/admin/health-report.png";
import appointmentsIcon from "../../images/admin/schedule.png";
import payIcon from "../../images/admin/receipt.png";
import virtualVisits from "../../images/admin/virtual-doctor.png";
import { onAuthStateChanged } from "firebase/auth";

function PractitionersDashboard() {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log("Auth state changed:", currentUser);
      if (currentUser) {
        checkAccess(currentUser.uid);
      } else {
        console.log("No user signed in, redirecting to login");
        navigate("/login");
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, [navigate]);

  const checkAccess = async (userId) => {
    const practitionerRef = doc(db, "practitioners", userId);
    const docSnap = await getDoc(practitionerRef);

    if (docSnap.exists() && docSnap.data().isVerified) {
      console.log("User is a verified practitioner");
      // Allow access to dashboard since the user is verified
      setShowModal(false);
    } else {
      console.log("Practitioner is not verified or document does not exist");
      setShowModal(true);
    }
  };

  const handleCardClick = (route) => {
    if (!showModal) {
      navigate(route);
    }
  };

  if (showModal) {
    return (
      <Modal show={showModal} onHide={() => navigate("/")} centered>
        <Modal.Header closeButton>
          <Modal.Title>Access Restricted</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You do not currently have access to this page. Your access will be
          granted once your application has been verified by our team.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={() => navigate("/")}
            className="header-btn"
            style={{
              marginTop: "10px",
              borderRadius: "5px",
              width: "100%",
              height: "40px",
            }}
          >
            Go to Home
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  const cardsInfo = [
    {
      id: 1,
      text: "Appointments Management",
      img: appointmentsIcon,
      route: "/appointments-mng",
    },
    {
      id: 2,
      text: "Patient Management",
      img: patientsIcon,
      route: "/clients-info",
    },
    {
      id: 3,
      text: "Upcoming Virtual Visit",
      img: virtualVisits,
      route: "/meetings-mng",
    },
    {
      id: 4,
      text: "Time and Pay Tracking",
      img: payIcon,
      route: "/pay-tracking",
    },
  ];

  return (
    <Container style={{ minHeight: "80vh", paddingTop: "100px" }}>
      <Row>
        {cardsInfo.map((card) => (
          <Col key={card.id} md={6} className="mb-4">
            <Card
              onClick={() => handleCardClick(card.route)}
              className="text-center"
              style={{ cursor: "pointer" }}
            >
              <Card.Body>
                <Card.Img
                  variant="top"
                  src={card.img}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                  }}
                  className="mx-auto d-block"
                />
                <Card.Title>{card.text}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default PractitionersDashboard;
