import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Table, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../../config/Firebase"; // Adjust this import path
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  Timestamp,
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import moment from "moment";
import note from "../../../images/utils/note.png";
import profile from "../../../images/utils/profile.png";
import ClinetsDetails from "../clients/ClinetsDetails";

const MeetingsManagement = () => {
  const [meetings, setMeetings] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMeetings = async (userId) => {
      const todayStart = Timestamp.fromDate(moment().startOf("day").toDate());
      const todayEnd = Timestamp.fromDate(moment().endOf("day").toDate());

      const meetingsRef = collection(db, "meetings");
      const q = query(
        meetingsRef,
        where("practitionerId", "==", userId),
        where("appointmentTime", ">=", todayStart),
        where("appointmentTime", "<=", todayEnd)
      );
      const querySnapshot = await getDocs(q);
      console.log(`Meetings fetched: ${querySnapshot.size}`);

      const meetingsData = await Promise.all(
        querySnapshot.docs.map(async (meetingDoc) => {
          const userRef = doc(db, "users", meetingDoc.data().userId);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const appointmentTimestamp = meetingDoc.data().appointmentTime;
            const appointmentTime = moment(
              appointmentTimestamp.toDate()
            ).format("HH:mm");

            return {
              id: meetingDoc.id,
              ...meetingDoc.data(),
              clientName: `${userSnap.data().firstName} ${
                userSnap.data().lastName
              }`,
              appointmentTime: appointmentTime, // Use the formatted string here
            };
          } else {
            console.log("No such user document!");
            return null;
          }
        })
      );

      // Filter out any null values that might have been added due to missing user documents
      setMeetings(
        meetingsData
          .filter((meeting) => meeting)
          .sort((a, b) => moment(a.timestamp).diff(moment(b.timestamp)))
      );
    };

    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log(`Fetching meetings for practitionerId: ${user.uid}`);
        fetchMeetings(user.uid);
      }
    });
  }, []);

  const handleJoinMeeting = (meetingId, channelName, practitionerId, token) => {
    console.log(
      "from meeting room: ",
      meetingId,
      channelName,
      practitionerId,
      token
    );
    // Construct the URL with query parameters including the meetingId
    const url = `/meeting-room?meetingId=${encodeURIComponent(
      meetingId
    )}&channelName=${encodeURIComponent(
      channelName
    )}&practitionerId=${encodeURIComponent(
      practitionerId
    )}&token=${encodeURIComponent(token)}`;
    // Open a new window or tab with the URL
    window.open(url, "_blank");
  };

  const handleNoteRedirect = (practitionerId, meetingId, userId) => {
    navigate("/meeting-notes", {
      state: { userId, meetingId, practitionerId },
    });
    console.log("*********", practitionerId, meetingId, userId);
  };

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleShowModal = (userId) => {
    // Step 2
    setSelectedUserId(userId);
    setShowModal(true);
  };

  return (
    <Container>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Client Name</th>
                <th>Appointment Time</th>
                <th>Action</th>
                <th>Notes</th>
                <th>Client Profile</th>
              </tr>
            </thead>
            <tbody>
              {meetings.map((meeting, index) => (
                <tr key={index}>
                  <td>{meeting.clientName}</td>
                  <td>{meeting.appointmentTime}</td>
                  <td>
                    <Button
                      onClick={() =>
                        handleJoinMeeting(
                          meeting.id,
                          meeting.channelName,
                          meeting.practitionerId,
                          meeting.token
                        )
                      }
                    >
                      Join the meeting room
                    </Button>
                  </td>
                  <td>
                    <Button
                      className="bi bi-clipboard-plus"
                      onClick={() =>
                        handleNoteRedirect(
                          meeting.practitionerId,
                          meeting.id, // Assuming the document id is stored as 'id' in the meeting object
                          meeting.userId
                        )
                      }
                    >
                      Write
                    </Button>
                  </td>
                  <td>
                    <Button onClick={() => handleShowModal(meeting.userId)}>
                      View
                    </Button>
                    {/* <Button onClick={handleShow}>View Patient Profile</Button> */}
                  </td>

                  <Modal show={showModal} onHide={handleClose} size="lg">
                    <Modal.Header closeButton>
                      <Modal.Title>Client Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* Here you would include the content you want to display, potentially another component */}
                      <ClinetsDetails userId={selectedUserId} />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default MeetingsManagement;
