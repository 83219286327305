import React from "react";
import Featured from "./Featured";
import TitleLineNoLink from "../utils/TitleLineNoLink";
import TitleLine from "../utils/TitleLine";
import CardGrid from "./CardGrid";
import EmailSubscription from "../utils/EmailSubscription";

function LibraryHome() {
  return (
    <div>
      <TitleLineNoLink title="Featured" />
      <Featured jsonFilePath="/data/featured.json" />
      <div className="content" style={{ paddingBottom: "30px" }}>
        {" "}
      </div>
      <TitleLine
        title="Basics of Medicinal Herbs"
        jsonData="/data/blogs/basics-of-herbalism.json"
      />
      <CardGrid jsonFilePath="/data/blogs/basics-of-herbalism.json" />
      <TitleLine title="Everyday Recipes" jsonData="/data/blogs/recipes.json" />
      <CardGrid jsonFilePath="/data/blogs/recipes.json" />
      {/* <TitleLine title="Diabetes Treatments" link="/view-all" />
      <CardGrid jsonFilePath="/data/diabetes.json" /> */}
      {/* <TitleLine title="Cancer Treatments" link="/view-all" />
      <CardGrid jsonFilePath="/data/cancer.json" /> */}
      {/* <TitleLine title="Weight Loss" link="/view-all" />
      <CardGrid jsonFilePath="/data/weightloss.json" /> */}
      {/* <TitleLine title="Mental Health" link="/view-all" /> */}
      <TitleLine
        title="Mental Health"
        jsonData="/data/blogs/depression-anxiety.json"
      />
      <CardGrid jsonFilePath="/data/blogs/depression-anxiety.json" />
      <TitleLine
        title="Digestive System"
        jsonData="/data/blogs/digestive.json"
      />
      <CardGrid jsonFilePath="/data/blogs/digestive.json" />
      {/* <TitleLine title="Ayuverda" link="/view-all" />
      <CardGrid jsonFilePath="/data/ayurveda.json" /> */}
      {/* <HerbCategories /> */}
    </div>
  );
}

export default LibraryHome;
