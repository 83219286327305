import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/Firebase"; // Adjust this import to your Firebase configuration file's path
import { Container, Row, Col, Card, Button, Badge } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ViewPractitioners = () => {
  const [practitioners, setPractitioners] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPractitioners = async () => {
      const querySnapshot = await getDocs(collection(db, "practitioners"));
      const practitionersData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        // Calculate the visit fee
        const minimumPay = Number(data.minimumPay);
        const visitFee = minimumPay + minimumPay * 0.25;
        // Use Math.floor to drop the decimal value
        const roundedVisitFee = Math.floor(visitFee);
        return {
          id: doc.id,
          ...data,
          visitFee: roundedVisitFee,
        };
      });
      setPractitioners(practitionersData);
    };

    fetchPractitioners();
  }, []);

  return (
    <Container style={{ paddingTop: "100px", paddingBottom: "100px" }}>
      <Row xs={1} md={2} lg={4} className="g-4">
        {practitioners.map((practitioner, idx) => (
          <Col key={idx}>
            <Card style={{ height: "400px" }}>
              <Card.Img
                variant="top"
                src={practitioner.imgUrl}
                className="practitioners-card-img"
              />
              <Card.Body>
                <Card.Title>
                  {practitioner.firstName} {practitioner.lastName}
                </Card.Title>
                {/* <Card.Text>
                  {practitioner.jobTitle}, {practitioner.yearsOfExperience}{" "}
                  years of experience
                </Card.Text> */}
                <Card.Text>
                  {practitioner.jobTitle
                    ? practitioner.jobTitle
                    : practitioner.appliedJobTitle}
                  <span> </span>with{" "}
                  <b style={{ color: "darkgreen" }}>
                    {practitioner.yearsOfExperience}{" "}
                  </b>{" "}
                  years of experience
                </Card.Text>

                <Row className="mb-2">
                  <Col>
                    <Badge bg="success">
                      Visit fee: ${practitioner.visitFee}{" "}
                      {/* Display the rounded visit fee */}
                    </Badge>
                  </Col>
                </Row>
                <Button
                  variant="outline-primary"
                  className="availability-button"
                  onClick={() =>
                    navigate(`/practitioner-profile-details/${practitioner.id}`)
                  }
                >
                  Check the availability
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ViewPractitioners;
