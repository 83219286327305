import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/General.css";

const FAQComponent = () => {
  const navigate = useNavigate();

  const redirectToReviews = () => {
    navigate("/faq"); // Make sure the path matches your route setup
  };

  return (
    <div className="faqs-container">
      <Container fluid className="text-center">
        {/* Second Row */}
        <Row className="justify-content-center" style={{ paddingTop: "50px" }}>
          <Col>
            <div className="overview-container">Have qouestions?</div>
          </Col>
        </Row>

        {/* Third Row */}
        <Row
          className="justify-content-center"
          style={{ paddingBottom: "50px" }}
        >
          <Col>
            <Button
              className="online-services-btn"
              onClick={redirectToReviews}
              style={{ marginTop: "50px" }}
            >
              Visit our FAQs
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FAQComponent;
