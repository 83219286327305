import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Headline = () => {
  const navigate = useNavigate();
  const redirectToOptions = () => {
    navigate("/options"); // Make sure the path matches your route setup
  };

  return (
    <Card style={{ border: "none", paddingBottom: "100px" }}>
      <Card.Body>
        <Row style={{ paddingLeft: "200px" }}>
          {/* Left Column with 8 Rows of Static Text */}
          <Col md={6}>
            <Row>
              <Col className="headline-title ">How Sârv can help you?</Col>
            </Row>
            <Row>
              <Col className="headline-text">
                <img
                  src="/img/check-mark.png"
                  alt="Check"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Boost your immune system
              </Col>
            </Row>
            <Row>
              <Col className="headline-text">
                <img
                  src="/img/check-mark.png"
                  alt="Check"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Support your heart health
              </Col>
            </Row>
            {/* <Row>
              <Col className="headline-text">
                <img
                  src="/img/check-mark.png"
                  alt="Check"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Support weight loss
              </Col>
            </Row> */}

            <Row>
              <Col className="headline-text">
                <img
                  src="/img/check-mark.png"
                  alt="Check"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Improve your sleep quality
              </Col>
            </Row>
            <Row>
              <Col className="headline-text">
                <img
                  src="/img/check-mark.png"
                  alt="Check"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Improve your digestive health
              </Col>
            </Row>
            <Row>
              <Col className="headline-text">
                <img
                  src="/img/check-mark.png"
                  alt="Check"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Manage pain and inflammation
              </Col>
            </Row>
            <Row>
              <Col className="headline-text">
                <img
                  src="/img/check-mark.png"
                  alt="Check"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Regulate your blood sugar level
              </Col>
            </Row>
            <Row>
              <Col className="headline-text">
                <img
                  src="/img/check-mark.png"
                  alt="Check"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Reduce your stress level & manager your anxiety
              </Col>
            </Row>
            <Row>
              <Col className="headline-text">
                <img
                  src="/img/check-mark.png"
                  alt="Check"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Enhance your brain health & boost cognitive function
              </Col>
            </Row>

            <Row>
              <Col className="headline-text">
                <img
                  src="/img/check-mark.png"
                  alt="Check"
                  style={{ height: "20px", marginRight: "10px" }}
                />
                Increase your knowledge of self-care and herbal medicine
              </Col>
            </Row>
            <Row>
              <Col className="headline-text">
                <Button className="header-btn" onClick={redirectToOptions}>
                  See how it works
                </Button>
              </Col>
            </Row>
            {/* <Row>
              <Col className="text-center">
                <Button className="headline-btn " onClick={redirectToOptions}>
                  See how it works
                </Button>
              </Col>
            </Row> */}
          </Col>

          {/* Right Column with an Image */}
          <Col
            md={6}
            className="d-flex justify-content-center align-items-center"
          >
            <img
              src="/img/article/flower-1.jpg"
              alt="Descriptive Alt Text"
              className="headline-image"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Headline;
