import React, { useState } from "react";
import { Container, Card, Form, Button, Alert } from "react-bootstrap";
import { db } from "../../config/Firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const AffiliateRequestForm = () => {
  const [formData, setFormData] = useState({
    companyName: "",
    websiteURL: "",
    industry: "",
    companySize: "",
    contactName: "",
    contactEmail: "",
    contactPhoneNumber: "",
    reasonForInterest: "",
    expectedOutcomes: "",
    marketingApproach: "",
    legalComplianceAgreement: false,
  });
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const preprocessURL = (url) => {
    if (!/^(https?:\/\/|ftp:\/\/)/i.test(url)) {
      return `http://${url}`;
    }
    return url;
  };

  const validateURL = (url) => {
    const pattern =
      /^(https?:\/\/|ftp:\/\/)([\da-z\.-]+)\.([a-z]{2,})\/?([\/\w \.-]*)*\/?$/;
    return pattern.test(url);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(false);
    if (!validateEmail(formData.contactEmail)) {
      setError("Please enter a valid email address.");
      return;
    }
    const processedURL = preprocessURL(formData.websiteURL);
    if (!validateURL(processedURL)) {
      setError("Please enter a valid website URL.");
      return;
    }

    if (!formData.legalComplianceAgreement) {
      setError("You must agree to the terms and privacy policy.");
      return;
    }
    if (
      !formData.companyName ||
      !formData.websiteURL ||
      !formData.industry ||
      !formData.companySize ||
      !formData.contactName ||
      !formData.reasonForInterest ||
      !formData.expectedOutcomes ||
      !formData.marketingApproach
    ) {
      setError("Please fill in all required fields.");
      return;
    }
    try {
      await addDoc(collection(db, "affiliates-request"), {
        ...formData,
        requestTimestamp: serverTimestamp(),
      });
      setIsSubmitted(true);
      setError("");
      // Optionally clear form here if you wish
    } catch (error) {
      setError("Failed to submit the request. Please try again.");
      console.error("Error submitting affiliate request:", error);
    }
  };

  return (
    <Container className="mt-5">
      <Card
        className="p-4 shadow"
        style={{ borderRadius: "5px", border: "none" }}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              name="companyName"
              placeholder="Company Name*"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              name="websiteURL"
              placeholder="Website URL*"
              value={formData.websiteURL}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Select
              name="industry"
              value={formData.industry}
              onChange={handleChange}
              required
            >
              <option value="">Select Industry *</option>
              <option value="Healthcare">Healthcare</option>
              <option value="Wellness">Wellness</option>
              <option value="Sports and Recreation">
                Sports and Recreation
              </option>
              <option value="Insurance">Insurance</option>
              <option value="Education">Education</option>
              <option value="Retail">Retail</option>
              <option value="Beauty and Personal Care">
                Beauty and Personal Care
              </option>
              <option value="Mental Health Services">
                Mental Health Services
              </option>
              <option value="Nutrition and Food Services">
                Nutrition and Food Services
              </option>
              <option value="Environmental and Sustainability Initiatives">
                Environmental and Sustainability Initiatives
              </option>

              <option value="Other">Other</option>
              {/* Add more options as needed */}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Select
              name="companySize"
              value={formData.companySize}
              onChange={handleChange}
              required
            >
              <option value="">Company Size *</option>
              <option value="1-100">1-100 Members</option>
              <option value="101-500">100-500 Members</option>
              <option value="501-1000">500-1000 Members</option>
              <option value="1000-2500">1000-2500 Members</option>
              <option value="2500-5000">2500-5000 Members</option>
              <option value="5000-10000">5000-10,000 Members</option>
              <option value="10000-50000">10,000-50,000 Members</option>
              <option value="50000-100000">50,000-100,000 Members</option>
              <option value="100000-500000">100,000-500,000 Members</option>
              <option value="500000-1000000">500,000-1,000,000 Members</option>
              {/* Add more options as needed */}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              name="contactName"
              placeholder="Contact Name*"
              value={formData.contactName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="email"
              name="contactEmail"
              placeholder="Contact Email*"
              value={formData.contactEmail}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="tel"
              name="contactPhoneNumber"
              placeholder="Contact Phone Number"
              value={formData.contactPhoneNumber}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              as="textarea"
              rows={3}
              name="reasonForInterest"
              placeholder="Reason for Interest*"
              value={formData.reasonForInterest}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label></Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="expectedOutcomes"
              placeholder="Expected Outcomes*"
              value={formData.expectedOutcomes}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label></Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="marketingApproach"
              placeholder="Marketing Approach*"
              value={formData.marketingApproach}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" id="agreement-checkbox">
            <Form.Check
              type="checkbox"
              label="I agree to the platform's affiliate program terms and conditions and consent to the privacy policy."
              name="legalComplianceAgreement"
              checked={formData.legalComplianceAgreement}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="business-button">
            Request to Join
          </Button>
        </Form>
        {error && (
          <Alert variant="danger" className="mt-3">
            {error}
          </Alert>
        )}
        {isSubmitted && (
          <Alert variant="success" className="mt-3">
            Your affiliate request has been submitted successfully!
          </Alert>
        )}
      </Card>
    </Container>
  );
};

export default AffiliateRequestForm;
