import React, { useState, useEffect } from "react";
import { db, auth } from "../../config/Firebase";
import { useNavigate } from "react-router-dom";
import { setDoc, doc } from "firebase/firestore";
import { Container, Form, Button } from "react-bootstrap";
import "../../styles/General.css";
import "../../styles/Enrollment.css";
import MultipleBars from "../utils/MultipleBars";

const HealthIssues = () => {
  const [selectedIssues, setSelectedIssues] = useState({
    Headaches: false,
    DigestiveIssues: false,
    SkinProblems: false,
    JointMusclePain: false,
    Fatigue: false,
    Insomnia: false,
    HighBloodPressure: false,
    ObesityAndWeightIssues: false,
    AnxietyAndStress: false,
    Depression: false,
    None: false,
  });
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedIssues({ ...selectedIssues, [name]: checked });
  };

  const handleSubmit = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    try {
      const userDocRef = doc(db, "users", userId);
      await setDoc(
        userDocRef,
        { healthIssues: selectedIssues },
        { merge: true }
      );
      navigate("/physicalHealthSatisfaction");
    } catch (error) {
      console.error("Error updating health issues in Firebase:", error);
    }
  };

  return (
    <div className="progress-container">
      <MultipleBars
        progresses={[
          { progress: 35, maxProgress: 100, bgClass: "" },
          { progress: 28, maxProgress: 100, bgClass: "bg-success" },
          { progress: 0, maxProgress: 100, bgClass: "bg-info" },
        ]}
      />
      <Container className="d-flex align-items-center justify-content-center">
        <div
          className="w-100"
          style={{
            maxWidth: "600px",
            paddingTop: "60px",
            paddingBottom: "20px",
          }}
        >
          <h3 className="header-text">
            Do you experience any of the following regularly?
          </h3>
          <Form>
            {Object.keys(selectedIssues).map((issue, index) => (
              <Form.Check
                key={index}
                type="checkbox"
                id={issue}
                label={issue.split(/(?=[A-Z])/).join(" ")} // Convert camelCase to normal text
                name={issue}
                checked={selectedIssues[issue]}
                onChange={handleCheckboxChange}
                className="mb-3"
              />
            ))}
            <Button onClick={handleSubmit} className="next-button mt-3">
              Submit
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default HealthIssues;
