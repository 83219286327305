import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const TitleLine = ({ title, jsonData }) => {
  const navigate = useNavigate();

  const handleViewAllClick = (e) => {
    e.preventDefault();
    navigate("/article-categories", { state: { title, jsonData } });
  };

  return (
    <Container style={{ paddingTop: "50px" }}>
      <Row className="align-items-center">
        <Col xs={6}>
          <h2 style={{ fontSize: "32px", color: "gray" }}>{title}</h2>
        </Col>
        <Col xs={6} className="text-end">
          <a
            onClick={handleViewAllClick}
            style={{
              cursor: "pointer",
              textDecoration: "none",
              fontSize: "20px",
            }}
            href={title}
            // onClick={onLinkClick}
          >
            View All
          </a>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            position: "relative",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              backgroundColor: "lightgray",
              height: "2px",
              width: "100%",
              position: "absolute",
              left: "0%",
              top: "5%",
            }}
          ></div>
        </Col>
      </Row>
    </Container>
  );
};

export default TitleLine;
