import React, { useState } from "react";
import "../../styles/General.css";
import { Form, Button, Container, Card, Alert } from "react-bootstrap";
import { auth } from "../../config/Firebase";
import { sendPasswordResetEmail } from "firebase/auth";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage("");
    setError("");
    setShowAlert(false); // Reset the showAlert state

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage("Password reset email sent. Check your inbox.");
      setShowAlert(true); // Show alert on success
    } catch (error) {
      console.log(error);
      setError(
        "Failed to send password reset email. Please check if the email is correct."
      );
      setShowAlert(true); // Show alert on error
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "400px", paddingTop: "60px" }}>
        <Card className="shadow no-border-card" style={{ height: "500px" }}>
          <Card.Body>
            <Card.Title className="login-card-title">Reset Password</Card.Title>
            <Form onSubmit={handleSubmit}>
              <Form.Group
                controlId="formBasicEmail"
                style={{ paddingTop: "30px" }}
              >
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
              {/* Display alerts below the input field */}
              {showAlert && error && (
                <Alert variant="danger" className="mt-3">
                  {error}
                </Alert>
              )}
              {showAlert && message && (
                <Alert variant="success" className="mt-3">
                  {message}
                </Alert>
              )}
              <div style={{ paddingTop: "50px" }}>
                <Button
                  variant="primary"
                  type="submit"
                  className="login-btn w-100 mt-3"
                >
                  Reset Password
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default ForgotPassword;
