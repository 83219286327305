import React, { useState } from "react";
import { Container, Card, Form, Button, Alert } from "react-bootstrap";
import { db } from "../../config/Firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import "bootstrap/dist/css/bootstrap.min.css";

const ContactCard = () => {
  const [formData, setFormData] = useState({
    workEmail: "",
    firstName: "",
    lastName: "",
    jobTitle: "",
    companyName: "",
    companySize: "",
    communicationsConsent: false,
  });
  const [error, setError] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const {
      workEmail,
      firstName,
      lastName,
      jobTitle,
      companyName,
      companySize,
      communicationsConsent,
    } = formData;
    const emailRegex = /\S+@\S+\.\S+/;

    if (
      !emailRegex.test(workEmail) ||
      !firstName ||
      !lastName ||
      !jobTitle ||
      !companyName ||
      !companySize ||
      !communicationsConsent
    ) {
      setError(
        "Please fill out all fields with valid information and consent to communications."
      );
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsSubmitted(false);

    if (!validateForm()) return;

    try {
      await addDoc(collection(db, "demo-request"), {
        ...formData,
        requestTime: Timestamp.fromDate(new Date()),
      });
      setIsSubmitted(true);
      setFormData({
        workEmail: "",
        firstName: "",
        lastName: "",
        jobTitle: "",
        companyName: "",
        companySize: "",
        communicationsConsent: false,
      });
    } catch (error) {
      setError("Failed to submit the request. Please try again.");
    }
  };

  return (
    <Container className="mt-5">
      <Card
        className="p-3 shadow-lg"
        style={{ borderRadius: "5px", border: "none" }}
      >
        {error && <Alert variant="danger">{error}</Alert>}
        {isSubmitted && (
          <Alert variant="success">Request submitted successfully!</Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Control
              type="email"
              placeholder="Work email*"
              name="workEmail"
              value={formData.workEmail}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="First name*"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Last name*"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Job title*"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Control
              type="text"
              placeholder="Company name*"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Select
              name="companySize"
              value={formData.companySize}
              onChange={handleChange}
            >
              <option value="">Company size (up to) *</option>
              {/* Map through company sizes for dropdown options */}
              {[
                "100 Members",
                "200 Members",
                "500 Members",
                "1000 Members",
                "2500 Members",
                "5000 Members",
                "10000 Members",
                "15000 Members",
                "20000 Members",
                "50000 Members",
                "100,000 Members",
                "250,000 Members",
                "500,000 Members",
                "1,000,000 Members",
              ].map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCheckbox">
            <Form.Check
              type="checkbox"
              label="Sarv may send me communications about its services and product offerings."
              name="communicationsConsent"
              checked={formData.communicationsConsent}
              onChange={handleChange}
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="business-button">
            Request Demo
          </Button>
        </Form>
      </Card>
    </Container>
  );
};

export default ContactCard;
