import React, { useState } from "react";
import { db } from "../../../config/Firebase";
import { collection, getDocs } from "firebase/firestore";

const UniqueCategories = () => {
  const [categories, setCategories] = useState([]);

  const fetchUniqueCategories = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "recipes"));
      const allCategories = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.category && Array.isArray(data.category)) {
          allCategories.push(...data.category);
        }
      });
      const uniqueCategories = [...new Set(allCategories)];
      setCategories(uniqueCategories);
    } catch (error) {
      console.error("Error fetching categories: ", error);
    }
  };

  return (
    <div>
      <button onClick={fetchUniqueCategories}>Get Unique Categories</button>
      <p>Categories: {categories.join(", ")}</p>
    </div>
  );
};

export default UniqueCategories;
