import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import pic from "../../images/flower-1.jpg";
import SubscriptionComponent from "../utils/SubscriptionComponent";
import "../../styles/General.css";
import MissionStatement from "./MissionStatement";
import Services from "./Services";
import Results from "../utils/Results";
import FAQComponent from "../utils/FAQComponent";
import JoinNewsLetter from "./JoinNewsLetter";

const Mission = () => {
  return (
    <div>
      <Container>
        <Row>
          {/* Column for Text */}
          <Col>
            <Row>
              <Col>
                <p className="mission-label">ABOUT SÂRV</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="header-agrandir">Our Mission</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="mission-text">
                  Sârv is dedicated to revolutionizing the way individuals
                  approach healthcare by intertwining the wisdom of traditional
                  herbal remedies with the precision of modern technology. Our
                  mission is to empower users with personalized, natural
                  healthcare solutions that align with their unique physical
                  characteristics and health conditions. By leveraging advanced
                  cutting edge technology, Sârv provides tailored
                  recommendations for medicinal herbs, fostering a holistic
                  approach to health and well-being.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="mission-text">
                  Sârv is not just an app; it's a movement towards a more
                  natural, informed, and personalized healthcare paradigm, where
                  the power of nature is harnessed in harmony with technological
                  innovation to promote health, healing, and harmony.
                </p>
              </Col>
            </Row>
          </Col>

          {/* Column for Picture */}
          <Col>
            <Image
              src={pic}
              style={{
                width: "100%",
                height: "500px",
                margin: "50px",
                borderRadius: "25px",
              }}
              alt="Descriptive Alt Text"
            />
          </Col>
        </Row>
        {/* <SubscriptionComponent /> */}
      </Container>
      <MissionStatement />
      <Services />
      {/* <Results /> */}
      <JoinNewsLetter />
      {/* <SubscriptionComponent /> */}
      {/* <FAQComponent /> */}
    </div>
  );
};

export default Mission;
