import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate

const FooterLink = ({ title, jsonDataPath }) => {
  const navigate = useNavigate();

  const handleFooterLinkClick = (e) => {
    e.preventDefault();
    navigate("/article-categories", {
      state: { title, jsonData: jsonDataPath },
    });
  };

  return (
    <li>
      <a
        href="/article-categories"
        onClick={handleFooterLinkClick}
        style={{ cursor: "pointer", textDecoration: "none" }}
      >
        {title}
      </a>
    </li>
  );
};

export default FooterLink;
