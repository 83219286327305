import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  InputGroup,
  FormControl,
  Button,
  Alert,
} from "react-bootstrap";
import { db } from "../../config/Firebase";
import { collection, addDoc } from "firebase/firestore";

const EmailSubscription = () => {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [subscribedEmails, setSubscribedEmails] = useState([]);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setIsValid(true); // Reset validation state on email change
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };



  const handleSubscribe = async () => {
    if (validateEmail(email)) {
      try {
        // Add the email to Firestore
        await addDoc(collection(db, "subscribed"), {
          email: email,
        });
        setEmail(""); // Reset email input
        setIsValid(true); // Reset validation state
        setFeedbackMessage("Subscription successful"); // Set success message
      } catch (error) {
        console.error("Error adding document: ", error);
        setFeedbackMessage("An error occurred. Please try again later."); // Set error message
      }
    } else {
      setIsValid(false);
      setFeedbackMessage("Please enter a valid email address."); // Set validation error message
    }
  };

  return (
    <Container className="my-4" style={{ width: "350px", border: "none" }}>
      <Card style={{ border: "none", borderRadius: "15px" }}>
        <Card.Body>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Email"
              aria-label="Email"
              value={email}
              onChange={handleEmailChange}
              isInvalid={!isValid}
              style={{ border: "none" }}
            />
            <Button
              variant="outline-primary"
              onClick={handleSubscribe}
              style={{
                backgroundColor: "white",
                color: "blue",
                border: "none",
              }}
            >
              Subscribe
            </Button>
          </InputGroup>
          {feedbackMessage && (
            <Alert variant={isValid ? "success" : "danger"}>
              {feedbackMessage}
            </Alert>
          )}
          {/* {!isValid && (
            <Alert variant="danger">Please enter a valid email address.</Alert>
          )} */}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EmailSubscription;
