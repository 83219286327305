import React, { useContext } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "./SubscriptionContext";
import "../../styles/General.css";
import "../../styles/Enrollment.css";

const Order = ({ showContinueButton = true, passedRate }) => {
  const navigate = useNavigate();
  const { rate, title, total, period, priceId } = useSubscription();

  // Use `priceId` as needed in your component

  const taxRate = 0.1; // Example: 10%
  //   const taxAmount = rate * taxRate;
  //   const dueToday = rate + taxAmount;
  const dueToday = total;

  const handleContinue = () => {
    navigate("/subscription-payment");
  };

  return (
    <Container
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: "auto" }}
    >
      <Row className="text-center">
        <Col>{/* <h2>Your Order</h2> */}</Col>
      </Row>
      <Row>
        <Col>
          <Card className="subscription-options-card">
            <Card.Body>
              <Row className="mb-3 row-separator">
                <Col>
                  <h5>Your Order - {title}</h5>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col md={8}>Today’s subtotal</Col>
                <Col md={4}>${total.toFixed(2)}</Col>
              </Row>
              <Row className="mb-2">
                <Col md={8}>Tax</Col>
                <Col md={4}>$0.00</Col>
                {/* <Col md={4}>${taxAmount.toFixed(2)}</Col> */}
              </Row>
              <Row className="mb-2">
                <Col md={8}>Due today</Col>
                <Col md={4}>${dueToday.toFixed(2)}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {showContinueButton && (
        <Row style={{ marginBottom: "50px" }}>
          <Col className="d-flex justify-content-end">
            <Button
              onClick={handleContinue}
              className="subscription-optionse-btn"
            >
              Continue
            </Button>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Order;
