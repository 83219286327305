import React from "react";
import { Card, Col, Container, Row, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/General.css";

const CustomComponent = () => {
  const navigate = useNavigate();
  const redirectToOptions = () => {
    navigate("/options"); // Make sure the path matches your route setup
  };

  return (
    <Container>
      {/* First Row */}
      <Row className="justify-content-md-center">
        <Col md="auto" className="howitworks-caption">
          How Sârv works
        </Col>
      </Row>

      {/* Second Row */}
      <Row className="my-3">
        <Col>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Row>
                {/* Left Column with Image */}
                <Col md={6}>
                  <Image
                    src="/img/article/body-1.jpg"
                    alt="Image"
                    className="howitworks-image"
                  />
                </Col>
                {/* Right Column divided into 2 Rows */}
                <Col md={6}>
                  <Row>
                    {/* First Row divided into 2 Columns */}
                    <Col md={2} className="howitworks-number">
                      1
                    </Col>{" "}
                    {/* This will be the smaller (right) column */}
                    <Col md={10} className="howitworks-title">
                      <span>
                        {" "}
                        Understand how <i>your </i> body works
                      </span>
                    </Col>{" "}
                    {/* This will be the larger (left) column */}
                  </Row>
                  <Row>
                    {/* Second Row with one Column */}
                    <Col className="howitworks-text">
                      Begin your journey to wellness by answering a few simple
                      questions about your lifestyle and health. This initial
                      step helps us grasp the unique workings of your body,
                      setting the foundation for personalized care.
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Third Row */}
      <Row className="my-3">
        <Col>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Row>
                {/* Left Column divided into 2 Rows */}
                <Col md={6}>
                  <Row>
                    {/* First Row divided into 2 Columns */}
                    <Col md={2} className="howitworks-number">
                      2
                    </Col>{" "}
                    {/* This will be the smaller (right) column */}
                    <Col md={10} className="howitworks-title">
                      Learn what natural medicines can help you{" "}
                    </Col>{" "}
                  </Row>
                  <Row>
                    {/* Second Row with one Column */}
                    <Col className="howitworks-text">
                      Gain access to our extensive knowledge base of medicinal
                      herbs and holistic natural remedies. This resource is
                      designed to educate you on the power of nature in
                      achieving your health goals and addressing concerns.
                    </Col>
                  </Row>
                </Col>
                {/* Right Column with Image */}
                <Col md={6}>
                  <Image
                    src="/img/article/natural-remedy-1.jpg"
                    alt="Another Image"
                    className="howitworks-image"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="my-3">
        <Col>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Row>
                {/* Left Column with Image */}
                <Col md={6}>
                  <Image
                    src="/img/article/app-1.jpg"
                    alt="Image"
                    className="howitworks-image"
                  />
                </Col>
                {/* Right Column divided into 2 Rows */}
                <Col md={6}>
                  <Row>
                    {/* First Row divided into 2 Columns */}
                    <Col md={2} className="howitworks-number">
                      3
                    </Col>{" "}
                    {/* This will be the smaller (right) column */}
                    <Col md={10} className="howitworks-title">
                      Receive personalized natural recommendations for your
                      condition
                    </Col>{" "}
                    {/* This will be the larger (left) column */}
                  </Row>
                  <Row>
                    {/* Second Row with one Column */}
                    <Col className="howitworks-text">
                      Based on your individual characteristics and health
                      objectives, our system crafts personalized
                      recommendations. These tailored suggestions aim to guide
                      you on the path to optimal health using natural and
                      holistic approaches.
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <Row className="my-3">
        <Col>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={2} className="howitworks-number">
                      4
                    </Col>{" "}
                    <Col md={10} className="howitworks-title">
                      Connect to a natural healthcare practitioner{" "}
                    </Col>{" "}
                  </Row>
                  <Row>
                    <Col className="howitworks-text">
                      Take your health journey a step further by connecting with
                      our network of experienced naturopaths and herbalists.
                      Through virtual appointments, receive deep insights and
                      specialized recommendations tailored to your condition,
                      ensuring you're supported every step of the way.
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Image
                    src="/img/article/healthcare-provider-1.jpg"
                    alt="Another Image"
                    className="howitworks-image"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}

      <Row
        className="justify-content-center"
        style={{ paddingTop: "50px", paddingBottom: "100px" }}
      >
        <Col className="text-center">
          <Button className="custom-services-btn" onClick={redirectToOptions}>
            See how it works
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CustomComponent;
