import React, { useEffect, useState } from "react";
import { Card, Container, Row, Col, Button, Image } from "react-bootstrap";
import { db, auth } from "../../../config/Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import moment from "moment";

const PastAppointments = () => {
  const [pastAppointments, setPastAppointments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchPastAppointments(user.uid);
      } else {
      }
    });

    return unsubscribe;
  }, []);

  const fetchPastAppointments = async (userId) => {
    const meetingsRef = collection(db, "meetings");
    const today = moment();

    const q = query(meetingsRef, where("userId", "==", userId));

    try {
      const querySnapshot = await getDocs(q);
      const meetings = [];

      for (const docSnapshot of querySnapshot.docs) {
        const meeting = docSnapshot.data();
        if (moment(meeting.appointmentTime).isBefore(today)) {
          const practitionerSnap = await getDoc(
            doc(db, "practitioners", meeting.practitionerId)
          );
          if (practitionerSnap.exists()) {
            meetings.push({
              id: docSnapshot.id,
              ...meeting,
              practitioner: practitionerSnap.data(),
            });
          }
        }
      }
    //   console.log("Meetings to set as past appointments:", meetings);
      setPastAppointments(meetings);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch past appointments:", error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Container>Loading past appointments...</Container>;
  } else if (pastAppointments.length === 0) {
    return <Container>No past appointments found.</Container>;
  } else {
    return (
      <Container style={{ minHeight: "450px" }}>
        {pastAppointments.map((appointment) => (
          <Card key={appointment.id} className="mt-3">
            <Row className="m-3">
              <Col md={4}>
                <Image
                  src={appointment.practitioner.imgUrl}
                  alt="Practitioner"
                  fluid
                />
              </Col>
              <Col md={8}>
                <Row>
                  <h4>{`${appointment.practitioner.firstName} ${appointment.practitioner.lastName}`}</h4>
                </Row>
                <Row>
                  <p>{moment(appointment.appointmentTime).format("LLLL")}</p>
                </Row>
                <Row>
                  <p>Status: {appointment.status}</p>
                </Row>
              </Col>
            </Row>
          </Card>
        ))}
      </Container>
    );
  }
};

export default PastAppointments;
