import React, { useEffect, useState } from "react";
import { Card, Button, Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom"; // Import Link
import "../../styles/Article.css";

const ArticlesRow = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    fetch("/data/mainPageArticles.json") // Adjust the path if necessary
      .then((response) => response.json())
      .then((data) => setArticles(data));
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <Container>
      <Row style={{ paddingTop: "100px" }}>
        {articles.map((article) => (
          <Col key={article.id} md={4}>
            <Card
              style={{
                border: "none",
                height: "500px",
                width: "400px",
                marginBottom: "50px",
              }}
            >
              <Card.Img
                className="zoom-effect"
                variant="top"
                src={article.img}
                alt={article.title}
                style={{
                  height: "200px",
                  objectFit: "cover",
                }}
              />
              <Card.Body>
                <Link
                  to={{
                    pathname: `/learn/article`,
                    search: `?topic=/data/mainPageArticles.json&articleId=${article.id}`,
                  }}
                  className="row-article-title"
                >
                  <Card.Title>{article.title}</Card.Title>
                </Link>
                <Card.Text style={{ paddingTop: "10px" }}>
                  {truncateText(article.text, 100)}
                </Card.Text>
                <Button
                  variant="link"
                  className="readmore-btn"
                  as={Link}
                  to={{
                    pathname: `/learn/article`,
                    search: `?topic=/data/mainPageArticles.json&articleId=${article.id}`,
                  }}
                >
                  Read More {">"}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ArticlesRow;
