import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { auth } from "../../../config/Firebase";
import { useSubscription } from "../SubscriptionContext";

function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const { isSubscription, period, priceId } = useSubscription();
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    async function fetchConfig() {
      // const response = await fetch("http://159.89.92.213:5255/config");
      const response = await fetch("https://api.joinsarv.com/config");
      const configData = await response.json();
      const stripe = await loadStripe(configData.publishableKey);
      setStripePromise(stripe);
    }
    fetchConfig();
  }, []);

  const handlePaymentMethodCreated = async (paymentMethodId) => {
    const userId = auth.currentUser ? auth.currentUser.uid : "";
    const userEmail = auth.currentUser ? auth.currentUser.email : "";
    if (isSubscription && userId) {
      try {
        const subscriptionResponse = await fetch(
          // "http://159.89.92.213:5255/create-subscription",
          "https://api.joinsarv.com/create-subscription",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              userId,
              email: userEmail,
              paymentMethodId,
              priceId,
            }),
          }
        );
        const subscriptionResult = await subscriptionResponse.json();
        if (subscriptionResult.error) {
          console.error(
            "Failed to create subscription:",
            subscriptionResult.error
          );
          // Handle subscription creation failure
        } else {
          // console.log("Subscription created successfully:", subscriptionResult);
          // Handle successful subscription creation
        }
      } catch (error) {
        console.error("Error creating subscription:", error);
        // Handle errors in subscription creation process
      }
    } else {
      // Handle one-time payment logic if not a subscription
    }
  };

  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CheckoutForm onPaymentMethodCreated={handlePaymentMethodCreated} />
        </Elements>
      )}
    </>
  );
}

export default Payment;
