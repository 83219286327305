import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../config/Firebase";
import { Plus } from "react-bootstrap-icons";

const RecipesInfo = () => {
  const [recipes, setRecipes] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecipes = async () => {
      const recipesCollectionRef = collection(db, "recipes");
      const recipesSnapshot = await getDocs(recipesCollectionRef);
      const recipesList = recipesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setRecipes(recipesList);
    };

    fetchRecipes();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRecipes = recipes.filter((recipe) => {
    const searchLower = searchTerm.toLowerCase();
    const titleMatch = recipe.title.toLowerCase().includes(searchLower);
    return titleMatch;
  });

  return (
    <Container style={{ paddingTop: "100px" }}>
      <Row className="mb-4" style={{ paddingBottom: "50px" }}>
        <Col>
          <Form.Control
            type="text"
            placeholder="Search for herbs by title or other names..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Button
            variant="outline-primary"
            onClick={() => navigate("/add-recipe")}
          >
            <Plus size={20} />
          </Button>
        </Col>
      </Row>
      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>Image</th>
            <th>Title</th>
            <th>Content</th>
          </tr>
        </thead>
        <tbody>
          {filteredRecipes.map((recipe, index) => (
            <tr
              key={recipe.id}
              onClick={() => navigate(`/recipe-details/${recipe.id}`)}
            >
              <td>{index + 1}</td>
              <td>{recipe.id}</td>
              <td>
                <img
                  src={recipe.imgUrl}
                  alt={recipe.title}
                  style={{ width: "50px", height: "50px" }}
                />
              </td>
              <td>{recipe.title}</td>
              <td>{recipe.content}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default RecipesInfo;
