import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const RelevantRemedies = ({ recipes = [] }) => {
  // Limit the number of recipes to 4
  const displayedRecipes = recipes.slice(0, 4);
  const navigate = useNavigate();

  const handleCardClick = (recipeId) => {
    // console.log("Clicked Recipe ID:", recipeId);
    window.open(`/recipe-details/${recipeId}`, "_blank");
  };

  return (
    <Container className="mt-4" style={{ paddingLeft: "30px" }}>
      <h4
        className="text-center"
        style={{
          paddingTop: "0px",
          paddingBottom: "25px",
          color: "rgb(58, 63, 93)",
        }}
      >
        Recommended Recipes
      </h4>
      {displayedRecipes.map((recipe) => (
        <Row key={recipe.id} className="mb-4">
          <Col sm={12}>
            <Card
              className="mb-4 shadow-sm explorer-card-hover-effect"
              style={{ cursor: "pointer", border: "none" }}
              onClick={() => handleCardClick(recipe.id)}
            >
              <Card.Img
                variant="top"
                src={recipe.imgUrl}
                style={{ height: "200px", objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>{recipe.title}</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default RelevantRemedies;
