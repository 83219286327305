import React, { useState, useEffect } from "react";
import { Table, Container, Form, Row, Col } from "react-bootstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../config/Firebase"; // Make sure this path matches your Firebase config file's location
import { useNavigate } from "react-router-dom";

function PractitionersInfo() {
  const [practitioners, setPractitioners] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPractitioners = async () => {
      const querySnapshot = await getDocs(collection(db, "practitioners"));
      const docs = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPractitioners(docs);
    };

    fetchPractitioners();
  }, []);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const filteredPractitioners = practitioners.filter((practitioner) => {
    return (
      practitioner.firstName.toLowerCase().includes(searchText) ||
      practitioner.lastName.toLowerCase().includes(searchText) ||
      practitioner.email.toLowerCase().includes(searchText)
    );
  });

  const handleRowClick = (id) => {
    navigate(`/practitioners-details/${id}`);
  };

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <Form>
            <Form.Group controlId="search">
              <Form.Control
                type="text"
                placeholder="Search by First Name, Last Name, or Email"
                onChange={handleSearchChange}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Unique ID</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Applied For</th>
            <th>Verified</th>
          </tr>
        </thead>
        <tbody>
          {filteredPractitioners.map((practitioner, index) => (
            <tr
              key={practitioner.id}
              onClick={() => handleRowClick(practitioner.id)}
            >
              <td>{index + 1}</td>
              <td>{practitioner.id}</td>
              <td>{practitioner.firstName}</td>
              <td>{practitioner.lastName}</td>
              <td>{practitioner.email}</td>
              <td>{practitioner.appliedJobTitle}</td>
              <td>{practitioner.isVerified ? "Yes" : "No"}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default PractitionersInfo;
