import React from "react";

const TermsOfService = () => {
  return (
    <div style={{ width: "80%", margin: "20px auto", padding: "10px" }}>
      <h4>Sarv Terms of Use</h4>
      <div style={{ paddingBottom: "50px", paddingTop: "20px" }}>
        Last revised: April 30, 2024
      </div>
      <p>
        Please review these Terms of Use attentively. This constitutes a legal
        agreement between you and Sarv Inc. (“Sarv”). These Terms of Use govern
        your access to test results from blood analysis performed on blood
        analyzers distributed by Sarv (each, an “Analyzer”) through an online
        web application provided by Sarv, and they impact your legal rights
        regarding access to Sarv platform and participation in testing using any
        Analyzer.
      </p>
      <p>
        By engaging with Sarv platform or an Analyzer, or by clicking the “I
        agree” button, you consent to be bound by these Terms of Use, including
        any changes made over time. If you do not accept these Terms of Use, do
        not use Sarv or participate in testing on any Analyzer.
      </p>
      <h5>1. No Medical Advice</h5>
      <p>
        Sarv is not intended to offer you any medical advice or any other
        advice. TEST RESULTS ARE PROVIDED FOR INFORMATIONAL PURPOSES ONLY. SARV
        AND THE TESTING PERFORMED ON ANALYZERS DO NOT REPLACE TRADITIONAL
        MEDICAL LABORATORY TESTING. IF YOU HAVE A MEDICAL CONDITION OR HAVE
        SYMPTOMS, CONSULT YOUR PHYSICIAN. Your physician or other health care
        provider has specialized medical training that enables him or her to
        decide which traditional medical laboratory tests to order, if any, and
        to interpret the implications of such test results. Sarv does not and is
        not providing any medical advice. For advice on test results provided on
        Sarv, consult your physician. If you do not discuss your test results
        with your physician, it is possible that you might misunderstand the
        implications of the test results.
      </p>
      <p>
        Any other health and health-related information contained within Sarv is
        intended to be general in nature and should not be used as a substitute
        for a visit to your physician or other health care provider. Any such
        information which you obtain from Sarv Online might be inappropriate for
        your own situation or might be misinterpreted. Sarv assumes no
        responsibility for how you use the information you obtain from Sarv .
      </p>
      <p>
        IF YOU HAVE AN EMERGENCY, OR NEED TO SPEAK TO SOMEONE URGENTLY, CONTACT
        YOUR PHYSICIAN OR GO TO THE NEAREST HOSPITAL. Do not use any online
        communication options provided to you by Sarv. Neither Sarv nor any
        other application available through Sarv platform is a substitute for
        appropriate and timely contact with your physician.
      </p>
      <p>
        NEVER CHANGE OR STOP ANY COURSE OF TREATMENT WITHOUT FIRST CONSULTING
        YOUR PHYSICIAN. RELIANCE ON ANY INFORMATION ON SARV PLATFORM IS SOLELY
        AT YOUR OWN RISK.
      </p>
      <h5>2. Use of Sarv</h5>
      <p>
        {" "}
        You may use the Sarv platform for your personal, non-commercial
        purposes. You are not allowed to use the Sarv platform for any other
        purpose. Resale, commercial use, or derivative use of the Sarv platform,
        as well as the use of data mining, robots, or similar data gathering and
        extraction methods, are prohibited. To register for the Sarv platform,
        you must be 16 years of age or older. If you are under the age of
        majority in the province or territory where you reside, you must obtain
        the consent of your parent or legal guardian. The Sarv platform is not
        intended for use by individuals who are under 16 years of age.
      </p>
      <h6>2.1 Limitations of our Services</h6>
      <p>
        Sarv is dedicated to assisting users in finding, coordinating, and
        maintaining quality healthcare services for their needs. However, it's
        important to note the following key aspects of our service:
      </p>
      <ul>
        <li>
          Sarv serves as a platform connecting individuals with holistic
          healthcare providers, but we do not employ these professionals
          directly. Therefore, we are not responsible for the conduct, either
          online or offline, of any client, provider, or other user of our
          platform. Users who engage healthcare providers are responsible for
          complying with all relevant employment and legal requirements,
          including laws related to employment eligibility, payroll, taxes, and
          minimum wage.
        </li>
        <li>
          The content created by healthcare seekers and providers on our
          platform is user-generated. We do not monitor or verify this content
          for accuracy and explicitly disclaim any responsibility for its
          reliability. Users should exercise due diligence and discretion when
          using information from our platform.
        </li>
        <li>
          Sarv does not endorse or recommend specific healthcare providers or
          seekers, nor do we vouch for the suitability, reliability, timeliness,
          or accuracy of the services offered. Our screening processes are
          limited and should not be solely relied upon in assessing the
          appropriateness of a provider or employer.
        </li>
        <li>
          We are not an employment agency; our role is solely to facilitate
          connections between healthcare seekers and providers. We do not play a
          part in securing employment for providers or staff for seekers.
        </li>
        <li>
          Sarv does not oversee or manage the specifics of the services
          delivered by healthcare providers, such as the quality, timing, hours,
          compensation, or legality of the services.
        </li>
        <li>
          We do not provide medical, diagnostic, treatment, or clinical
          services, nor do we engage in activities that require professional
          medical licensure.
        </li>
        <li>
          Some registered users may have the opportunity to verify certain
          information, like email addresses or phone numbers, through our
          platform. Verification means the user has completed our established
          process, but this does not guarantee the absolute accuracy of the
          information or its ownership by the user who provided it.
        </li>
      </ul>
      <p>
        Please carefully review the Release of Liability for Third-Party Content
        and Conduct in Section 9 for critical information regarding the
        limitations of Sarv’s liability by using our platform and services.
      </p>
      <h6> 2.2 Exclusive Use</h6>
      <p>
        If you are seeking healthcare services through Sarv, your account should
        solely be utilized for locating healthcare for yourself, your parents,
        children, grandchildren, or any individuals under your legal
        guardianship. This also includes arrangements for shared healthcare with
        another service seeker's children. As a healthcare provider, your
        account should be used exclusively for finding healthcare job
        opportunities for yourself. You hold full responsibility for all
        activities conducted through your account, and the transfer or
        assignment of your account to another individual or entity is strictly
        prohibited.
      </p>
      <h6> 2.3 Prohibited Uses</h6>
      <p>
        By utilizing Sarv's Site or Services, you agree to adhere to the
        following conduct guidelines:
      </p>
      <ul>
        <li>
          Avoid using the Site or Services in any manner that is abusive,
          threatening, obscene, defamatory, libelous, or offensive on racial,
          sexual, religious, or other grounds.
        </li>
        <li>
          Do not use the Site or Services for fraudulent, unlawful purposes, or
          for any purposes unrelated to healthcare services.
        </li>
        <li>
          Refrain from harassing, abusing, or harming another person or group,
          or attempting such actions.
        </li>
        <li>Using another user's Sarv account is strictly prohibited.</li>
        <li>
          Ensure that all information provided during registration, service
          usage, or in communication with other users is accurate and truthful.
        </li>
        <li>
          Avoid attempting to re-register with Sarv if your account has been
          terminated, or manipulating registration to evade reviews.
        </li>
        <li>
          Do not interfere with the proper functioning of Sarv's Services.
        </li>
        <li>
          Avoid automated or manual actions that could overload Sarv's servers
          or network infrastructure.
        </li>
        <li>
          Do not bypass any measures implemented to restrict access to the
          Services or engage in data scraping or similar activities.
        </li>
        <li>
          Use Sarv's communication systems and contacts only for purposes
          expressly permitted by Sarv.
        </li>
        <li>
          Avoid publishing or linking to malicious content that could harm
          another user's browser or computer.
        </li>
      </ul>
      <p>
        Sarv reserves the right to take necessary actions against prohibited
        activities, including limiting message frequency. Violation of these
        guidelines may lead to immediate termination of Site and Services use.
        Users may be subject to a penalty for maintaining or misusing
        information obtained from Sarv, or for using Sarv's content on
        unauthorized platforms.Sarv also reserves the right to block access from
        certain IP addresses to maintain the integrity of the Site and Services.
      </p>
      <h6> 2.4 Registration, Posting, and Content Restrictions </h6>
      <p>
        "Content" refers to any communications, images, sounds, videos, and all
        material, data, and information uploaded or transmitted through the Sarv
        Site or Services by users or to other Registered Users or Site Visitors.
      </p>
      <ul>
        <li>
          Content posted on the Sarv platform should exclusively serve the
          purpose of seeking, managing, or sharing information about healthcare
          and healthcare-related services.
        </li>
        <li>
          Users must provide accurate, current, and complete information for
          registration and use of the Sarv Site and Services.
        </li>
        <li>
          Accounts must be registered under the user's legal name, even when
          seeking healthcare services for another person.
        </li>
        <li>
          Content on the Sarv platform should primarily be in English, as
          multi-language support is not generally available.
        </li>
        <li>
          Users are solely responsible for the Content they post or transmit,
          ensuring it does not include any objectionable or illegal material.
        </li>
        <li>
          Sarv reserves the right to review, edit, or delete any Content that
          violates these Terms or might be deemed offensive or inappropriate.
        </li>
        <li>
          By posting Content, users grant Sarv a perpetual, non-exclusive
          license to use, reproduce, modify, and distribute such Content.
        </li>
        <li>
          Users agree that any feedback or communications shared with Sarv
          become the exclusive property of Sarv, usable without any obligation
          to the user.
        </li>
      </ul>
      <p>
        As most Content on Sarv is user-generated, Sarv does not guarantee its
        accuracy or reliability, and users are advised to exercise due
        diligence. Information and content provided by others on the Sarv
        platform are the responsibility of their respective authors, and Sarv
        does not endorse or assume responsibility for such content. If a user
        disagrees with any content or review about them on the Sarv Site, they
        have the right to respond, provided their rebuttal complies with Sarv's
        Terms.
      </p>
      <h5>3. Changes to Sarv</h5>
      <p>
        Sarv may alter, suspend, or discontinue any aspect of the Sarv platform
        at any time, including the availability of any feature or content. Sarv
        may also impose limits on certain features and aspects of the Sarv
        platform or restrict your access to parts of or the entire platform
        without notice or liability. Sarv may discontinue or change the Sarv
        platform or its availability to you at any time without notice.
      </p>
      <h5>4. Registration Information, User Ids and Passwords</h5>
      <p>
        The Sarv platform requires personal information that must be provided
        during the registration process in order for you to gain access. Your
        user ID and password for accessing the Sarv platform are confidential
        and should not be used by or shared with anyone else. You are solely
        responsible for all usage or activity on the Sarv platform by anyone
        using your ID or password, including but not limited to use of your
        password by any third party. Sarv will not be liable for any
        unauthorized use of your user ID or password or for any charges incurred
        through unauthorized use.
      </p>
      <h5>5. Payment and Refund Policy</h5>
      <p>
        To access certain services or products on Sarv, users may be required to
        pay fees, which could be in the form of recurring subscriptions,
        one-time payments, or other charges. Additionally, users are responsible
        for any applicable state or local sales taxes related to the services or
        products they purchase through Sarv.
      </p>
      <p>
        However, for specific Healthcare Seekers who join Sarv through their
        employer's benefits program and gain access to paid membership
        subscriptions, the subscription fees will be covered by their employer.
        In such cases, the Healthcare Seeker is exempt from paying the recurring
        subscription fee as long as the subscription continues to be a part of
        the employer's benefits program.
      </p>
      <h6>5.1 Billing and Payment</h6>
      <p>
        When you opt for a paid membership subscription or any service or
        product that involves a recurring fee on Sarv, you agree to pay all
        charges linked to your chosen subscription, service, or product as
        outlined on the Site at the time of providing your payment details. You
        authorize Sarv, or a third-party payment processor acting on our behalf,
        to process payments using your selected payment method in accordance
        with the terms of your selected subscription, service, or product. Sarv
        retains the right to rectify any errors or inaccuracies in billing, even
        after payment has been requested or received.
      </p>
      <p>
        If you decide to buy other services or products offered by Sarv that
        have a non-recurring fee, you give Sarv permission to charge the payment
        method you have provided for these purchases. Furthermore, if Sarv has
        your credit card details on file from previous transactions, you consent
        to us charging that credit card for any additional services or products
        you acquire.
      </p>
      <h6>5.2 Automatic Subscription Renewal and Cancellation</h6>
      <p>
        All paid membership subscriptions and services or products with
        recurring fees will continue indefinitely until they are cancelled by
        the user. For paid Sarv memberships, your subscription will
        automatically renew at the end of your selected term for an equivalent
        additional period at the rate and frequency initially disclosed to you
        when you subscribed, unless different terms were provided at the time of
        subscription. If your subscription term is longer than one month and
        includes monthly billing, and you decide to cancel during this term, you
        acknowledge and agree to continue being billed monthly until the end of
        your current subscription term.
      </p>
      <p>
        For other Sarv services or products with recurring fees, the associated
        fee will be charged at the interval specified at the time of purchase,
        until you explicitly cancel the service or product. You have the ability
        to cancel your paid membership subscription at any time through your
        account settings. Upon cancellation of a paid subscription, you are
        generally allowed to continue using the subscription until the end of
        your current term. Following the expiration of your current term, your
        subscription will not be renewed, but you may be charged, and required
        to pay, any applicable cancellation or early termination fees, as
        disclosed when you initially signed up for the subscription plan.
      </p>
      <h6>5.3 Installment Plan Options</h6>
      <p>
        Sarv occasionally offers products or services, including premium
        subscriptions, with the option to pay fees in installments. For
        recurring subscriptions, this installment option may only be applicable
        to the initial subscription term, and not to subsequent renewals or
        recurring payments. By opting for an installment payment plan, you agree
        to pay Sarv all charges associated with your purchase as outlined on the
        Site when submitting your payment details. You also give authorization
        to Sarv, or a third-party payment processor acting on our behalf, to
        process payments via your chosen payment method as per the terms of your
        selected installment plan.
      </p>
      <p>
        If you purchase a service, such as a premium subscription, with an
        installment plan, you understand and agree that you are committed to
        completing all the agreed-upon installment payments, irrespective of any
        cancellation of the service. If you cancel your subscription before
        fulfilling all installment payments, the remaining balance will remain
        due as per the agreed payment schedule, unless otherwise mandated by
        applicable law. If Sarv is unable to process any payment using your
        chosen payment method, we reserve the right to take any appropriate
        action, including suspending or terminating your subscription and/or
        Sarv account. Sarv and its Affiliates bear no liability for actions
        taken in response to failed payment attempts. However, these provisions
        do not limit or modify Sarv’s right to terminate your account at any
        time as stated in earlier sections.
      </p>
      <h6>5.4 Free Trial Offers</h6>
      <p>
        Sarv occasionally provides limited-time free trial subscriptions to
        selected users. Those who sign up for Sarv services on a free trial
        basis may experience restricted access to certain services and site
        features during the trial period. If you sign up for a free trial
        subscription and do not cancel before the end of the trial period, you
        will be billed at the current subscription rate for continued service
        access, unless Sarv specifies otherwise at the time of your initial
        subscription. To avoid charges after the free trial period, users must
        either downgrade or cancel their subscription and may request a refund
        of the subscription fee within thirty (30) days after being charged. In
        the event of cancellation, your credit card will be refunded the amount
        of the most recent subscription charge, provided the subscription was
        not used post the expiration of the free trial period.
      </p>
      <h6>5.5 Reward Points</h6>
      <p>
        As a benefit of your Sarv membership or as part of promotional
        activities, you may be awarded reward points, redeemable for specific
        products or services as detailed on the Sarv website. These points can
        only be redeemed through the Sarv platform and hold no cash value. They
        cannot be exchanged for cash, except where required by law, and are not
        transferable or refundable under any circumstances. The value of reward
        points is not fixed and can be redeemed up to the lesser of: (1) the
        cost of goods or services being redeemed; (2) any specified caps,
        limits, or restrictions listed on the Sarv website; or (3) the maximum
        point value stated on the site, which may vary at Sarv's discretion.
        Redemption of points can only occur in whole increments, matching the
        total value indicated on the site. No partial redemptions are allowed.
        Multiple reward points can be used in a single transaction.
      </p>
      <p>
        Reward points accrued through premium subscriptions or services are
        automatically applied to relevant purchases before other available
        points. The validity of these points is limited and is specified on the
        Sarv website and in any additional terms applicable to the service
        through which the points are earned or redeemed. Unused reward points
        are forfeited immediately if you fail to maintain the required
        membership status outlined on the site, or if your account is closed or
        terminated by either you or Sarv, as per the terms of Section 5.
      </p>
      <h6>5.6 Refund Policy</h6>
      <p>
        Unless explicitly stated in these Terms or as specified on the Sarv
        platform at the time of your transaction, all payments made for
        subscriptions, services, or products via the Sarv website or its
        services are final and non-refundable. This policy applies regardless of
        whether the subscriptions, services, or products are unused or partially
        used. Consequently, no refunds or credits will be issued for
        cancellations or changes made to your membership, subscription, service,
        or product orders, even if these occur mid-term. This includes any fees
        or charges associated with your Sarv account.
      </p>
      <h5>6. Release of Liability for Conduct and Disputes</h5>
      <p>
        As a user of Sarv and its services, you acknowledge and agree to release
        Sarv (including its officers, directors, shareholders, employees, parent
        entities, subsidiaries, other affiliates, successors, assignees, agents,
        representatives, advertisers, marketing partners, licensors, independent
        contractors, recruiters, corporate partners, resellers, or your employer
        if you have enrolled through an employer’s benefits program,
        collectively referred to as “Affiliates”) from any claims, disputes, or
        controversies that may arise from:
      </p>
      <ul>
        <li>
          Interactions, actions, or inactions between you and any other Sarv
          user, service provider, or client who are not directly employed by
          Sarv or its subsidiaries.
        </li>
        <li>
          Any content, advice, information, or services provided by any Sarv
          user, service provider, client, or other third parties, whether
          displayed on the Sarv platform or communicated through other channels.
        </li>
      </ul>
      <p>
        Sarv and its Affiliates expressly disclaim any and all liability for
        damages, lawsuits, claims, and controversies that may arise from the
        aforementioned scenarios, irrespective of whether they are currently
        known or unknown.
      </p>
      <h5>7. Age Restrictions</h5>
      <p>
        Sarv is designed for individuals who are 18 years of age or older. In
        compliance with our commitment to responsible service provision, we do
        not knowingly collect or solicit personal information from individuals
        under the age of 13. If it is determined that a user does not meet our
        age requirement criteria for using the Sarv platform or services, their
        registration will be promptly terminated. This policy is in place to
        ensure compliance with legal standards and to maintain the integrity and
        safety of our service environment.
      </p>
      <h5>8. Termination of Access</h5>
      <p>
        Sarv may, at its sole discretion, terminate or suspend your access to
        all or part of the Sarv platform at any time, with or without notice,
        for any reason, including, without limitation, breach of these Terms of
        Use. Without limiting the generality of the foregoing, any fraudulent,
        abusive, or otherwise illegal activity, or activity that may otherwise
        affect the enjoyment of the Sarv platform or access to it by others, may
        be grounds for termination of your access to all or part of the Sarv
        platform at the sole discretion of Sarv. Termination of access to the
        Sarv platform may include the deletion of your user ID, password, and
        all related registration information, blood test results, files, and
        data associated with or inside your account or user profile.
      </p>
      <h5>9. Ownership</h5>
      <p>
        Between you and Sarv, Sarv remains the sole and exclusive owner of all
        rights, titles, and interests, including copyrights and all other
        intellectual property rights, in and to the Sarv platform, all of the
        technology used by Sarv to provide the Sarv platform, any software
        provided directly or indirectly by Sarv for your access to or use of the
        Sarv platform, and all information posted by Sarv within the Sarv
        platform, as well as all enhancements, modifications, or additions
        thereto. You will not acquire any rights to any part of the foregoing,
        except the right to access and use them as intended and permitted by the
        Sarv platform and in accordance with these Terms of Use. You agree not
        to modify, rent, lease, loan, sell, assign, distribute, license,
        sublicense, reverse engineer, or create derivative works based on any of
        the foregoing.
      </p>
      <p>
        If, for your access to or use of the Sarv platform, you have downloaded
        any software from the Sarv platform or have otherwise received such
        software (for example, from your physician or another caregiver), then
        Sarv grants you a personal, non-transferable, and non-exclusive right
        and license to use the object code of the software on a single computer;
        provided that you do not (and do not allow any third party to) copy,
        modify, create a derivative work of, reverse engineer, reverse assemble,
        or otherwise attempt to discover any source code, or sell, assign,
        sublicense, or otherwise transfer any rights in the software. You agree
        not to modify the software in any manner or form, or to use modified
        versions of the software, including (without limitation) for the purpose
        of obtaining unauthorized access to the Sarv platform. You agree not to
        access the Sarv platform by any means other than through the interface
        that is provided by Sarv for accessing the Sarv platform.
      </p>
      <h5>10. Trademark Notice</h5>
      <p>
        No part of what is shown on Sarv Online should be interpreted as
        conferring any license or right to utilize any trademark, service mark,
        or logo found on this website without the explicit written consent of
        Sarv or any third party that owns the trademark, service mark, or logo.
      </p>
      <h5>11. Warranty Disclaimer</h5>
      <p>
        TO THE EXTENT ALLOWED BY LAW, SARV DISCLAIMS ALL REPRESENTATIONS,
        WARRANTIES, OR CONDITIONS, WHETHER EXPRESS, IMPLIED, OR STATUTORY,
        RELATED TO SARV, INCLUDING BUT NOT LIMITED TO, IMPLIED WARRANTIES OR
        CONDITIONS OF MERCHANTABILITY, QUALITY SUITABLE FOR SELLING, ENDURANCE,
        SUITABILITY FOR A PARTICULAR PURPOSE, OWNERSHIP, AND NON-VIOLATION OF
        RIGHTS. SARV DOES NOT GUARANTEE THAT SARV OR ANY RESULTS OR CONTENT WILL
        BE ACCURATE, COMPLETE, RELIABLE, UP-TO-DATE, THAT SARV WILL OPERATE
        WITHOUT DISRUPTIONS OR ERRORS, OR THAT SARV IS FREE FROM VIRUSES OR
        OTHER HARMFUL COMPONENTS.{" "}
      </p>
      <p>
        THE INFORMATION AND MATERIALS PROVIDED ON THE SARV PLATFORM, INCLUDING
        TEXT, GRAPHICS, LINKS, AND OTHER ITEMS, ARE OFFERED ON AN “AS IS” AND
        “AS AVAILABLE” BASIS. OPINIONS, ADVICE, STATEMENTS, OFFERS, OR OTHER
        CONTENT PROVIDED THROUGH OUR SERVICES, BUT NOT DIRECTLY BY SARV, ARE THE
        SOLE RESPONSIBILITY OF THEIR RESPECTIVE AUTHORS AND SHOULD NOT BE
        INHERENTLY RELIED UPON. SARV EXPLICITLY DISCLAIMS ANY GUARANTEES
        REGARDING THE ACCURACY, ADEQUACY, OR COMPLETENESS OF THE SITE'S
        MATERIALS. WE DO NOT ENDORSE OR TAKE RESPONSIBILITY FOR THE RELIABILITY
        OR ACCURACY OF ANY CONTENT FROM THIRD PARTIES. ADDITIONALLY, SARV DOES
        NOT WARRANT THAT THE USE OF THE SITE OR SERVICES WILL BE UNINTERRUPTED,
        FREE OF COMPUTER VIRUSES, SECURE, ERROR-FREE, OR MEET SPECIFIC
        REQUIREMENTS, NOR DO WE GUARANTEE THAT ANY DEFECTS IN THE SERVICES WILL
        BE CORRECTED. SARV ALSO DISCLAIMS ANY WARRANTIES, EXPRESS OR IMPLIED,
        REGARDING THE SUITABILITY OF ANY USER OF OUR PLATFORM TO PROVIDE OR
        EMPLOY CARE SERVICES. ALL CONDITIONS, WARRANTIES, AND OTHER TERMS THAT
        MIGHT OTHERWISE BE IMPLIED BY LAW ARE EXPRESSLY EXCLUDED TO THE EXTENT
        PERMITTED BY LAW, AND Sarv WILL NOT BE LIABLE FOR ANY ERRORS OR
        OMISSIONS IN THIS INFORMATION.
      </p>
      <p>
        For corporate users, the information on the Sarv site is provided for
        internal use only and may not be copied or redistributed. This
        information is the intellectual property of the corporate user or Sarv
        and is provided without any warranty. The use of this information must
        always comply with applicable laws and regulations. You recognize that
        Sarv is not liable for any interruptions or suspensions of service,
        regardless of the cause.
      </p>
      <h5>12. LIMITATION AND WAIVER OF LIABILITY</h5>
      <p>
        TO THE EXTENT PERMITTED BY LAW, IN NO EVENT WILL SARV OR ITS RESPECTIVE
        DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, VOLUNTEERS, INDEPENDENT
        CONTRACTORS, SUBCONTRACTORS, SPONSORS, SUCCESSORS, ASSIGNS,
        REPRESENTATIVES, SHAREHOLDERS, AFFILIATES, OR SUBSIDIARIES BE LIABLE FOR
        DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION ANY DIRECT,
        INCIDENTAL, PUNITIVE, EXEMPLARY, AGGRAVATED, INDIRECT, SPECIAL, OR
        CONSEQUENTIAL DAMAGES, LOSS OF PROFITS, FAILURE TO REALIZE EXPECTED
        SAVINGS, OR LOSS OF DATA, FOR ANY CAUSE OF ACTION WHATSOEVER, AND
        WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL OR
        EQUITABLE THEORY ARISING OUT OF OR IN ANY WAY CONNECTED WITH USE OF SARV
        OR PARTICIPATION IN ANY RELATED ACTIVITIES. EVERY EXEMPTION FROM
        LIABILITY, LIMITATION, AND CONDITION CONTAINED IN THESE TERMS OF USE FOR
        THE BENEFIT OF SARV, AND EVERY DEFENCE AND IMMUNITY OF WHATSOEVER NATURE
        APPLICABLE TO SARV OR TO WHICH SARV IS ENTITLED UNDER THESE TERMS OF
        USE, WILL ALSO BE AVAILABLE AND EXTEND TO PROTECT EACH OF ITS RESPECTIVE
        DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, VOLUNTEERS, INDEPENDENT
        CONTRACTORS, SUBCONTRACTORS, SPONSORS, SUCCESSORS, ASSIGNS,
        REPRESENTATIVES, SHAREHOLDERS, AFFILIATES, AND SUBSIDIARIES, EACH OF
        WHOM IS AN INTENDED THIRD-PARTY BENEFICIARY OF SUCH PROVISIONS.
      </p>
      <p>
        IN CONSIDERATION OF SARV ALLOWING YOU TO USE SARV AND RECEIVE TESTING
        RESULTS, YOU AGREE TO WAIVE ANY AND ALL CLAIMS THAT YOU HAVE OR MAY IN
        THE FUTURE HAVE AGAINST SARV AND ITS RESPECTIVE DIRECTORS, OFFICERS,
        EMPLOYEES, AGENTS, VOLUNTEERS, INDEPENDENT CONTRACTORS, SUBCONTRACTORS,
        SPONSORS, SUCCESSORS, ASSIGNS, REPRESENTATIVES, SHAREHOLDERS,
        AFFILIATES, AND SUBSIDIARIES AND TO RELEASE SARV AND ITS RESPECTIVE
        DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, VOLUNTEERS, INDEPENDENT
        CONTRACTORS, SUBCONTRACTORS, SPONSORS, SUCCESSORS, ASSIGNS,
        REPRESENTATIVES, SHAREHOLDERS, AFFILIATES, AND SUBSIDIARIES FROM ANY AND
        ALL LIABILITY FOR ANY LOSS, DAMAGE, EXPENSE, OR INJURY INCLUDING DEATH
        THAT YOU MAY SUFFER, OR THAT YOUR NEXT OF KIN MAY SUFFER AS A RESULT OF
        YOUR USE OF SARV OR PARTICIPATION IN ANY RELATED ACTIVITIES DUE TO ANY
        CAUSE WHATSOEVER, INCLUDING NEGLIGENCE, BREACH OF CONTRACT, OR BREACH OF
        ANY STATUTORY OR OTHER DUTY OF CARE ON THE PART OF SARV OR ANY OF ITS
        RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, VOLUNTEERS,
        INDEPENDENT CONTRACTORS, SUBCONTRACTORS, SPONSORS, SUCCESSORS, ASSIGNS,
        REPRESENTATIVES, SHAREHOLDERS, AFFILIATES, OR SUBSIDIARIES.
      </p>
      <b>Incidental Damages and Aggregate Liability </b>
      <span>
        Sarv shall not be liable for any indirect, special, incidental, or
        consequential damages, losses, or expenses arising from the use or
        inability to use our Site or Services. This includes, but is not limited
        to, damages related to information received, content removal, email
        distribution, linked websites, or any failure in performance, error,
        omission, interruption, defect, delay in operation or transmission,
        computer virus, or system failure. Even if advised of the possibility of
        such damages, Sarv's aggregate liability under any form of action
        connected with this Agreement or the use of the Services or Site will
        not exceed the amount paid by you for your account, or $25.00 if no
        payment was made. Sarv will not be liable for damages of any kind,
        whether direct, indirect, general, special, compensatory, consequential,
        or otherwise, arising from your conduct or that of any other person in
        connection with the use of our Site or Services. This includes any
        injuries, distress, or damages resulting from reliance on information or
        content posted on the Site, or from interactions with other registered
        users, whether online or offline. This includes issues arising from
        users who have registered under false pretenses or who attempt
        fraudulent or harmful actions.
      </span>
      <p>
        {" "}
        <b>Advice and Information Disclaimer </b>
        <span>
          Any advice posted on our Site serves for informational purposes only
          and is not a substitute for professional advice in financial, medical,
          legal, or other fields. Sarv disclaims all liability concerning the
          impact or effects on any person following the information provided on
          our Site. If you have specific concerns that require professional
          advice, we encourage consulting with a qualified specialist.
        </span>
      </p>
      <h5>13. Indemnity</h5>
      <p>
        You agree to defend, indemnify, and hold harmless Sarv, its
        shareholders, the suppliers of any results or other content, and its and
        their affiliates, licensees, partners, and suppliers, along with the
        directors, officers, employees, agents, and representatives of each,
        from any losses, damages, or claims, including without limitation actual
        legal fees, which they may incur as a result of your activities on or
        use of Sarv, including without limitation any breach by you of these
        Terms of Use or any charges or complaints made by other parties against
        you. You will cooperate fully as required in the defense of any claim.
        Sarv reserves the right to assume the exclusive defense and control of
        any matter otherwise subject to indemnification by you; however, you
        will remain liable for any such claim and for the costs of defense.
      </p>
      <h5>14. Privacy and Confidentiality</h5>
      <p>
        Your privacy is our top priority. Please review Sarv's Privacy Policy
        for a comprehensive explanation of how we collect, transmit, and protect
        your personal information. You consent to Sarv collecting, using, and
        disclosing personal information about you as outlined in the Sarv
        Privacy Policy (which may be updated periodically), accessible online at
        sarv.com/privacy. Furthermore, you agree that Sarv may share your
        personal information confidentially with third-party service providers
        as specified in Sarv’s Privacy Policy. You acknowledge that if any of
        these service providers are located in the U.S. or another foreign
        country, your personal information may be processed and stored in that
        country, and could be subject to access by the governments, courts, and
        law enforcement or regulatory agencies under that country's laws. More
        details about Sarv's privacy practices can be found at the URL mentioned
        above. If you have any questions or concerns about these practices,
        please contact us at supportr@joinsarv.com.
      </p>
      <h5>15. Links or Pointers to Other Sites</h5>
      <p>
        Sarv makes no representations about any other website that you may
        access through Sarv or any entity that may post its advertisements on
        Sarv. When you access a non-Sarv website, please understand that it is
        independent from Sarv and that Sarv has no control over the content on
        that website. Additionally, a hyperlink to a non-Sarv site does not
        imply that Sarv endorses or accepts any responsibility for the content
        or the use of such a linked site. Sarv does not vouch for the accuracy
        or reliability of any information, content, or advertisements found on,
        distributed through, linked, downloaded, or accessed from any of the
        services on its site, nor the quality of any products, information, or
        other materials displayed, purchased, or obtained by you as a result of
        an advertisement or any other information or offer in connection with
        these services. It is your responsibility to ensure that whatever you
        select for your use or download is free of viruses, worms, Trojan
        horses, and other harmful items. If you decide to access any of the
        third-party sites linked to Sarv, you do so entirely at your own risk.
      </p>
      <p>
        Links on the Sarv platform that lead to external sites, including those
        framed by Sarv, as well as advertisements and other third-party content
        featured on our Site, are not endorsements by Sarv of these external
        sites or their content, products, advertising, or materials. These links
        are provided for the convenience and reference of our users. Sarv does
        not have control over these third-party sites or content and is not
        responsible for them. The usage of these external sites and their
        content is governed by their own terms of use and privacy policies,
        which are separate from Sarv’s Terms and Privacy Policy.
      </p>
      <p>
        Sarv explicitly disclaims any liability related to the use or viewing of
        links that may appear on our platform. All users of Sarv agree to
        release Sarv from any liability that may arise from the utilization of
        these external links.
      </p>
      <h5>16. Hyperlinks to this Site</h5>
      <p>
        Persons creating other websites may link to the homepage of Sarv, but
        are not permitted to link to any other pages on Sarv. You are prohibited
        from copying any materials from this site onto your own web server for
        any reason. Furthermore, you may not link to isolated elements on this
        site (including photographs, illustrations, diagrams, buttons, text, or
        any other elements whatsoever) for the purpose of "inlining" them into
        the context of other web pages, or for linking such isolated elements to
        other web pages. Essentially, you should link only to the complete
        homepage of this site, or not link at all. Any other use will be
        considered a violation of copyright and may subject you to legal action.
      </p>
      <h5>17. Changes to these Terms of Use</h5>
      <p>
        Sarv may update these Terms of Use periodically. If you disagree with
        any modifications, you should cease using Sarv and refrain from
        participating in any further tests on any Analyzer. Your continued use
        of Sarv or participation in further tests on any Analyzer after the
        posting of changes to these Terms of Use will signify your acceptance of
        the revised Terms of Use, including any subsequent modifications. You
        can always review the most current version of these Terms of Use at
        sarv.com/terms.
      </p>
      <h5>18. Application of Terms of Use</h5>
      <p>
        These Terms of Use remain in effect and apply to all access and use by
        you at any time of any test results on Sarv and any participation in
        testing on any Analyzer.
      </p>
      <h5>19. Governing Law</h5>
      <p>
        These Terms, and all claims related to or stemming from this agreement,
        whether based in contract, tort, or other legal theories, will be
        governed by the laws of the State of Delaware. This includes Delaware's
        statutes of limitations for your claims but excludes its conflict of law
        principles. In cases where the Arbitration Agreement is deemed
        inapplicable to you or to a specific claim or dispute, whether due to
        your choice to opt-out of the Arbitration Agreement or a decision by an
        arbitrator or a court, any claim or dispute between you and Sarv must be
        exclusively resolved by a state or federal court in Delaware. This
        condition applies unless a different agreement is made between you and
        Sarv. You and Sarv consent to the jurisdiction of the courts situated in
        Delaware for addressing all such claims or disputes.
      </p>
      <h5>20. Entire Agreement</h5>
      <p>
        These Terms of Use represent the complete agreement between you and Sarv
        regarding the subject matter herein, replacing any previous agreements
        between you and Sarv concerning the same subject matter.
      </p>
      <h5>21. Notices</h5>
      <p>
        Sarv may deliver notices to you, including notifications about changes
        to these Terms of Use, through postings on Sarv or by email.
      </p>
      <h5>22. Waiver</h5>
      <p>
        The failure of Sarv to exercise or enforce any right or provision of
        these Terms of Use does not constitute a waiver of such right or
        provision.
      </p>
      <h5>23. Assignment</h5>
      <p>
        You are not permitted to transfer any of your rights to use Sarv or your
        interests in these Terms of Use to anyone else, and any rights you may
        have to your Sarv user ID, password, and user profile stored on Sarv's
        systems will end upon your death. Upon receiving a copy of a death
        certificate, your account may be terminated and all its contents
        permanently deleted. Sarv retains the right to assign any or all of its
        rights and responsibilities under these Terms of Use to any third party.
        If Sarv’s obligations under these Terms are assumed by a third party,
        Sarv will be released from any and all future liability under these
        Terms of Use.
      </p>
      <h5>24. Contractual Limitation Period</h5>
      <p>
        You agree that, regardless of any statute or law to the contrary, any
        claim or cause of action arising out of or related to the use of Sarv or
        these Terms of Use must be filed within one year after such claim or
        cause of action arises.
      </p>
      <h5>25. Severability</h5>
      <p>
        If any provision of these Terms of Use is found to be unlawful, void, or
        for any reason unenforceable, then that provision will be considered
        severable from these Terms of Use and will not impact the validity and
        enforceability of the remaining provisions.
      </p>
      <h5>26. Miscellaneous</h5>
      <p>
        This Agreement does not create a partnership, joint venture, agency,
        legal representation, employer, contractor, or employee relationship
        between the parties. Neither party shall represent or commit to any
        action on behalf of the other, except as explicitly stated in this
        Agreement or authorized in writing by the party to be bound. The
        invalidity, illegality, or unenforceability of any part of these Terms
        will not affect the validity, legality, or enforceability of the
        remaining provisions. Each affiliate, as defined in earlier sections, is
        a third-party beneficiary of this Agreement and has the right to enforce
        it directly against you. This Agreement is legally binding and will
        benefit the legal representatives, successors, and assigns of the
        parties involved.
      </p>
      <h5>27. Copyright Notices/Complaints</h5>
      <p>
        Sarv adheres to the Digital Millennium Copyright Act (“DMCA”) and
        responds to notices of alleged copyright infringement. If you believe
        that any materials on our Site infringe upon your copyright, you may
        request their removal by contacting Sarv's copyright agent and providing
        the following information:
      </p>
      <ol>
        <li>
          Identification of the copyrighted work that you believe has been
          infringed. Please describe the work and, where possible, include a
          copy or the URL of an authorized version of the work.
        </li>
        <li>
          Identification of the allegedly infringing material, including its
          location on the Site. Please provide the URL or any other relevant
          information to help us locate the material.
        </li>
        <li>
          Your contact details: name, address, telephone number, and email
          address, if available.
        </li>
        <li>
          A statement of good faith belief that the use of the materials in
          question is not authorized by the copyright owner, its agent, or the
          law.
        </li>
        <li>
          A declaration that the information you have provided is accurate and
          that, under penalty of perjury, you are the copyright owner or
          authorized to act on the copyright owner's behalf.
        </li>
        <li>
          A signature or electronic equivalent from the copyright holder or
          authorized representative.
        </li>
      </ol>
      <p>
        Please address all copyright infringement notices to Sarv's designated
        copyright agent at:
      </p>
      <h5>28 Opt-Out Procedure</h5>
      <p>
        If you wish to opt out of this Arbitration Agreement, you can do so by
        sending us a written opt-out notice (“Opt-Out Notice”) as per the terms
        specified in this section. For new Sarv users, the Opt-Out Notice must
        be mailed within 30 days from your first use of our Site or Services. If
        you're an existing Sarv user who previously accepted our Terms before
        this Arbitration Agreement was introduced, your Opt-Out Notice must be
        postmarked by a specific deadline provided by Sarv. Mail the Opt-Out
        Notice to Sarv Services, Attn: Legal Department, [Insert appropriate
        address here]. The notice must clearly state your refusal to agree to
        the Arbitration Agreement and include your name, address, phone number,
        and the email address(es) associated with your Sarv account(s). A
        signature is required for the Opt-Out Notice to be valid. This is the
        sole method to opt out of the Arbitration Agreement. If you opt out,
        Sarv will also not be bound by the arbitration provisions. All other
        aspects of the Terms remain in effect. Opting out of this Arbitration
        Agreement doesn't impact any other past, present, or future arbitration
        agreements you might have with us. Users who accepted previous versions
        of our Terms with an arbitration agreement and did not opt out are still
        bound by that agreement. Sarv will provide a copy of the last
        arbitration agreement accepted by any user who submits a valid Opt-Out
        Notice, if available.
      </p>
      <h5>29. Consent to Electronic Communication</h5>
      <p>
        By utilizing the Site or Services of Sarv, you agree to receive
        electronic communications from Sarv. This includes your consent to the
        electronic delivery of notices, documents, or products (such as reports
        or copies of background checks) through Sarv's Site, mobile application,
        online messaging platform, or email. You also agree to regularly check
        your Sarv account, alerts, and messages, as well as the email address
        associated with your Sarv account, to stay informed about important
        notices and information pertaining to your account.
      </p>
    </div>
  );
};

export default TermsOfService;
