import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Card, Container, Row, Col } from "react-bootstrap";
import { db } from "../../../config/Firebase"; // Adjust import according to your Firebase config file path
import { doc, getDoc } from "firebase/firestore";

// const RecipeDetails = ({ recipeId }) => {
const RecipeDetails = () => {
  const [recipe, setRecipe] = useState(null);
  const { id } = useParams(); // Capture the 'id' parameter from the URL
  console.log("Recipe ID from URL:", id); // Log the ID to verify

  useEffect(() => {
    const fetchRecipe = async () => {
      const docRef = doc(db, "recipes", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setRecipe(docSnap.data());
      } else {
        console.log("No such recipe!");
      }
    };

    fetchRecipe();
  }, [id]);

  if (!recipe) {
    return <div>Loading recipe...</div>;
  }

  return (
    <Container style={{ paddingTop: "100px", paddingBottom: "100px" }}>
      <Card>
        <Card.Body>
          <Row>
            <Col md={3}>
              {/* Assuming there is an imageUrl in your recipe data */}
              <img
                src={recipe.imgUrl || "default-image-url.jpg"}
                alt="Recipe Image"
                className="img-fluid"
                style={{ height: "300px", width: "100%" }}
              />
            </Col>
            <Col md={9}>
              <Row>
                <Col xs={12}>
                  <h2>{recipe.title}</h2>
                </Col>
              </Row>
              <Row style={{ paddingTop: "15px", fontSize: "20px" }}>
                {/* Displaying ingredients in two columns */}
                {recipe.ingredients && (
                  <React.Fragment>
                    <Col md={6}>
                      {recipe.ingredients
                        .filter((_, index) => index % 2 === 0)
                        .map((ingredient, index) => (
                          <p key={index}>{ingredient}</p>
                        ))}
                    </Col>
                    <Col md={6}>
                      {recipe.ingredients
                        .filter((_, index) => index % 2 !== 0)
                        .map((ingredient, index) => (
                          <p key={index}>{ingredient}</p>
                        ))}
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            </Col>
          </Row>
          <Row style={{ paddingTop: "25px", fontSize: "20px" }}>
            <Col xs={12}>
              <p>{recipe.content}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default RecipeDetails;
