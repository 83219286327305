import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db, auth } from "../../../config/Firebase"; // Adjust this import path as necessary
import { Card, Container, Row, Col, Button, Image } from "react-bootstrap";
import { doc, getDoc } from "firebase/firestore";
import "../../../styles/General.css";

// const ClinetsDetails = () => {
const ClinetsDetails = ({ userId: propUserId }) => {
  const { id: routeUserId } = useParams();
  const userId = propUserId || routeUserId; //
  // Define the state for loading status
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  //   const { id } = useParams(); // Retrieves the ID from the URL
  //   const { userId } = useParams(); // Retrieves the ID from the URL

  //   console.log("UserID in ClientDetails:", userId);
  const userRef = doc(db, "users", userId);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsLoading(true); // Set loading true when starting to fetch
        // Use 'userId' here instead of 'id'
        const userRef = doc(db, "users", userId);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists() && userSnap.data().isComplete) {
          setUserData(userSnap.data());
        } else {
          setUserData(null);
        }
        setIsLoading(false); // Set loading false after fetching
      } else {
        setUserData(null);
        setIsLoading(false); // Set loading false if no user is signed in
      }
    });

    return () => unsubscribe();
    // Add 'userId' to the dependency array to rerun the effect when 'userId' changes
  }, [userId]);

  const booleanToYesNo = (value) => {
    return value ? "Yes" : "No";
  };
  const filterTrueKeys = (obj) => {
    return Object.keys(obj)
      .filter((key) => obj[key]) // Filter keys where the value is true
      .join(", "); // Join the filtered keys with a comma and a space
  };

  if (isLoading) {
    return <Container>Loading user profile...</Container>;
  }

  if (!userData) {
    return (
      <Container>No user profile found or profile is incomplete.</Container>
    );
  }

  return (
    <Container style={{ paddingBottom: "50px", paddingTop: "50px" }}>
      <Card className="mt-3" style={{ fontSize: "18px" }}>
        <Card.Body>
          {/* General Info Section */}
          <Card.Title>General Info</Card.Title>
          <Row>
            <Col>
              Full name: {userData.firstName}
              {"  "} {userData.lastName}
            </Col>
            <Col>Age range: {userData.ageRange}</Col>
            <Col>Gender: {userData.gender}</Col>
          </Row>
          {/* <Row>
            <Col>Email:{userData.email}</Col>
            <Col>Phone:{userData.phone}</Col>
          </Row> */}

          {/* General Health Info Section */}
          <Card.Title className="user-profile-card-title">
            General Health Info
          </Card.Title>
          <hr />
          <Row>
            <Col>What is your main health goal?{userData.healthGoal}</Col>
          </Row>
          <Row>
            <Col>
              How satisfied are you with your current physical health?
              {userData.physicalHealthSatisfaction}
            </Col>
          </Row>
          <Row>
            <Col>
              Are you currently pregnant or planning to become pregnant in the
              near future?{userData.pregnancyStatus}
            </Col>
          </Row>
          <Row>
            <Col>
              How often do you visit a healthcare provider?
              {userData.healthcareVisitFrequency}
            </Col>
          </Row>
          <Row>
            <Col>What is your current weight?{userData.weightCategory}</Col>
          </Row>
          <Row>
            <Col>
              How often do you exercise per week?{userData.exerciseFrequency}
            </Col>
          </Row>
          <Row>
            <Col>
              Do you use any of the following wellness practices? (Check all
              that apply) {filterTrueKeys(userData.wellnessPractices)}
            </Col>
          </Row>
          <Row>
            <Col>
              Do you experience any of the following regularly?{" "}
              {filterTrueKeys(userData.healthIssues)}
            </Col>
          </Row>

          {/* Mental Health Info Section */}
          <Card.Title className="user-profile-card-title">
            Mental Health Info
          </Card.Title>
          <hr />
          <Row>
            <Col>
              How would you describe your mental health?{userData.mentalHealth}
            </Col>
          </Row>
          <Row>
            <Col>
              How satisfied are you with your current mental health?
              {userData.mentalHealthSatisfaction}
            </Col>
          </Row>
          <Row>
            <Col>
              Do you have any sleep disorders?
              {booleanToYesNo(userData.hasSleepDisorder)} , details:
              {userData.sleepDisorderDetails}
            </Col>
            {/* <Col></Col> */}
          </Row>
          <Row>
            <Col>
              What is your average sleep duration per night?
              {userData.sleepDuration}
            </Col>
          </Row>
          <Row>
            <Col>
              How would you rate your stress level on average?
              {userData.stressLevel}
            </Col>
          </Row>
          <Row>
            <Col>
              How often do you experience stress-related symptoms? (e.g.,
              headache, stomachache){userData.stressSymptomsFrequency}
            </Col>
          </Row>
          <Row>
            <Col>
              How much screen time do you have daily?{userData.dailyScreenTime}
            </Col>
          </Row>

          {/* Diet Info Section */}
          <Card.Title className="user-profile-card-title">Diet Info</Card.Title>
          <hr />
          <Row>
            <Col>Do you consume alcohol?{userData.alcoholConsumption}</Col>
          </Row>
          <Row>
            <Col>
              How much water do you drink daily?{userData.dailyWaterIntake}
            </Col>
          </Row>
          <Row>
            <Col>Do you follow a specific diet?{userData.dietType}</Col>
          </Row>
          <Row>
            <Col>
              How many servings of fruits and vegetables do you consume daily?
              {userData.fruitVeggieServings}
            </Col>
          </Row>

          {/* Chronic Health Issues Section */}
          <Card.Title className="user-profile-card-title">
            Chronic Health Issues
          </Card.Title>
          <hr />
          <Row>
            <Col>
              Do you have any chronic diseases?
              {booleanToYesNo(userData.hasChronicDiseases)}
            </Col>
            <Col>{userData.chronicDiseaseDetails}</Col>
          </Row>
          <Row>
            <Col>
              Do you have any known allergies?
              {booleanToYesNo(userData.hasAllergies)}, Details:{" "}
              {userData.allergyDetails}
            </Col>
            {/* <Col>{userData.allergyDetails}</Col> */}
          </Row>
          <Row>
            <Col>
              Do you experience chronic pain?{" "}
              {booleanToYesNo(userData.hasChronicPain)}, Details:{" "}
              {userData.chronicPainDetails}
            </Col>
            {/* <Col></Col> */}
          </Row>
          <Row>
            <Col>
              Do you have any family history of major illnesses?
              {booleanToYesNo(userData.hasFamilyHistory)}, Details:
              {userData.familyHistoryDetails}
            </Col>
            {/* <Col>{userData.familyHistoryDetails}</Col> */}
          </Row>
          <Row>
            <Col>
              Do you have any mobility issues?{" "}
              {booleanToYesNo(userData.hasMobilityIssue)}, Details:{" "}
              {userData.mobilityIssueDetails}
            </Col>
            {/* <Col>{userData.mobilityIssueDetails}</Col> */}
          </Row>
          <Row>
            <Col>
              Are you currently taking any medication?
              {booleanToYesNo(userData.takingMedication)}, Details:{" "}
              {userData.medicationDetails}
            </Col>
            {/* <Col>{userData.medicationDetails}</Col> */}
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ClinetsDetails;
