import React, { useState } from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../config/Firebase"; // Ensure this path is correct

const SubscriptionForm = ({ onFormSubmit }) => {
  // Added onFormSubmit as a prop
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};

    if (!name) {
      formIsValid = false;
      errors["name"] = "Please enter your name.";
    }

    if (!email) {
      formIsValid = false;
      errors["email"] = "Please enter your email.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false;
      errors["email"] = "Email is not valid.";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await addDoc(collection(db, "subscribed"), {
        // Ensure the collection name is correct
        name,
        email,
        phone, // Phone is optional
      });
      // Assuming onFormSubmit is a function passed down as a prop to handle successful submission
      if (onFormSubmit) onFormSubmit();
      // Optionally, clear the form fields here
    } catch (error) {
      console.error("Error adding document: ", error);
      // Handle submission error
    }
  };

  return (
    <Card>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col>
              <p>To see your results, please enter your email and name</p>
            </Col>
          </Row>

          <Form.Group as={Row} className="mb-3">
            <Col>
              <Form.Control
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                isInvalid={!!errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Col>
              <Form.Control
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Col>
              <Form.Control
                type="tel"
                placeholder="Phone Number (Optional)"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Row className="mb-3">
            <Col>
              <p>
                By submitting, I consent to Sarv, and its affiliates contacting
                me by email at the address provided and/or by telephone at the
                number provided (by live, automated, or prerecorded phone calls
                or text messages) about its products and services.
              </p>
            </Col>
          </Row>

          <Button type="submit" className="business-button">
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default SubscriptionForm;
