import React, { createContext, useContext, useState } from "react";

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const [rate, setRate] = useState(0);
  const [tax, setTax] = useState(0);
  const [title, setTitle] = useState("");
  const [total, setTotal] = useState(0);
  const [period, setPeriod] = useState(0); // Subscription period
  const [isSubscription, setIsSubscription] = useState(false); // Track if it's a subscription
  const [priceId, setPriceId] = useState("");

  // Correctly define update functions here
  const updateRate = (newRate) => setRate(newRate);
  const updateTax = (newTax) => setTax(newTax);
  const updateTitle = (newTitle) => setTitle(newTitle);
  const updateTotal = (newTotal) => setTotal(newTotal);
  const updatePeriod = (newPeriod) => setPeriod(newPeriod);
  const updateIsSubscription = (value) => setIsSubscription(value);
  const updatePriceId = (newPriceId) => setPriceId(newPriceId);

  return (
    <SubscriptionContext.Provider
      value={{
        rate,
        updateRate,
        tax,
        updateTax,
        title,
        updateTitle,
        total,
        updateTotal,
        period,
        updatePeriod,
        isSubscription,
        updateIsSubscription,
        priceId,
        updatePriceId,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};
