import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { auth, db } from "../../../config/Firebase";

function HerbalistForm() {
  const [currentPosition, setCurrentPosition] = useState("");
  const [mentorName, setMentorName] = useState("");
  const [mentorEmail, setMentorEmail] = useState("");
  const [mentorPhone, setMentorPhone] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Instantiate useNavigate

  const db = getFirestore();
  const auth = getAuth();
  const user = auth.currentUser;

  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const validatePhoneNumber = (number) => {
    return /^\d{10}$/.test(number); // Adjust regex based on the expected format
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      currentPosition === "student" &&
      (!validateEmail(mentorEmail) || !validatePhoneNumber(mentorPhone))
    ) {
      setError("Please enter a valid email address and phone number.");
      return;
    }
    if (currentPosition === "student" && !mentorName) {
      setError("Please enter the name of your mentor.");
      return;
    }

    setError("");
    const updateData = {
      currentPosition,
      ...(currentPosition === "student" && {
        mentorName,
        mentorEmail,
        mentorPhone,
      }),
      ...(currentPosition === "practicing" && { jobTitle }),
    };

    if (user) {
      const userRef = doc(db, "practitioners", user.uid);
      await updateDoc(userRef, updateData).catch((error) => {
        console.error("Error updating document: ", error);
      });
    }
    navigate("/expertise"); // Redirect after updating the document
  };

  // Added function to handle job title selection and redirect
  const handleJobTitleSelection = (title) => {
    setJobTitle(title);
    navigate("/expertise"); // Redirect when a job title is selected
  };

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ minHeight: "80vh", minWidth: "800px" }}
    >
      <Row>
        <Col>
          <Card
            className="shadow no-border-card w-100"
            style={{ minWidth: "650px", minHeight: "500px" }}
          >
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label className="dosha-card-title">
                    Are you currently a student or practicing herbalist?
                  </Form.Label>
                  <Form.Check
                    type="radio"
                    label="Student"
                    name="herbalistStatus"
                    id="student"
                    onChange={() => setCurrentPosition("student")}
                  />
                  <Form.Check
                    type="radio"
                    label="Practicing Herbalist"
                    name="herbalistStatus"
                    id="practicing"
                    onChange={() => setCurrentPosition("practicing")}
                  />
                </Form.Group>
                {currentPosition === "student" && (
                  <Card style={{ paddingTop: "50px", marginTop: "50px" }}>
                    <Card.Body>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          Who is your mentor who will assist you with your
                          cases?
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Full Name"
                          onChange={(e) => setMentorName(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Row>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="email"
                              placeholder="Email"
                              onChange={(e) => setMentorEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Control
                              type="text"
                              placeholder="Phone Number"
                              onChange={(e) => setMentorPhone(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {error && <p className="text-danger">{error}</p>}
                      <Button
                        variant="primary"
                        className="next-button mt-3"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Card.Body>
                  </Card>
                )}
                {currentPosition === "practicing" && (
                  <Card style={{ paddingTop: "50px", marginTop: "50px" }}>
                    <Card.Body>
                      <Form.Group>
                        <Form.Label>
                          Which one describes your job title:
                        </Form.Label>
                        {/* Update onChange to use handleJobTitleSelection */}
                        <Form.Check
                          type="radio"
                          label="Community Herbalist"
                          name="jobTitle"
                          onChange={() =>
                            handleJobTitleSelection("Community Herbalist")
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Bio-regional Herbalist"
                          name="jobTitle"
                          onChange={() =>
                            handleJobTitleSelection("Bio-regional Herbalist")
                          }
                        />
                        <Form.Check
                          type="radio"
                          label="Clinical Herbalist"
                          name="jobTitle"
                          onChange={() =>
                            handleJobTitleSelection("Clinical Herbalist")
                          }
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default HerbalistForm;
