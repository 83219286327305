import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const CommonServices = () => {
  const servicesLeftColumn = [
    "Manage diabetes through dietary and lifestyle changes, along with specific medicinal herbs.",
    "Alleviate symptoms of depression and anxiety through dietary changes, physical activity, and herbal supplements.",
    "Support weight loss by detoxification, herbal supplements, dietary & behavioral changes.",
    "Reduce blood pressure levels through dietary adjustments, stress management, and specific herbal interventions.",
    "Ease arthritis pain and inflammation with a combination of dietary strategies, physical therapy, and herbal remedies.",
    "Improve asthma control using stress reduction techniques, dietary adjustments, and herbal supplements to support respiratory health.",
    "Enhance Sleep Quality through stress reduction practices, establishing a healthy sleep environment, and using sleep-supportive herbs.",
  ];

  const servicesRightColumn = [
    "Address digestive disorders like IBS and Crohn's Disease through probiotics, dietary fiber, and gentle herbal remedies.",
    "Support heart health with lifestyle modifications, nutritional counseling, and herbal approaches to improve cardiovascular function.",
    "Treat skin conditions such as eczema and psoriasis with dietary changes, stress management, and topical herbal preparations.",
    "Enhance energy levels and manage Chronic Fatigue Syndrome through dietary changes, supplements, and lifestyle modifications.",
    "Assist in cancer support by focusing on nutrition, herbal medicine, and stress reduction techniques to manage symptoms and side effects.",
    "Support thyroid function with dietary changes, stress management techniques, and herbal supplements aimed at hormonal balance.",
    "Improve mood and cognitive function in neurodegenerative diseases with a focus on anti-inflammatory diet, physical activity, and cognitive-enhancing herbs.",
  ];

  return (
    <div className="common-services-container">
      <Container className="common-services-bg py-5">
        <Row className="justify-content-center mb-4">
          <Col md={8} className="text-center common-types-header">
            <h2>Common types of online therapy</h2>
          </Col>
        </Row>
        <Row className="justify-content-center mb-5">
          <Col md={8} className="text-center">
            <p>
              See Sarv's library for more information about the following types
              of services offered.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            {servicesLeftColumn.map((service, index) => (
              <Row key={index} className="mb-3">
                <Col className="common-types-text">{service}</Col>
              </Row>
            ))}
          </Col>
          <Col md={6}>
            {servicesRightColumn.map((service, index) => (
              <Row key={index} className="mb-3">
                <Col className="common-types-text">{service}</Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CommonServices;
