import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { db, auth } from "../../../config/Firebase";
import { doc, getDoc } from "firebase/firestore";

const Payment = ({
  visitFeeAmount,
  membershipOption,
  practitionerId,
  selectedDate,
  selectedTime,
}) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [priceId, setPriceId] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [error, setError] = useState(null);

  // console.log("Selected Date in Payment Component:", selectedDate);

  useEffect(() => {
    async function fetchConfig() {
      const response = await fetch("/config");
      const configData = await response.json();
      const stripe = await loadStripe(configData.publishableKey);
      setStripePromise(stripe);
    }
    fetchConfig();
  }, []);

  async function getStripeCustomerId(userId) {
    const userDocRef = doc(db, "users", userId);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      return docSnap.data().stripeCustomerId;
    } else {
      console.log("No such document!");
      return null;
    }
  }

  useEffect(() => {
    const fetchCustomerId = async () => {
      const userId = auth.currentUser?.uid; // Adjust this to how you access the user ID
      if (userId) {
        const id = await getStripeCustomerId(userId);
        setCustomerId(id);
      }
    };

    fetchCustomerId();
  }, []); // The empty dependency array means this effect runs once on component mount

  useEffect(() => {
    // Fetch the price ID when membershipOption changes
    async function fetchPriceId() {
      if (membershipOption) {
        try {
          const response = await fetch(
            `/get-price-id-multi-objective/${encodeURIComponent(
              membershipOption
            )}`
          );
          const { priceId } = await response.json();
          setPriceId(priceId); // Set the fetched priceId
        } catch (error) {
          console.error("Error fetching priceId:", error);
          setError(error.message);
        }
      }
    }

    fetchPriceId();
  }, [membershipOption]);

  const adjustedVisitFee =
    membershipOption === "1-year membership"
      ? visitFeeAmount * 0.5
      : visitFeeAmount;

  // console.log(" membershipOption in Payment is **** ", membershipOption);
  // console.log(" priceId in Payment is *** ", priceId.priceId);
  // console.log(" stripeCostumerId in payment is ****", customerId);

  console.log("stripePromise in Payment is: ", stripePromise);
  return stripePromise ? (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        visitFeeAmount={adjustedVisitFee}
        // visitFeeAmount={visitFeeAmount}
        membershipOption={membershipOption}
        customerId={customerId}
        priceId={priceId}
        practitionerId={practitionerId} // Pass practitionerId to CheckoutForm
        selectedDate={selectedDate} // Pass selectedDate to CheckoutForm
        selectedTime={selectedTime} // Pass selectedTime to CheckoutForm
        // clientSecret={clientSecret}
        // You can pass additional props as needed
      />
    </Elements>
  ) : (
    <div>Loading...</div>
  );
};

export default Payment;
