const BiologicalActivity = [
  {
    image: "immune_modulators.jpg",
    title: "Immune System Modulators",
    description: "Herbs that boost or modulate the immune system",
    subcategories: [
      "Antibacterial",
      "Antibiotic",
      "Antifungal",
      "Antiviral",
      "Antimicrobial",
      "Antiparasitic",
      "Antiseptic",
      "Anticancer",
      "Immune-boosting",
      "Immune-modulating",
      "Immunomodulatory",
    ],
  },
  {
    image: "cardiovascular_health.jpg",
    title: "Cardiovascular Health",
    description: "Herbs that support heart health and blood circulation",
    subcategories: [
      "Cardioprotective",
      "Cardiotonic",
      "Anticoagulant",
      "Cholesterol-lowering",
      "Hypotensive",
      "Circulatory Stimulant",
      "Blood Sugar Regulating",
      "Hypoglycemic",
      "Regulation of Blood Sugar and Energy Production",
    ],
  },
  {
    image: "stress_mood.jpg",
    title: "Stress Response and Mood Regulation",
    description: "Herbs that help manage stress and mood",
    subcategories: [
      "Adaptogen",
      "Adaptogenic",
      "Anti-stress and Antidepressant",
      "Antistress",
      "Anxiolytic",
      "Mood Enhancer",
      "Nervine",
    ],
  },
  {
    image: "metabolic_enhancers.jpg",
    title: "Metabolic Enhancers",
    description: "Herbs that enhance metabolism and internal balance",
    subcategories: [
      "Metabolism-enhancing",
      "Blood Purifier",
      "Diaphoretic",
      "Diuretic",
      "Electrolyte Balance",
      "Mild Stimulant",
    ],
  },
  {
    image: "miscellaneous_actions.jpg",
    title: "Miscellaneous Biological Actions",
    description: "Herbs with various biological actions",
    subcategories: [
      "Antinausea",
      "Anti-inflammatory",
      "Antiemetic",
      "Insecticidal",
      "Lymphatic Cleanser",
      "Pectoral",
      "Secretolytic",
      "Soothing Tonic",
      "Stimulant",
      "Styptic",
      "Tonic",
      "Vermifuge",
    ],
  },
  {
    image: "neuroprotective_agents.jpg",
    title: "Neuroprotective Agents",
    description: "Herbs that support and protect nervous system health",
    subcategories: [
      "Neuroprotective",
      "Cognitive Enhancer",
      "Nervine",
      "Migraine Prophylaxis",
      "Sedative",
      "Hypnotic",
      "Sleep Aid",
    ],
  },
  {
    image: "anti_aging.jpg",
    title: "Anti-Aging and Longevity Promoters",
    description: "Herbs that promote longevity and protect against aging",
    subcategories: ["Antioxidant", "Tonic", "Restorative"],
  },
  {
    image: "gastrointestinal_regulators.jpg",
    title: "Gastrointestinal Regulators",
    description: "Herbs that aid digestion and gastrointestinal health",
    subcategories: [
      "Carminative",
      "Digestive Aid",
      "Stomachic",
      "Laxative",
      "Antinausea",
      "Anti-inflammatory",
      "Antiemetic",
      "Purgative",
      "Aperient",
      "Cathartic",
      "Cholagogue",
      "Demulcent",
      "Alterative",
      "Alternative",
      "Appetite suppressant",
    ],
  },
  {
    image: "respiratory_supporters.jpg",
    title: "Respiratory System Supporters",
    description: "Herbs that enhance respiratory function and health",
    subcategories: [
      "Expectorant",
      "Decongestant",
      "Anticatarrhal",
      "Mucolytic",
      "Cough Suppressant",
      "Anti-asthmatic",
      "Respiratory Health",
    ],
  },
  {
    image: "detox_cleansing.jpg",
    title: "Detoxification and Cleansing Agents",
    description: "Herbs that support detoxification and cleansing",
    subcategories: [
      "Detoxifying",
      "Depurative",
      "Diuretic",
      "Hepatoprotective",
      "Liver Tonic",
      "Blood Purifier",
    ],
  },
  {
    image: "hormonal_regulators.jpg",
    title: "Hormonal Regulators",
    description: "Herbs that help regulate hormonal balance",
    subcategories: ["Aphrodisiac", "Emmenagogue", "Galactagogue", "Parturient"],
  },
  {
    image: "antiallergenic_herbs.jpg",
    title: "Antiallergenic Herbs",
    description: "Herbs that help manage allergic reactions",
    subcategories: ["Antihistamine"],
  },
  {
    image: "skin_hair_health.jpg",
    title: "Skin and Hair Health Promoters",
    description: "Herbs that enhance skin and hair health",
    subcategories: [
      "Emollient",
      "Vulnerary",
      "Wound Healing",
      "Healing",
      "Nourishing to the skin (when used in oils and creams)",
      "Moisturizing",
      "Promotes Tissue Repair",
    ],
  },
  {
    image: "energy_vitality.jpg",
    title: "Energy and Vitality Boosters",
    description: "Herbs that boost energy and overall vitality",
    subcategories: [
      "Stimulant",
      "Mild Stimulant",
      "Tonic",
      "Adaptogen",
      "Adaptogenic",
    ],
  },
];

export default BiologicalActivity;
