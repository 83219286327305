import React, { useEffect, useState } from "react";
import { Table, Container, Form, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../../config/Firebase";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc as firebaseDoc,
} from "firebase/firestore";

const ClientsInfo = () => {
  const [clients, setClients] = useState([]);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchClientsInfo(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchClientsInfo = async (practitionerId) => {
    const appointmentsRef = collection(db, "appointments");
    const q = query(
      appointmentsRef,
      where("practitionerId", "==", practitionerId),
      where("status", "==", "confirmed")
    );

    try {
      const querySnapshot = await getDocs(q);
      const clientIds = querySnapshot.docs.map((doc) => doc.data().userId);

      const clientsDetails = await Promise.all(
        clientIds.map(async (userId) => {
          const userSnap = await getDoc(firebaseDoc(db, "users", userId));
          return userSnap.exists() ? { id: userId, ...userSnap.data() } : null;
        })
      );

      setClients(clientsDetails.filter((client) => client !== null));
    } catch (error) {
      console.error("Error fetching client info:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const filteredUsers = clients.filter((client) => {
    return (
      client.firstName?.toLowerCase().includes(searchText) ||
      client.lastName?.toLowerCase().includes(searchText) ||
      client.email?.toLowerCase().includes(searchText)
    );
  });

  const handleRowClick = (userId) => {
    navigate(`/client-details/${userId}`);
  };

  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <Form>
            <Form.Group controlId="search">
              <Form.Control
                type="text"
                placeholder="Search by First Name, Last Name, or Email"
                onChange={handleSearchChange}
              />
            </Form.Group>
          </Form>
        </Col>
      </Row>
      <Table hover>
        <thead>
          <tr>
            <th>#</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Gender</th>
            <th>Age range</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((client, index) => (
            <tr key={client.id} onClick={() => handleRowClick(client.id)}>
              <td>{index + 1}</td>
              <td>{client.firstName}</td>
              <td>{client.lastName}</td>
              <td>{client.gender}</td>
              <td>{client.ageRange}</td>
              <td>{client.email}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ClientsInfo;
