import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const Benefits = () => {
  // Example benefits data structure
  const benefitsData = [
    {
      title: "Effective",
      description:
        "Holistic healthcare, focusing on the body, mind, and spirit, includes medicinal herbs and naturopathic practices, which have garnered scientific support for their health benefits. Research has demonstrated that these natural remedies and techniques can effectively address various health concerns, from boosting immune function to managing stress and chronic conditions. By integrating these practices with conventional medical approaches, individuals often experience a more rounded and effective health care strategy, leading to improved outcomes and a more satisfying journey toward wellness. This evidence suggests that incorporating medicinal herbs and naturopathy into one's health regimen can offer significant, long-term benefits.",
    },
    {
      title: "Accessible",
      description:
        "Sarv provides a flexible platform for engaging with holistic healthcare practitioners via telehealth, accessible from anywhere with a reliable internet connection. With options for audio, video, and messaging consultations, you can easily connect with your healthcare provider at your convenience, bypassing the need for physical travel. This is especially beneficial if you're situated in an area with few holistic health options, have a tight schedule, or face challenges with transportation. Sarv's affordable online services are designed to make holistic healthcare accessible, ensuring you can receive comprehensive support whenever you need it.",
    },
    {
      title: "Comfortable",
      description:
        "With Sarv, you can establish a rapport with your holistic healthcare practitioner within the sanctuary of your own environment. Many clients have shared that they feel more positive about their treatment experiences through virtual consultations compared to traditional, in-person interactions. For individuals who find it challenging to communicate with a new person face-to-face, the Sarv platform offers a valuable alternative. This different mode of communication can make it easier for some people to express themselves and engage more fully in their healthcare journey.",
    },
    {
      title: "Private",
      description:
        "Privacy is a crucial consideration for many in the digital age. Sarv ensures that your interactions remain confidential, eliminating the possibility of encountering acquaintances or sharing details of your sessions with anyone other than your holistic healthcare practitioner. You have the flexibility to use a pseudonym and an email that doesn't reveal your identity when creating your account, allowing you to participate in sessions from the comfort of a personal space. For those interested in understanding more about how Sarv handles privacy, our comprehensive privacy policy and business practices are fully outlined on our website for your review.",
    },
    {
      title: "Professional",
      description:
        "Sarv's certified holistic healthcare practitioners maintain the same standard of professionalism in online consultations as you would expect in face-to-face sessions, with the additional advantage of flexible communication that fits your schedule and location. Before joining Sarv's telehealth platform, all clinicians undergo a rigorous screening process, ensuring they are well-equipped to provide comprehensive care. Moreover, many of our practitioners are skilled in a diverse array of holistic healthcare approaches, offering tailored support to meet your specific wellness needs.",
    },
  ];

  return (
    <Container className="my-5">
      <Row className="justify-content-center mb-5">
        <Col>
          <h2 className="text-center header-agrandir">Sarv Benefits</h2>
        </Col>
      </Row>
      {benefitsData.map((benefit, index) => (
        <Row key={index} className="mb-4" style={{ padding: "10px" }}>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title
                  className="text-center"
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "#011d09",
                    paddingBottom: "25px",
                    paddingTop: "15px",
                  }}
                >
                  {benefit.title}
                </Card.Title>
                <Card.Text style={{ fontSize: "18px" }}>
                  {benefit.description}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default Benefits;
