import React, { useEffect, useState } from "react";
import { Row, Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../../config/Firebase";
import {
  getDoc,
  doc,
  query,
  collection,
  where,
  getDocs,
} from "firebase/firestore";
import seedrandom from "seedrandom";
import ReactGA from "react-ga4";

const PersonalizedRecipes = () => {
  const [recipes, setRecipes] = useState(() => {
    const savedData = localStorage.getItem("recipesData");
    ReactGA.event({
      category: "Recipes",
      action: "Load from Storage",
      label: savedData ? "Loaded" : "Not Found",
    });
    return savedData ? JSON.parse(savedData).recipes : [];
  });
  const [userName, setUserName] = useState(() => {
    return localStorage.getItem("userName") || "";
  });
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        fetchUserDetails(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (userId) {
      loadPersonalizedRecipes(userId);
    }
  }, [userId]);

  const fetchUserDetails = async (uid) => {
    try {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists() && userDoc.data().firstName) {
        setUserName(userDoc.data().firstName);
        localStorage.setItem("userName", userDoc.data().firstName);
      } else {
        console.log("User name not found in 'users' collection.");
      }
    } catch (error) {
      console.error("Failed to fetch user details:", error);
    }
  };

  const loadPersonalizedRecipes = async (uid) => {
    const currentWeek = getWeekNumber(new Date());
    const savedData = localStorage.getItem("recipesData");
    const { week, recipes: savedRecipes } = savedData
      ? JSON.parse(savedData)
      : {};

    if (week === currentWeek && savedRecipes) {
      setRecipes(savedRecipes);
      return;
    }

    try {
      const userDocRef = doc(db, "users", uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const { healthGoal } = userDoc.data();
        const recipeCategories = getRecipeCategoriesByHealthGoal(healthGoal);
        const recipesQuery = query(
          collection(db, "recipes"),
          where("category", "array-contains-any", recipeCategories)
        );
        const querySnapshot = await getDocs(recipesQuery);
        const fetchedRecipes = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        const randomRecipes = selectRecipesWeekly(fetchedRecipes, currentWeek);
        setRecipes(randomRecipes);
        localStorage.setItem(
          "recipesData",
          JSON.stringify({ week: currentWeek, recipes: randomRecipes })
        );
        ReactGA.event({
          category: "Recipes",
          action: "Loaded",
          label: `Recipes Loaded - ${healthGoal}`,
        });
      }
    } catch (error) {
      console.error("Failed to load personalized recipes:", error);
    }
  };

  const getWeekNumber = (date) => {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDays = (date - startOfYear) / 86400000;
    return Math.ceil((pastDays + startOfYear.getDay() + 1) / 7);
  };

  const getRecipeCategoriesByHealthGoal = (healthGoal) => {
    const healthGoalMap = {
      "Healthy aging / longevity": [
        "Brain Support",
        "Immune Boost",
        "Heart Health",
        "Circulation",
      ],
      "Heart health (avoid heart attacks stroke high blood pressure etc.)": [
        "Heart Health",
        "Circulation",
        "Coldness And Circulation",
      ],
      "Enhance stamina and address impotence": [
        "Stamina And Impotence",
        "Muscle Soreness And Stiffness",
        "Brain Fatigue",
      ],
      "Brain health": ["Brain Support", "Concussion", "Dizziness"],
      "Immune system health": [
        "Immune Boost",
        "Immune Defense",
        "Chronic Low Immunity",
      ],
      "Improve general physical performance": [
        "Muscle Soreness And Stiffness",
        "Fatigue",
        "Circulation",
      ],
      "Stress & anxiety management": [
        "Anxiety",
        "Emotional Support",
        "Nervousness",
        "Panic",
      ],
      "Manage depression and enhance emotional well-being": [
        "Depression",
        "Emotional Support",
        "Grief",
      ],
      "Improve sleep quality": [
        "Insomnia",
        "Stress & Anxiety Management",
        "Emotional Support",
        "Nervousness",
      ],
      "Improve mood and cognitive function": [
        "Brain Support",
        "Nervousness",
        "Neuralgia Neuropathy",
      ],
      "Ease arthritis pain and inflammation": [
        "Arthritis",
        "Muscle Soreness And Stiffness",
        "Neuralgia Neuropathy",
        "Lumbago",
      ],
      "Address digestive disorders": [
        "Indigestion",
        "Constipation",
        "Diarrhea",
        "Colic",
      ],
      "Treat skin conditions": [
        "Eczema",
        "Rosacea",
        "Impetigo",
        "Poison Oak/Ivy",
      ],
      "Enhance energy levels and manage Chronic Fatigue Syndrom": [
        "Fatigue",
        "Brain Fatigue",
        "Nervousness",
      ],
      "Support thyroid function": ["Hypoglycemia", "Nervousness"],
      "Manage menopause symptoms and support hormonal balance": [
        "Menopause",
        "Pms, Emotional Aspects",
        "Irregular Cycles",
      ],
      "Assist in cancer support": [
        "Immune Boost",
        "Immune Defense",
        "Chronic Low Immunity",
      ],
      "Prevent and manage kidney stones": [
        "Kidney Stones",
        "Bladder Infections",
        "Urination Frequency",
        "Bladder Control",
      ],
      Other: [
        "Immune Defense",
        "Immune Boost",
        "Brain Support",
        "Digestive Health",
        "Heart Health",
      ],
    };

    return healthGoalMap[healthGoal] || ["Everyday Recipes"];
  };

  const selectRecipesWeekly = (recipes, weekNumber) => {
    const rng = seedrandom(weekNumber);
    return recipes.sort((a, b) => rng() - 0.5).slice(0, 6);
  };

  const handleCardClick = (id) => {
    console.log("Clicked Recipe ID:", id);
    navigate(`/recipe-details/${id}`);
  };

  return (
    <>
      <Row>
        <Col>
          <h4
            style={{
              fontSize: "24px",
              color: "rgb(22, 26, 58)",
              fontFamily: "Agrandir",
              paddingBottom: "15px",
            }}
          >
            This Week's Top Picks for {userName}
          </h4>
        </Col>
      </Row>
      <Row style={{ paddingBottom: "50px" }}>
        {recipes.map((recipe, index) => (
          <Col key={index} md={2} sm={6}>
            <Card
              className="mb-4 shadow-sm explorer-card-hover-effect"
              style={{ cursor: "pointer", border: "none" }}
              onClick={() => handleCardClick(recipe.id)}
            >
              <Card.Img
                variant="top"
                src={recipe.imgUrl}
                style={{ height: "200px", objectFit: "cover" }}
              />
              <Card.Body>
                <Card.Title>{recipe.title}</Card.Title>
                <Card.Text>
                  {recipe.content.length > 50
                    ? `${recipe.content.substring(0, 50)}...`
                    : recipe.content}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default PersonalizedRecipes;
