import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../../config/Firebase"; // Adjust the import path according to your Firebase config file
import { doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import moment from "moment-timezone";

const GeneralInfo = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [unit, setUnit] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");
  const [timezone, setTimezone] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  // Define the states, countries, and timezones directly within the component
  const states = [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  const countries = [
    "U.S.A",
    "Puerto Rico",
    "Virgin Islands",
    "Guam",
    "Northern Mariana Islands",
  ];

  const timezones = [
    "America/Adak", // Hawaii-Aleutian (without DST)
    "America/Anchorage",
    "America/Los_Angeles", // Pacific Time
    "America/Phoenix", // Arizona Time (no DST)
    "America/Denver", // Mountain Time
    "America/Chicago", // Central Time
    "America/New_York", // Eastern Time
    // Add other time zones as needed
  ];

  const timezoneDescriptions = {
    "America/Adak": "Hawaii-Aleutian Standard Time",
    "America/Anchorage": "Alaska Standard Time",
    "America/Los_Angeles": "Pacific Time - Los Angeles",
    "America/Phoenix": "Mountain Standard Time - Phoenix",
    "America/Denver": "Mountain Time - Denver",
    "America/Chicago": "Central Time - Chicago",
    "America/New_York": "Eastern Time - New York",
    // Add more mappings as needed
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
  }, []);

  const handleSubmit = async () => {
    setErrorMessage("");
    if (!userId) {
      console.error("User not authenticated.");
      return;
    }

    // Validate DOB for age >= 21
    if (!dob || moment().diff(moment(dob), "years") < 21) {
      setErrorMessage("You must be at least 21 years old.");
      return;
    }

    if (!gender) {
      setErrorMessage("Please select a gender.");
      return;
    }
    if (
      !address ||
      !zipCode ||
      !(zipCode.length === 5 || zipCode.length === 9)
    ) {
      setErrorMessage("Please enter a valid address and zip code.");
      return;
    }

    if (!timezone) {
      setErrorMessage("Please select a timezone.");
      return;
    }

    if (!country) {
      setErrorMessage("Please select a country");
      return;
    }

    try {
      await updateDoc(doc(db, "practitioners", userId), {
        dob,
        gender,
        address: {
          addressLine1: address,
          unit,
          city,
          state,
          zipCode,
          country,
          timezone,
        },
      });
      navigate("/bio");
    } catch (error) {
      console.error("Error updating document: ", error);
      setErrorMessage("Failed to update information. Please try again.");
    }
  };

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ minHeight: "100vh", minWidth: "600px" }}
    >
      {errorMessage && (
        <Row className="mb-3">
          <Col>
            <Alert variant="danger">{errorMessage}</Alert>
          </Col>
        </Row>
      )}
      <Row className="mt-3">
        <Col>
          <h2 className="text-center mb-4 career-h2">
            Add your general information
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card
            className="shadow no-border-card w-100"
            style={{ minWidth: "750px" }}
          >
            <Card.Body>
              <Card.Title>About you</Card.Title>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      value={dob}
                      onChange={(e) => setDob(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Gender</Form.Label>
                    <Form.Select
                      value={gender}
                      onChange={(e) => setGender(e.target.value)}
                    >
                      <option value="">Select Gender</option>
                      <option value="Female">Female</option>
                      <option value="Male">Male</option>
                      <option value="Nonconforming">Nonconforming</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "25px" }}>
        <Col>
          <Card
            Card
            className="shadow no-border-card w-100"
            style={{ minWidth: "750px" }}
          >
            <Card.Body>
              <Card.Title>Contact Information</Card.Title>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Unit Number (Optional)</Form.Label>
                <Form.Control
                  type="text"
                  value={unit}
                  onChange={(e) => setUnit(e.target.value)}
                />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>State</Form.Label>
                    <Form.Select
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                    >
                      <option value="">Select State</option>
                      {states.map((state, index) => (
                        <option key={index} value={state}>
                          {state}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Zip Code</Form.Label>
                    <Form.Control
                      type="text"
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Country</Form.Label>
                    <Form.Select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                    >
                      <option value="">Select Country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Timezone</Form.Label>

                    {/* <Form.Select
                      value={timezone}
                      onChange={(e) => setTimezone(e.target.value)}
                    >
                      <option value="">Select Timezone</option>
                      {Object.entries(timezoneDescriptions).map(
                        ([tzId, description]) => (
                          <option key={tzId} value={tzId}>
                            {description}
                          </option>
                        )
                      )}
                    </Form.Select> */}

                    <Form.Select
                      value={timezone}
                      onChange={(e) => setTimezone(e.target.value)}
                    >
                      <option value="">Select Timezone</option>
                      {timezones.map((tz, index) => (
                        <option key={index} value={tz}>
                          {tz}
                        </option> // Directly use IANA IDs
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginTop: "25px" }}>
                <Col className="d-flex justify-content-center">
                  <Button
                    onClick={handleSubmit}
                    className="practitioners-button"
                  >
                    Next
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default GeneralInfo;
