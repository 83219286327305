// import React, { useState, useEffect } from "react";
// import { db, auth } from "../../config/Firebase";
// import { useNavigate } from "react-router-dom";
// import { setDoc, doc } from "firebase/firestore";
// import { Container, ListGroup, Button } from "react-bootstrap";
// import "../../styles/General.css";
// import "../../styles/Enrollment.css";
// import MultipleBars from "../utils/MultipleBars";

// const Exercise = () => {
//   const [selectedExerciseFrequency, setSelectedExerciseFrequency] =
//     useState("");
//   const exerciseOptions = [
//     "Not at all",
//     "1-2 times",
//     "3-4 times",
//     "5 or more times",
//   ];
//   const [userId, setUserId] = useState("");
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         setUserId(user.uid);
//       }
//     });
//     return unsubscribe;
//   }, []);

//   const handleSelection = async () => {
//     if (!userId) {
//       console.error("User ID not found");
//       return;
//     }

//     try {
//       const userDocRef = doc(db, "users", userId);
//       await setDoc(
//         userDocRef,
//         { exerciseFrequency: selectedExerciseFrequency },
//         { merge: true }
//       );

//       navigate("/pregnancyStatus");
//       // navigate("/screenTime");
//     } catch (error) {
//       console.error("Error updating exercise frequency in Firebase:", error);
//     }
//   };

//   return (
//     <div className="progress-container">
//       <MultipleBars
//         progresses={[
//           { progress: 22, maxProgress: 100, bgClass: "" },
//           { progress: 0, maxProgress: 100, bgClass: "bg-success" },
//           { progress: 0, maxProgress: 100, bgClass: "bg-info" },
//         ]}
//       />
//       <Container className="d-flex align-items-center justify-content-center">
//         <div
//           className="w-100"
//           style={{
//             maxWidth: "600px",
//             paddingTop: "60px",
//             paddingBottom: "20px",
//           }}
//         >
//           <h3 className="header-text">How often do you exercise per week?</h3>
//           <ListGroup>
//             {exerciseOptions.map((option, index) => (
//               <ListGroup.Item
//                 key={index}
//                 action
//                 active={option === selectedExerciseFrequency}
//                 onClick={() => setSelectedExerciseFrequency(option)}
//                 style={{ marginBottom: "15px" }}
//               >
//                 {option}
//               </ListGroup.Item>
//             ))}
//           </ListGroup>
//           <Button
//             onClick={handleSelection}
//             disabled={!selectedExerciseFrequency}
//             className="next-button mt-3"
//           >
//             Next
//           </Button>
//         </div>
//       </Container>
//     </div>
//   );
// };

// export default Exercise;

import React, { useState, useEffect } from "react";
import { db, auth } from "../../config/Firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Container, ListGroup, Button } from "react-bootstrap";
import "../../styles/General.css";
import "../../styles/Enrollment.css";
import MultipleBars from "../utils/MultipleBars";

const Exercise = () => {
  const [selectedExerciseFrequency, setSelectedExerciseFrequency] =
    useState("");
  const exerciseOptions = [
    "Not at all",
    "1-2 times",
    "3-4 times",
    "5 or more times",
  ];
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
    return unsubscribe;
  }, []);

  const handleSelection = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const gender = userData.gender;

        if (gender !== "Female") {
          navigate("/wellnessPractices");
        } else {
          await setDoc(
            userDocRef,
            { exerciseFrequency: selectedExerciseFrequency },
            { merge: true }
          );
          navigate("/pregnancyStatus");
        }
      } else {
        console.error("User document not found");
      }
    } catch (error) {
      console.error("Error fetching user data from Firebase:", error);
    }
  };

  return (
    <div className="progress-container">
      <MultipleBars
        progresses={[
          { progress: 22, maxProgress: 100, bgClass: "" },
          { progress: 0, maxProgress: 100, bgClass: "bg-success" },
          { progress: 0, maxProgress: 100, bgClass: "bg-info" },
        ]}
      />
      <Container className="d-flex align-items-center justify-content-center">
        <div
          className="w-100"
          style={{
            maxWidth: "600px",
            paddingTop: "60px",
            paddingBottom: "20px",
          }}
        >
          <h3 className="header-text">How often do you exercise per week?</h3>
          <ListGroup>
            {exerciseOptions.map((option, index) => (
              <ListGroup.Item
                key={index}
                action
                active={option === selectedExerciseFrequency}
                onClick={() => setSelectedExerciseFrequency(option)}
                style={{ marginBottom: "15px" }}
              >
                {option}
              </ListGroup.Item>
            ))}
          </ListGroup>
          <Button
            onClick={handleSelection}
            disabled={!selectedExerciseFrequency}
            className="next-button mt-3"
          >
            Next
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Exercise;
