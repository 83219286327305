import React, { useState } from "react";
import { Form, Button, Container, Card, Alert } from "react-bootstrap";
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPopup,
} from "firebase/auth";
import "../../styles/General.css";
import { auth, db, googleProvider } from "../../config/Firebase";
import "../../styles/Login.css";
import { setDoc, doc } from "firebase/firestore";

const SignUp = () => {
  // const SignUp = ({ onSignUpSuccess }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const auth = getAuth();

  const createStripeCustomer = async (userEmail) => {
    const user = auth.currentUser;

    if (user) {
      const userId = user.uid;

      try {
        const response = await fetch("http://localhost:5255/create-customer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: userEmail, userId }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.error.message);
        }

        // Here we include email in the Firestore document.
        // Note: Password is not stored for security reasons.
        const userDocRef = doc(db, "users", userId);
        await setDoc(
          userDocRef,
          { stripeCustomerId: data.customerId, email: userEmail },
          { merge: true }
        );
      } catch (error) {
        console.error("Failed to create an account", error);
      }
    } else {
      console.error("No authenticated user found.");
    }
  };

  const signUpWithPopup = () => {
    signInWithPopup(auth, googleProvider)
      .then(async (result) => {
        const user = result.user;
        await createStripeCustomer(user.email); // Pass email directly from the Google sign-in result
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setMessage("");
    setError("");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email) || !password.trim()) {
      setError("Both email and password are required and email must be valid.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await createStripeCustomer(email); // Use the email state variable
      await sendEmailVerification(userCredential.user);
      setMessage(
        "Account has been created successfully! Check your inbox for verification."
      );
    } catch (signUpError) {
      let errorMessage = signUpError.message;
      errorMessage = errorMessage.replace("Firebase: ", "");
      setError(`Failed to create an account: ${errorMessage}`);
      console.error(signUpError);
    }
  };

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh", height: "600px" }}
    >
      <div className="w-100" style={{ maxWidth: "500px", paddingTop: "60px" }}>
        <Card className="shadow no-border-card" style={{ height: "auto" }}>
          <Card.Body>
            <Card.Title className="login-card-title">
              Create an account
            </Card.Title>
            <div
              className="w-100"
              style={{ maxWidth: "400px", paddingTop: "25px" }}
            ></div>
            <Form onSubmit={handleSignUp}>
              <Form.Group controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              <div style={{ paddingTop: "20px", paddingBottom: "25px" }}>
                <Form.Group controlId="formBasicPassword">
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
              </div>

              {/* Include Phone Number Field and Verification Code Field Here */}

              {/* {message && <div style={{ color: "green" }}>{message}</div>}
              {error && <div style={{ color: "red" }}>{error}</div>} */}

              {message && (
                <Alert variant="success" className="mt-3">
                  {message}
                </Alert>
              )}
              {error && (
                <Alert variant="danger" className="mt-3">
                  {error}
                </Alert>
              )}
              <div style={{ paddingTop: "20px" }}>
                <Button
                  variant="primary"
                  type="submit"
                  className="login-btn w-100 mt-3"
                >
                  Sign Up
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={signUpWithPopup}
                  className="w-100 mt-3 google-sign-up-button"
                >
                  Sign Up With Google
                </Button>
              </div>
              <div className="text-center mt-3">
                <a href="/login">Already have an Account? Sign In</a>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
};

export default SignUp;
