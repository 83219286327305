import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../../config/Firebase"; // Adjust this import path
import { doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { FaInfoCircle } from "react-icons/fa";
import CryptoJS from "crypto-js";
import moment from "moment";

const DirectDepositInfo = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [legalFirstName, setLegalFirstName] = useState("");
  const [legalLastName, setLegalLastName] = useState("");
  const [ssn, setSSN] = useState("");
  const [confirmSSN, setConfirmSSN] = useState("");
  const [dob, setDOB] = useState("");
  const [confirmDOB, setConfirmDOB] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [confirmRoutingNumber, setConfirmRoutingNumber] = useState("");
  const [checkingAccountNumber, setCheckingAccountNumber] = useState("");
  const [confirmCheckingAccountNumber, setConfirmCheckingAccountNumber] =
    useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
  }, []);

  const validateForm = () => {
    if (ssn !== confirmSSN) {
      setError("SSN and Confirm SSN do not match.");
      return false;
    }
    if (dob !== confirmDOB) {
      setError("Date of Birth and Confirm Date of Birth do not match.");
      return false;
    }
    if (routingNumber !== confirmRoutingNumber) {
      setError("Routing Number and Confirm Routing Number do not match.");
      return false;
    }
    if (checkingAccountNumber !== confirmCheckingAccountNumber) {
      setError(
        "Checking Account Number and Confirm Checking Account Number do not match."
      );
      return false;
    }

    // Check for empty fields
    if (
      !legalFirstName ||
      !legalLastName ||
      !ssn ||
      !dob ||
      !routingNumber ||
      !checkingAccountNumber
    ) {
      setError("Please fill in all required fields.");
      return false;
    }

    // Validate that DOB is more than 21 years ago
    if (!moment(dob).isValid() || moment().diff(moment(dob), "years") < 21) {
      setError("You must be at least 21 years old.");
      return false;
    }

    // Assuming a basic validation for routing and account numbers
    if (routingNumber.length !== 9) {
      setError("Routing number must be 9 digits.");
      return false;
    }

    // Assuming a basic length check for checking account numbers (typically between 8 to 12 digits in the U.S.A, this can vary)
    if (checkingAccountNumber.length < 8 || checkingAccountNumber.length > 12) {
      setError("Invalid checking account number.");
      return false;
    }

    // Add more specific checks as necessary
    return true;
  };

  const handleSubmit = async () => {
    setError("");
    if (validateForm()) {
      try {
        // Ensure the encryption key is present
        const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;
        if (!encryptionKey) {
          console.error("Encryption key is not set.");
          setError("Encryption setup error. Please contact support.");
          return;
        }

        // Encrypt the SSN
        const encryptedSSN = CryptoJS.AES.encrypt(
          ssn,
          encryptionKey
        ).toString();

        await updateDoc(doc(db, "practitioners", userId), {
          directDepositInfo: {
            legalFirstName,
            legalLastName,
            ssn: encryptedSSN, // Store the encrypted SSN
            dob,
            routingNumber,
            checkingAccountNumber,
          },
        });
        navigate("/application-complete"); // Update with your next page's route
      } catch (error) {
        console.error("Error saving document: ", error);
        setError("Failed to save information. Please try again.");
      }
    }
  };

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ minHeight: "100vh", width: "850px" }}
    >
      <Row className="mt-3">
        <Col>
          <h2 className="text-center mb-4 career-h2">
            Add your direct deposit information
          </h2>
        </Col>
      </Row>
      <Card
        className="shadow no-border-card w-100"
        style={{ minWidth: "850px" }}
      >
        <Card.Body>
          <Card.Title>Let us know where to send your earnings</Card.Title>
          <Card.Text>Payee</Card.Text>
          <Card.Text>
            Avoid any hiccups with your account setup by ensuring all your
            information is correct. In the event that we are unable to verify
            the payment information, you will be required to submit additional
            documentation for verification.
          </Card.Text>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={legalFirstName}
                    onChange={(e) => setLegalFirstName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={legalLastName}
                    onChange={(e) => setLegalLastName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>SSN</Form.Label>
                  <Form.Control
                    type="password"
                    value={ssn}
                    onChange={(e) => setSSN(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm SSN</Form.Label>
                  <Form.Control
                    type="password"
                    value={confirmSSN}
                    onChange={(e) => setConfirmSSN(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    value={dob}
                    onChange={(e) => setDOB(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    value={confirmDOB}
                    onChange={(e) => setConfirmDOB(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Card.Text style={{ fontWeight: "bold", marginTop: "30px" }}>
              Direct deposit information
            </Card.Text>
            <Card.Text>
              Please use a checking account. We cannot make payments to savings
              accounts.
            </Card.Text>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Routing Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={routingNumber}
                    onChange={(e) => setRoutingNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm Routing Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={confirmRoutingNumber}
                    onChange={(e) => setConfirmRoutingNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Checking Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={checkingAccountNumber}
                    onChange={(e) => setCheckingAccountNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm Checking Account Number</Form.Label>
                  <Form.Control
                    type="text"
                    value={confirmCheckingAccountNumber}
                    onChange={(e) =>
                      setConfirmCheckingAccountNumber(e.target.value)
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ paddingTop: "50px", marginBottom: "30px" }}>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title
                      style={{
                        fontWeight: "bold",
                        color: "darkgreen",
                        fontSize: "16px",
                      }}
                    >
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="info-tooltip">
                            More information about paperless 1099 consent.
                          </Tooltip>
                        }
                      >
                        <FaInfoCircle
                          style={{ marginRight: "5px", cursor: "pointer" }}
                        />
                      </OverlayTrigger>
                      Paperless 1099 consent
                    </Card.Title>
                    <Card.Text>
                      By clicking "Save", you consent to paperless delivery of
                      your 1099 tax forms vIa our online portal. Once your
                      account is approved, you may opt-out of paperless delivery
                      of tax forms at any time by visiting your payments tab.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
      <Row>
        <Col>
          {error && <Alert variant="danger">{error}</Alert>}
          <Button onClick={handleSubmit} className="save-btn">
            Save
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default DirectDepositInfo;
