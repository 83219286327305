import React from "react";
import PropTypes from "prop-types";
import "../../styles/General.css";

const MultipleBars = ({ progresses }) => {
  return (
    <div
      className="progress"
      style={{ height: "5px", borderRadius: "5px", backgroundColor: "#e9ecef" }}
    >
      {progresses.map((item, index) => (
        <div
          key={index}
          className={`progress-bar ${item.bgClass || ""}`}
          role="progressbar"
          style={{
            width: `${(item.progress / item.maxProgress) * 100}%`,
            height: "5px",
            borderRadius: "5px",
          }}
          aria-valuenow={item.progress}
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      ))}
    </div>
  );
};

MultipleBars.propTypes = {
  progresses: PropTypes.arrayOf(
    PropTypes.shape({
      progress: PropTypes.number.isRequired,
      maxProgress: PropTypes.number,
      bgClass: PropTypes.string,
    })
  ).isRequired,
};

MultipleBars.defaultProps = {
  progresses: [
    { progress: 0, maxProgress: 100, bgClass: "" },
    { progress: 0, maxProgress: 100, bgClass: "bg-success" },
    { progress: 0, maxProgress: 100, bgClass: "bg-info" },
  ],
};

export default MultipleBars;
