import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { auth, db, storage } from "../../../config/Firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const ProfilePhoto = () => {
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
  }, []);

  const handleUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        const storageRef = ref(storage, `practitioners-pictures/${userId}.jpg`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        setImageURL(url);
        setIsUploaded(true);
        await updateDoc(doc(db, "practitioners", userId), { imgUrl: url });
      }
    };
    fileInput.click();
  };

  const handleNextClick = () => {
    navigate("/availability"); // Replace with your actual next page route
  };

  return (
    <Container
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ minHeight: "80vh", minWidth: "600px" }}
    >
      <Row className="mt-3">
        <Col>
          <h2 className="text-center mb-4 career-h2">Add your profile photo</h2>
        </Col>
      </Row>
      <Card
        // className="text-center"
        // style={{ minWidth: "600px" }}
        className="text-center shadow no-border-card w-100"
        style={{ maxWidth: "750px" }}
      >
        {" "}
        {/* Adjust the maxWidth as needed */}
        <Card.Body className="d-flex flex-column justify-content-center align-items-center">
          <Card.Title>
            Please upload a professional photo of yourself for use on your
            profile
          </Card.Title>
          <Row
            className="justify-content-center mt-3"
            style={{ marginTop: "50px" }}
          >
            <Col>
              <Image
                src={imageURL || "/img/default-pic.jpeg"}
                roundedCircle
                style={{ width: "150px", height: "150px" }}
              />
            </Col>
          </Row>

          <Row
            className="justify-content-center mt-3"
            style={{ marginTop: "40px", marginBottom: "50px" }}
          >
            <Button onClick={handleUploadClick} className="upload-btn">
              Upload
            </Button>
          </Row>
          {/* Add examples of good and bad photos as described */}
        </Card.Body>
      </Card>
      <Row className="mt-3 text-center">
        <Col>
          <p>You'll be able to continue when you finish editing your photo.</p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Button
          onClick={handleNextClick}
          className="practitioners-button"
          disabled={!isUploaded}
        >
          Next
        </Button>
      </Row>
    </Container>
  );
};

export default ProfilePhoto;
