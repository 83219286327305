import React, { useState, useEffect } from "react";
import { db, auth } from "../../config/Firebase";
import { useNavigate } from "react-router-dom";
import { setDoc, doc } from "firebase/firestore";
import { Container, Form, Button } from "react-bootstrap";
import "../../styles/General.css";
import "../../styles/Enrollment.css";
import MultipleBars from "../utils/MultipleBars";

const HealthGoal = () => {
  const [selectedHealthGoal, setSelectedHealthGoal] = useState("");
  const [otherHealthGoal, setOtherHealthGoal] = useState("");
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSelection = async (event) => {
    setSelectedHealthGoal(event.target.value);
    if (event.target.value !== "Other") {
      setOtherHealthGoal("");
    }
  };

  const handleSubmit = async () => {
    if (!userId) {
      console.error("User ID not found");
      return;
    }

    const goal =
      selectedHealthGoal === "Other" ? otherHealthGoal : selectedHealthGoal;

    try {
      const userDocRef = doc(db, "users", userId);
      await setDoc(userDocRef, { healthGoal: goal }, { merge: true });
      navigate("/chronicDiseases");
    } catch (error) {
      console.error("Error updating health goal in Firebase:", error);
    }
  };

  return (
    <div className="progress-container">
      <MultipleBars
        progresses={[
          { progress: 35, maxProgress: 100, bgClass: "" },
          { progress: 5, maxProgress: 100, bgClass: "bg-success" },
          { progress: 0, maxProgress: 100, bgClass: "bg-info" },
        ]}
      />
      <Container className="d-flex align-items-center justify-content-center">
        <div
          className="w-100"
          style={{
            maxWidth: "600px",
            paddingTop: "60px",
            paddingBottom: "20px",
          }}
        >
          <h3 className="header-text">What is your main health goal?</h3>
          <p
            style={{
              fontSize: "14px",
              paddingTop: "0px",
              paddingBottom: "20px",
            }}
          >
            You can add more goals later.
          </p>
          <Form>
            {[
              "Healthy aging / longevity",
              "Heart health (avoid heart attacks, stroke, high blood pressure etc.)",
              "Enhance stamina and address impotence",
              "Brain health",
              "Immune system health",
              "Improve general physical performance",
              "Stress & anxiety management",
              "Manage depression and enhance emotional well-being",
              "Improve sleep quality",
              "Improve mood and cognitive function",
              "Ease arthritis pain and inflammation",
              "Address digestive disorders",
              "Treat skin conditions",
              "Enhance energy levels and manage Chronic Fatigue Syndrom",
              "Support thyroid function",
              "Manage menopause symptoms and support hormonal balance",
              "Assist in cancer support",
              // "Assist in weight loss",
              "Prevent and manage kidney stones",
              "Other",
            ].map((goal, index) => (
              <div key={index} className="mb-3">
                <Form.Check
                  type="radio"
                  id={`goal-${goal}`}
                  label={goal}
                  value={goal}
                  name="healthGoal"
                  checked={selectedHealthGoal === goal}
                  onChange={handleSelection}
                />
                {goal === "Other" && selectedHealthGoal === "Other" && (
                  <Form.Control
                    type="text"
                    placeholder="Please specify"
                    value={otherHealthGoal}
                    onChange={(e) => setOtherHealthGoal(e.target.value)}
                    className="mt-2"
                  />
                )}
              </div>
            ))}
            <Button
              onClick={handleSubmit}
              disabled={
                !selectedHealthGoal ||
                (selectedHealthGoal === "Other" && !otherHealthGoal)
              }
              className="next-button mt-3"
            >
              Submit
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
};

export default HealthGoal;
