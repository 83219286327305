import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import "../../styles/General.css";

const itemsWithInfo = [
  {
    text: "Work from anywhere",
    info: "Licensed practioners will come to you",
    showTickInHoma: true,
    showCrossInHealthcare: true,
  },
  {
    text: "Set your own hours",
    info: "You can choose what kind of care you want to receive",
    showTickInHoma: true,
    showCrossInHealthcare: true,
  },
  {
    text: "See clients in-person",
    info: "Convenient and private chat with your healthcare provider",
    showTickInHoma: false,
    showCrossInHealthcare: false,
  },
  {
    text: "Clinical autonomy",
    info: "Flexible appointment times",
    showTickInHoma: true,
    showCrossInHealthcare: false,
  },
  {
    text: "No overhead or marketing costs",
    info: "Find the best fit for your needs",
    showTickInHoma: true,
    showCrossInHealthcare: true,
  },
  {
    text: "No insurance paneling or paperwork",
    info: "Change providers easily if needed",
    showTickInHoma: true,
    showCrossInHealthcare: true,
  },
  {
    text: "Free Sârv membership",
    info: "Often more affordable than traditional therapy",
    showTickInHoma: true,
    showCrossInHealthcare: true,
  },
  {
    text: "Access to the world's largest natural healing practitioners network",
    info: "No geographical limitations",
    showTickInHoma: true,
    showCrossInHealthcare: true,
  },
];

const renderTooltip = (info) => (props) =>
  (
    <Tooltip id="button-tooltip" {...props}>
      {info}
    </Tooltip>
  );

const ProsandCons = () => {
  return (
    <div
      className="proscons-container"
      style={{ backgroundColor: "white", width: "100%" }}
    >
      {" "}
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div style={{ maxWidth: "800px", textAlign: "center", width: "100%" }}>
          {/* <div className="prosandcons-table-container "> */}{" "}
          <div
            justify-content-center
            style={{ textAlign: "center", paddingTop: "150px" }}
          >
            {" "}
            <span className="proscons-title">
              Sârv vs. traditional in office healthcare services
            </span>
          </div>
          <div className="proscons-container">
            <Table
              style={{
                border: "none",
                backgroundColor: "darkseagreen !important",
                widows: "800px",
                marginTop: "100px",
                marginBottom: "100px",
                maxWidth: "800px",
              }}
            >
              <thead>
                <tr>
                  <th></th> {/* Left column title (empty) */}
                  <th
                    style={{
                      textAlign: "center",
                    }}
                    className="prosandcons-title"
                  >
                    Sârv
                  </th>{" "}
                  {/* Middle column title */}
                  <th
                    style={{
                      textAlign: "center",
                    }}
                    className="prosandcons-title"
                  >
                    In office
                  </th>{" "}
                  {/* Right column title */}
                </tr>
              </thead>
              <tbody>
                {itemsWithInfo.map(
                  (
                    { text, info, showTickInHoma, showCrossInHealthcare },
                    index
                  ) => (
                    <tr key={index}>
                      <td className="prosandcons-firstColumn">
                        <OverlayTrigger
                          placement="right"
                          overlay={
                            <Tooltip id={`tooltip-${index}`}>{info}</Tooltip>
                          }
                        >
                          <span>
                            {text} <i className="bi bi-info-circle"></i>
                          </span>
                        </OverlayTrigger>
                      </td>
                      <td
                        className="center-align"
                        style={{ textAlign: "center" }}
                      >
                        {showTickInHoma ? (
                          <span style={{ color: "green", fontSize: "24px" }}>
                            ✓
                          </span>
                        ) : (
                          <span style={{ color: "red", fontSize: "24px" }}>
                            ✗
                          </span>
                        )}
                      </td>
                      <td
                        className="center-align"
                        style={{ textAlign: "center" }}
                      >
                        {showCrossInHealthcare ? (
                          <span style={{ color: "red", fontSize: "24px" }}>
                            ✗
                          </span>
                        ) : (
                          <span style={{ color: "green", fontSize: "24px" }}>
                            ✓
                          </span>
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </Table>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProsandCons;
