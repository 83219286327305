import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const HerbsAll = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const activities = location.state?.activities || [];
  const title = location.state?.title || "";

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const imagePath = (image) => `/img/explorer/herbs/${image}`;

  // Updated handleCardClick to pass title and subcategories as query parameters
  const handleCardClick = (title, subcategories) => {
    const querySubcategories = subcategories.map(encodeURIComponent).join(",");
    const queryTitle = encodeURIComponent(title);
    ReactGA.event({
      category: "Herb Interactions",
      action: "Card Clicked",
      label: title,
    });
    navigate(
      `/view-herbs?title=${queryTitle}&subcategories=${querySubcategories}`
    );
  };

  return (
    <Container
      style={{ minWidth: "1200px", margin: "0 auto", padding: "25px" }}
    >
      <Row
        className="mb-3 justify-content-center"
        style={{
          alignContent: "center",
          alignItems: "center",
          paddingTop: "100px",
          paddingBottom: "50px",
        }}
      >
        <Col>
          <h1 className="article-categoories-title text-center">{title}</h1>
        </Col>
      </Row>
      <Row
        className="g-4"
        style={{ paddingTop: "50px", paddingBottom: "100px" }}
      >
        {activities.map((activity, index) => (
          <Col
            key={index}
            md={3}
            sm={6}
            onClick={() =>
              handleCardClick(activity.title, activity.subcategories)
            }
          >
            <Card
              className="h-100"
              style={{ cursor: "pointer", border: "none" }}
            >
              <Card.Img
                variant="top"
                src={imagePath(activity.image)}
                style={{ height: "300px" }}
              />
              <Card.Body>
                <Card.Title>{activity.title}</Card.Title>
                <Card.Text>{truncateText(activity.description, 50)}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default HerbsAll;
