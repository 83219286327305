import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const BillingDetails = ({
  selectedFee,
  selectedOption,
  nextChargeDate,
  practitioner,
  selectedDate,
  selectedTime,
}) => {
  // Calculate the base visit fee and round down to the nearest whole number
  const baseVisitFee =
    practitioner && practitioner.minimumPay
      ? Math.floor(practitioner.minimumPay * 1.25)
      : 0;

  // Determine if a discount should be applied
  const isOneYearMembership = selectedOption === "1-year membership";
  const discount = isOneYearMembership ? baseVisitFee * 0.5 : 0;
  const visitFee = isOneYearMembership ? baseVisitFee - discount : baseVisitFee;

  const membershipFee = selectedFee;
  const todaysTotal =
    visitFee + (selectedOption !== "One-time visit" ? membershipFee : 0);

  const formattedNextChargeDate = nextChargeDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  return (
    <Container style={{ width: "600px", paddingTop: "30px" }}>
      <Row className="mb-3">
        <Col>
          <h4>Billing Details</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card style={{ width: "550px" }}>
            <Card.Body>
              {/* First Section - Visit Fee */}
              <Row className="mb-2">
                <Col xs={8}>
                  <div>Visit fee</div>
                  <div>
                    With{" "}
                    {practitioner
                      ? `${practitioner.firstName} ${practitioner.lastName}`
                      : "Dr. -"}
                  </div>
                  <div>
                    {selectedDate} at {selectedTime}
                  </div>
                </Col>
                <Col xs={4} className="text-end">
                  ${baseVisitFee.toFixed(2)}
                </Col>
              </Row>

              {/* Discount Section */}

              {/* Membership Section */}
              {selectedOption !== "One-time visit" && (
                <Row className="mb-3">
                  <Col xs={8}>
                    <div>Membership fee</div>
                    <div>Next charge on {formattedNextChargeDate}</div>
                  </Col>
                  <Col xs={4} className="text-end">
                    ${membershipFee}
                  </Col>
                </Row>
              )}

              {isOneYearMembership && (
                <Row className="mb-3">
                  <Col xs={8}>
                    <div>Discount applied</div>
                  </Col>
                  <Col xs={4} className="text-end" style={{ color: "red" }}>
                    -${discount.toFixed(2)}
                  </Col>
                </Row>
              )}
              {/* Total Section */}
              <Row>
                <Col xs={8}>
                  <strong>Today's total</strong>
                </Col>
                <Col xs={4} className="text-end">
                  <strong>${todaysTotal.toFixed(2)}</strong>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BillingDetails;
