import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/General.css";
// import "../../styles/Buttons.css";
import img1 from "../../images/about/custom_recipes.png";
import img2 from "../../images/about/book-1.jpg";
import img3 from "../../images/about/natural-remedy-1.jpg";
import img4 from "../../images/about/holistic_practioners.png";
import img5 from "../../images/about/symptom-checker-2.jpg";

import ReactGA from "react-ga4";

const Services = () => {
  const navigate = useNavigate();

  const redirectToOptions = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked Choose Plan Button",
      label: "Plan Selection",
    });

    navigate("/options");
  };

  const services = [
    {
      imgSrc: img1,
      title: "Personalized Medicinal Plants Recommendations",
      summary:
        "Utilizing advanced technology, we analyze your physical characteristics, such as age, gender, and existing health conditions, to provide personalized recommendations for natural remedies. This tailored approach ensures you receive the most suitable natural solutions for your unique health needs.",
    },
    {
      imgSrc: img5,
      title: "Symptom Checker",
      summary:
        "Our symptom checker guides you through a series of questions to understand your health concerns and then suggests holistic remedies, such as medicinal plants. This focused approach provides you with natural healthcare solutions tailored to your specific symptoms.",
    },
    {
      imgSrc: img3,
      title: "Customized Herbal Recipes",
      summary:
        "Offering hundreds of herbal recipes tailored to specific health conditions, this feature allows you to explore and utilize natural remedies in practical, everyday applications. From teas to tinctures, these recipes provide accessible and natural ways to enhance health and well-being.",
    },
    {
      imgSrc: img2,
      title: "Educational Resource on Medicinal Herbs",
      summary:
        "The platform serves as a comprehensive educational resource, offering detailed information on various medicinal herbs, including their properties, benefits, and historical uses. This feature empowers you to learn about natural healthcare and make informed decisions about your wellness journey.",
    },
    // {
    //   imgSrc: img4,
    //   title: "Access to Holistic Health Practitioners",
    //   summary:
    //     "This key feature connects you with a vast network of experienced herbalists and holistic healthcare practitioners. Through the platform, you can easily make online appointments to discuss your health needs, seek professional advice, and receive guidance on your natural healthcare journey.",
    // },
  ];

  return (
    <Container>
      <Row className="justify-content-center" style={{ paddingTop: "100px" }}>
        <Col className="text-center">
          <div className="services-bigtitle">What do we offer?</div>
          <div className="mission-text">Backed by Science</div>
        </Col>
      </Row>
      {/* First Row */}
      <Row style={{ paddingTop: "50px", paddingBottom: "100px" }}>
        {services.slice(0, 2).map((service) => (
          <Col md={6} key={service.title}>
            <Card className="services-card" style={{ border: "none" }}>
              <Card.Img
                variant="top"
                src={service.imgSrc}
                className="service-image"
              />
              <Card.Body>
                <Card.Title className="services-title">
                  {service.title}
                </Card.Title>
                <Card.Text className="services-text">
                  {service.summary}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {/* Second Row */}
      <Row style={{ paddingBottom: "100px" }}>
        {services.slice(2, 4).map((service) => (
          <Col md={6} key={service.title}>
            <Card className="services-card" style={{ border: "none" }}>
              <Card.Img
                variant="top"
                src={service.imgSrc}
                className="service-image"
              />
              <Card.Body>
                <Card.Title className="services-title">
                  {service.title}
                </Card.Title>
                <Card.Text className="services-text">
                  {service.summary}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row
        className="justify-content-center"
        style={{ paddingTop: "50px", paddingBottom: "100px" }}
      >
        <Col className="text-center">
          <Button className="custom-services-btn" onClick={redirectToOptions}>
            Choose your plan
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
