import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import EmailSubscription from "../utils/EmailSubscription";
import SocialShare from "../utils/SocialShare";
import { useLocation } from "react-router-dom";
import "../../styles/Article.css";
import SubscriptionComponent from "../utils/SubscriptionComponent";

const useQuery = () => new URLSearchParams(useLocation().search);

const ArticleComponent = () => {
  const query = useQuery();
  const topic = query.get("topic");
  const articleId = parseInt(query.get("articleId"));
  const [article, setArticle] = useState(null);

  useEffect(() => {
    if (topic) {
      fetch(topic.startsWith("/data/") ? topic : `/data/${topic}`)
        .then((response) => response.json())
        .then((data) => {
          const foundArticle = data.find((art) => art.id === articleId);
          setArticle(foundArticle);
        })
        .catch((error) => console.error("Error fetching article:", error));
    }
  }, [topic, articleId]);

  const renderContent = (content) => {
    return content.map((item, index) => {
      switch (item.type) {
        case "header":
          return (
            <h2
              key={index}
              className="my-agrandir-Regular-class"
              style={{ paddingBottom: "25px", paddingTop: "25px" }}
            >
              {item.text}
            </h2>
          );
        case "paragraph":
          return (
            <p key={index} className="all-article-content">
              {item.text}
            </p>
          );
        case "image":
          return (
            <img
              key={index}
              src={`${process.env.PUBLIC_URL}/${item.src}`}
              alt={item.alt}
              style={{ maxWidth: "100%", height: "auto" }}
            />
          );
        case "list":
          return (
            <ul key={index}>
              {item.items.map((listItem, listItemIndex) => (
                <li key={listItemIndex}>{listItem}</li>
              ))}
            </ul>
          );
        case "reference":
          return (
            <div key={index}>
              <h4
                className="my-agrandir-Regular-class"
                style={{ paddingBottom: "25px", paddingTop: "25px" }}
              >
                Sources
              </h4>
              <ul>
                {item.references.map((reference, refIndex) => (
                  <li key={refIndex}>{reference}</li>
                ))}
              </ul>
            </div>
          );
        default:
          return null;
      }
    });
  };

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <Container style={{ paddingTop: "50px" }}>
      <Row>
        <Col md={12}>
          <div className="text-content">
            <label>Updated: {article.lastUpdate}</label>
            <h1 style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              {article.title}
            </h1>
            <div className="text-center"> </div>
            {/* Div for centering the image */}
            <img
              src={`${process.env.PUBLIC_URL}/${article.img}`}
              alt={article.title}
              className="article-image"
            />
          </div>
          <div className="text-content">
            {article.content && renderContent(article.content)}
          </div>
          {/* <EmailSubscription /> */}
          <SocialShare />
          <SubscriptionComponent />

          {/* <div className="my-canela-class"> Ayurveda in Modern Healthcare </div>
          <div className="my-agrandir-class">
            {" "}
            Ayurveda in Modern Healthcare{" "}
          </div> */}
        </Col>
      </Row>
    </Container>
  );
};

export default ArticleComponent;
