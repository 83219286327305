const TherapeuticUse = [
  {
    image: "pain_management.jpg",
    title: "Pain Management",
    description: "Herbs that reduce pain and inflammation",
    subcategories: [
      "Analgesic",
      "Anti-inflammatory",
      "Antipyretic",
      "Antispasmodic",
      "Ntispasmodic",
      "Mild Analgesic",
      "Anti-asthmatic",
      "Muscle Relaxant",
      "Anti-fatigue",
      "Counterirritant",
    ],
  },
  {
    image: "digestive-health.jpg",
    title: "Digestive Health",
    description: "Herbs that support digestive health and function",
    subcategories: [
      "Alterative",
      "Alternative",
      "Carminative",
      "Digestive Aid",
      "Stomachic",
      "Cholagogue",
      "Aperient",
      "Laxative",
      "Mild Laxative",
      "Cathartic",
      "Purgative",
      "Demulcent",
      "Appetite suppressant",
    ],
  },
  {
    image: "respiratory_health.jpg",
    title: "Respiratory Health",
    description: "Herbs that enhance respiratory health",
    subcategories: [
      "Expectorant",
      "Decongestant",
      "Anticatarrhal",
      "Mucolytic",
      "Respiratory Health",
      "Cough Suppressant",
    ],
  },
  {
    image: "womens_health.jpg",
    title: "Women's Health and Hormonal Support",
    description: "Herbs that support women's health and hormonal balance",
    subcategories: [
      "Emmenagogue",
      "Parturient",
      "Galactagogue",
      "Aphrodisiac",
      "Menstrual Support",
    ],
  },
  {
    image: "liver_detox.jpg",
    title: "Liver and Detoxification",
    description: "Herbs that support liver health and detoxification",
    subcategories: [
      "Hepatoprotective",
      "Hepatic",
      "Detoxifying",
      "Depurative",
      "Liver Tonic",
    ],
  },
  {
    image: "neuro_health.jpg",
    title: "Neurological and Cognitive Health",
    description: "Herbs that support neurological and cognitive functions",
    subcategories: [
      "Neuroprotective",
      "Cognitive Enhancer",
      "Sedative",
      "Hypnotic",
      "Migraine Prophylaxis",
      "Sleep Aid",
      "Nervine",
      "Anxiolytic",
      "Mood Enhancer",
    ],
  },
  {
    image: "skin_health.jpg",
    title: "Skin and Tissue Health",
    description: "Herbs that nourish and heal the skin and tissues",
    subcategories: [
      "Emollient",
      "Vulnerary",
      "Wound Healing",
      "Healing",
      "Nourishing to the skin (when used in oils and creams)",
      "Moisturizing",
      "Promotes Tissue Repair",
      "Supports Bone and Connective Tissue Health",
    ],
  },
  {
    image: "antioxidant_support.jpg",
    title: "Antioxidant Support",
    description: "Herbs that provide antioxidant benefits",
    subcategories: ["Antioxidant"],
  },
  {
    image: "urinary_health.jpg",
    title: "Urinary and Kidney Health",
    description: "Herbs that promote urinary and kidney health",
    subcategories: ["Diuretic"],
  },
  {
    image: "gastro_health.jpg",
    title: "Gastrointestinal Health",
    description: "Herbs that aid in gastrointestinal health and wellness",
    subcategories: [
      "Carminative",
      "Digestive Aid",
      "Stomachic",
      "Laxative",
      "Antinausea",
      "Anti-inflammatory",
      "Antiemetic",
    ],
  },
  {
    image: "reproductive_health.jpg",
    title: "Reproductive Health",
    description: "Herbs that enhance reproductive health and function",
    subcategories: ["Aphrodisiac", "Emmenagogue", "Galactagogue", "Parturient"],
  },
  {
    image: "endocrine_health.jpg",
    title: "Endocrine Health",
    description:
      "Herbs that regulate endocrine activity and blood sugar levels",
    subcategories: ["Hypoglycemic", "Blood Sugar Regulating"],
  },
  {
    image: "mental_health.jpg",
    title: "Mental and Cognitive Function",
    description: "Herbs that enhance mental health and cognitive performance",
    subcategories: [
      "Cognitive Enhancer",
      "Anxiolytic",
      "Mood Enhancer",
      "Sedative",
      "Hypnotic",
      "Sleep Aid",
    ],
  },
  {
    image: "bone_joint_health.jpg",
    title: "Bone and Joint Health",
    description: "Herbs that support bone and joint health",
    subcategories: ["Anti-inflammatory", "Analgesic"],
  },
  {
    image: "immune_health.jpg",
    title: "Immune System Health",
    description: "Herbs that strengthen the immune system",
    subcategories: [
      "Immunomodulatory",
      "Antimicrobial",
      "Antibacterial",
      "Antiviral",
    ],
  },
  {
    image: "cancer_prevention.jpg",
    title: "Cancer Prevention",
    description: "Herbs that may help in reducing the risk of cancer",
    subcategories: ["Anticancer"],
  },
  {
    image: "eye_health.jpg",
    title: "Eye Health",
    description: "Herbs that support vision and eye health",
    subcategories: ["Antioxidant"],
  },
];

export default TherapeuticUse;
