import React, { useState, useEffect } from "react";
import { Card, Container, Form, Button, ListGroup } from "react-bootstrap";
import { auth, db } from "../../config/Firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore";

function UserSettings() {
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [receiveEmail, setReceiveEmail] = useState(false);
  const [receiveSMS, setReceiveSMS] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        fetchData(user.uid);
      }
    });

    return unsubscribe;
  }, []);

  const fetchData = async (uid) => {
    const userRef = doc(db, "users", uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      setName(userData.firstName || "");
      setEmail(userData.email || "");
      setPhone(userData.phone || "");
      setReceiveEmail(userData.receiveEmail || false);
      setReceiveSMS(userData.receiveSMS || false);
    } else {
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!auth.currentUser) {
      alert("No user logged in!");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    const phoneRegex = /^\+?([0-9]{2,3})?[-. ]?([0-9]{3})[-. ]?([0-9]{4,5})$/;
    if (!phoneRegex.test(phone)) {
      alert("Please enter a valid phone number.");
      return;
    }

    const userRef = doc(db, "users", auth.currentUser.uid);
    try {
      await updateDoc(userRef, {
        email: email,
        phone: phone,
      });
      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile.");
    }
  };

  const handleNotificationChange = async (e) => {
    e.preventDefault();

    if (!auth.currentUser) {
      return;
    }

    const userId = auth.currentUser.uid;
    const userRef = doc(db, "users", userId);

    try {
      await updateDoc(userRef, {
        receiveEmail: receiveEmail,
        receiveSMS: receiveSMS,
      });
      alert("Notification settings updated successfully!");
    } catch (error) {
      console.error("Error updating notification settings:", error);
      alert("Failed to update notification settings.");
    }
  };

  const cancelSubscription = async () => {
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, "users", userId);

      try {
        // Update the user's document to set cancelMembership to true
        await updateDoc(userRef, {
          cancelMembership: true,
        });
        // Show a detailed message about the cancellation
        alert(
          "We received your cancellation request. You will not be charged after your current subscription period ends."
        );
        // Optionally redirect the user or update the state to reflect the change
      } catch (error) {
        console.error("Error updating document:", error);
        alert("Failed to cancel subscription. Please try again.");
      }
    } else {
      alert("No user is currently logged in.");
    }
  };

  return (
    <Container className="mt-5" style={{ paddingBottom: "50px" }}>
      <Card>
        <Card.Header as="h5"> Settings</Card.Header>
        <Card.Body>
          {/* Account Information Section */}
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h6>Account Information</h6>
              <Form onSubmit={handleUpdate}>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" value={name} readOnly />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
                <Button variant="outline-primary" type="submit">
                  Update Profile
                </Button>
              </Form>
            </ListGroup.Item>
            <hr />
            {/* <ListGroup.Item>
              <h6>Password Change</h6>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control type="password" placeholder="New Password" />
                </Form.Group>
                <Button variant="secondary">Change Password</Button>
              </Form>
            </ListGroup.Item> */}
          </ListGroup>

          {/* Notification Settings Section */}

          <ListGroup variant="flush">
            <ListGroup.Item>
              <h6>Notification Settings</h6>
              <Form onSubmit={handleNotificationChange}>
                <Form.Check
                  type="checkbox"
                  label="Email Notifications (Appointments, News, Offers)"
                  className="mb-3"
                  checked={receiveEmail}
                  onChange={(e) => setReceiveEmail(e.target.checked)}
                />
                <Form.Check
                  type="checkbox"
                  label="SMS Alerts (Appointments, Service Changes)"
                  checked={receiveSMS}
                  onChange={(e) => setReceiveSMS(e.target.checked)}
                />
                <Button
                  variant="outline-primary"
                  type="submit"
                  className="mt-3"
                >
                  Update Notifications
                </Button>
              </Form>
            </ListGroup.Item>
          </ListGroup>

          {/* Subscription Management Section */}
          <hr />
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h6>Subscription Management</h6>
              <Form>
                {/* <Form.Group className="mb-3">
                  <Form.Label>Subscription Level</Form.Label>
                  <Form.Select defaultValue="Choose...">
                    <option>1 Month </option>
                    <option>4 Months</option>
                    <option>12 Months</option>
                  </Form.Select>
                </Form.Group>
                <Button variant="info" className="me-2">
                  Update Subscription
                </Button> */}
                <Button variant="outline-danger" onClick={cancelSubscription}>
                  Cancel Subscription
                </Button>
              </Form>
            </ListGroup.Item>
            {/* <hr />
            <ListGroup.Item>
              <h6>Billing Information</h6>
              <Button variant="link">View Billing History</Button>
            </ListGroup.Item> */}
          </ListGroup>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default UserSettings;
