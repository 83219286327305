import React, { useState } from "react";
import { auth, db } from "../../config/Firebase"; // Adjust path as needed
import { useNavigate } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import "../../styles/General.css";
import "../../styles/Enrollment.css";
import MultipleBars from "../utils/MultipleBars";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, updateDoc, getFirestore } from "firebase/firestore";

function HowDidYouHearAboutUs() {
  const [heardFrom, setHeardFrom] = useState("");
  const [heardFromDetails, setheardFromDetails] = useState("");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setHeardFrom(event.target.value);
    setheardFromDetails("");
  };

  const handleDetailChange = (event) => {
    setheardFromDetails(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (auth.currentUser) {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, "users", userId);
      try {
        await updateDoc(userRef, {
          heardFrom: heardFrom,
          heardFromDetails: heardFromDetails,
          isComplete: true,
        });

        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const selectedService = docSnap.data().selectedService;
          if (selectedService === "virtual visit") {
            // navigate(`/view-practitioners`);
            navigate("/subscriptionOptions");
          } else {
            // navigate(`/view-practitioners`);
            navigate("/subscriptionOptions");
          }
        } else {
          console.log("No such document!");
          navigate("/subscriptionOptions");
        }
      } catch (error) {
        console.error("Error updating document: ", error);
      }
    } else {
      console.log("No user logged in");
    }
  };

  return (
    <div className="progress-container">
      <MultipleBars
        progresses={[
          { progress: 35, maxProgress: 100, bgClass: "" },
          { progress: 35, maxProgress: 100, bgClass: "bg-success" },
          { progress: 30, maxProgress: 100, bgClass: "bg-info" },
        ]}
      />
      <Container className="d-flex align-items-center justify-content-center">
        <div
          className="w-100"
          style={{
            maxWidth: "600px",
            paddingTop: "60px",
            paddingBottom: "20px",
          }}
        >
          <h3 className="header-text">How did you hear about us?</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              {[
                "Online Advertising",
                "Social Media",
                "Search Engine",
                "Word of Mouth",
                "Email Marketing",
                "Television",
                "Radio",
                "Print Media",
                "Events",
                "Podcasts",
                "Direct Mail",
                "Referral",
                "Influencers",
                "Other",
              ].map((option, index) => (
                <Form.Check
                  type="radio"
                  name="heardFrom"
                  id={`radio-${option}`}
                  label={option}
                  value={option}
                  checked={heardFrom === option}
                  onChange={handleChange}
                  key={index}
                  style={{ marginTop: "10px" }}
                />
              ))}
            </Form.Group>
            {["Events", "Podcasts", "Referral", "Influencers"].includes(
              heardFrom
            ) && (
              <Form.Group>
                <Form.Control
                  type="text"
                  value={heardFromDetails}
                  onChange={handleDetailChange}
                  placeholder="Please specify"
                  style={{
                    marginTop: "20px",
                    width: "70%",
                    marginBottom: "30px",
                  }}
                />
              </Form.Group>
            )}
            <Button
              variant="primary"
              type="submit"
              className="next-button mt-3"
              style={{ marginTop: "30px", marginBottom: "50px" }}
            >
              Submit
            </Button>
          </Form>
        </div>
      </Container>
    </div>
  );
}

export default HowDidYouHearAboutUs;
