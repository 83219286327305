import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TitleLineNoLink = ({ title, link, onLinkClick }) => {
  return (
    <Container>
      <Row className="align-items-center">
        <Col xs={6}>
          <h2 style={{ fontSize: "32px", color: "gray" }}>{title}</h2>
        </Col>
        <Col xs={6} className="text-end">
          <a
            href={link}
            onClick={onLinkClick}
            style={{ textDecoration: "none", fontSize: "20px" }}
          ></a>
        </Col>
      </Row>
      <Row>
        <Col
          style={{
            position: "relative",
            marginTop: "10px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              backgroundColor: "lightgray",
              height: "2px",
              width: "100%",
              position: "absolute",
              left: "0%",
              top: "5%",
            }}
          ></div>
        </Col>
      </Row>
    </Container>
  );
};

export default TitleLineNoLink;
