import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Container, Form, Button, Card } from "react-bootstrap";
import { db, auth } from "../../../config/Firebase";
import "../../../styles/General.css";

const dayOptions = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const highOptions = [
  { label: "$60", value: "60" },
  { label: "$70", value: "70" },
  { label: "$80", value: "80" },
  { label: "$90", value: "90" },
  { label: "$100", value: "100" },
  { label: "$110", value: "110" },
  { label: "$120", value: "120" },
  { label: "$130", value: "130" },
  { label: "$140", value: "140" },
  { label: "$150", value: "150" },
  { label: "$160", value: "160" },
  { label: "$170", value: "170" },
  { label: "$180", value: "180" },
  { label: "$190", value: "190" },
  { label: "$200", value: "200" },
  { label: "$210", value: "210" },
  { label: "$220", value: "220" },
  { label: "$230", value: "230" },
  { label: "$240", value: "240" },
  { label: "$250", value: "250" },
  { label: "$260", value: "260" },
  { label: "$270", value: "270" },
  { label: "$280", value: "280" },
  { label: "$290", value: "290" },
  { label: "$300", value: "300" },
];

const lowOptions = [
  { label: "$30", value: "30" },
  { label: "$40", value: "40" },
  { label: "$50", value: "50" },
  { label: "$60", value: "60" },
  { label: "$70", value: "70" },
];

const Availability = () => {
  const [availability, setAvailability] = useState({
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  });
  const [selectedAmount, setSelectedAmount] = useState("");
  const [appliedJobTitle, setAppliedJobTitle] = useState("");
  const [userId, setUserId] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserId(user.uid);
        const docRef = doc(db, "practitioners", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setAppliedJobTitle(docSnap.data().appliedJobTitle);
        }
      }
    });
  }, []);

  const handleAmountChange = (e) => {
    setSelectedAmount(e.target.value);
  };

  const handleUpdateTimeSlots = (day, timeSlot) => {
    setAvailability((prevAvailability) => {
      const currentSlots = prevAvailability[day];
      const updatedSlots = currentSlots.includes(timeSlot)
        ? currentSlots.filter((slot) => slot !== timeSlot)
        : [...currentSlots, timeSlot];
      return {
        ...prevAvailability,
        [day]: updatedSlots,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    if (!selectedAmount) {
      setError("Please select an hourly rate.");
      return;
    }

    if (!userId) {
      setError("User not authenticated.");
      return;
    }

    try {
      await updateDoc(doc(db, "practitioners", userId), {
        availability,
        minimumPay: selectedAmount,
      });
      setMessage("Your preferences have been updated successfully!");
      navigate("/payment-info"); // Adjust as necessary for your routing
    } catch (error) {
      console.error("Error updating document:", error);
      setError("Failed to update your preferences.");
    }
  };

  console.log("******** applied for ******", appliedJobTitle);

  const amountOptions =
    appliedJobTitle === "Herbalist" || appliedJobTitle === "Naturopathic Doctor"
      ? highOptions
      : lowOptions;

  return (
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "600px" }}>
        <h3 className="text-center mb-4">Tell us about your preferences</h3>
        <Form onSubmit={handleSubmit}>
          {/* Days and Time Slots */}
          {dayOptions.map((day) => (
            <Card key={day} className="mb-3 shadow no-border-card">
              <Card.Body>
                <Card.Title>{day}</Card.Title>
                <Form.Group>
                  {generateTimeSlots().map((time, index) => (
                    <Form.Check
                      inline
                      key={index}
                      type="checkbox"
                      label={time}
                      onChange={() => handleUpdateTimeSlots(day, time)}
                      checked={availability[day].includes(time)}
                    />
                  ))}
                </Form.Group>
              </Card.Body>
            </Card>
          ))}

          <Card className="mb-3 shadow no-border-card">
            <Card.Body>
              <Card.Title>
                What is the minimum hourly rate you would accept for this job?
              </Card.Title>
              <Card.Text>
                * A lower rate may increase your chances of attracting clients.
              </Card.Text>
              <Form.Group controlId="formAmount">
                <Form.Control
                  as="select"
                  value={selectedAmount}
                  onChange={handleAmountChange}
                  required
                >
                  <option value="">Select hourly rate</option>
                  {amountOptions.map((option) => (
                    <option key={option.label} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Card.Body>
          </Card>

          {message && <div style={{ color: "green" }}>{message}</div>}
          {error && <div style={{ color: "red" }}>{error}</div>}
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "30px" }}
          >
            <Button
              variant="primary"
              type="submit"
              className="practitioners-button"
            >
              Next
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
};

// Generate time slots for selection, 1-hour intervals in 12-hour format with AM/PM.
const generateTimeSlots = () => {
  const slots = [];
  // Start loop at 7 for 7 AM and end loop before 21 (which is 9 PM in 24-hour format)
  for (let hour = 7; hour <= 21; hour++) {
    const hour12 = hour % 12 === 0 ? 12 : hour % 12;
    const amPm = hour < 12 || hour === 24 ? "AM" : "PM";
    slots.push(`${hour12}:00 ${amPm}`);
  }
  return slots;
};

export default Availability;
