import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OneTimeCheckout from "./OneTimeCheckout";
import { db, auth } from "../../../../config/Firebase";
import { doc, getDoc } from "firebase/firestore";

const OneTimePayment = ({
  visitFeeAmount,
  practitionerId,
  selectedDate,
  selectedTime,
  oldAppointmentId,
}) => {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [error, setError] = useState(null);

  //   console.log(
  //     "^^^^^^^^^^^oldAppointmentId in OneTimePayment is ^^^^^^",
  //     oldAppointmentId
  //   );
  //   console.log("******* visist fee in payment is *******", visitFeeAmount);

  useEffect(() => {
    async function fetchConfig() {
      const response = await fetch("/config");
      const configData = await response.json();
      const stripe = await loadStripe(configData.publishableKey);
      setStripePromise(stripe);
    }
    fetchConfig();
  }, []);

  async function getStripeCustomerId(userId) {
    const userDocRef = doc(db, "users", userId);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      return docSnap.data().stripeCustomerId;
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      return null;
    }
  }

  useEffect(() => {
    const fetchCustomerId = async () => {
      const userId = auth.currentUser?.uid; // Adjust this to how you access the user ID
      if (userId) {
        const id = await getStripeCustomerId(userId);
        setCustomerId(id);
      }
    };

    fetchCustomerId();
  }, []); // The empty dependency array means this effect runs once on component mount

  //   console.log(" stripeCostumerId in payment is ****", customerId);
  //   console.log("stripePromise in Payment is: ", stripePromise);

  return stripePromise ? (
    <Elements stripe={stripePromise}>
      <OneTimeCheckout
        visitFeeAmount={visitFeeAmount}
        customerId={customerId}
        practitionerId={practitionerId}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        oldAppointmentId={oldAppointmentId}
      />
    </Elements>
  ) : (
    <div>Loading...</div>
  );
};

export default OneTimePayment;
