import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Card, Button, Container, Row, Col, Modal } from "react-bootstrap";
import { db, auth } from "../../config/Firebase";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import MedicinaHerbs from "./MedicinaHerbs";
import RelevantRemedies from "./RelevantRemedies";
import HealthCareRecommendation from "./HealthCareRecommendation";
import { useAuthState } from "react-firebase-hooks/auth";
import ReactGA from "react-ga4";

const RemedyRecommendation = () => {
  const [currentNode, setCurrentNode] = useState(null);
  const [quizEnded, setQuizEnded] = useState(false);
  const [herbs, setHerbs] = useState([]);
  const [data, setData] = useState({});
  const [userResponses, setUserResponses] = useState([]);
  const [finalReasoning, setFinalReasoning] = useState("");
  const [showRateLimitModal, setShowRateLimitModal] = useState(false);
  const [user] = useAuthState(auth);
  const location = useLocation();
  //   const fileName = location.state?.file;
  const { file: fileName, checkFor, condition } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const [recipes, setRecipes] = useState([]);

  //   console.log("**** checkedfor is for ***", checkFor);

  useEffect(() => {
    if (fileName) {
      const filePath = `/data/symptoms/${fileName}`;
      fetch(filePath)
        .then((response) => response.json())
        .then((fetchedData) => {
          setData(fetchedData);
          setCurrentNode(fetchedData["1"]);
          ReactGA.event({
            category: "Quiz",
            action: "Load Quiz Data",
            label: fileName,
          });
        })
        .catch((error) => console.error("Failed to load quiz data:", error));
    }
  }, [fileName]);

  useEffect(() => {
    const fetchRecipes = async () => {
      if (condition) {
        const q = query(
          collection(db, "recipes"),
          where("category", "array-contains", condition)
        );
        const querySnapshot = await getDocs(q);
        const recipesList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRecipes(recipesList);
      }
    };

    fetchRecipes();
  }, [condition]);

  const fetchUserProfile = async (userId) => {
    const userDocRef = doc(db, "users", userId);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists()) {
      return docSnap.data().narrative; // Assuming `narrative` is the field name
    }
    return "No profile narrative available.";
  };

  const fetchHerbDetails = async (userId, herbIds) => {
    // First, check if the rate limit has been reached.
    try {
      await checkAndUpdateApiUsage(userId, "gpt3");
    } catch (error) {
      console.error("Rate limit exceeded:", error.message);
      // Optionally, throw the error to be handled by the caller or handle it here
      throw error;
    }

    const ids = Array.isArray(herbIds) ? herbIds : [herbIds];
    // console.log("Fetching details for herb IDs:", ids);

    const fetchedHerbs = await Promise.all(
      ids.map(async (id) => {
        const herbDocRef = doc(db, "herbs", id);
        try {
          const herbDocSnap = await getDoc(herbDocRef);
          if (herbDocSnap.exists()) {
            const herbData = herbDocSnap.data();
            // console.log(`Data retrieved for herb ID ${id}:`, herbData);
            // Fetch all necessary details for display
            return {
              id: id,
              title: herbData.title,
              benefits: herbData.benefits,
              imgUrl: herbData.imgUrl, // Assuming the field name is imgUrl
              summary: herbData.summary,
              dosage: herbData.dosage, // Assuming there is a dosage field
            };
          } else {
            console.error(`No data exists for herb ID ${id}`);
            return null;
          }
        } catch (error) {
          console.error(`Error fetching data for herb ID ${id}:`, error);
          return null;
        }
      })
    );

    const validHerbs = fetchedHerbs.filter(Boolean);
    // console.log("Fetched herbs data:", fetchedHerbs);
    // console.log("Valid herbs after filtering nulls:", validHerbs);
    setHerbs(validHerbs); // Assuming setHerbs is a React state setter function from a useState hook in the component scope
    return validHerbs; // Ensure this is returned
  };

  function generateDocumentId(userId, endpointName) {
    const date = new Date().toISOString().slice(0, 10).replace(/-/g, "");
    return `${userId}_${endpointName}_${date}`;
  }

  async function checkAndUpdateApiUsage(userId, endpointName) {
    const docId = generateDocumentId(userId, endpointName); // Generate document ID
    const docRef = doc(db, "api_usage", docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (data.count > 10) {
        // Assuming the limit is 3 for demonstration
        setShowRateLimitModal(true); // Show rate limit modal
        return false; // Return false to indicate the rate limit has been exceeded
      } else {
        await updateDoc(docRef, {
          count: data.count + 1,
        });
        return true; // Return true to indicate that the API call can proceed
      }
    } else {
      // Set the document if it doesn't exist
      await setDoc(docRef, {
        userId: userId,
        endpoint: endpointName,
        date: new Date().toISOString().slice(0, 10),
        count: 1,
      });
      return true; // Return true as the count is within limits
    }
  }

  const callGenerateReasoningForSomeoneElseAPI = async (
    herbDetails,
    userResponses
  ) => {
    if (!herbDetails || !Array.isArray(herbDetails)) {
      console.error("Invalid herbDetails:", herbDetails);
      return "Invalid herb data provided";
    }

    const requestData = {
      herb_details: herbDetails.map((herb) => ({
        title: herb.title,
        benefits: herb.benefits,
      })),
      user_responses: userResponses,
    };

    // console.log("Sending data to backend:", requestData);
    // console.log(
    //   "Sending data to backend:",
    //   JSON.stringify(requestData, null, 2)
    // );

    try {
      const response = await fetch(
        // "http://localhost:8002/generate-reasoning-for-someone-else/",
        // "http://34.16.52.127:8002/generate-reasoning-for-someone-else/",//GCP
        "https://rec-api.joinsarv.com/generate-reasoning-for-someone-else/", // Use HTTPS URL
        // "http://204.48.18.82:8002/generate-reasoning-for-someone-else/", // digital ocean
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (!response.ok) {
        const errorDetail = await response.json();
        console.error("API error details:", errorDetail);
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const responseData = await response.json();
      return responseData.response;
    } catch (error) {
      console.error("Failed to call API:", error);
      throw error;
    }
  };

  const callGenerateReasoningAPI = async (
    herbDetails,
    userNarrative,
    userResponses
  ) => {
    if (!herbDetails || !Array.isArray(herbDetails)) {
      console.error("Invalid herbDetails:", herbDetails);
      return "Invalid herb data provided";
    }

    const requestData = {
      user_profile: userNarrative,
      herb_details: herbDetails.map((herb) => ({
        title: herb.title,
        benefits: herb.benefits,
      })),
      user_responses: userResponses,
    };

    // console.log("Sending data to backend:", requestData);
    // console.log(
    //   "Sending data to backend:",
    //   JSON.stringify(requestData, null, 2)
    // );

    try {
      const response = await fetch(
        // "http://localhost:8002/generate-reasoning/",
        // "http://34.16.52.127:8002/generate-reasoning/", //GCP
        // "http://204.48.18.82:8002/generate-reasoning/", //Digital Ocean
        "https://rec-api.joinsarv.com/generate-reasoning/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (!response.ok) {
        const errorDetail = await response.json();
        console.error("API error details:", errorDetail);
        throw new Error(
          `API responded with ${response.status}: ${response.statusText}`
        );
        // throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const responseData = await response.json();
      return responseData.response;
    } catch (error) {
      console.error("Failed to call API:", error);
      throw error;
    }
  };

  const handleOptionClick = async (selectedOption) => {
    ReactGA.event({
      category: "Quiz",
      action: "Select Option",
      label: selectedOption,
    });
    // Update user responses
    setUserResponses((prevResponses) => [
      ...prevResponses,
      { question: currentNode.question, answer: selectedOption },
    ]);

    const nextQuestionKey = currentNode.next[selectedOption];
    if (nextQuestionKey === "end") {
      const canProceed = await checkAndUpdateApiUsage(user.uid, "gpt3");
      if (!canProceed) {
        setIsLoading(false); // Ensure loading is off if the rate limit is exceeded
        return; // Stop further execution if rate limit is exceeded
      }

      setIsLoading(true); // Continue with the operation if rate limit check is passed
      const resultIds = currentNode.result[selectedOption];
      if (resultIds) {
        const fetchedHerbs = await fetchHerbDetails(user.uid, resultIds);
        if (fetchedHerbs && fetchedHerbs.length > 0) {
          const userNarrative = await fetchUserProfile(user.uid);
          const userResponsesFormatted = generateReasoning();
          let finalReasoning;
          if (checkFor === "someone_else") {
            finalReasoning = await callGenerateReasoningForSomeoneElseAPI(
              fetchedHerbs,
              userResponsesFormatted
            );
          } else {
            finalReasoning = await callGenerateReasoningAPI(
              fetchedHerbs,
              userNarrative,
              userResponsesFormatted
            );
          }
          setFinalReasoning(finalReasoning);
          setQuizEnded(true);
        } else {
          console.error("No herbs data fetched");
          setFinalReasoning("No detailed information on selected herbs.");
          setQuizEnded(true);
        }
      } else {
        console.error("Results identification failed.");
      }
      setIsLoading(false);
    } else {
      const nextNode = data[nextQuestionKey];
      setCurrentNode(nextNode);
    }
  };

  const generateReasoning = () => {
    let lastQuestion = "";
    const reasoning = userResponses
      .map((response, index) => {
        let transition = "";
        if (index === 0) {
          transition = "Initially,";
        } else {
          transition = "Furthermore,";
        }
        return `${transition} you mentioned "${response.answer}" when asked about "${response.question}".`;
      })
      .concat(`${lastQuestion.toLowerCase()}`)
      .join(" ");

    return reasoning;
  };

  if (!currentNode) {
    return <div>Loading...</div>;
  }

  return (
    <Container
      style={{
        width: "100%",
        minWidth: "600px",
        minHeight: "600px",
        marginTop: "100px",
      }}
    >
      <Row className="justify-content-md-center">
        <Col md={12}>
          {!quizEnded ? (
            <Card style={{ width: "100%", height: "auto", minWidth: "600px" }}>
              <Card.Body>
                <Card.Title>Question</Card.Title>
                <Card.Text>{currentNode.question}</Card.Text>
                {currentNode.options.map((option, index) => (
                  <Button
                    key={index}
                    variant="outline-primary"
                    className="availability-button"
                    onClick={() => handleOptionClick(option)}
                    style={{ margin: "10px" }}
                  >
                    {option}
                  </Button>
                ))}
              </Card.Body>
            </Card>
          ) : (
            <div style={{ paddingTop: "100px" }}>
              <h4>Your personalized medicinal herb recommendations:</h4>
              <p className="howitworks-text">{finalReasoning}</p>
              <Row
                style={{ paddingTop: "25px", paddingBottom: "100px" }}
                className="align-items-start"
              >
                <Col md={9}>
                  {herbs.map((herb, index) => (
                    <MedicinaHerbs key={index} data={[herb]} />
                  ))}
                </Col>
                <Col md={3}>
                  <RelevantRemedies recipes={recipes} />
                  {/* <HealthCareRecommendation /> */}
                </Col>
              </Row>
            </div>
          )}
        </Col>
      </Row>
      <Modal show={isLoading} centered>
        <Modal.Body>
          <div className="text-center">
            <p>
              Please wait a few seconds while we are building your personalized
              recommendation...
            </p>
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showRateLimitModal}
        onHide={() => setShowRateLimitModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Rate Limit Exceeded</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You have reached your limit of API calls for today. Please try again
          tomorrow.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowRateLimitModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default RemedyRecommendation;
